import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { handleResDataLength, multiSort } from "../../../../Common/Helper";
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";


class ListNotifications extends Component
{
    private get labels() { return appContext().labels; }
    private local = appContext().localStorageProvider;

    state = {
        notificationsData: [],
        isCreateRight: false
    };
    componentDidMount()
    {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: 'Notification Setup' });
        apis.getNotification().then((res) =>
        {
            let tempNotifications: any = [];
            for (let i = 0; i < res.data.length; i++)
            {
                let notification: any = { name: "", eventType: "", shouldSendMail: "", shouldSendMobilePush: "" }

                notification = {
                    name: res.data[i].name,
                    eventType: res.data[i].eventType,
                    shouldSendMail: (res.data[i].shouldSendMail === true ? "Yes" : "No"),
                    shouldSendMobilePush: (res.data[i].shouldSendMobilePush === true ? "Yes" : "No"),
                    clickEvent: () => this.handleClick(res.data[i].id),
                }
                tempNotifications.push(notification)
            }
            this.setState({
                notificationsData: tempNotifications
            })
        }).catch((error: any) => { });
        this.getRightList();
    }

    getRightList = () =>
    {

        const res = this.local.getIbssRightList();
        if (res.ADMINPORTAL && res.ADMINPORTAL.Notifications && res.ADMINPORTAL.Notifications.indexOf("Create") > -1)
        {
            this.setState({
                isCreateRight: true
            })
        }
    }

    handleClick(params: any)
    {
        const { history }: any = this.props;
        history.push("notifications/" + params);
    }

    render()
    {
        const { notificationsData }: any = this.state;

        const data = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEventType,
                    field: "eventType",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEmailNotification,
                    field: "shouldSendMail",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelPushNotification,
                    field: "shouldSendMobilePush",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                },
            ],
            rows: notificationsData
        };

        return (
            <div className="rightPanel-main-content">
                <div className="table-panel">
                    <TablePanelInfo
                        tableHeading={this.labels.HubLabelNotifications}
                        iconSrc="Notification"
                    />
                    <div className="table-panel-content">
                        <Link to="/notifications/0">
                            {this.state.isCreateRight === false &&
                                <button className="button-primary-disable" disabled={true}>{this.labels.HubButtonAdd}</button>
                            }
                            {this.state.isCreateRight === true &&
                                <button className="button-primary">{this.labels.HubButtonAdd}</button>
                            }
                        </Link>
                    </div>
                    <MDBDataTable className="standardTable tablePageNoButtons tableNotificationSetup" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={data} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(ListNotifications);
function params(params: any): void
{
    throw new Error("Function not implemented.");
}

