import { Component } from 'react';
import { appContext } from '../../../AppContext';
import IbssDialog from '../BaseDialog/IbssDialog';
import IbssButton from '../../Buttons/Button/IbssButton';
import { Box, Typography } from '@mui/material';

class CancelBookingsDialog extends Component<IProps, IState>
{
    private get labels(){ return appContext().labels; }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    private async onSubmit(): Promise<void>
    {
        await this.props.updateBookings();
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="cancel booking warning modal"
                fullWidth
                maxWidth={'md'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelWarning}</label>
                    </>
                }
                dialogContent=
                {
                    <Box>
                        <Typography>{this.labels.funcBookingScheduleCancelSubtitle_L}</Typography>
                    </Box>
                }
                footer=
                {
                    <div className='d-flex w-90' style={{ justifyContent: 'center', gap: '16px'}}>
                        <IbssButton
                            variant="contained"
                            color='secondary'
                            onClick={()=> this.props.onClose()}
                        >
                            {this.labels.funcBookingScheduleCancelNo_S}
                        </IbssButton>
                        <IbssButton
                            variant={'contained'}
                            color={'error'}
                            onClick={() => this.onSubmit()}
                        >
                            {this.labels.funcBookingsYesProceed_S}
                        </IbssButton>
                    </div>
                }
            />
        )
    }
}

export default CancelBookingsDialog;

export interface IProps
{
    show: boolean;
    onClose: ()=> void;
    updateBookings: ()=> Promise<void>;
}

export interface IState
{
}