
import React, { Component } from 'react';

export default class LinkIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 17C5.58333 17 4.39583 16.5208 3.4375 15.5625C2.47917 14.6042 2 13.4167 2 12C2 10.5833 2.47917 9.39583 3.4375 8.4375C4.39583 7.47917 5.58333 7 7 7H10.5C10.7125 7 10.8906 7.07229 11.0344 7.21687C11.1781 7.36147 11.25 7.54064 11.25 7.75437C11.25 7.96812 11.1781 8.14583 11.0344 8.2875C10.8906 8.42917 10.7125 8.5 10.5 8.5H7C6 8.5 5.16667 8.83333 4.5 9.5C3.83333 10.1667 3.5 11 3.5 12C3.5 13 3.83333 13.8333 4.5 14.5C5.16667 15.1667 6 15.5 7 15.5H10.5C10.7125 15.5 10.8906 15.5723 11.0344 15.7169C11.1781 15.8615 11.25 16.0406 11.25 16.2544C11.25 16.4681 11.1781 16.6458 11.0344 16.7875C10.8906 16.9292 10.7125 17 10.5 17H7ZM8.875 12.75C8.6625 12.75 8.48438 12.6777 8.34063 12.5331C8.19688 12.3885 8.125 12.2094 8.125 11.9956C8.125 11.7819 8.19688 11.6042 8.34063 11.4625C8.48438 11.3208 8.6625 11.25 8.875 11.25H15.125C15.3375 11.25 15.5156 11.3223 15.6594 11.4669C15.8031 11.6115 15.875 11.7906 15.875 12.0044C15.875 12.2181 15.8031 12.3958 15.6594 12.5375C15.5156 12.6792 15.3375 12.75 15.125 12.75H8.875ZM13.5 17C13.2875 17 13.1094 16.9277 12.9656 16.7831C12.8219 16.6385 12.75 16.4594 12.75 16.2456C12.75 16.0319 12.8219 15.8542 12.9656 15.7125C13.1094 15.5708 13.2875 15.5 13.5 15.5H17C18 15.5 18.8333 15.1667 19.5 14.5C20.1667 13.8333 20.5 13 20.5 12C20.5 11 20.1667 10.1667 19.5 9.5C18.8333 8.83333 18 8.5 17 8.5H13.5C13.2875 8.5 13.1094 8.42771 12.9656 8.28313C12.8219 8.13853 12.75 7.95936 12.75 7.74563C12.75 7.53188 12.8219 7.35417 12.9656 7.2125C13.1094 7.07083 13.2875 7 13.5 7H17C18.4167 7 19.6042 7.47917 20.5625 8.4375C21.5208 9.39583 22 10.5833 22 12C22 13.4167 21.5208 14.6042 20.5625 15.5625C19.6042 16.5208 18.4167 17 17 17H13.5Z" fill="#191C1D"/>
            </svg>

        )
    }
};
