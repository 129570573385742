import { IGetRestrictionsEndpoint } from "./GetRestrictionsEndpoint";
import { IGetCateringRestrictions } from "../CateringRestrictions/CateringRestrictionsRepository";
import { ICreateCateringRestrictionsPayload, ICreateEndpoint } from "./CreateEndpoint";
import { IUpdateCateringRestrictionsPayload, IUpdateEndpoint } from "./UpdateEndpoint";

export class CateringItemRestrictionRepository implements ICateringItemRestrictionRepository
{
    private createEndpoint: ICreateEndpoint;
    private updateEndpoint: IUpdateEndpoint;
    private getRestrictionsEndpoint: IGetRestrictionsEndpoint;

    constructor(
        createEndpoint: ICreateEndpoint,
        updateEndpoint: IUpdateEndpoint,
        getRestrictionsEndpoint: IGetRestrictionsEndpoint,
    )
    {
        this.createEndpoint = createEndpoint;
        this.updateEndpoint = updateEndpoint
        this.getRestrictionsEndpoint = getRestrictionsEndpoint;
    }


    public create(buildingId: number,cateringMenuItemId:string, payload: ICreateCateringRestrictionsPayload) : Promise<void>
    {
        return this.createEndpoint.execute(buildingId,cateringMenuItemId,payload);
    }

    public update(buildingId: number,cateringMenuItemId:string, payload: IUpdateCateringRestrictionsPayload) : Promise<void>
    {
        return this.updateEndpoint.execute(buildingId,cateringMenuItemId,payload);
    }

    
    public getRestrictions(buildingId: number, cateringMenuItemId: string) : Promise<IGetCateringRestrictions>
    {
        return this.getRestrictionsEndpoint.execute(buildingId,cateringMenuItemId);
    }
}

export interface ICateringItemRestrictionRepository
{
    create(buildingId: number, cateringMenuItemId:string, payload: ICreateCateringRestrictionsPayload): Promise<void>;
    update(buildingId: number, cateringMenuItemId:string, payload: IUpdateCateringRestrictionsPayload): Promise<void>;
    getRestrictions(buildingId: number,cateringMenuItemId:string) : Promise<IGetCateringRestrictions>;
}

