import { appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";
import Helper from "../../../../Common/Helper";

export class SearchSpacesHelper
{
    public static buildQuery(buildingId?: number): string
    {
        const localStorageProvider = appContext().localStorageProvider;
        const userPreferences = localStorageProvider.getUserPreferences();

        const buildingIdOrDefault = buildingId ?? userPreferences.SearchPrefs.DefaultBuilding;
        const floorId = userPreferences.Nodes.find(i => i.NodeId === buildingIdOrDefault)?.DefaultFloor ?? null;
        const viewAsString = userPreferences.SearchPrefs.DefaultSearchResults?.toLocaleLowerCase();
        const view = (viewAsString == 'list' ? 'list' : (viewAsString == 'map' ? 'map' : null));
        let start = DateHelper.null();
        let end = DateHelper.null();

        if (buildingIdOrDefault)
        {
            const rootNode = localStorageProvider.getNodeData();
            const config = appContext().config;
            const buildingConfig = config.getBuildingConfig(rootNode, buildingIdOrDefault);
            const defaultTimes = config.getDefaultTimes(buildingConfig, userPreferences.WorkingHoursPrefs, false, true);
            start = defaultTimes.start;
            end = defaultTimes.end;
        }

        const params = new Map<string, string>();
        params.set('building', buildingIdOrDefault?.toString() ?? '');
        params.set('floor', floorId?.toString() ?? '');
        params.set('view', view ?? '');
        params.set('date', start.isNull() ? '' : start.toUrlDate());
        params.set('start', start.isNull() ? '' : start.toUrlTime());
        params.set('end', end.isNull() ? '' : end.toUrlTime());

        const query = Array.from(params).filter(i => !!i[1]).map(i => i.join('=')).join('&');
        return query;
    }
}