import { DateTime } from "luxon";
import { TypeHelper } from "../Common/TypeHelper";
import { VisitStatus } from "./Visits/VisitsRepository";
import { ODataSelect } from "./ODataQuery";

export interface IRawPagedResponse<TValue>
{
    "@odata.nextLink": (string | null);
    Value: TValue;
}

export interface IRawPagedResponse2<TValue>
{
    httpStatusCode: number;
    nextToken: string;
    results: TValue;
}

export interface IRawPagedResponse3<TValue>
{
    "@odata.nextLink": (string | null);
    value: TValue;
}

export interface IRawPagedResponseBookingSeries<TValue>
{
    "OdataNextLink": (string | null);
    Booking: TValue;
}

export class PagedResponse<TValue>
{
    public skipToken: string;
    public value: TValue;

    constructor(data: { skipToken: string,  value: TValue })
    {
        this.skipToken = data.skipToken;
        this.value = data.value;
    }

    public static fromRawResponse<TValue>(rawResponse: IRawPagedResponse<TValue>)
    {
        const nextLink = rawResponse["@odata.nextLink"];
        const skipToken = PagedResponse.fromNextLinkToSkipToken(nextLink);

        const pagedResponse = new PagedResponse<TValue>(
        {
            skipToken: skipToken,
            value: rawResponse.Value,
        });
        return pagedResponse;
    }

    public static fromRawResponse2<TValue>(rawResponse: IRawPagedResponse2<TValue>)
    {
        const pagedResponse = new PagedResponse<TValue>(
        {
            skipToken: rawResponse.nextToken,
            value: rawResponse.results,
        });
        return pagedResponse;
    }
    
    public static fromRawResponse3<TValue>(rawResponse: IRawPagedResponse3<TValue>)
    {
        const nextLink = rawResponse["@odata.nextLink"];
        const skipToken = PagedResponse.fromNextLinkToSkipToken(nextLink);

        const pagedResponse = new PagedResponse<TValue>(
        {
            skipToken: skipToken,
            value: rawResponse.value,
        });
        return pagedResponse;
    }

    public static fromRawResponseBookingSeries<TValue>(rawResponse: IRawPagedResponseBookingSeries<TValue>)
    {
        const nextLink = rawResponse["OdataNextLink"];
        const skipToken = PagedResponse.fromNextLinkToSkipToken(nextLink);

        const pagedResponse = new PagedResponse<TValue>(
        {
            skipToken: skipToken,
            value: rawResponse.Booking,
        });
        return pagedResponse;
    }

    private static fromNextLinkToSkipToken(nextLink: string | null): string
    {
        if (!nextLink)
        {
            return '';
        }
       
        const tokenExpression = /\$skiptoken=([a-f0-9-]+)/i;
        const tokenMatch = nextLink.match(tokenExpression);
        const skipToken = (tokenMatch && tokenMatch[1] ? tokenMatch[1] : "");
        return skipToken;
    }
}

export interface IRootNode
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsSearchable: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Building_Address: string;
    Building_City: string;
    Building_Country: string;
    Building_Longitude: number;
    Building_Latitude: number;
    Building_Operator: string;
    Building_Owner: string;
    Node_Search_Config: string;
    Regions: IRegion[];
}

export interface IRegion
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsSearchable: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Buildings: INode[];
}

export interface INode
{
    Node_Id: number;
    Name: string;
    Address: string;
    City: string;
    Region: string;
    Node_IsSearchable: number;
    Country: string;
    Longitude: number;
    Latitude: number;
    Capacity: number;
    Zones: string[];
    SearchConfig: ISearchConfig;
    Building_Operator: string;
    Building_Owner: string;
    Node_FloorCount: number;
    Node_SpaceCount: number;
    Building_Occupier: string;
    Floor_Occupier: string;
    Floor_MapURI: string;
    Bkng_Early_Chkin_Thld: number;
    Vis_Arvl_Loc: string;
    Floors: IFloor[];
    Occ_Wkng_Hrs_Stt: string;
    Occ_Wkng_Hrs_Stp: string;
    Occ_Wkng_Days_Stt: string;
    Occ_Wkng_Days_Stp: string;
    Occ_Space_Rsrv_Time: string;
    Task_Due_Amber_Thld: string;
    Bkng_Auto_Cancel_Thld: number;
    Building_ImageURI: string;
    Bkng_Room_Red_Thld: number;
    Bkng_Room_Amber_Thld: number;
    Building_Roamer_Menu: number;
    Vis_Arvl_Wndw_Mins: number;
    Uty_Page_Delay: number;
    Insp_VM_Page_Delay: number;
    Insp_LV_Page_Delay: number;
    Insp_SV_Page_Delay: number;
    Insp_FV_Page_Delay: number;
    Env_Temp_Hi: number;
    Env_Temp_Lo: number;
    Building_TZ: string;
    Building_TZ_DST: number;
    BuildingC19Mode: number;
    Env_Temp_Hi_SP: number;
    Env_Temp_Lo_SP: number;
    Pre_Book_Cat_Mins: number;
    Occ_Working_Hrs_Mode: number;
    Occ_Office_hrs_stt: string;
    Occ_Office_hrs_stp: string;
    Occ_Verify_Loc: number;
    Node_IsDefault: number;
    Cat_Crncy: string;
    Pre_Book_Cat_Time: string;
    Cat_Use_List: number;
    Bkng_Allowed_Starts: string;
    Vis_Req_Approval: number;
}

export interface IFloor
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_FloorCount: number;
    Node_SpaceCount: number;
    Floor_Occupier: string;
    Floor_MapURI: string;
    Zones: (IZone[] | null);
}

export interface IZone
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_SpaceCount: number;
    Meta_Loc_Zone: string;
}

export interface ISearchConfig
{
    Config: ISearchConfigConfig;
    Map: ISearchConfigMap;
    Workspaces: IWorkspaces;
    Amenities: IAmenities;
    CapitalPlantEquipment: ICapitalPlantEquipment;
    WorkplaceEquipment: IWorkplaceEquipment;
    Colleagues: ISearchConfigItem;
}

export interface ISearchConfigConfig
{
    PageWhenDefaultTime: string;
    PageWhenMinDuration: number;
    PageWhenStartTime: string;
    PageWhenEndTime: string;
    ShowPageExtra: number;
    PageExtraShowTemperature: number;
    PageExtraShowNoise: number;
    PageExtraShowCatering: number;
    PageExtraShowAV: number;
    PageExtraShowHearingAid: number;
    PageExtraShowPresentationAid: number;
}

export interface ISearchConfigMap
{
    MapTimeMode: string;
    MapEndTimeOffset: number;
    MapDefaultType: string;
}

export interface IWorkspaces
{
    WORKTYPES: ISearchConfigItem[];
    WORKSPACES: ISearchConfigItem[];
    "SUPPORT SPACES": ISearchConfigItem[];
}

export interface IAmenities
{
    AMENITIES: ISearchConfigItem[];
}

export interface ICapitalPlantEquipment
{
    CAPITALPLANT: ISearchConfigItem[];
}

export interface IWorkplaceEquipment
{
    WORKPLACE: ISearchConfigItem[];
}

export interface ISearchConfigItem
{
    Name: string;
    Icon: string;
    Label: string;
    Config: ISearchConfigItemConfig;
}

export interface ISearchConfigItemConfig
{
    PageExtraShowCapacity: number;
    PageWhenDefaultTime: string;
    PageWhenMinDuration: number;
    PageWhenStartTime: string;
    PageWhenEndTime: string;
    ShowPageExtra: number;
    PageExtraShowTemperature: number;
    PageExtraShowNoise: number;
    PageExtraShowCatering: number;
    PageExtraShowAV: number;
    PageExtraShowHearingAid: number;
    PageExtraShowPresentationAid: number;
    ShowAddRecurrence: number;
    ShowLinkedSpaces: number;
    ShowBookAsap: number;
    ShowAddMultiple: number;
    DefaultSpaceId: string;
    DefaultEquipId: string;
    SearchRadius: number;
    ShowOnBookingPage: number;
}

export interface IUserList
{
    displayName: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    pin: string | number | null,
    userName: string,
    oid: string | number | null,
    label?: string,
}

export interface IUpdateEquipmentRequest
{
    EquipmentType: string,
    StartDate: string,
    EndDate: string,
}

export interface IUpdateEquipmentResponse
{
    Node_Id: number;
    Equip_Id: string;
    Equip_Name: string;
    Equip_Type: string;
    Equip_Class: string;
    ImageURI: string;
    Space_Id: string;
    Booking_Resource_Id: string;
    Meta_Loc_Zone: string;
}

export interface IVisit
{
    Node_Id: number;
    Space_Id: string;
    Space_Name: string;
    Visit_End_Date: string;
    Visit_Host_Email: string;
    Visit_Host_Name: string;
    Visit_Id: string;
    Visit_Start_Date: string;
    Visit_Status: VisitStatus;
    Visitor_Company: string;
    Visitor_Email: string;
    Visitor_First_Name: string;
    Visitor_Last_Name: string;
    Visit_Pacs_Id: string;
}

export interface IEquipmentDetailsResponse
{
    Node_Id: number;
    Equip_Id: string;
    Equip_Name: string;
    Equip_Type: string;
    Equipment_Class: string;
    ImageURI: string;
    Space_Id: string;
    Booking_Resource_Id: string;
    Meta_Loc_Zone: string;
}

export interface IIdentityProviderPayload
{
    securityProviderTypeId?: number;
    clientId?: string;
    tenantDomain?: string;
    tenantId?: string;
    name?: string;
    additionalAudiences?: string; 
    nameClaimType?: string;
    maxPasswordAge?: number;
    minPasswordLength?: number;
    passwordComplexity?: number 
    status?: number;
    isDefault?: boolean;
    clientSecret?: string;
}

export interface IBookingPolicy 
{
    Node_Id: number;
    Booking_Policy_Id: string;
    Booking_Policy_Name: string;
    Booking_Policy: {
        PolicyType: string;
        BuildingId: string;
        FloorId: string;
        Allowed_TimeRange: string;
        Booking_Policy_Description: string;
        BookingSlots: 
        {
            ExcludedDates: 
            {
                StartDate: string;
                EndDate: string;
                StartTime: string;
                EndTime: string;
            }[];
        };
    };
}

export class ISpace extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Type = "";
    public Space_Type_Label = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public Space_Status = "";
    public ImageURI = "";
    public Booking_Policy_Id = "";
    public Meta_Bookable = 0;
    public Meta_Occ_Type = 0;
    public Meta_Ext_Occ_Count_Mode = 0;
    public Meta_Loc_Zone = "";
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
    public Space_Work_Type = "";
    public Space_Custom_Info = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
    public Env_Zone_Id = 0;
    public Meta_Ext_Catering_Space_Id = [];
    public Meta_Ext_Booking_System = "";
    public Space_Occupancy = 0;
}