import { ODataSelect } from "../../../../Providers.Api/ODataQuery"

export class Space extends ODataSelect
{
    Node_Id = 0;
    Space_Id = '';
    Space_Type = '';
}

export interface ISpaceData
{
    Node_Id: number;
    Space_Id: string;
    Space_Type: string;
}


export interface ITaskCategoryData
{
    task_Category_Id: string;
    task_Category_Name: string;
    space_Type: string;
}

export class TaskTypeData extends ODataSelect
{
    public Task_Type_Id = '';
    public Task_Type_Name = '';
}
