import { DateTime } from "luxon";
import { DateHelper } from "../Common/DateHelper";

export class SessionStorageProvider implements ISessionStorageProvider
{

    public getBuildingId(): number
    {
        const value = parseInt(sessionStorage["buildingId"]);
        return (isNaN(value) ? 0 : value);
    }

    public setBuildingId(value: number): void
    {
        sessionStorage["buildingId"] = value;
    }

    public removeBuildingId():void
    {
        sessionStorage.removeItem("buildingid");
    }

    public removeBuildingName(): void 
    {
        sessionStorage.removeItem("buildingName"); 
    }

    public getFlexSpaceSearchCriteria(): { startTime: DateTime; endTime: DateTime; capacity: number } 
    {
        let searchCriteria = {
            startTime: DateHelper.null(),
            endTime: DateHelper.null(),
            capacity: 0,
        };

        const valueAsString = sessionStorage.getItem("flexSpaceSearchCriteria");

        if (valueAsString) 
        {
            try 
            {
                const valueAsObject = JSON.parse(valueAsString) as { startTime: string; endTime: string; capacity?: number };
                searchCriteria = {
                    startTime: DateTime.fromISO(valueAsObject.startTime),
                    endTime: DateTime.fromISO(valueAsObject.endTime),
                    capacity: valueAsObject.capacity ?? 0,
                };
            } 
            catch (error) 
            {
            }
        }

        return searchCriteria;
    }



    public setFlexSpaceSearchCriteria(startTime: DateTime, endTime: DateTime, capacity: number): void 
    {
        const searchCriteria = {
            startTime,
            endTime,
            capacity,
        };

        sessionStorage["flexSpaceSearchCriteria"] = JSON.stringify(searchCriteria);
    }


    public getOnelensBookingSearchCriteria(): IOnelensBookingFilter
    {
        const searchCriteria = { startTime: DateHelper.null(), endTime: DateHelper.null(), filterId: '' };
        const valueAsString = sessionStorage["onelensBookingSearchCriteria"] as (string | null);

        if (valueAsString != null)
        {
            const valueAsObject = JSON.parse(valueAsString) as IOnelensBookingStringFilter;
            searchCriteria.startTime = DateTime.fromISO(valueAsObject.startTime);
            searchCriteria.endTime = DateTime.fromISO(valueAsObject.endTime);
            searchCriteria.filterId = valueAsObject.filterId;
        }
        return searchCriteria;
    }

    public setOnelensBookingSearchCriteria( startTime: DateTime, endTime : DateTime, filterId: string): void
    {
        sessionStorage["onelensBookingSearchCriteria"] = JSON.stringify({ startTime: startTime, endTime: endTime, filterId: filterId });
    }

    public getSpaceAnalyticsCriteria(): ISpaceAnalyticsFilter
    {
        let searchCriteria: ISpaceAnalyticsFilter = { startDate: DateHelper.null(), endDate: DateHelper.null(), spaceId: "", periodType:  1};
        let valueAsString = sessionStorage["spaceAnalyticsCriteria"] as (string | null);

        if (valueAsString != null)
        {
            const valueAsObject = JSON.parse(valueAsString) as ISpaceAnalyticsFilter;
            searchCriteria.startDate = DateHelper.fromIsoOrDateTime(valueAsObject.startDate);
            searchCriteria.endDate = DateHelper.fromIsoOrDateTime(valueAsObject.endDate);
            searchCriteria.spaceId = valueAsObject.spaceId;
            searchCriteria.periodType = valueAsObject.periodType;

        }
        return searchCriteria;
    }

    public setSpaceAnalyticsCriteria(filter : ISpaceAnalyticsFilter): void
    {
        sessionStorage["spaceAnalyticsCriteria"] = JSON.stringify(filter);
    }
    

    public clear(): void
    {
        sessionStorage.clear();
    }
}

export interface ISessionStorageProvider
{
    getBuildingId(): number;
    setBuildingId(value: number): void;
    removeBuildingId(): void;
    removeBuildingName(): void;
    clear(): void;
    getFlexSpaceSearchCriteria(): { startTime: DateTime, endTime: DateTime, capacity: number };
    setFlexSpaceSearchCriteria(startTime: DateTime, endTime: DateTime, capacity: number): void;
    getOnelensBookingSearchCriteria(): { startTime: DateTime, endTime: DateTime, filterId: string };
    setOnelensBookingSearchCriteria(startTime: DateTime, endTime : DateTime, filterId: string): void
    setSpaceAnalyticsCriteria(filter : ISpaceAnalyticsFilter): void;
    getSpaceAnalyticsCriteria(): ISpaceAnalyticsFilter;
}

export interface ISpaceAnalyticsFilter
{
    startDate: DateTime,
    endDate : DateTime,
    spaceId: string,
    periodType?: number,
}

export interface IOnelensBookingFilter
{ 
    startTime: DateTime, 
    endTime: DateTime, 
    filterId: string, 
}

export interface IOnelensBookingStringFilter
{
    startTime: string, 
    endTime: string, 
    filterId: string, 
}