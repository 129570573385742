import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public period?: string;
    public start?: DateTime;
    public end?: DateTime;
    public status?: string;
    public host?: string;
    public filter?: string;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'start':
            case 'end':
                return this.serialiseDate(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue) || null;
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'start':
            case 'end':
                return this.deserialiseDate(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
