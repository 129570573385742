import { appContext } from '../../../../AppContext';
import { IFloor } from '../../../../Providers.Api/Models';
import { Box, Grid, Link, Paper, SelectChangeEvent, Typography } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import { DateHelper } from '../../../../Common/DateHelper';
import { DateTime } from 'luxon';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
import { BuildingBookingPolicy, FloorZones, IBookingPolicy } from './DataModels';
import { TimeValidationHelper } from './TimeValidationHelper';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import { IZone } from './SearchSpaces';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import SearchResultIcon from '../../../../Components/Icons/SearchResultIcon';
import CloseIcon from '../../../../Components/Icons/CloseIcon';

class SearchSpaceFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private bookingSlotHelper = new BookingSlotHelper();
    private minCapacityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i => ({ label: (i == 15 ? '15+' : i.toString()), value: i }))
    private get startTimeOfDay() { return this.props.startTimeOfDay.set({ year: this.props.date.year, month: this.props.date.month, day: this.props.date.day }); }
    private get endTimeOfDay() { return this.props.endTimeOfDay.set({ year: this.props.date.year, month: this.props.date.month, day: this.props.date.day }); }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            openDrawer: false,
            lightModeTheme: appContext().state.lightModeTheme,
            startTimeMessage: "",
            endTimeMessage: "",
            buildingOptions: [],
            floorOptions: [],
            zoneOptions: [],
            workTypeOptions: [],
            spaceTypeOptions: [],
            bookingPolicies: [],
        };
    }

    private defaultWorkType(buildingId: number): string
    {
        const workTypes = Helper.getWorkSpaceTypesByNodeId(buildingId);
        const formalMeeting = workTypes.find(i => i.Name == 'FormalMeeting')?.Name;
        return (formalMeeting ?? workTypes[0]?.Name ?? 'Any');
    }

    private defaultSpaceType(buildingId: number): string
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(buildingId);
        const desk = spaceTypes.result.find(i => i.Name == 'Desk')?.Name;
        return (desk ?? spaceTypes.result[0]?.Name ?? 'Any');
    }

    public async componentDidMount(): Promise<void>
    {
        appContext().state.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
        this.loadBuildings();
        this.loadFloors(this.props.building, this.props.floor, true);
        this.loadZones(this.props.floor, true);
        this.loadWorkTypes(this.props.building, this.props.workType, true);
        this.loadSpaceTypes(this.props.building, this.props.spaceType, true);
        this.loadBookingPolicies(this.props.building)
    }

    private loadBuildings(): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options });
    }

    private loadFloors(selectedBuildingId: number, selectedFloor: string, componentIsMounting: boolean): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ floorOptions: options });
        this.props.onPropsChange({ floor: selectedFloor }, componentIsMounting);
    }

    private setZoneToAny(componentIsMounting: boolean): void
    {
        this.props.onPropsChange({ zone: { name: 'Any', id: 'Any'} }, componentIsMounting);
    }

    private async loadZones(selectedFloor: string, componentIsMounting: boolean): Promise<void>
    {
        if (selectedFloor === "Any")
        {
            this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: "Any" }] });
            this.props.onPropsChange({ zone: { name: 'Any', id: 'Any'} }, componentIsMounting);
            return;
        }

        const zonesResponse = await appContext().ibssApiClientV1.v1.byNodeid.spaceZones.get<FloorZones[]>({
            nodeId: parseInt(selectedFloor),
            select: FloorZones
        })
        const options = zonesResponse.map(i => ({ label: i.Meta_Loc_Zone, value: i.Space_Zone_Id.toString() }));
        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });

        this.setState({ zoneOptions: options });
    }
    private loadWorkTypes(selectedBuildingId: number, selectedWorkSpace: string, componentIsMounting: boolean): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ workTypeOptions: options });
        this.props.onPropsChange({ workType: selectedWorkSpace }, componentIsMounting);
    }

    private loadSpaceTypes(selectedBuildingId: number, selectedSpaceType: string, componentIsMounting: boolean): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ spaceTypeOptions: options });
        this.props.onPropsChange({ spaceType: selectedSpaceType }, componentIsMounting);
    }

    private async loadBookingPolicies( buildingId: number): Promise<void>
    {
        const policies = await appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>({
            nodeId: buildingId,
            top: 200,
            select: BuildingBookingPolicy,
        })

        if (policies && policies.length > 0) 
        {
            const policy: IBookingPolicy[] = policies.map(policy => ({
                Node_Id: policy.Node_Id,
                Booking_Policy_Id: policy.Booking_Policy_Id,
                Booking_Policy_Name: policy.Booking_Policy_Name,
                Booking_Policy: {
                    PolicyType: policy.Booking_Policy.PolicyType,
                    BuildingId: policy.Booking_Policy.BuildingId,
                    FloorId: policy.Booking_Policy.FloorId,
                    Allowed_TimeRange: policy.Booking_Policy.Allowed_TimeRange,
                    Booking_Policy_Description: policy.Booking_Policy.Booking_Policy_Description,
                    BookingSlots: {
                        ExcludedDates: policy.Booking_Policy.BookingSlots.ExcludedDates,
                    },
                },
            }));

            this.setState({ bookingPolicies: policy });
        }
    }

    private handleDateChange(date: DateTime | null): void
    {
        if (!date || !date.isValid)
        {
            return;
        }
        this.props.onPropsChange({ date: date }, false);
    }

    private handleStartTimeOfDayChange(value: DateTime | null): void
    {
        const date = this.props.date;
        const startTime = (value?.set({ year: date.year, month: date.month, day: date.day }) ?? DateHelper.null());
        const validator = new TimeValidationHelper();
        validator.validate(startTime, this.endTimeOfDay, this.props.building, this.props.date);
        this.setState({ startTimeMessage: validator.toStartTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
        this.props.onPropsChange({ startTimeOfDay: startTime }, false);
    }

    private handleEndTimeOfDayChange(value: DateTime | null): void
    {
        const date = this.props.date;
        const endTime = (value?.set({ year: date.year, month: date.month, day: date.day }) ?? DateHelper.null());
        const validator = new TimeValidationHelper();
        validator.validate(this.startTimeOfDay, endTime, this.props.building, this.props.date);
        this.setState({ startTimeMessage: validator.toStartTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
        this.props.onPropsChange({ endTimeOfDay: endTime }, false);
    }

    private async handleBuildingChange(event: SelectChangeEvent<number>): Promise<void> 
    {
        const userPreferences = this.local.getUserPreferences();
        const selectedBuildingId = event.target.value as number;
        const defaultFloor = userPreferences.Nodes.find(building => building.NodeId === selectedBuildingId)?.DefaultFloor?.toString() ?? "Any";
        const defaultSpaceType = this.defaultSpaceType(selectedBuildingId);       
        const startTime= DateHelper.now(selectedBuildingId)
        const endTime = DateHelper.now(selectedBuildingId).endOf('day');
        
        this.loadFloors(selectedBuildingId, defaultFloor, false);
        this.loadZones(defaultFloor, false);
        this.setZoneToAny(false);
        this.loadWorkTypes(selectedBuildingId, "Any", false);
        this.loadSpaceTypes(selectedBuildingId, defaultSpaceType, false);
        this.loadBookingPolicies(selectedBuildingId);

        this.props.onPropsChange({ startTimeOfDay: startTime, endTimeOfDay: endTime, date: startTime, building: selectedBuildingId, zone: { name: 'Any', id: 'Any'}}, false);        
    }

    private handleFloorChange(event: SelectChangeEvent): void
    {
        this.loadZones(event.target.value, false);
        this.setZoneToAny(false);
        this.props.onPropsChange({ floor: event.target.value }, false);
    }

    private handleZoneChange(event: SelectChangeEvent): void
    {
        const zoneName = this.state.zoneOptions.find(zone => zone.value === event.target.value)?.label || '';
        const zone = { name: zoneName, id: event.target.value };
        this.props.onPropsChange({ zone: zone} , false);
    }

    private handleWorkTypeChange(event: SelectChangeEvent): void
    {
        let spaceType = (event.target.value == "Any" ? this.defaultSpaceType(this.props.building) : "Any");
        this.props.onPropsChange({ workType: event.target.value, spaceType: spaceType }, false);
    }

    private handleSpaceTypeChange(event: SelectChangeEvent): void
    {
        let workSpaceType = (event.target.value == "Any" ? this.defaultWorkType(this.props.building) : "Any");
        this.props.onPropsChange({ spaceType: event.target.value, workType: workSpaceType }, false);
    }

    private handleLinkedSpaceChange(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.props.onPropsChange({
            linkedSpace: e.target.checked,
            layouts: (e.target.checked ? true : undefined),
        }, false);
    }

    private async handleUpdate(): Promise<void>
    {
        const SearchFilter: ISearchFilterResult =
        {
            startTimeOfDay: this.startTimeOfDay,
            endTimeOfDay: this.endTimeOfDay,
            date: this.props.date,
            building: this.props.building,
            floor: this.props.floor === "Any" ? null : this.props.floor,
            workType: this.props.workType === "Any" ? null : this.props.workType,
            spaceType: this.props.spaceType === "Any" ? null : this.props.spaceType,
            zone: this.props.zone.id === "Any" ? null : this.props.zone,
            minCapacity: this.props.minCapacity,
            audioVisual: this.props.audioVisual,
            presentationAids: this.props.presentationAids,
            hearingAids: this.props.hearingAids,
            catering: this.props.catering,
            linkedSpace: this.props.linkedSpace,
            layouts: this.props.layouts,
        }
        await this.props.onUpdate(SearchFilter)
        this.setState({ openDrawer: false });
    }

    private async handleClear(): Promise<void>
    {
        this.props.onPropsChange({
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
        }, false);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true} >
                            <IbssTimePicker
                                label={this.labels.HubLabelFrom}
                                value={this.startTimeOfDay}
                                onChange={time => this.handleStartTimeOfDayChange(time)}
                                minTime={this.props.date.hasSame(DateHelper.now(this.props.building), 'day') ? DateHelper.now(this.props.building) : undefined}
                                slotProps={{ textField: { error: this.state.startTimeMessage.length > 0, helperText: this.state.startTimeMessage } }}
                                minutesStep={1}
                                ampm={false}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true} >
                            <IbssTimePicker
                                label={this.labels.HubLabelTo}
                                value={this.endTimeOfDay}
                                onChange={time => this.handleEndTimeOfDayChange(time)}
                                minTime={this.startTimeOfDay.isValid ? this.startTimeOfDay : DateHelper.now(this.props.building)}
                                slotProps={{ textField: { error: this.state.endTimeMessage.length > 0, helperText: this.state.endTimeMessage } }}
                                minutesStep={1}
                                ampm={false}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true} >
                            <IbssDatePicker
                                label={this.labels.HubLabelDate}
                                minDate={DateHelper.now(this.props.building)}
                                value={this.props.date}
                                onChange={event => this.handleDateChange(event)}
                                shouldDisableDate={(date) => this.bookingSlotHelper.disableExcludedDates(date, this.state.bookingPolicies, parseInt(this.props.floor) ?? 0, this.props.building)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={6.6}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <IbssButton sx={{ whiteSpace: 'nowrap' }} variant="contained" color="secondary" size='large' onClick={() => this.setState({ openDrawer: true })}> {this.labels.funcAdditionalCriteria_S} </IbssButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="buildingSelection"
                                inputLabel={this.labels.HubLabelBuilding}
                                fullWidth={true}
                                options={this.state.buildingOptions}
                                value={this.props.building}
                                onChange={(e: SelectChangeEvent<number>) => this.handleBuildingChange(e)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="floorSelection"
                                inputLabel={this.labels.HubLabelFloor}
                                fullWidth={true}
                                options={this.state.floorOptions}
                                value={this.props.floor}
                                onChange={(e: SelectChangeEvent) => this.handleFloorChange(e)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="zoneSelection"
                                inputLabel={this.labels.HubLabelZone}
                                fullWidth={true}
                                options={this.state.zoneOptions}
                                value={this.props.zone.id}
                                onChange={(e: SelectChangeEvent) => this.handleZoneChange(e)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="workTypeSelection"
                                inputLabel={this.labels.HubLabelworkType}
                                fullWidth={true}
                                options={this.state.workTypeOptions}
                                value={this.props.workType}
                                onChange={(e: SelectChangeEvent) => this.handleWorkTypeChange(e)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.8}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="spaceTypeSelection"
                                inputLabel={this.labels.HubLabelSpaceType}
                                fullWidth={true}
                                options={this.state.spaceTypeOptions}
                                value={this.props.spaceType}
                                onChange={(e: SelectChangeEvent) => this.handleSpaceTypeChange(e)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <IbssFormControl fullWidth={true}>
                            <IbssInputDropDown
                                id="capacitySelection"
                                inputLabel={this.labels.HubLabelCapacity}
                                fullWidth={true}
                                options={this.minCapacityOptions}
                                value={this.props.minCapacity}
                                onChange={(e: SelectChangeEvent<number>) => this.props.onPropsChange({ minCapacity: e.target.value as number }, false)}
                            />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <IbssButton sx={{ whiteSpace: 'nowrap' }} variant="contained" size='large' color="primary" onClick={() => this.handleUpdate()} disabled={this.state.endTimeMessage.length > 0 || this.state.startTimeMessage.length > 0}>
                                <IbssSvgIcon>
                                    <SearchResultIcon />
                                </IbssSvgIcon> 
                                {this.labels.HubLabelMySearchLabel}
                            </IbssButton>
                        </Box>
                    </Grid>
                </Grid>

                <IbssDrawer open={this.state.openDrawer} onClose={() => this.setState({ openDrawer: false })} anchor='right' className='flex-search-filter-criteria' style={{ backgroundColor: "var(--ui-background-alternate)" }}>
                    <Box className="flexMySearch-filter-criteria">
                        <Box className="flexMySearch-filter-criteria-header">
                            <Box className="flexMySearch-filter-criteria-icon" component="span"><img className="flexMySearch-filter-criteria-img " src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Filter-2.svg`} alt="icon" /></Box>
                            <Box className="flexMySearch-filter-criteria-title" component="span">{this.labels.funcAdditionalSearchCriteria_S}</Box>
                            <Box className="flexMySearch-filter-criteria-close" component="span" onClick={() => this.setState({ openDrawer: false })}><IbssSvgIcon><CloseIcon /></IbssSvgIcon></Box>
                        </Box>
                        <Box className="flexMySearch-filter-criteria-content">
                            <fieldset>
                                <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel">
                                    {this.labels.HubLabelSpaceFeatures}
                                </Typography>
                                <Box sx={{ paddingTop: '20px' }}>
                                    <Paper elevation={0} sx={{paddingTop:'16px',backgroundColor:'inherit'}}>
                                        <IbssSwitchLabel
                                            id="need-AV"
                                            label={
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedAudioVisual}
                                                </Typography>
                                            }
                                            checked={this.props.audioVisual}
                                            onChange={e => this.props.onPropsChange({ audioVisual: e.target.checked }, false)}
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel
                                            id="need-presentation-aids"
                                            checked={this.props.presentationAids}
                                            onChange={e => this.props.onPropsChange({ presentationAids: e.target.checked }, false)}
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                        {this.labels.HubLabelNeedPresentationAids}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedPresentationAids_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            }
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel
                                            id="need-hearing-aids"
                                            checked={this.props.hearingAids}
                                            onChange={e => this.props.onPropsChange({ hearingAids: e.target.checked }, false)}
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                        {this.labels.HubLabelNeedHearingAids}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedHearingAids_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            }
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel
                                            id="need-catering"
                                            checked={this.props.catering}
                                            onChange={e => this.props.onPropsChange({ catering: e.target.checked }, false)}
                                            label={
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedCatering}
                                                </Typography>
                                            }
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel
                                            id="need-linked-space"
                                            checked={this.props.linkedSpace}
                                            onChange={e => this.handleLinkedSpaceChange(e)}
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                        {this.labels.HubLabelNeedLinkedSpace}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedLinkedSpace_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            }
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel
                                            id="need-layouts"
                                            checked={this.props.layouts}
                                            onChange={e => this.props.onPropsChange({ layouts: e.target.checked }, false)}
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                        {this.labels.HubLabelNeedLayouts}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedLayouts_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            }
                                            labelPlacement={LabelPlacementType.start}
                                        />
                                        <Box className="flexMySearch-filter-criteria-border" />
                                    </Paper>
                                </Box>
                            </fieldset>
                            <Box className="right-space-box-cont">
                                <Box className="d-flex justify-content-center">
                                    <IbssButton variant="contained" color="primary" onClick={() => this.handleUpdate()}>{this.labels.HubButtonUpdate}</IbssButton>
                                </Box>
                                <Box className="d-flex justify-content-center">
                                    <Link color="secondary" sx={{ cursor: 'pointer' }} mt={2} onClick={() => this.handleClear()}>{this.labels.HubLabelClearSelections}</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </IbssDrawer>
            </>
        )
    }
}

export default SearchSpaceFilter;

export interface IProps
{
    date: DateTime;
    startTimeOfDay: DateTime;
    endTimeOfDay: DateTime;

    building: number;
    floor: string;
    zone: IZone;
    workType: string;
    spaceType: string;
    minCapacity: number;

    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;

    onOpen: () => void;
    onUpdate: (result: ISearchFilterResult) => Promise<void>;
    onPropsChange: (props: Partial<IProps>, componentIsMounting: boolean) => void;
}

export interface IState
{
    lightModeTheme: boolean;
    openDrawer: boolean;

    startTimeMessage: string;
    endTimeMessage: string;

    buildingOptions: Array<IListOption<number>>;
    floorOptions: Array<IListOption<string>>;
    zoneOptions: Array<IListOption<string>>;
    workTypeOptions: Array<IListOption<string>>;
    spaceTypeOptions: Array<IListOption<string>>;
    bookingPolicies: IBookingPolicy[];
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface ISearchFilterResult
{
    date: DateTime;
    startTimeOfDay: DateTime;
    endTimeOfDay: DateTime;
    building: number;
    floor: (string | null);
    zone: (IZone | null);
    workType: (string | null);
    spaceType: (string | null);
    minCapacity: number;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
}