import * as React from 'react';
import Switch from './IbssSwitch';
import { FormControlLabel } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

export enum LabelPlacementType {
    top = "top",
    start = "start",
    bottom = "bottom",
    end = "end"
}

type IbssFormControlLabelProps = FormControlLabelProps & {
    // custom props created to allow us to style the component via passing in props.  
    name?: string,
 };

const StyledFormControlLabel = styled(FormControlLabel, {shouldForwardProp:(propName: PropertyKey) => {
    return !['name'].includes(propName as string)}})<IbssFormControlLabelProps>(() => ({}));

class IbssFormControlLabel extends React.Component<IbssFormControlLabelProps>{
    render(){
        return <StyledFormControlLabel {...this.props}/>
    }
}

type IbssSwitchLabelProps = {
    // FormControlLabel
    //control: Element, // A control element. For instance, it can be a Radio, a Switch or a Checkbox.
    checked?: boolean, // If true, the component appears selected.
    disabled?: boolean, // If true, the control is disabled.
    disableTypography?: boolean, //	If true, the label is rendered as it is passed without an additional typography node.
    labelClasses?: Record<string, unknown>, // Override or extend the styles applied to the component. 
    label?: JSX.Element | string, //A text or an element to be used in an enclosing label element.
    labelPlacement?: LabelPlacementType, // where to place label relative to switch.
    // Switch
    id?: string,
    required?: boolean,
    name?: string,
    defaultChecked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    switchDisabled?: boolean;
}

class IbssSwitchLabel extends React.Component<IbssSwitchLabelProps> {
    render(): JSX.Element {
        return <IbssFormControlLabel 
            checked={this.props?.checked}    
            disabled={this.props?.disabled}
            disableTypography={this.props?.disableTypography}
            classes={this.props?.labelClasses} 
            label={this.props?.label}
            labelPlacement={this.props?.labelPlacement}
            name={this.props?.name}
            control={
                <Switch disabled={this.props.switchDisabled} id={this.props?.id} required={this.props.required} onChange={this.props?.onChange} name={this.props?.name} defaultChecked={this.props.defaultChecked}/>
            }
            />
    }
  }

export default IbssSwitchLabel;