import React from 'react';
import * as ReactDOM from 'react-dom';
import { appContext } from '../../../../AppContext';
import { RouteComponentProps } from 'react-router-dom';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { DateTime, Interval } from 'luxon';
import { DateHelper } from '../../../../Common/DateHelper';
import { Day, Week, WorkWeek, Month, Agenda, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews, GroupModel, ResourceDetails, RenderCellEventArgs, EventRenderedArgs, PopupOpenEventArgs, SelectEventArgs } from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssIconButton from '../../../../Components/Buttons/IconButton/IbssIconButton';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayIcon from '@mui/icons-material/Today';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { Box, Grid, IconButton, SvgIcon, Typography, TextField, ToggleButton, ToggleButtonGroup, TablePagination } from '@mui/material';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import EditBookingDrawer from './EditBookingDrawer';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { PagedResponse } from '../../../../Providers.Api/Models';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { Booking, IDeleteTagToEventType, ITagToEventType, ICreateTagResponse, Filter, SpaceData, BuildingBookingPolicy, IBookableTime } from './DataModels';
import { ParentBookingRelationTypeEnum } from '../../../../Providers.Api/Bookings/GetV2BookingsEndpoint';
import IconHousing from './IconHousing';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BookingTagsDialog from './BookingTagsDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BookingsCount from './BookingsCount';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import { ReactComponent as NoSpaceScheduleViewIcon } from '../ViewSchedule/NoSpacesScheduleView.svg';
import "./ViewBookingsManagedSchedule.scss"
import SpaceInfoDialogWithCustomInfo from '../../../../Components/Dialogs/SpaceInfoDialogWithCustomInfo/SpaceInfoDialogWithCustomInfo';
import { IBatch, Pager } from '../../../../Common/Pager';
import { QueryParams } from './QueryParams';
import { StringHelper } from '../../../../Common/StringHelper';
import { Constants } from '../../../../Common/Constants';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
import { getBuildingNameUsingFloorNodeId } from '../../../../Common/Helper';
import { BookableSlotAdapter } from '../../../../Services/Spaces/BookableSpaces/BookableSlotAdapter';
import { BookableSpaces } from '../../../../Services/Spaces/BookableSpaces/BookableSpaces';
import { ExcludedDatesAdapter } from '../../../../Services/Spaces/BookableSpaces/ExcludedDatesAdapter';

export default class ViewBookingsManagedSchedule extends IbssPage<IProps, IState, QueryParams>
{
    private get apiCache() { return appContext().apiCache; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private pager = new Pager<SpaceView>();
    private scheduleRef: React.RefObject<ScheduleComponent>;
    private instance: Internationalization = new Internationalization();
    private canReadTags: boolean;
    private canReadFilters: boolean;
    private string = new StringHelper();
    private bookingSlotHelper = new BookingSlotHelper();
    private bookableSpaces = new BookableSpaces([], []);

    constructor(props: IProps)
    {
        super(props, new QueryParams());
        this.scheduleRef = React.createRef<ScheduleComponent>();
        this.canReadTags = this.local.hasRight('DATAMODEL.Tag2EventType.Read');
        this.canReadFilters = this.local.hasRight('DATAMODEL.Filters.Read');
        this.state =
        {
            bookings: [],
            bookingsFormat: 'schedule',
            buildingId: 0,
            buildingStartHrs: '06:00',
            buildingEndHrs: '23:00',
            cachedSpaces: [],
            filters: [],
            isLoading: false,
            linkedSpacesIds: [],
            selectedBooking: undefined,
            selectedFilterId: '',
            selectedSpaceId: '',
            showEditBooking: false,
            showSpaceInfoDialog: false,
            showBookingTagsDialog: false,
            slotCount: 2, // number of time slots to be allocated for the specified time interval (60 minutes) duration on schedule view
            spaces: [],
            spacePageIndex: 0,
            startTime: DateTime.now(),
            tagsToEvents: [],
            tagToCreate: {} as TagToEventTypeView,
            tagToRemove: '',
            bookingPolicies: [],
        };

        this.pager.pageSize = 10;
        this.pager.getItems = (skipToken, pageSize) => this.getPageOfSpaces(skipToken, pageSize)
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.HubLabelBookings;
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        const hasTagToCreateChanged = (prevState.tagToCreate?.tagId !== this.pendingState.tagToCreate?.tagId && this.pendingState.tagToCreate?.tagId);
        const hasTagToRemoveChanged = (prevState.tagToRemove !== this.pendingState.tagToRemove && this.pendingState.tagToRemove);

        if (hasTagToCreateChanged || hasTagToRemoveChanged)
        {
            this.loading(async () =>
            {
                if (hasTagToCreateChanged)
                {
                    await this.addBookingTag();
                    return;
                }

                if (hasTagToRemoveChanged)
                {
                    await this.removeBookingTag(this.pendingState.tagToRemove);
                    return;
                }
            });
        }
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevParams: QueryParams): Promise<void>
    {
        this.loading(async () =>
        {
            const queryParams = this.queryParams;
            const buildingId = queryParams.building ?? appContext().state.buildingId;
            this.setStateAsync({ buildingId: buildingId });
            await this.loadFilters();
            await this.loadCachedSpaces(buildingId);

            const selectedFilter = this.getFilterFromQuery(queryParams.systemFilter ?? '');
            if ((selectedFilter?.name || '') != (queryParams.systemFilter || ''))
            {
                return this.pushQueryParams({ systemFilter: selectedFilter?.name || undefined }, true);
            }
            if (!queryParams.date)
            {
                return this.pushQueryParams({ date: DateHelper.now(buildingId) }, true);
            }

            await this.setStateAsync({
                selectedFilterId: selectedFilter?.filterId || '',
                startTime: queryParams.date,
            });
            await this.loadSpacesAndBookings();
            await this.loadBookingPolicies();
        });
    }

    private getFilterFromQuery(systemFilter: string): FilterView | null
    {
        const buildingId = this.pendingState.buildingId;
        let selectedFilter: FilterView | null = null;
        const selectedFilterName = this.string.toConcatenatedLowercase(systemFilter);
        selectedFilter = this.pendingState.filters.find(i => this.string.toConcatenatedLowercase(i.name) == selectedFilterName) ?? null;

        if (!selectedFilter)
        {
            const userPreferences = appContext().localStorageProvider.getUserPreferences();
            const defaultFilterId = userPreferences.Nodes.find(building => building.NodeId == buildingId)?.DefaultFilter?.[0]?.Filter_Id;
            selectedFilter = this.pendingState.filters.find(i => i.filterId == defaultFilterId && (i.status == 'StatusActiveDefault' || i.status == 'StatusActive')) ?? null;
        }
        if (!selectedFilter)
        {
            selectedFilter = this.pendingState.filters.find(i => i.status != 'StatusInactive') ?? null;
        }
        if (!selectedFilter)
        {
            selectedFilter = this.pendingState.filters[0] ?? null;
        }
        return selectedFilter;
    }

    private async loading(task: () => Promise<void>): Promise<void>
    {
        try
        {
            this.setStateAsync({ isLoading: true });
            await task();
        }
        finally
        {
            this.setStateAsync({ isLoading: false });
        }
    }

    private async loadFilters(): Promise<void>
    {
        try 
        {
            const endpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.filters.get',
                options: {
                    nodeId: this.pendingState.buildingId,
                    top: 1000,
                    select: Filter,
                    filter: `Status ne 'StatusInactive'`,
                }
            };

            const response = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<Filter[]>>(endpoint.options)
            );

            const filters = response.value.map(i => FilterView.fromFilter(i));
            this.setStateAsync({ filters: filters });
        }
        catch
        {
            this.setStateAsync({ filters: [] });
        }
    }

    private async loadCachedSpaces(nodeId: number): Promise<void>
    {
        // this nodeId can either be the nodeId of a building, or the nodeId of a grouping higher in the hierarchy. which this.apiCache.getSpacesByBuilding can work with.
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(nodeId);
            const spaceView = response.map(i => SpaceView.fromSpace(i));
            const spaces = spaceView.sort((a, b) => a.spaceName.toLocaleLowerCase().localeCompare(b.spaceName.toLocaleLowerCase())); // sort spaces by Ascending Order on loading cached spaces.
            this.setStateAsync({ cachedSpaces: spaces });
        }
        catch
        {
            this.setStateAsync({ cachedSpaces: [] });
        }
    }

    private async loadBookingPolicies(): Promise<void>
    {
        const endpoint = {
            name: 'ibssApiClientV2.v2.byNodeid.bookingpolicies.get',
            options: {
                nodeId: this.higherLevelNodeIdOrBuildingId,
                top: 1000,
                select: BuildingBookingPolicy,
                recursive: true,
            }
        };

        const policies = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
            JSON.stringify(endpoint),
            () => appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>(endpoint.options)
        );

        if (policies && policies.length > 0) 
        {
            const policy: IBookingPolicy[] = policies.map(policy => ({
                Node_Id: policy.Node_Id,
                Booking_Policy_Id: policy.Booking_Policy_Id,
                Booking_Policy_Name: policy.Booking_Policy_Name,
                Booking_Policy: {
                    PolicyType: policy.Booking_Policy.PolicyType,
                    BuildingId: policy.Booking_Policy.BuildingId,
                    FloorId: policy.Booking_Policy.FloorId,
                    Allowed_TimeRange: policy.Booking_Policy.Allowed_TimeRange,
                    Booking_Policy_Description: policy.Booking_Policy.Booking_Policy_Description,
                    BookingSlots: {
                        BookableTime: policy.Booking_Policy.BookingSlots.BookableTime,
                        ExcludedDates: policy.Booking_Policy.BookingSlots.ExcludedDates,
                    },
                },
            }));

            const excludedBookingDates = ExcludedDatesAdapter.fromBookingPolicies(policy);
            const bookingSlots = BookableSlotAdapter.fromBookingPolicies(policy);
            this.bookableSpaces = new BookableSpaces(bookingSlots, excludedBookingDates);
            this.setStateAsync({ bookingPolicies: policy });
        }
    }

    private async loadSpacesAndBookings(): Promise<void>
    {
        this.pager.clearItems();
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setStateAsync({ spaces: pageOfSpaces });

        await this.loadLinkedSpaces();
        await this.loadBookings();
        if (this.canReadTags)
        {
            await this.loadEventTags();
        }
    }

    private async loadLinkedSpaces(): Promise<void>
    {
        // spaces cache does not have linked spaces ids.
        // this function acts on spaces, parse space.spaceLayout, writes the spaceIds into a linkedSpacesIds state.
        const spaceLayouts = this.pendingState.spaces
            .filter(space => space.spaceLayout !== "")
            .flatMap(space => 
            {
                try
                {
                    const layouts: ISpaceLayout[] = JSON.parse(space.spaceLayout)?.Layouts ?? [];
                    if (layouts.every(layout => this.isSpaceLayoutType(layout)))
                    {
                        return layouts;
                    }
                    else
                    {
                        return null;
                    }
                }
                catch
                {
                    return null;
                }
            }
            )
            .filter(space => space !== null && space.Space_Id.includes(';')) as ISpaceLayout[];

        const linkedSpaceIds = spaceLayouts.map(j => j.Space_Id);
        const uniqueLinkedSpaceIds = new Set(linkedSpaceIds);
        this.setStateAsync({ linkedSpacesIds: Array.from(uniqueLinkedSpaceIds)});
    }

    private isSpaceLayoutType(obj: any): obj is ISpaceLayout
    {
        // the parsed JSON's type begins as any, this function puts some type guard on the returned obj.
        return "Name" in obj && "Space_Id" in obj && typeof obj['Name'] === 'string' && typeof obj['Space_Id'] === 'string';
    }

    private async loadBookings(): Promise<void>
    {
        const startDateUtc = this.pendingState.startTime.startOf('day').toUtcByNode(this.pendingState.buildingId);
        const endDateUtc = startDateUtc.plus({ days: 1 });
        const spaceIds = this.pendingState.spaces.map(space => space.spaceId);
        const filteredValue = spaceIds.map(spaceId => `Space_Id eq '${spaceId}'`).join(' or ');
        const linkedSpaceIds = this.pendingState.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, spaceIds));
        const linkedSpaceIdsQuery = linkedSpaceIds.map(linkedSpaceId => ` or Space_Id eq '${linkedSpaceId}'`).join('');
        const filterQuery = `${filteredValue}${linkedSpaceIdsQuery}`;

        let filter = `Booking_Status ne 'Cancelled' and Booking_Status ne 'Auto Cancelled' and Booking_Start ge datetime'${startDateUtc.toISO()}' and Booking_End lt datetime'${endDateUtc.toISO()}'`;
        if (filterQuery) 
        {
            filter += ` and (${filterQuery})`;
        }

        try
        {
            const endpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.bookings.get',
                options: {
                    nodeId: this.higherLevelNodeIdOrBuildingId,
                    top: 1000,
                    select: Booking,
                    filter: filter,
                    recursive: true,
                }
            };

            const response = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>(endpoint.options)
            );

            const newBookings = response.value.map(booking => BookingView.fromBooking(booking));
            this.setStateAsync({ bookings: newBookings });
        }
        catch
        {
            this.setStateAsync({ bookings: [] });
        }
    }
 
    private linkedSpaceIdIncludesSpaceId(linkeSpaceId: string, spaceIds: string[]): boolean
    {
        return spaceIds.some(spaceId => linkeSpaceId.split(';').includes(spaceId));
    }

    private async loadEventTags(): Promise<void>
    {       
        try
        {
            const endpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.tag2Eventtype.get',
                options: {
                    nodeId: this.higherLevelNodeIdOrBuildingId,
                    select: ITagToEventType,
                    top: 1000,
                    recursive: true,
                }
            };

            const response = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.get<PagedResponse<ITagToEventType[]>>(endpoint.options)
            );

            const tagsToEvents = response.value.map(tagToEventType => TagToEventTypeView.fromTagToEventType(tagToEventType));
            this.setStateAsync({ tagsToEvents: tagsToEvents });
        }
        catch (error)
        {
            return;
        }
    }

    private get higherLevelNodeIdOrBuildingId(): number
    {
        const selectedFilter = (this.pendingState.filters.find(filter => filter.filterId === this.pendingState.selectedFilterId));
        const upperHierarchyNodeId = this.findUpperHierarchyNodeId(this.pendingState.buildingId);
        return selectedFilter?.buildingBypass ? upperHierarchyNodeId : this.pendingState.buildingId;
    }

    private findUpperHierarchyNodeId(buildingNodeId: number): number
    {
        const regions = this.local.getNodeData().Regions;
        return regions.find(region => region.Buildings.some(building => building.Node_Id === buildingNodeId))?.Node_Id ?? 1;
    }
    
    private getBuildingIdWithFloorId(floorId: number): number
    {
        const buildings = this.local.getNodeData().Regions.flatMap(region => region.Buildings.flatMap(building => building));
        return buildings.find(building => building.Floors.find(floor => floor.Node_Id === floorId))?.Node_Id ?? 70;
    }

    private async getPageOfSpaces(skipToken: string | null, pageSize: number): Promise<IBatch<SpaceView>>
    {
        const selectedFilter = (this.pendingState.filters.find(filter => filter.filterId === this.pendingState.selectedFilterId));
        const filterQueryString = selectedFilter?.filter;
        if (!filterQueryString)
        {
            return { skipToken: '', items: [] };
        }

        try
        {
            const endpoint =
            {
                name: 'ibssApiClientV2.v2.byNodeid.spaces.get',
                options: {
                    nodeId: this.higherLevelNodeIdOrBuildingId,
                    select: SpaceData,
                    filter: filterQueryString ?? undefined,
                    top: pageSize,
                    skipToken: skipToken ?? undefined,
                }
            };

            const response = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.spaces.get<PagedResponse<SpaceData[]>>(endpoint.options)
            );

            await this.loadCachedSpaces(this.higherLevelNodeIdOrBuildingId);

            const spacesData = response.value.map(space =>
            {
                const cachedSpace = this.pendingState.cachedSpaces.find(cachedSpace => cachedSpace.spaceId === space.Space_Id) ?? null;
                return {
                    nodeId: cachedSpace?.nodeId ?? 0,
                    spaceId: space.Space_Id,
                    spaceName: cachedSpace?.spaceName ?? '',
                    spaceCapacity: cachedSpace?.spaceCapacity ?? 0,
                    spaceClass: cachedSpace?.spaceClass ?? '',
                    spaceType: cachedSpace?.spaceType ?? '',
                    spaceTypeLabel: cachedSpace?.spaceTypeLabel ?? '',
                    spaceLayout: cachedSpace?.spaceLayout ?? '',
                    spaceSetup: cachedSpace?.spaceSetup ?? 0,
                    spaceStatus: cachedSpace?.spaceStatus ?? "",
                    imageURI: cachedSpace?.imageURI ?? "",
                    bookingPolicyId: cachedSpace?.bookingPolicyId ?? "",
                    metaBookable: cachedSpace?.metaBookable ?? 0,
                    metaOccType: cachedSpace?.metaOccType ?? 0,
                    metaLocZone: cachedSpace?.metaLocZone ?? "",
                    metaServReqsCatering: cachedSpace?.metaServReqsCatering ?? 0,
                    metaServReqsAV: cachedSpace?.metaServReqsAV ?? 0,
                    metaServReqsHearing: cachedSpace?.metaServReqsHearing ?? 0,
                    metaServReqsPresentation: cachedSpace?.metaServReqsPresentation ?? 0,
                    spaceWorkType: cachedSpace?.spaceWorkType ?? "",
                    meetingLinkAvailable: cachedSpace?.meetingLinkAvailable ?? "",
                } as SpaceView;
            });

            const spaces = spacesData.sort((a, b) => a.spaceName.toLocaleLowerCase().localeCompare(b.spaceName.toLocaleLowerCase())); // sort spaces by Ascending Order on loading cached spaces.
            return { skipToken: response.skipToken, items: spaces };
        }
        catch
        {
            return { skipToken: '', items: [] }
        }
    }

    private getSpaceIdsFromQuery(query: string): string[]
    {
        const conditions = query.split(' or ');
        const spaceIds = conditions.map(condition => 
        {
            const match = condition.match(/Space_Id eq '(.*)'/);
            return match ? match[1] : '';
        }
        );

        const filteredSpaceIds = spaceIds.filter(value => value !== '');
        return filteredSpaceIds;
    }

    private getFilterOptions(): ILabelOption[]
    {
        return this.pendingState.filters.filter(filter => filter.status !== 'StatusInactive').map((filter) => ({ label: filter.name, value: filter.filterId }));
    }

    private getSpaceName(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.name;
    }

    private getFloorName(value: ResourceDetails): JSX.Element
    {
        const buildingName = getBuildingNameUsingFloorNodeId(value.resourceData.nodeId);
        const availableSpaces = this.local.getNodeData();
        const floor = availableSpaces.Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i && i.Node_Id == (value as ResourceDetails).resourceData.nodeId);
        return (
            <span>
                {floor?.Node_Name && <div>{floor.Node_Name}</div>}
                {buildingName && <div>{buildingName}</div>}
            </span>
        );
    }

    private getSpaceCapacity(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.capacity;
    }

    private getSpaceId(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.id;
    }

    private getTimeString(value: Date)
    {
        return this.instance.formatDate(value, { skeleton: 'hm' });
    }

    private transformBookings(): IScheduleDate[]
    {
        const bookings = this.pendingState.bookings.flatMap(booking =>
        {
            const tagsOnBooking = this.pendingState.tagsToEvents.filter(tag => tag.recordId === booking.bookingId);
            const scheduleDatesForBooking = booking.spaceId.split(';').map(spaceId =>
            {
                const isMainBooking = !(booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Setup || booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Teardown);
                const scheduleDate: IScheduleDate =
                {
                    // Id, Subject, StartTime & EndTime fields (default action event data field names) cannot be written as camelCase strings without first mapping camelCase field names to ScheduleComponent.eventSettings.fields
                    // e.g. fields: { subject: { title: 'subject', name: 'subject'}}, make sure value of name property matches the camelCase field name.
                    id: booking.bookingId,
                    subject: isMainBooking ? booking.bookingName : ' ',
                    bookingOwnerEmail: booking.bookingOwnerEmail,
                    bookingOwnerName: booking.bookingOwnerName,
                    startTime: booking.bookingStart.toJSDate(),
                    endTime: booking.bookingEnd.toJSDate(),
                    bookingStatus: booking.bookingStatus,
                    spaceId: spaceId,
                    categoryColor: this.getBookingColour(booking),
                    icons: tagsOnBooking.map(tag => <IconHousing tagToEventTypeId={tag.tagToEventTypeId} name={tag.name} tagType={tag.tagType} value={tag.value} location={tag.icon.split(';')[0]} />),
                    parentBookingRelationType: booking.parentBookingRelationType,
                }
                return scheduleDate;
            });

            return scheduleDatesForBooking;
        });

        return bookings;
    }

    private getBookingColour(booking: BookingView): string
    {
        if (booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Setup || booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Teardown) 
        {
            return '#DAE9F5'; // --Light-Pastel-Blue
        }
        else if (booking.bookingStatus === 'New' || booking.bookingStatus === 'Amended')
        {
            return '#FFF'; // white colour for bookings which haven't started.
        }
        else if (booking.bookingStatus === 'Completed')
        {
            return '#FFF'; // white colour for completed bookings
        }
        else if (booking.bookingStatus === 'No Show')
        {
            return '#FFBBBF'; //-Light-Pastel-Red
        }
        else if (booking.bookingStatus === 'In Progress')
        {
            return '#CCF4DD'; //--Light-Pastel-Green
        }
        else if (booking.bookingStatus === 'Early Check In')
        {
            return '#DCE1E5'; // --Light-Mid-Tone
        }
        else if (booking.bookingStatus === 'Late Checkin')
        {
            return '#FFF3CC'; // --Light-Pastel-Yellow
        }
        else
        {
            return 'black';
        }
    }

    private groupData: GroupModel = {
        // group bookings by spaces - in ResourceDirective with name="Spaces", matching on SpaceId field of bookings transformed by transformBookings() and id field of ResourceDirective's datasource.
        resources: ['Spaces']
    }

    private async handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): Promise<void>
    {
        this.loading(async () =>
        {
            const currentPage = this.pager.pageIndex;
            if (newPage > currentPage)
            {
                await this.changePage(+1);
            }
            else
            {
                await this.changePage(-1);
            }
        });
    }

    private async changePage(offset: number): Promise<void>
    {
        this.pager.pageIndex += offset;
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setStateAsync({ spaces: pageOfSpaces });
        await this.loadBookings();
    }

    private handleCreateBookingClick(spaceId: string, nodeId: number, start: DateTime, end: DateTime): void
    {
        const { history } = this.props;
        const buildingId = this.getBuildingIdWithFloorId(nodeId);
        this.session.setFlexSpaceSearchCriteria(start, end, 0);
        history.push(`/flex-find-a-space/${buildingId}/searchaspace/${spaceId}`);
    }

    private handleFilterChange(newFilterValue: string): void
    {
        const selectedFilter = this.pendingState.filters.find(i => i.filterId == newFilterValue);
        this.pushQueryParams({ systemFilter: selectedFilter?.name });
    }

    private handleAddTag(tag: TagToEventTypeView): void
    {
        this.setStateAsync({ tagToCreate: tag });
    }

    private async addBookingTag(): Promise<void>
    {
        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.post<ICreateTagResponse>({
                nodeId: this.pendingState.tagToCreate.nodeId,
                body: [{
                    Tag_Id: this.pendingState.tagToCreate.tagId,
                    EventType_Id: this.pendingState.tagToCreate.eventTypeId,
                    TagType: this.pendingState.tagToCreate.tagType,
                    Name: this.pendingState.tagToCreate.name,
                    Value: this.pendingState.tagToCreate.value,
                    Icon: this.pendingState.tagToCreate.icon,
                    Node_Id: this.pendingState.tagToCreate.nodeId,
                    Record_Id: this.pendingState.tagToCreate.recordId,
                }]
            });

            const tagsToEvents = response.Details.map(tagToEventType => TagToEventTypeView.fromTagToEventType(tagToEventType));
            this.setStateAsync({
                tagsToEvents: [...this.pendingState.tagsToEvents.filter(tag => !tagsToEvents.find(tagToEvent => tagToEvent.tagToEventTypeId === tag.tagToEventTypeId)), ...tagsToEvents],
                tagToCreate: {} as TagToEventTypeView
            });
        }
        catch
        {
            return;
        }
    }

    private handleRemoveTag(tagToEventTypeId: string): void
    {
        this.setStateAsync({ tagToRemove: tagToEventTypeId });
    }

    private async removeBookingTag(tagToEventTypeId: string): Promise<void>
    {
        const selectedTag = this.pendingState.tagsToEvents.find(tag => tag.tagToEventTypeId === tagToEventTypeId);
        if (!selectedTag)
        {
            return;
        }
        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.byTag2Eventtypeid.delete<IDeleteTagToEventType>({
                nodeId: selectedTag.nodeId,
                tag2Eventtypeid: tagToEventTypeId,
            });

            if (response)
            {
                this.setStateAsync({
                    tagsToEvents: this.pendingState.tagsToEvents.filter(tag => tag.tagToEventTypeId !== tagToEventTypeId),
                    tagToRemove: '',
                });
            }
        }
        catch (error)
        {
            return;
        }
    }

    private handleBookingsFormatChange(newBookingsFormat: string): void
    {
        if (newBookingsFormat && newBookingsFormat !== this.pendingState.bookingsFormat)
        {
            this.setStateAsync({ bookingsFormat: newBookingsFormat });

            const startTime = this.pendingState.startTime.startOf('day');
            const endTime = this.pendingState.startTime.endOf('day');
            this.session.setOnelensBookingSearchCriteria(startTime, endTime, this.pendingState.selectedFilterId);
            this.props.history.push(`/operational-services-bookings/${this.pendingState.buildingId.toString()}/0`);
        }
    }

    private handleRedirectToAdmin(): void
    {
        this.props.history.push(`/admin/system-filters/buildingId=${this.pendingState.buildingId.toString()}`);
    }

    private handleSpaceInfoClick(spaceId: string): void
    {
        const selectedSpace = this.pendingState.spaces.find(room => room.spaceId === spaceId);
        if (!selectedSpace)
        {
            return;
        }
        this.setStateAsync({ selectedSpaceId: spaceId, showSpaceInfoDialog: true });
    }

    private handleSchedulePopupOpen(args: PopupOpenEventArgs): void
    {
        const data = args.data as IScheduleDate | undefined;

        if (args.type === 'QuickInfo' && data?.id)
        {
            args.cancel = true;

            const selectedBooking = this.pendingState.bookings.find(booking =>
            {
                return booking.bookingId === data.id
            }
            )

            this.setStateAsync({
                showEditBooking: true,
                selectedBooking: selectedBooking,
            });
        }
        else if (args.type === 'QuickInfo' && data && data.id === undefined)
        {
            // create booking popup that appears when user clicks on a slot on the schedule
            const space = this.state.spaces.find(i => i.spaceId == data.spaceId);
            const bookableSpace = { spaceId: space?.spaceId ?? '', nodeId: space?.nodeId ?? 1, bookingPolicyId: space?.bookingPolicyId ?? '' };
            const slotStart = DateTime.fromJSDate(data.startTime);
            const slotEnd = slotStart.plus({ minute: 30 });
            const isSlotBookable = this.bookableSpaces.canBook(bookableSpace, slotStart, slotEnd);

            if (!isSlotBookable)
            {
                args.cancel = !isSlotBookable;
                return;
            }

            const floorId = this.pendingState.spaces.find(space => space.spaceId === data.spaceId)?.nodeId ?? 0;
            const buildingId = this.getBuildingIdWithFloorId(floorId)
            const targetElement = document.querySelector(`.e-popup-content`);

            if (targetElement)
            {
                ReactDOM.render(
                    <Box display='flex' justifyContent={'center'}>
                        <IbssButton
                            variant={'contained'}
                            onClick={() => this.handleCreateBookingClick(data.spaceId, floorId, DateTime.fromJSDate(data.startTime), DateTime.fromJSDate(data.endTime))}
                        >
                            {/* Create a booking */}
                            {this.labels.funcScheduleViewCreateBooking_S}
                        </IbssButton>
                    </Box>,
                    targetElement
                )
            }
            else
            {
                args.cancel = true;
            }
        }
        else
        {
            // disables ALL other popups besides 'QuickInfo'
            args.cancel = true;
        }
    }

    private handleEventRendered(args: EventRenderedArgs): void
    {
        // before bookings are rendered on the scheduler, apply some styles to the bookings, but primarily to assign borderColor to the CategoryColor in the bookings data.

        (args.element as HTMLElement).style.color = '#263238'; // this is equivalent to var(ui--text), change to this var if syncfusion scheduler has dark mode.
        (args.element as HTMLElement).style.backgroundColor = args.data.categoryColor;
        (args.element as HTMLElement).style.borderColor = '#263238';
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
        (args.element as HTMLElement).style.opacity = '1';
    }

    private handleToggleDrawer(showEditBooking: boolean): void
    {
        this.setStateAsync({ showEditBooking: showEditBooking });
    }

    private debounceDateTimer: NodeJS.Timeout | null = null;
    private handleDateChange(value: DateTime | null): void
    {
        clearTimeout(this.debounceDateTimer ?? undefined);
        if (!value || !value.isValid)
        {
            return;
        }
        this.setStateAsync({ startTime: value });
        this.debounceDateTimer = setTimeout(() => this.pushQueryParams({ date: value }), Constants.longDebounceTimeInMilliseconds);
    }

    private handleReduceSlotCount(): void
    {
        if (this.pendingState.slotCount > 1)
        {
            this.setStateAsync({ slotCount: this.pendingState.slotCount - 1 });
        }
    }

    private handleAddSlotCount(): void
    {
        if (this.pendingState.slotCount < 6)
        {
            this.setStateAsync({ slotCount: this.pendingState.slotCount + 1 });
        }
    }

    public render(): JSX.Element
    {
        const filterQueryString = (this.state.filters.find(filter => filter.filterId === this.state.selectedFilterId))?.filter ?? '';
        
        return (
            <>
                {
                    <BookingTagsDialog
                        show={this.state.showBookingTagsDialog}
                        onClose={() => this.setStateAsync({ showBookingTagsDialog: false })}
                        tags={this.state.tagsToEvents.filter(tag => tag.recordId === this.state.selectedBooking?.bookingId)}
                    />
                }
                {(this.state.isLoading) && <LoadingOverlay text={this.labels.HubLabelLoadingText} />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div className="table-panel">
                            {
                                <>
                                    <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                        <Grid item md={7} sx={{ pt: 0, pb: 1.5 }} >
                                            {/* Space Schedule */}
                                            <Box className="table-panel-header" component="div">{this.labels.funcBookingDashboard_S}</Box>
                                            <Box className='bookingsDashboard-subHeader'>
                                                <Typography variant='body1' className='bookingsDashboard-paragraph'>{this.labels.funcOverViewBookings_D}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={5} sx={{ pt: 0, pb: 1.8 }}>
                                            <IbssInputDropDown
                                                id='bookings-filters'
                                                inputLabel={this.labels.funcSelectSpaceFilter_S}
                                                options={this.getFilterOptions()}
                                                value={this.state.selectedFilterId}
                                                onChange={e => this.handleFilterChange(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={12} sx={{ pt: 0 }}>
                                            <Box display='flex' justifyContent='space-between' gap='0.5rem'>
                                                <BookingsCount bookings={this.state.bookings} />
                                                <Box display='inherit' gap='inherit'>
                                                    <IbssButton
                                                        variant='contained'
                                                        disableElevation={true}
                                                        sx={{ backgroundColor: (theme) => theme.palette.background.default, color: (theme) => theme.palette.text.primary }}
                                                        onClick={() => this.loading(() => this.loadSpacesAndBookings())}
                                                    >
                                                        <Box display='flex' gap='8px'>
                                                            <AutorenewIcon />
                                                            <Typography className='bookingsDashboard-text'>{this.labels.funcRefreshBookings_S}</Typography>
                                                        </Box>
                                                    </IbssButton>
                                                    <ToggleButtonGroup
                                                        aria-label='bookings format'
                                                        exclusive
                                                        value={this.state.bookingsFormat}
                                                        onChange={(event, newBookingsFormat) => this.handleBookingsFormatChange(newBookingsFormat)}
                                                    >
                                                        <ToggleButton value='list' aria-label='bookings list format'>
                                                            <FormatListBulletedIcon fontSize='small' />
                                                        </ToggleButton>
                                                        <ToggleButton value='schedule' aria-label='bookings schedule format'>
                                                            <CalendarTodayIcon fontSize='small' />
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} sx={{ pt: 1.5 }} >
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                backgroundColor: (theme) => theme.palette.common.uiCore,
                                            }}>
                                                <Box width='100%' display={'flex'} justifyContent={'space-between'} paddingTop='0.5rem' paddingBottom='0.5rem'>
                                                    <Box display={'flex'}>
                                                        <Box sx={{ padding: '0rem 1rem' }}>
                                                            <IbssIconButton
                                                                aria-label="navigate-to-previous-day"
                                                                key={"navigate-to-previous-day"}
                                                                onClick={() => this.handleDateChange(this.state.startTime.plus({ days: -1 }))}
                                                            >
                                                                <SvgIcon fontSize={'medium'} component={ChevronLeftIcon}></SvgIcon>
                                                            </IbssIconButton>
                                                            <IbssIconButton
                                                                aria-label="navigate-to-next-day"
                                                                key={"navigate-to-next-day"}
                                                                onClick={() => this.handleDateChange(this.state.startTime.plus({ days: 1 }))}
                                                            >
                                                                <SvgIcon fontSize={'medium'} component={ChevronRightIcon}></SvgIcon>
                                                            </IbssIconButton>
                                                        </Box>
                                                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                                                            <DesktopDatePicker
                                                                value={this.state.startTime}
                                                                onChange={event => this.handleDateChange(event)}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                            />
                                                        </LocalizationProvider>
                                                        <IbssButton
                                                            aria-label="navigate-today"
                                                            key={"navigate-today"}
                                                            sx={{ marginLeft: '2rem', color: (theme) => theme.palette.text.primary }}
                                                            onClick={() => this.handleDateChange(DateHelper.now(this.pendingState.buildingId))}
                                                        >
                                                            <SvgIcon fontSize={'medium'} component={TodayIcon}></SvgIcon>
                                                            <Typography className='bookingsDashboard-text' paddingLeft={'0.5rem'}>
                                                                {this.labels.funcCalendarJumpToToday_S}
                                                            </Typography>
                                                        </IbssButton>
                                                    </Box>
                                                    <Box paddingRight='1rem'>
                                                        <IconButton
                                                            onClick={() => this.handleAddSlotCount()}
                                                            disabled={this.state.slotCount === 6}
                                                            sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
                                                            <AddIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => this.handleReduceSlotCount()}
                                                            disabled={this.state.slotCount === 1}
                                                            sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {this.state.spaces.length > 0 &&
                                        <>
                                            <ScheduleComponent
                                                cssClass={'onelens-bookings-dashboard'}
                                                currentView="Day"
                                                height={this.state.spaces.slice(0, (this.state.spacePageIndex + 1) * 10).length <= 10 ? '62vh' : 'auto'}
                                                width='100%'
                                                ref={this.scheduleRef}
                                                // show time line indicator of current time only if browser timezone is equal to building timezone.
                                                showTimeIndicator={Intl.DateTimeFormat().resolvedOptions().timeZone === DateHelper.getZoneByNode(this.state.buildingId)}
                                                timeScale={{ enable: true, interval: 60, slotCount: this.state.slotCount }}
                                                eventSettings=
                                                {{
                                                    dataSource: this.transformBookings(),
                                                    fields:
                                                    {
                                                        id: { title: 'id', name: 'id' },
                                                        subject: { title: 'subject', name: 'subject' },
                                                        startTime: { title: 'start time', name: 'startTime' },
                                                        endTime: { title: 'end time', name: 'endTime' },
                                                    },
                                                    template: this.renderEvent.bind(this),
                                                }}
                                                group={this.groupData}
                                                showHeaderBar={false}
                                                startHour='00:00'
                                                endHour='24:00'
                                                workHours={{ highlight: true, start: this.state.buildingStartHrs, end: this.state.buildingEndHrs }}
                                                eventRendered={this.handleEventRendered.bind(this)}
                                                popupOpen={this.handleSchedulePopupOpen.bind(this)}
                                                quickInfoTemplates={{ footer: () => { return <div></div> } }} // display nothing in place of the default syncfusion quickInfo popup footer.
                                                renderCell={this.renderCell.bind(this)}
                                                resourceHeaderTemplate={this.renderHeader.bind(this)}
                                                selectedDate={this.state.startTime.toJSDate()}
                                                rowAutoHeight={true} // property that allows the timeline row containing appointment cells to expand to accommodate multiple appointments at the same time, as well as displaying singluar appointments properly.
                                                delayUpdate={true} // stops ScheduleComponent from rerendering after each state update. updates template only after all state changes made. Crucially enables correct rendering after bookings state is updated.
                                            >
                                                <ResourcesDirective>
                                                    <ResourceDirective
                                                        field='spaceId' // has to match to a field of the data passed to ScheduleComponent eventSettings DataSource.  
                                                        title='Space Id' // string displayed when one clicks on the booking
                                                        name='Spaces' // match to a string in the arrya of groupData.resources
                                                        allowMultiple={true}
                                                        idField='id'
                                                        textField='name'
                                                        colorField='Color'
                                                        dataSource={this.state.spaces.map(space =>
                                                        ({
                                                            id: space.spaceId,
                                                            capacity: space.spaceCapacity,
                                                            name: space.spaceName,
                                                            nodeId: space.nodeId,
                                                            type: space.spaceType
                                                        })
                                                        )}
                                                    />
                                                </ResourcesDirective>
                                                <Inject services={[TimelineViews]} />
                                                <ViewsDirective>
                                                    <ViewDirective option='TimelineDay' />
                                                </ViewsDirective>
                                            </ScheduleComponent>
                                            <TablePagination
                                                component="div"
                                                count={this.getSpaceIdsFromQuery(filterQueryString).length}
                                                page={this.pager.pageIndex}
                                                onPageChange={(event, page) => this.handleChangePage(event, page)}
                                                rowsPerPage={this.pager.pageSize}
                                                rowsPerPageOptions={[this.pager.pageSize]}
                                                className={'bookingsDashboard-pagination'}
                                            />
                                        </>
                                    }
                                    {
                                        this.state.spaces.length === 0 && this.state.isLoading === false &&
                                        <Box sx={{ height: '62vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <NoSpaceScheduleViewIcon />
                                            <Typography variant="h5" className='bookingsDashboard-paragraph' sx={{ mt: 4, mb: 2 }}>
                                                {this.labels.funcScheduleNoSpaces_L}
                                            </Typography>
                                            {
                                                this.canReadFilters &&
                                                <>
                                                    {
                                                        this.state.filters.length > 0 && <Typography className='bookingsDashboard-paragraph'>
                                                            {this.labels.funcBookingsNoSpaces_D}
                                                        </Typography>

                                                    }
                                                    {
                                                        this.state.filters.length === 0 && <Typography className='bookingsDashboard-paragraph'>
                                                            {this.labels.funcBookingsCreateFilter_L}
                                                        </Typography>

                                                    }

                                                    <IbssButton
                                                        size='large'
                                                        className='bookingsDashboard-paragraph'
                                                        onClick={() => this.handleRedirectToAdmin()}
                                                    >
                                                        <Typography variant='body1'>{this.labels.funcBookingsManageFilters_S}</Typography>
                                                    </IbssButton>
                                                </>
                                            }
                                            {
                                                !this.canReadFilters &&
                                                <>
                                                    <Typography className='bookingsDashboard-paragraph'>
                                                        {this.labels.funcBookingsSelectAnotherFilter_S}
                                                    </Typography>
                                                </>
                                            }
                                        </Box>
                                    }
                                    {
                                        this.state.selectedBooking &&
                                        <EditBookingDrawer
                                            open={this.state.showEditBooking}
                                            toggleDrawer={this.handleToggleDrawer.bind(this)}
                                            bookingId={this.state.selectedBooking.bookingId}
                                            bookingDescription={this.state.selectedBooking.bookingDescription}
                                            bookingEarlyCheckIn={this.state.selectedBooking.bookingEarlyCheckin}
                                            bookingEnd={this.state.selectedBooking.bookingEnd}
                                            bookingStart={this.state.selectedBooking.bookingStart}
                                            bookingIsApproved={this.state.selectedBooking.bookingIsApproved}
                                            bookingName={this.state.selectedBooking.bookingName}
                                            bookingOwnerEmail={this.state.selectedBooking.bookingOwnerEmail}
                                            bookingOwnerName={this.state.selectedBooking.bookingOwnerName}
                                            bookingStatus={this.state.selectedBooking.bookingStatus}
                                            selectedBookingTags={this.state.tagsToEvents.filter(tag => tag.recordId === this.state.selectedBooking?.bookingId)}
                                            spaceLayout={this.state.selectedBooking.spaceLayout}
                                            spaceName={this.state.selectedBooking.spaceName}
                                            buildingId={this.getBuildingIdWithFloorId(this.state.selectedBooking.nodeId)}
                                            spaceId={this.state.selectedBooking.spaceId}
                                            nodeId={this.state.selectedBooking.nodeId}
                                            addTag={this.handleAddTag.bind(this)}
                                            removeTag={this.handleRemoveTag.bind(this)}
                                            refreshSpacesAndBookings={() => this.loading(() => this.loadSpacesAndBookings())}
                                            bookingAttendance={this.state.selectedBooking.bookingAttendance}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <SpaceInfoDialogWithCustomInfo
                    isOpen={this.state.showSpaceInfoDialog}
                    onClose={() => this.setStateAsync({ showSpaceInfoDialog: false })}
                    spaceId={this.state.selectedSpaceId}
                    nodeId={this.state.spaces.find(space => space.spaceId === this.state.selectedSpaceId)?.nodeId ?? 0}
                    buildingId={this.state.buildingId}
                />
            </>
        )
    }

    private renderHeader(spaceDetails: ResourceDetails): JSX.Element
    {
        return (
            <div className="template-wrap">
                <IbssToolTip
                    arrow={true}
                    title={this.getSpaceName(spaceDetails)}
                    placement='bottom'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -14],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <div className="room-name">
                        {this.getSpaceName(spaceDetails)}
                    </div>
                </IbssToolTip>
                <div className="room-type">{this.getFloorName(spaceDetails)}</div>
                <div className="room-capacity">{this.getSpaceCapacity(spaceDetails)}</div>
                <div id="room-info">
                    <IbssIconButton
                        aria-label="info"
                        key={this.getSpaceId(spaceDetails)}
                        sx={{ padding: 0 }}
                        onClick={() => this.handleSpaceInfoClick(spaceDetails.resourceData.id)}
                    >
                        <SvgIcon fontSize={'small'} component={InfoIcon}></SvgIcon>
                    </IbssIconButton>
                </div>
            </div>
        );
    }

    private renderEvent(props: IScheduleDate): JSX.Element
    {
        if (props.parentBookingRelationType === ParentBookingRelationTypeEnum.Main)
        {
            return (
                <div className="e-inner-wrap bookingsDashboard-appointment">
                    <div className="e-subject">{props.bookingOwnerName}</div>
                    <div className="e-time">{`${this.getTimeString(props.startTime)} - ${this.getTimeString(props.endTime)}`}</div>
                    <div className="e-location"></div>
                    <div>
                        {this.canReadTags &&
                            <>
                                <div className="icons-row">{props.icons.map((icon, key) => <div key={key}>{icon}</div>)}</div>
                                {props.icons.length > 0 &&
                                    <div>
                                        <MoreHorizIcon fontSize='small' onClick={() => this.setStateAsync({ showBookingTagsDialog: true, selectedBooking: this.pendingState.bookings.find(booking => booking.bookingId === props.id) })} />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        }
        else
        {
            // else if booking type is setup or teardown
            return (
                <div className="e-inner-wrap bookingsDashboard-appointment">
                    <div className="setup-teardown-icons-container">
                        {this.canReadTags &&
                            <>
                                <div className="icons-row setup-teardown">{props.icons.map((icon, key) => <div key={key}>{icon}</div>).slice(0, 2)}</div>
                                {props.icons.length > 0 &&
                                    <div>
                                        <MoreHorizIcon fontSize='small' onClick={() => this.setStateAsync({ showBookingTagsDialog: true, selectedBooking: this.pendingState.bookings.find(booking => booking.bookingId === props.id) })} />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        }
    }

    private renderCell(args: RenderCellEventArgs): void
    {
        if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td'))
        {
            // labels for Space, Floor, Cap (as in Capacity), 4th header is empty.
            const target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
            target.innerHTML = `<div class="name">${this.labels.HubLabelSpace}</div><div class="type">${this.labels.HubLabelFloor}</div><div class="capacity">${this.labels.HubLabelCapacity}</div><div class="capacity">${''}</div>`
        }
        else if (args.elementType === "workCells" && args.date && args.groupIndex != null)
        {
            const space = this.state.spaces[args.groupIndex] as SpaceView | undefined;
            const bookableSpace = { spaceId: space?.spaceId ?? '', nodeId: space?.nodeId ?? 1, bookingPolicyId: space?.bookingPolicyId ?? '' };
            const slotStart = DateTime.fromJSDate(args.date);
            const slotEnd = slotStart.plus({ minute: 30 });
            const isSlotBookable = this.bookableSpaces.canBook(bookableSpace, slotStart, slotEnd);

            if (!isSlotBookable)
            {
                args.element.classList.add("e-disable-cell");
            }
        }
    }
}

export interface IProps extends RouteComponentProps
{
}

export interface IState
{
    bookings: BookingView[],
    bookingsFormat: string,
    buildingId: number,
    buildingStartHrs: string,
    buildingEndHrs: string,
    cachedSpaces: SpaceView[],
    filters: FilterView[],
    isLoading: boolean,
    linkedSpacesIds: string[],
    selectedBooking: BookingView | undefined,
    selectedFilterId: string,
    selectedSpaceId: string,
    showEditBooking: boolean,
    showSpaceInfoDialog: boolean,
    showBookingTagsDialog: boolean,
    slotCount: number,
    spaces: SpaceView[],
    spacePageIndex: number,
    startTime: DateTime,
    tagsToEvents: TagToEventTypeView[],
    tagToCreate: TagToEventTypeView,
    tagToRemove: string,
    bookingPolicies: IBookingPolicy[],
}

export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public spaceSetup = 0;
    public spaceStatus = "";
    public imageURI = "";
    public bookingPolicyId = "";
    public metaBookable = 0;
    public metaOccType = 0;
    public metaLocZone = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceWorkType = "";
    public meetingLinkAvailable = "";

    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            spaceSetup: space.Space_Setup,
            spaceStatus: space.Space_Status,
            imageURI: space.ImageURI,
            bookingPolicyId: space.Booking_Policy_Id,
            metaBookable: space.Meta_Bookable,
            metaOccType: space.Meta_Occ_Type,
            metaLocZone: space.Meta_Loc_Zone,
            metaServReqsCatering: space.Meta_Serv_Reqs_Presentation,
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceWorkType: space.Space_Work_Type,
            meetingLinkAvailable: space.Meta_Ext_Booking_System,
        };
    }
}

export interface ISpaceLayout
{
    Space_Id: string;
    Name: string;
}

export interface IScheduleDate
{
    id: string,
    subject: string,
    startTime: Date,
    endTime: Date,
    bookingStatus: string,
    bookingOwnerEmail: string,
    bookingOwnerName: string,
    spaceId: string, // singular, delimited spaceId, e.g.  spaceId: 1CC_03-NE-R005;1CC_03-SE-R006
    categoryColor: string,
    icons: JSX.Element[],
    parentBookingRelationType: ParentBookingRelationTypeEnum,
}

export class BookingView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceLayout = ""
    public bookingId = "";
    public bookingName = "";
    public bookingDescription = "";
    public bookingStart = DateHelper.null();
    public bookingEarlyCheckin = DateHelper.null();
    public bookingEnd = DateHelper.null();
    public bookingStatus = "";
    public bookingIsActive = 0;
    public bookingIsApproved = 0;
    public bookingOwnerEmail = "";
    public bookingOwnerName = "";
    public bookingParties = "";
    public bookingShareLocation = 0;
    public createdAt = DateHelper.null();
    public createdBy = "";
    public parentBookingId = "";
    public parentBookingRelationType = ParentBookingRelationTypeEnum.Main;
    public bookingAttendance = 0

    public static fromBooking(booking: Booking): BookingView
    {
        return {
            nodeId: booking.Node_Id,
            spaceId: booking.Space_Id,
            spaceName: booking.Space_Name,
            spaceLayout: booking.Space_Layout,
            bookingId: booking.Booking_Id,
            bookingName: booking.Booking_Name,
            bookingDescription: booking.Booking_Description,
            bookingStart: DateHelper.fromIsoByNode(booking.Booking_Start as unknown as string, booking.Node_Id),
            bookingEarlyCheckin: DateHelper.fromIsoByNode(booking.Booking_Early_Checkin as unknown as string, booking.Node_Id),
            bookingEnd: DateHelper.fromIsoByNode(booking.Booking_End as unknown as string, booking.Node_Id),
            bookingStatus: booking.Booking_Status,
            bookingIsActive: booking.Booking_IsActive,
            bookingIsApproved: booking.Booking_IsApproved,
            bookingOwnerEmail: booking.Booking_Owner_Email,
            bookingOwnerName: booking.Booking_Owner_Name,
            bookingParties: booking.Booking_Parties,
            bookingShareLocation: booking.Booking_Share_Loc,
            createdAt: DateHelper.fromIsoByNode(booking._CreatedAt as unknown as string, booking.Node_Id),
            createdBy: booking._CreatedBy,
            parentBookingId: booking.Parent_Booking_Id,
            parentBookingRelationType: booking.Parent_Booking_Relation_Type,
            bookingAttendance: booking.Booking_Attendance
        };
    }
}

export class FilterView
{
    public filterId = '';
    public eventTypeId = 0;
    public filter = '';
    public status = '';
    public name = '';
    public nodeId = 0;
    public owndedByUserId = '';
    public createdByUserId = '';
    public createdAt = DateHelper.null();
    public modifiedByUserId = '';
    public modifiedAt = DateHelper.null();
    public concurrencyStamp = '';
    public buildingBypass = false;

    public static fromFilter(filter: Filter): FilterView
    {
        return {
            filterId: filter.Filter_Id,
            eventTypeId: filter.EventType_Id,
            filter: filter.Filter,
            status: filter.Status,
            name: filter.Name,
            nodeId: filter.Node_Id,
            owndedByUserId: filter.OwnedByUserId,
            createdByUserId: filter.CreatedByUserId,
            createdAt: DateHelper.fromIsoByNode(filter.CreatedAt, filter.Node_Id),
            modifiedByUserId: filter.ModifiedByUserId,
            modifiedAt: DateHelper.fromIsoByNode(filter.ModifiedAt, filter.Node_Id),
            concurrencyStamp: filter.ConcurrencyStamp,
            buildingBypass: filter.Building_Bypass,
        }
    }
}

export class TagToEventTypeView
{
    public nodeId = 0;
    public tagToEventTypeId = "";
    public tagId = "";
    public tagType = "";
    public name = "";
    public value = "";
    public icon = "";
    public recordId = "";
    public eventTypeId = 0;

    public static fromTagToEventType(tagToEventType: ITagToEventType): TagToEventTypeView
    {
        return {
            nodeId: parseInt(tagToEventType.Node_Id),
            tagToEventTypeId: tagToEventType.Tag2EventType_Id,
            tagId: tagToEventType.Tag_Id,
            tagType: tagToEventType.TagType,
            name: tagToEventType.Name,
            value: tagToEventType.Value,
            icon: tagToEventType.Icon,
            recordId: tagToEventType.Record_Id,
            eventTypeId: tagToEventType.EventType_Id,
        }
    }
}

export interface ILabelOption
{
    label: string;
    value: string;
}

export interface IBookingPolicy 
{
    Node_Id: number;
    Booking_Policy_Id: string;
    Booking_Policy_Name: string;
    Booking_Policy: {
        PolicyType: string;
        BuildingId: string;
        FloorId: string;
        Allowed_TimeRange: string;
        Booking_Policy_Description: string;
        BookingSlots: 
        {
            BookableTime: IBookableTime[],
            ExcludedDates: 
            {
                StartDate: string;
                EndDate: string;
                StartTime: string;
                EndTime: string;
            }[];
        };
    };
}
