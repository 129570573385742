import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Building extends ODataSelect
{
    ID = 0;
    Name = ""
    Deleted = false;
    CustomNodeID = "";
    DisplayName = "";
    Parent_ID = "";
    Level = "";
    IsEnabled = 0;
    SortOrder = "";
}