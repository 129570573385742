import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import IbssButton from "../../Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import { ISpaceData } from "./DataModels";
import { INode, PagedResponse } from "../../../Providers.Api/Models";
import SpaceFilterDialog, { IFloor, ISpaceType, ISpaceFilter } from "../../Dialogs/SpaceFilterDialog/SpaceFilterDialog";
import IbssChip from "../../Navigation/Chip/IbssChip";
import { DateTime } from "luxon";
import { ApiConstants } from "../../../Providers.Api/ApiConstants";
import Spinner from "../../Navigation/LoadingSpinner/Spinner";
import { Space } from "../../../Providers.Api/Spaces/SpaceRepository";
import Helper, { getFloorUsingFloorId } from "../../../Common/Helper";
import { IBatch, Pager } from "../../../Common/Pager";
import { ISearchRecurring } from "../../../Providers.IbssApiClientV2/IbssApiClientV2";
import SwapSpaceCard from "../../Cards/SwapSpaceCard/SwapSpaceCard";

export class SpacePicker extends IbssComponent<IProps, IState>
{
    private pager = new Pager<ISpace>();
    private get nodeId() { return this.state.floor?.id ?? this.props.buildingId }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoading: true,
            spaces: new Map(),
            pageOfSpaces: [],
            openFilterDialog: false,
            buildingId: 0,
            floor: null,
            spaceType: null,
            minCapacity: 0,
            requiresCatering: false,
            requiresAudioVisual: false,
            requiresPresentationAids: false,
            requiresHearingAids: false,
            selectedSpaceId: '',
        };

        this.pager.pageSize = 10;
        this.pager.getItems = (skipToken, pageSize) => this.loadSpaces(skipToken, pageSize);
    }

    public async componentDidMount(): Promise<void>
    {
        await this.getSpaces(this.props.buildingId);
        await this.setDefaultFilters();
        const pageOfSpaces = await this.pager.getCurrentPage();
        await this.setState({ pageOfSpaces: pageOfSpaces });
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>): Promise<void>
    {
        if (prevProps.buildingId != this.props.buildingId)
        {
            await this.getSpaces(this.props.buildingId);
        }

        if (
            prevProps.availableFrom != this.props.availableFrom ||
            prevProps.availableTo != this.props.availableTo ||
            prevProps.floorId != this.props.floorId ||
            prevProps.buildingId != this.props.buildingId
        )
        {
            await this.setDefaultFilters();
        }
    }

    private async setDefaultFilters(): Promise<void>
    {
        const props = this.props;

        const building = appContext().localStorageProvider
            .getNodeData()
            .Regions
            .flatMap(region => region.Buildings)
            .find(building => building.Node_Id == props.buildingId) as INode;

        const floor = building.Floors.find(i => i.Node_Id == props.floorId) ?? null;
        const spaceType = Helper.getSpaceTypesByNodeId(props.buildingId).result.find(i => i.Name == props.spaceTypeId) ?? null;

        await this.setStateAsync({
            floor: (floor == null ? null : { id: floor.Node_Id, name: floor.Node_Name }),
            spaceType: (spaceType == null ? null : { id: spaceType.Name, name: spaceType.Label }),
            minCapacity: props.minCapacity,
            requiresCatering: props.requiresCatering,
            requiresAudioVisual: props.requiresAudioVisual,
            requiresPresentationAids: props.requiresPresentationAids,
            requiresHearingAids: props.requiresHearingAids,
        });
    }

    private async getSpaces(buildingId: number): Promise<void>
    {
        const spaces = await appContext().apiCache.getSpacesByBuilding(buildingId);
        const indexedSpaces = new Map(spaces.map(i => [i.Space_Id, i]));
        await this.setStateAsync({ spaces: indexedSpaces });
    }

    private async loadSpaces(skipToken: string | null, pageSize: number): Promise<IBatch<ISpace>>
    {
        try
        {
            this.setState({ isLoading: true });
            const { spaces } = this.state;

            const spacesData = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<ISpaceData[]>>({
                nodeId: this.nodeId == 0 ? this.state.buildingId : this.nodeId,
                top: pageSize,
                skipToken: skipToken ?? undefined,
                body: this.payload,
            });

            const spacesDetails = spacesData.value.map(i =>
            {
                const space = spaces.get(i.Space_Id) ?? null;
                return {
                    id: i.Space_Id,
                    nodeId: space?.Node_Id ?? 0,
                    name: space?.Space_Name ?? '',
                    bookingPolicyId: space?.Booking_Policy_Id ?? '',
                    imageURI: space?.ImageURI ?? '',
                    spaceType: space?.Space_Type ?? '',
                    capacity: space?.Space_Capacity ?? 0,
                    hasCatering: space?.Meta_Serv_Reqs_Catering == 1,
                    hasHearing: space?.Meta_Serv_Reqs_Hearing == 1,
                    hasAv: space?.Meta_Serv_Reqs_AV == 1,
                    hasPresentationAids: space?.Meta_Serv_Reqs_Presentation == 1,
                    zone: space?.Meta_Loc_Zone ?? '',
                } as ISpace;
            });

            return { skipToken: spacesData.skipToken, items: spacesDetails };
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private get payload(): ISearchRecurring
    {
        const props = this.props;
        const state = this.state;

        const payload: ISearchRecurring = {
            Booking_Dates: [{
                Start_Time: props.availableFrom.toUtcByNode(this.nodeId).toISO(),
                End_Time: props.availableTo.toUtcByNode(this.nodeId).toISO(),
            }],
            Floor_Id: state.floor?.name != "Any" ?  state.floor?.id : undefined,
            Space_Type: state.spaceType?.id,
            Space_Capacity: state.minCapacity,
            Meta_Serv_Reqs_Catering: state.requiresCatering ? 1 : undefined,
            Meta_Serv_Reqs_AV: state.requiresAudioVisual ? 1 : undefined,
            Meta_Serv_Reqs_Presentation: state.requiresPresentationAids ? 1 : undefined,
            Meta_Serv_Reqs_Hearing: state.requiresHearingAids ? 1 : undefined,
        };
        return payload;
    }

    private handleSpaceChange(event: React.ChangeEvent<HTMLInputElement>): void
    {
        const props = this.props;
        const state = this.state;
        const spaceId = event.target.value;
        const space = state.pageOfSpaces.find(i => i.id == spaceId) as ISpace;
        if(props.onChange)
        {
            props.onChange(space);
        }
    }

    private async handlePreviousPageClick(): Promise<void>
    {
        return this.changePage(-1);
    }

    private async handleNextPageClick(): Promise<void>
    {
        return this.changePage(+1);
    }

    private async changePage(offset: number): Promise<void>
    {
        this.pager.pageIndex += offset;
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setState({ pageOfSpaces: pageOfSpaces });
    }

    private async handleFilterSubmit(filter: ISpaceFilter): Promise<void>
    {
        await this.getSpaces(filter.selectedBuildingId);
        await this.setStateAsync({
            pageOfSpaces: [],
            floor: filter.floor,
            spaceType: filter.spaceType,
            minCapacity: filter.minCapacity,
            requiresCatering: filter.requiresCatering,
            requiresAudioVisual: filter.requiresAudioVisual,
            requiresPresentationAids: filter.requiresPresentationAids,
            requiresHearingAids: filter.requiresHearingAids,
            buildingId: filter.selectedBuildingId,
            openFilterDialog: false,
        });

        this.pager.clearItems();
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setState({ pageOfSpaces: pageOfSpaces });
    }

    public handleSpaceSelect(spaceId: string): void
    {
        this.setState({ selectedSpaceId: spaceId });
        if (this.props.onSpaceChange)
        {
            this.props.onSpaceChange(spaceId);
        }    
    }

    public render(): JSX.Element
    {
        const props = this.props;
        const state = this.state;
        const chipMargin = '0 4px 4px 0';
        const labels = appContext().labels;

        const pagingButtonStyle = (isDisabled: boolean) => ({
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '2rem',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            margin: '0 5px',
            color: isDisabled ? 'var(--ui-mid-tone)' : 'var(--ui-text-light)',
        });

        return (
            <>
                {state.isLoading && <Spinner />}
                <Grid container direction="column">
                    <Grid item>
                        {state.floor && <IbssChip sx={{ margin: chipMargin }} label={state.floor.name} />}
                        {state.spaceType && <IbssChip sx={{ margin: chipMargin }} label={state.spaceType.name} />}
                        <IbssChip sx={{ margin: chipMargin }} label={`${labels.funcMinCapacity_S}: ${state.minCapacity}`} />
                        {state.requiresCatering && <IbssChip sx={{ margin: chipMargin }} label={labels.funcCatering_S} />}
                        {state.requiresAudioVisual && <IbssChip sx={{ margin: chipMargin }} label={labels.funcHasAudioVisualEquipment_S} />}
                        {state.requiresPresentationAids && <IbssChip sx={{ margin: chipMargin }} label={labels.funcHasPresentationAids_S} />}
                        {state.requiresHearingAids && <IbssChip sx={{ margin: chipMargin }} label={labels.funcHasHearingAids_S} />}
                    </Grid>
                    <Grid item>
                    {props.swapSpace ?
                            this.state.pageOfSpaces.map(spaces =>
                            <SwapSpaceCard
                                key={spaces.id}
                                space={spaces}
                                floorName={getFloorUsingFloorId(spaces.nodeId)}
                                selectedSpaceId={this.state.selectedSpaceId}
                                handleSpaceSelect={(id) => this.handleSpaceSelect(id)}
                            />
                        )
                    :        
                    <RadioGroup
                        value={props.selectedSpaceId}
                        onChange={e => this.handleSpaceChange(e)}
                     >
                        {this.state.pageOfSpaces.map(i =>
                            <FormControlLabel
                                key={i.id}
                                value={i.id}
                                control={<Radio />}
                                label={i.name}
                            />
                        )}
                    </RadioGroup>
                    }
                         
                    </Grid>
                    <Grid item container justifyContent='flex-end'>
                        <button
                            disabled={this.pager.isFirstPage}
                            onClick={() => this.handlePreviousPageClick()}
                            title={labels.HubButtonPrevious}
                            style={pagingButtonStyle(this.pager.isFirstPage)}
                        >
                            &lt;
                        </button>
                        <button
                            disabled={this.pager.isLastPage}
                            onClick={() => this.handleNextPageClick()}
                            title={labels.HubButtonNext}
                            style={pagingButtonStyle(this.pager.isLastPage)}
                        >
                            &gt;
                        </button>
                    </Grid>
                    <Grid item>
                        <IbssButton
                            color="secondary"
                            variant="contained"
                            fullWidth
                            sx={{ mt: '1rem', padding: '0.75rem' }}
                            onClick={() => this.setState({ openFilterDialog: true })}
                        >
                            {labels.funcAdjustFilters_S}
                        </IbssButton>
                    </Grid>
                </Grid>
                <SpaceFilterDialog
                    open={state.openFilterDialog}
                    buildingId={props.buildingId}
                    floorId={state.floor?.id ?? 0}
                    hideWorkType={true}
                    spaceTypeId={state.spaceType?.id ?? 'Any'}
                    minCapacity={state.minCapacity}
                    lowestMinCapacity={this.props.lowestMinCapacity ?? 1}
                    requiresCatering={state.requiresCatering}
                    hideEquipment={true}
                    requiresAudioVisual={state.requiresAudioVisual}
                    requiresPresentationAids={state.requiresPresentationAids}
                    requiresHearingAids={state.requiresHearingAids}
                    onClose={() => this.setState({ openFilterDialog: false })}
                    onSubmit={i => this.handleFilterSubmit(i)}
                    swapSpace={true}
                />
            </>
        );
    }
}

export interface IProps
{
    buildingId: number;
    floorId: number;
    spaceTypeId: string;
    minCapacity: number;
    lowestMinCapacity?: number;
    selectedSpaceId?: string;
    requiresCatering: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
    availableFrom: DateTime;
    availableTo: DateTime;
    onSpaceChange?: (space: string) => void;
    onChange?: (space: ISpace) => void;
    swapSpace?: boolean;
}

export interface IState
{
    isLoading: boolean;
    spaces: Map<string, Space>;
    pageOfSpaces: ISpace[];
    openFilterDialog: boolean;
    buildingId: number;
    floor: IFloor | null;
    spaceType: ISpaceType | null;
    minCapacity: number;
    requiresCatering: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
    selectedSpaceId: string,
}

export interface ISpace
{
    id: string;
    nodeId: number;
    name: string;
    bookingPolicyId: string,
    imageURI: string;
    capacity: number;
    hasCatering: boolean;
    spaceType: string;
    hasHearing: boolean;
    hasAv: boolean;
    hasPresentationAids: boolean;
    zone: string;
}
