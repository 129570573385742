import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { headerSelectionCall, headerImage } from "../../../../../src/Common/Helper";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { appContext } from "../../../../AppContext";

export class SpaceAnalyticsLeftHeader extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state = { lightModeTheme: appContext().state.lightModeTheme };
    }

    public componentDidMount(): void
    {
        appContext().state.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public render(): JSX.Element
    {
        const { lightModeTheme } = this.state;
        const { numOfSummaries, spaceType, classType, workSpaceType, buildingOptions, floorIdOrAny, groupBy } = this.props;

        return (
            <div className="card-result p-10 my-6">
                <div className="d-flex justify-content-center p-10">
                    <div className="">
                        {numOfSummaries === 0 ? "" : <img
                            alt=""
                            src={`/images/SpaceType_Icons/${lightModeTheme ? "Light_Theme" : "Dark_Theme"
                                }/${headerImage(
                                    groupBy,
                                    floorIdOrAny,
                                    buildingOptions,
                                    workSpaceType
                                )}.svg`}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                            {
                                e.stopPropagation();
                                (e.target as HTMLImageElement).onerror = null;
                                (e.target as HTMLImageElement).src = `/images/SpaceType_Icons/${lightModeTheme ? "Light_Theme" : "Dark_Theme"
                                    }/${spaceType}.svg`;
                            }}
                        />}

                    </div>
                    <div className="mx-10 result-item-center">
                        <div className="text-ui-20">{numOfSummaries}</div>
                        <div className="text-ui-14">
                            {headerSelectionCall(
                                groupBy,
                                floorIdOrAny,
                                buildingOptions,
                                workSpaceType,
                                classType,
                                numOfSummaries
                            )}
                        </div>
                    </div>
                    <div>
                        <img alt="arrowImg" src="/images/Arrow.svg" />
                    </div>
                </div>
            </div>
        );
    }
};

export default SpaceAnalyticsLeftHeader;

export interface IProps
{
    numOfSummaries: number;
    spaceType?: string;
    classType: string;
    workSpaceType: string;
    buildingOptions: string;
    floorIdOrAny: string;
    groupBy: string;
}

export interface IState
{
    lightModeTheme: boolean;
}
