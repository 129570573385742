import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import MediaSelector from '../../../../Components/DialogLaunchers/MediaSelector/MediaSelector';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssCheckBox from '../../../../Components/Inputs/CheckBox/IbssCheckBox';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';

class BuildingDetailsForm extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            selectedImage: '',
            showSearchConfigurationPopup: false,
            showSearchConfigurationError: false,
            searchConfigurationJson: "{}",
            searchConfigurationJsonUpdated: false
        };
    };

    public componentDidMount(): void
    {
        this.setState({ searchConfigurationJson: this.props.searchConfigurationJson });
    }

    public componentDidUpdate(): void
    {
        if(this.state.searchConfigurationJson != this.props.searchConfigurationJson && this.state.searchConfigurationJsonUpdated == false && !this.props.newBuilding)
        {
            //on initial load when editing a building the search config in this components state needs aligning to what is passed in from props
            this.setState({ searchConfigurationJson: this.props.searchConfigurationJson, searchConfigurationJsonUpdated: true });
        }
    }

    private get searchConfigurationIsValid(): boolean
    {
        if (this.state.showSearchConfigurationError)
        {
            return false;
        }
        try
        {
            const object = JSON.parse(this.state.searchConfigurationJson);
            return !Array.isArray(object);
        }
        catch
        {
            return false;
        }
    }

    private async searchConfigurationSubmitted(): Promise<void>
    {
        this.props.onSearchConfigurationSubmitted(this.state.searchConfigurationJson);
        this.setState({ showSearchConfigurationPopup: false, showSearchConfigurationError: false });
    }

    private prettify(): void
    {
        try
        {
            this.setState({ searchConfigurationJson: JSON.stringify(JSON.parse(this.state.searchConfigurationJson), null, 4) });
        }
        catch
        {
        }
    }

    public render(): JSX.Element
    {
        return (
            <div>
                <MediaSelector
                    options={this.props.images}
                    imageSelected={file => { this.setState({ selectedImage: file.url }); this.props.imageChanged(file.url) }}
                    uploadFile={(filename, file, processAsMap) => this.props.uploadImage(filename, file, processAsMap)}
                    defaultSelectedImage={this.props.imageUrl}
                />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcTimeZone_S} fullWidth value={this.props.timeZone} onChange={(e) => this.props.timeZoneChanged(e.target.value)} />
                <IbssTextField type='number' className='mt-1' variant='outlined' label={this.labels.funcBuildingSortName_S} fullWidth value={this.props.sortName} onChange={(e) => this.props.sortNameChanged(e.target.value)} helperText={this.labels.funcSortNameInfo_Message} />
                <IbssCheckBox label={this.labels.funcTimeZoneDST_S} checked={this.props.timeZoneDst} onClicked={(e: { target: { checked: boolean; }; }) => this.props.timeZoneDstChanged(e.target.checked)} />
                <IbssButton className='mt-1' variant='contained' onClick={() => this.setState({ showSearchConfigurationPopup: true })}>{this.labels.funcSearchConfigurations_S}</IbssButton>
                <div style={{ fontSize: '0.75rem', marginTop: '4px', marginLeft: '4px' }}>{this.labels.funcBuildingSearchConfigurationInfo_Message}</div>
                <IbssDialog
                    open={this.state.showSearchConfigurationPopup}
                    onClose={() => this.setState({ showSearchConfigurationPopup: false, searchConfigurationJson: this.props.searchConfigurationJson })}
                    fullWidth
                    header={this.labels.funcSearchConfigurationJSONViewer_S}
                    dialogContent=
                    {
                        <>
                            <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                                <label>{this.labels.funcSearchConfigJsonSubheader_S}</label>
                                <IbssButton
                                    color='primary'
                                    disabled={!this.searchConfigurationIsValid}
                                    onClick={() => this.prettify()}
                                >
                                    {this.labels.funcFormatJSON_S}
                                </IbssButton>
                            </div>
                            {
                                !this.searchConfigurationIsValid &&
                                <div className="text-danger">{this.labels.HubLabelInvalidJSONMessage}</div>
                            }
                            <div className="nonePointer" style={{ margin: "10px 0 0 0" }}>
                                <textarea
                                    name="modalJSON"
                                    onChange={e => this.setState({ searchConfigurationJson: e.target.value, showSearchConfigurationError: false })}
                                    className="textAreaUserDetails"
                                    value={this.state.searchConfigurationJson} />
                            </div>
                        </>
                    }
                    footer=
                    {
                        <>
                            <IbssButton
                                color='primary'
                                variant='contained'
                                className='mr-2'
                                disabled={!this.searchConfigurationIsValid}
                                onClick={() => this.searchConfigurationSubmitted()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        </>
                    }
                />
            </div>
        )
    }
}

export default BuildingDetailsForm;

export interface IProps
{
    images: IMediaSelectorOption[];
    timeZone: string;
    timeZoneChanged: (value: string) => void;
    timeZoneDst: boolean;
    timeZoneDstChanged: (value: boolean) => void;
    uploadImage: (filename: string, file: FormData, processAsMap: boolean) => Promise<void>;
    imageUrl: string;
    imageChanged: (imageUrl: string) => void;
    onSearchConfigurationSubmitted: (json: string) => void;
    searchConfigurationJson: string;
    sortName: string;
    sortNameChanged: (value: string) => void;
    newBuilding: boolean;
}

export interface IState
{
    selectedImage: string;
    showSearchConfigurationPopup: boolean;
    showSearchConfigurationError: boolean;
    searchConfigurationJson: string;
    searchConfigurationJsonUpdated: boolean;
}

export interface IMediaSelectorOption
{
    name: string;
    url: string;
}