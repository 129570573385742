import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class SpaceLayouts extends ODataSelect
{
    Space_Id = '';
    Space_Name = '';
    Node_Id = 0;
    Meta_Loc_Zone = '';
    Space_Layout = '';
    ConcurrencyStamp = '';
}

