import { DateHelper } from "../../../../Common/DateHelper";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";
import { DateTime } from "luxon";

export class CateringOrder extends ODataSelect
{
    Menu_Id = "";
    Order_Id = "";
    Node_Id = 0;
    Menu_Items = "";
    Catering_Service_Time = "";
    Catering_Clearing_Time = "";
    Catering_Order_Status = "";
    Catering_Attendees = "";
    Catering_Notes = "";
    Cost_Code_Allocation = "";
    Catering_Order_Restrictions = "";
    Booking_Name = "";
    Booking_Id = "";
    Space_Name = "";
    Space_Id = "";
}

export class ICateringMenuExpanded extends ODataSelect
{
    public Node_Id = 0;
    public Menu_Id = '';
    public Name = '';
    public Description = '';
    public Short_Description = '';
    public ImageURI = '';
    public Status = '';
    public ConcurrencyStamp = '';
    public Available_From = DateTime.now();
    public Available_Until = DateTime.now();
    public Menu_MenuItems: IMenuItems[] = [];

    public toExpand()
    {
        return 'Menu_MenuItems($expand=MenuItem($expand=MenuItem_Restrictions($expand=Restriction)))';
    }

    public init(response: any, nodeId: number): ICateringMenuExpanded
    {
        super.init(response, nodeId)
        return {
            ...response,
            Node_Id: response.Node_Id,
            Menu_Id: response.Menu_Id,
            Name: response.Name,
            Description: response.Description,
            Short_Description: response.Short_Description,
            ImageURI: response.ImageURI,
            Status: response.Status,
            ConcurrencyStamp: response.ConcurrencyStamp,
            Available_From: DateHelper.fromIsoByNode(response.Available_From, nodeId),
            Available_Until: DateHelper.fromIsoByNode(response.Available_Until, nodeId),
            Menu_MenuItems: response.Menu_MenuItems,
        }
    }
}

export interface IMenuItems
{
    MenuItem_Id: string;
    Menu_Id: string;
    MenuItem: IMenuItem;
}

export interface IMenuItem
{
    Description: string;
    Status: Status;
    UnitPrice: number;
    Classification: Classification;
    RetailPrice: number;
    Name: string;
    Supplier_Id: string;
    MenuItem_Id: string;
    ConcurrencyStamp: string;
    NutritionalInformation: string;
    OrderPolicy_Id: string;
    ImageURI: string;
    CreatedAt: string;
    MenuItem_Restrictions: ICateringRestriction[];
}

export interface ICateringRestriction
{
    Restriction_Id: string;
    Restriction: ICateringRestrictionDetails;
}

export interface ICateringRestrictionDetails
{
    Restriction_Id: string;
    Section: string;
    ImageURI: string;
    Name: string;
    ConcurrencyStamp?: string;
}

export type Classification = "MenuItemClassificationBeverage" | "MenuItemClassificationSnack" | "MenuItemClassificationFood"

export type Status = "StatusActive" | "StatusInactive"

export interface IFloor
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_FloorCount: number;
    Node_SpaceCount: number;
    Floor_Occupier: string;
    Floor_MapURI: string;
    Zones: (IZone[] | null);
}

export interface IZone
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_SpaceCount: number;
    Meta_Loc_Zone: string;
}

export class ISpace
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Type = "";
    public Space_Type_Label = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public Space_Status = "";
    public ImageURI = "";
    public Booking_Policy_Id = "";
    public Meta_Bookable = 0;
    public Meta_Occ_Type = 0;
    public Meta_Ext_Occ_Count_Mode = 0;
    public Meta_Loc_Zone = "";
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
    public Space_Work_Type = "";
    public Space_Custom_Info = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
    public Env_Zone_Id = 0;
    public Meta_Ext_Catering_Space_Id = [];
    public Meta_Ext_Booking_System = "";
    public Space_Occupancy = 0;
}