import { DateTime } from "luxon";
import { appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";

export class TimeValidationHelper 
{
    private get labels() { return appContext().labels; }
    private errors: TimeValidationError[];

    constructor() 
    {

        this.errors = [];
    }

    public addError(error: TimeValidationError)
    {
        this.errors.push(error);
    }

    public isValid(): boolean
    {
        return (this.errors.length == 0);
    }

    public validate(startTime: DateTime, endTime: DateTime, nodeId: number, date: DateTime): void
    {
        this.errors = new Array<TimeValidationError>();
        const now = DateHelper.now(nodeId).plus({ minutes: 1 });
        if (!startTime.isValid)
        {
            this.addError(TimeValidationError.StartInvalid);
        }
        if (!endTime.isValid)
        {
            this.addError(TimeValidationError.EndInvalid);
        }
        
        if (startTime.isValid)
        {
            if (startTime < now && date.hasSame(DateHelper.now(nodeId), 'day'))
            {
                this.addError(TimeValidationError.StartLessThanNow);
            }
        }

        if (endTime.isValid)
        {
            if (endTime < now && date.hasSame(DateHelper.now(nodeId), 'day'))
            {
                this.addError(TimeValidationError.EndLessThanNow);
            }
        }

        if (startTime.isValid && endTime.isValid)
        {
            if (startTime >= endTime)
            {
                this.addError(TimeValidationError.StartGreaterThanEqualToEnd);
            }
            if (startTime.date().diff(endTime.date()).milliseconds != 0)
            {
                this.addError(TimeValidationError.StartDateNotEqualToEndDate);
            }
        }
    }

    public toStartTimeMessage(): string
    {
        let messages = new Array<string>();
        this.errors.map(i =>
        {
            switch (i)
            {
                case TimeValidationError.StartInvalid:
                    messages.push(this.labels.HubLabelMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.StartLessThanNow:
                    messages.push(this.labels.HubLabelMustNotBeInThePast);
                    break;
                case TimeValidationError.StartGreaterThanEqualToEnd:
                    messages.push(this.labels.HubLabelMustBeBeforeTheEndTime);
                    break;
                case TimeValidationError.StartDateNotEqualToEndDate:
                    messages.push(this.labels.HubLabelMustBeOnTheSameDayAsTheEndTime);
                    break;
            }
        });
        return messages.join(" ");
    }

    public toEndTimeMessage(): string
    {
        let messages = new Array<string>();
        this.errors.map(i =>
        {
            switch (i)
            {
                case TimeValidationError.EndInvalid:
                    messages.push(this.labels.HubLabelMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.EndLessThanNow:
                    messages.push(this.labels.HubLabelMustNotBeInThePast);
                    break;
                case TimeValidationError.StartGreaterThanEqualToEnd:
                    messages.push(this.labels.HubLabelMustBeAfterTheStartTime);
                    break;
                case TimeValidationError.StartDateNotEqualToEndDate:
                    messages.push(this.labels.HubLabelMustBeOnTheSameDayAsTheStartTime);
                    break;
            }
        });
        return messages.join(" ");
    }
}

enum TimeValidationError
{
    StartInvalid,
    StartLessThanNow,
    StartGreaterThanEqualToEnd,
    StartDateNotEqualToEndDate,
    EndInvalid,
    EndLessThanNow,
}
