import { Box } from "@mui/material";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import MobileDeviceIcon from "../../Icons/MobileDevicesIcon";
import Bar from "../../Icons/SpaceTypeIcons/bar";
import MobileIcon from "../../Icons/MobileIcon";
import TabletIcon from "../../Icons/TabletIcon";
import KiskoIcon from "../../Icons/KiskoIcon";
import IdProvider from "../../Icons/IdProviderIcon";
import NotificationIcon from "../../Icons/NotificationIcon";
import OrganisationIcon from "../../Icons/OrganisationIcon";
import RegionIcon from "../../Icons/RegionIcon";
import UserIcon from "../../Icons/UserIcon";
import Meetingroom from "../../Icons/SpaceTypeIcons/meetingroom";
import ZoneIcon from "../../Icons/ZoneIcon";
import BuildingIcon from "../../Icons/BuildingIcon";
import Drink from "../../Icons/SpaceTypeIcons/drink";
import SensorIcon from "../../Icons/SensorIcon";
export class TablePanelInfo extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state = {
        };
    }

    private renderIcon(value: string): JSX.Element | null 
    {
        switch (value) {
            case "Mobile":
                return <MobileIcon />;
            case "Tablet":
                return <TabletIcon />;
            case "Kisko":
                return <KiskoIcon />;
            case "MobileDevices":
                return <MobileDeviceIcon />;
            case "IdProvider":
                return <IdProvider />;
            case "Notification":
                return <NotificationIcon />;
            case "Organisation":
                return <OrganisationIcon />;
            case "Region":
                return <RegionIcon />;
            case "User":
                return <UserIcon />;
            case "MeetingRoom":
                return <Meetingroom />;
            case "Zone":
                return <ZoneIcon />;
            case "Building":
                return <BuildingIcon />;
            case "Drink":
                return <Drink />;
            case "Sensor":
                return <SensorIcon />;
            default:
                return null;
        }
    }
    
 
    public render(): JSX.Element
    {
        return (
            <div className="chart-panel-info">
                <div className="chart-panel-heading">
                    <Box className="chart-panel-icon" mx={1}>
        
                        <IbssSvgIcon>
                            {this.renderIcon(this.props.iconSrc)}
                        </IbssSvgIcon>
                       
                    </Box>
                    <h1 className="chart-panel-header">{this.props.tableHeading}</h1>
            </div>
            </div>
        );
    }
}
export interface IProps
{
    tableHeading: string;
    iconSrc: string;
}
export interface IState
{
}