import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Floor extends ODataSelect
{
    ID = 0;
    Name = "";
    DisplayName = "";
    SortOrder = "";
    MapURI = "";
    ImageURI = "";
    IsEnabled = 0;
    Agents: IAgents = 
    {
        Owner:  "",
        Operator: "",
        Occupier: "", 
    }
    Node_Search_Config: INodeSearchConfig =
    {
        config: ""
    }
    Physical_Properties: IPhysicalProperties =
    {
        Capacity: "",
        Size: ""
    }
} 



export interface IAgents 
{
    Owner: string;
    Operator: string;
    Occupier: string;
}

export interface INodeSearchConfig
{
    config: string;
}
export interface IPhysicalProperties
{
    Capacity: string;
    Size: string;
}