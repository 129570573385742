import { appContext } from '../../../../AppContext';
import { CateringOrderStatus } from '../../../../Common/Enums';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class UpdateCateringStatusButtons extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div className="mt-1" style={{ marginLeft: '25px' }}>
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.PendingApproval &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        color='error'
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.NotApproved)}
                    >
                        {this.labels.funcCateringOrderDecline_S}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.PendingApproval &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.Approved)}
                    >
                        {this.labels.HubLabelApprove}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.Approved && !this.props.pastCutOffPeriod &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.ReadyForPrep)}
                    >
                        {this.labels.funcCateringOrderReadyForPrep_S}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.ReadyForPrep &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.ReadyForDelivery)}
                    >
                        {this.labels.funcReadyforDelivery_S}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.ReadyForDelivery &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.Delivered)}
                    >
                        {this.labels.funcCateringOrderDelivered_S}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.Delivered &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.Cleared)}
                    >
                        {this.labels.funcCateringOrderCleared_S}
                    </IbssButton>
                }
                {
                    this.props.cateringOrderStatus == CateringOrderStatus.Cleared &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.Completed)}
                    >
                        {this.labels.HubLabelCompleted}
                    </IbssButton>
                }
                {
                    (this.props.cateringOrderStatus == CateringOrderStatus.Approved ||
                        this.props.cateringOrderStatus == CateringOrderStatus.ReadyForPrep ||
                        this.props.cateringOrderStatus == CateringOrderStatus.ReadyForDelivery ||
                        this.props.cateringOrderStatus == CateringOrderStatus.Delivered ||
                        this.props.cateringOrderStatus == CateringOrderStatus.CancellationPending) &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        color="error"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.CancelledCharged)}
                    >
                        {this.labels.funcCateringOrderCancelOrderCharge_S}
                    </IbssButton>
                }
                {
                    (this.props.cateringOrderStatus == CateringOrderStatus.Approved ||
                        this.props.cateringOrderStatus == CateringOrderStatus.ReadyForPrep ||
                        this.props.cateringOrderStatus == CateringOrderStatus.ReadyForDelivery ||
                        this.props.cateringOrderStatus == CateringOrderStatus.Delivered ||
                        this.props.cateringOrderStatus ==  CateringOrderStatus.CancellationPending) &&
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '30px', marginBottom: '15px' }}
                        variant="contained"
                        color="error"
                        onClick={() => this.props.onStatusChangeButtonClicked(CateringOrderStatus.CancelledNoCharge)}
                    >
                        {this.labels.funcCateringOrderCancelOrderNoCharge_S}
                    </IbssButton>
                }
            </div>
        )
    }
}

export default UpdateCateringStatusButtons;

export interface IProps
{
    cateringOrderStatus: string;
    onStatusChangeButtonClicked: (status: string) => void;
    pastCutOffPeriod: boolean;
}

export interface IState
{
}