export enum CateringOrderStatus
{
    PendingApproval = 'PendingApproval',
    NotApproved = 'NotApproved',
    Approved = 'Approved',
    ReadyForPrep = 'ReadyForPrep',
    ReadyForDelivery = 'ReadyForDelivery',
    Delivered = 'Delivered',
    Cleared = 'Cleared',
    Completed = 'Completed',
    CancellationPending = 'CancellationPending',
    CancelledNoCharge = 'CancelledNoCharge',
    CancelledCharged = 'CancelledCharged'
}