import { IconButton } from '@mui/material';
import { Icons } from '../../../../Common/AllsvgIcons';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import CloseIcon from '@mui/icons-material/Close';

class ExistingSpaceLayoutBox extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div className='d-flex justify-content-between' style={{ cursor: 'pointer', background: 'var(--ui-background)', borderRadius: '8px' }}>
                <div onClick={() => this.props.onSpaceLayoutSelected(this.props.spaceLayoutName)} style={{ alignContent: 'center', width: '85%', padding: '20px' }}>{this.props.spaceLayoutName}</div>
                {
                    this.props.canBeDeleted &&
                    <div style={{ padding: '20px' }}>
                        <IconButton onClick={() => this.props.onDeleteSelected()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                }
            </div>
        )
    }
}

export default ExistingSpaceLayoutBox;

export interface IProps
{
    spaceLayoutName: string;
    canBeDeleted: boolean;
    onDeleteSelected: () => void;
    onSpaceLayoutSelected: (spaceLayoutName: string) => void;
}

export interface IState
{
}