import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class BookingPolicy extends ODataSelect
{
    public Node_Id = 0;
    public Booking_Policy_Id = '';
    public Booking_Policy_Name = '';
    public Booking_Policy_Description = '';
    public Booking_Policy = '';
}

export interface IBookingPolicyPayload
{
    Node_Id: number;
    Booking_Policy_Id?: string;
    Booking_Policy_Name: string;
    Booking_Policy: IBookingPolicyData;
}

export interface IBookingPolicyData
{
    PolicyType: string;
    BuildingId?: string;
    FloorId?: string;
    Allowed_TimeRange?: string;
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
    ArrivalPolicies?: IArrivalPolicies;
    ApprovalPolicies?: IApprovalPolicies;
    ExtendedServices?: 
    {
        SpaceSetupManagement: IExtendedServices;
        CateringManagement: IExtendedServices;
    };
    BookingAttendanceControl?: string;
}

export interface IBookingSlots
{
    BookableTime?: IBookableTime[];
    ExcludedDates: IExcludedDate[];
    BookingStart?: IBookingStart;
    BookingEnd?: IBookingEnd;
    BookingDuration?: IBookingDuration;
    BookingHorizon?: IBookingHorizon;
    BookingRecurrence?: IBookingRecurrence;
    BookingSetupTime?: number;
    BookingTearDownTime?: number;
}

export interface IBookableTime
{
    Days: string[];
    StartTime: string;
    EndTime: string;
}

export interface IExcludedDate
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
    Reason: string;
}

export interface IBookingStart
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IBookingEnd
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IBookingDuration
{
    Minimum: number;
    Maximum: number;
    Fixed: string[];
}
export interface IBookingHorizon
{
    Minimum: number;
    Maximum: number;
    BookingOffset: string;
}
export interface IArrivalPolicies
{
    AutoCheckin: boolean;
    EarlyCheckin: number;
    AutoCancellation: number;
}

export interface IApprovalPolicies
{
    ApprovalRequired: boolean;
    AutoRejectionAfter: number;
    ApprovalGroups: string[];
}

export interface IBookingRecurrence
{
    Allowed: boolean;
    Maximum: number;
}

export interface IExtendedServices
{
    ManagementType: 'user' | 'group',
    User: string[],
    Group: string[]
}

export class Roles extends ODataSelect
{
    public id = '';
    public name = '';
}