import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetCateringOrderEndpoint implements IGetCateringOrderEndpoint
{
    public async execute(nodeId: number, orderId: string): Promise<ICateringOrder>
    {
        try
        {
            const response = await axios.get<ICateringOrder>(`${apis.allApiv2}${nodeId}/catering-order/${orderId}`);
            response.data.Menu_Items = JSON.parse(response.data.Menu_Items as unknown as string);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetCateringOrderEndpoint
{
    execute(nodeId: number, orderId: string): Promise<ICateringOrder>;
}

export interface ICateringOrder
{
    Menu_Id: string;
    Order_Id: string;
    Node_Id: number,
    Menu_Items: IMenuItem[];
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Attendees: string;
    Catering_Notes: string;
    Cost_Code_Allocation: string;
    Catering_Order_Restrictions: string;
    Booking_Name: string;
    Booking_Id: string;
    Space_Name: string;
    Space_Id: string;
}

export interface IMenuItem
{
    Id: string;
    Menu_Id: string;
    QuantityOfItems: number;
}
