import React, { ReactNode } from 'react';
import '../../../../styles/css/table.scss';
import '../../../../App.css';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { RootStateOrAny } from 'react-redux';
import apis from '../../../../Providers.Api/apis';
import IbssbreadcrumbLeft, { IBreadcrumbModel } from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import SearchCriteria, { ISearchCriteria } from './SearchCriteria';
import Helper, { classSapceCall, periodTypeCall, classCapacityCall, spaceDiff, getPeriodTypeString, imgCall, donutColor, getFloorsFromSelectedBuildingUsingBuildingId, spaceName } from '../../../../../src/Common/Helper';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import { appContext } from '../../../../AppContext';
import { ONELENS_SPACEANALYTICS_FILTER, ONE_LENSE_DATE_BULDING, UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';
import SpaceAnalyticsLeftHeader from './SpaceAnalyticsLeftHeader';
import SpaceAnalyticsRightHeader from './SpaceAnalyticsRightHeader';
import { defaultAny, workspaceTypeDefault, constTotal } from '../../../../app/constants';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import { DateTime } from 'luxon';
import { DateHelper } from '../../../../Common/DateHelper';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { Space, SpaceUtilisationData, SpaceUtilisationFilter } from './DataModels';
import IbssDonutProgressChart from '../../../../Components/Data/DonutProgressChart/IbssDonutProgressChart';
import TrendChart from './TrendChart';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFloor, INode } from '../../../../Providers.Api/Models';

export class ViewSpaceUtilisation extends IbssPage<IProps, IState>
{
    private appState = appContext().state;
    private labels = appContext().labels;
    private filterIcons = ["Building", "Floor", "Desk", "Desk", "Calendar", "Calendar"];

    constructor(props: IProps)
    {
        super(props);

        const locationState = this.props.location.state;
        const now = DateHelper.now(this.appState.buildingId);
        const startOfMonth = now.startOf('month');
        const defaultMonthStartDate = startOfMonth.minus({ months: 1 }).startOf('month');
        const defaultMonthEndDate = startOfMonth.minus({ months: 1 }).endOf('month');
        const monthFirstStartDate = now.day !== 1 ? startOfMonth : defaultMonthStartDate;
        const monthFirstEndDate = now.day !== 1 ? startOfMonth.endOf('month') : defaultMonthEndDate.endOf('month');

        this.state = {
            lightModeTheme: this.appState.lightModeTheme,
            width: window.innerWidth,
            isLoading: true,
            openDrawer: false,
            spaceKpiTarget: { Parameter_Value: 0 },
            capacityKpiTarget: { Parameter_Value: 0 },
            filterChips: [],
            buildingId: this.appState.buildingId,
            floorIdOrAny: locationState.floortype ?? 'Any',
            classType: locationState.classtype ?? 'Work',
            workSpaceType: locationState.workspaceType ?? 'Total',
            metaBookable: locationState.metabookable ?? 0, // all spaces
            periodType: locationState.periodtype ?? 1, // month
            startDate: (locationState.startdate ? DateTime.fromISO(locationState.startdate) : monthFirstStartDate),
            endDate: (locationState.enddate ? DateTime.fromISO(locationState.enddate) : monthFirstEndDate),
            groupBy: locationState.groupby ?? 'Floor',
            summaries: [],
            allSpaces: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        try
        {
            this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme, buildingId: i.buildingId }));
            this.pageTitle = this.labels.HublabelSpaceAnalyticsOverview;
            this.setState({ isLoading: true });
            window.addEventListener("resize", this.updateDimensionsRef);
            await this.loadAll();
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.setStateAsync({ floorIdOrAny: 'Any' });
            await this.loadAll();
        }
    }

    public componentWillUnmount(): void
    {
        window.removeEventListener("resize", this.updateDimensionsRef);
    }

    private updateDimensionsRef = this.updateDimensions.bind(this);
    private updateDimensions(): void
    {
        this.setState({ width: window.innerWidth });
    }

    private getTotalSpaceCapacity(row: SpaceUtilisationData): number
    {
        const spaces = this.getSpaces(row).map(i => i.Space_Capacity);
        const totalCapacity = spaces.reduce((acc, curr) => acc + curr, 0);
        return totalCapacity;
    }

    private getSpaces(row: SpaceUtilisationData): Space[]
    {
        const spaces = this.state.allSpaces.filter(i =>
            (this.state.groupBy == "Space" ? i.Space_Name == row.Space_Name : (this.state.groupBy == "Floor" ? i.Node_Id == row.Node_Id : true)) &&
            i.Space_Class == row.Space_Class &&
            (row.Space_Type_Label == 'Total' || i.Space_Type_Label == row.Space_Type_Label)
        );
        return spaces;
    }

    private async loadAll(): Promise<void>
    {
        const spacesByBuilding = await appContext().apiCache.getSpacesByBuilding(this.appState.buildingId);
        this.setState({ allSpaces: spacesByBuilding });
        this.buildFilterChips();
        await this.loadSummaries();
    }

    private buildFilterChips(): void
    {
        const chips: IBreadcrumbModel[] = [];
        const floor = appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i.Node_Id == +this.state.floorIdOrAny);

        const chipValues = [
            this.appState.buildingName,
            floor?.Node_Name ?? 'Any',
            this.state.classType,
            this.state.workSpaceType === "Total" ? "Any" : this.state.workSpaceType,
            getPeriodTypeString(this.state.periodType),
            this.state.startDate.toLocaleDateString(),
        ];
        for (let i = 0; i <= chipValues.length - 1; i++)
        {
            chips.push({
                src: `/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/${this.filterIcons[i]}.svg`,
                value: chipValues[i] ?? "NO DATA",
            });
        }
        this.setState({ filterChips: chips });
    }

    private async loadSummaries(): Promise<void>
    {
        const summaries = await appContext().ibssApiClientV1.v1.byNodeid.spaceUtilisation.spaceUtilisationData.get<SpaceUtilisationData[]>({
            nodeId: this.appState.buildingId,
            filter: this.summariesFilter,
            select: SpaceUtilisationData,
            recursive: true,
        });
        this.setState({ summaries: summaries });

        const res = await apis.getHeaderKpiTargetSpaceUtilization(this.state.buildingId, classSapceCall(this.state.classType));
        this.setState({ spaceKpiTarget: res.data[0] });

        const res2 = await apis.getHeaderKpiTargetCapacityUtilization(this.state.buildingId, classCapacityCall(this.state.classType));
        this.setState({ capacityKpiTarget: res2.data[0] });
    }

    private get summariesFilter(): string
    {
        const nodeId = this.state.buildingId;
        const nodeLevelName = this.state.groupBy;
        const spaceClass = this.state.classType;
        const spaceTypeLabel = this.state.workSpaceType;
        const periodType = this.state.periodType;
        const periodStart = this.state.startDate;
        const periodEnd = this.state.endDate;
        const floorNodeId = this.state.floorIdOrAny;
        const bookable = this.state.metaBookable;

        let filterParts = new Array<string>();
        filterParts.push(`Node_Level_Name eq '${(floorNodeId !== "Any" && nodeLevelName === "Building") ? "Floor" : nodeLevelName}'`);
        filterParts.push(`Space_Class eq '${spaceClass}'`);
        filterParts.push(`Period_Type eq ${periodType}`);
        filterParts.push(`Period_Start_Date ge datetime'${periodStart.toUtcByNode(nodeId).toISO()}'`);
        filterParts.push(`Period_Start_Date lt datetime'${periodEnd.toUtcByNode(nodeId).toISO()}'`);

        if (bookable != 0)
        {
            switch (bookable)
            {
                case 1345:
                    filterParts.push(`(Meta_Bookable eq 1 or Meta_Bookable eq 3 or Meta_Bookable eq 4 or Meta_Bookable eq 5)`);
                    break;
                case 2:
                    filterParts.push(`(Meta_Bookable eq 2)`);
                    break;
                case 12345:
                    filterParts.push(`(Meta_Bookable eq 1 or Meta_Bookable eq 2 or Meta_Bookable eq 3 or Meta_Bookable eq 4 or Meta_Bookable eq 5)`);
                    break;
            }
        }
        if (floorNodeId != "Any" && floorNodeId != "")
        {
            filterParts.push(`Node_Id eq ${floorNodeId}`);
        }
        if (nodeLevelName != "Space" || spaceTypeLabel != "Total")
        {
            filterParts.push(`Space_Type_Label ${spaceTypeLabel === "Total" && nodeId !== 1 && nodeLevelName === "Building" ? "ne" : 'eq'} '${spaceTypeLabel === "Total" && nodeId !== 1 && nodeLevelName === "Building" ? "Total" : spaceTypeLabel}'`);
        }
        return filterParts.join(" and ");
    }

    private handleSearchCriteriaShow(): void
    {
        this.setState({ openDrawer: true });
    }

    private handleSearchCriteriaHide(): void
    {
        this.setState({ openDrawer: false })
    }

    private async handleSearchCriteriaSubmit(criteria: ISearchCriteria): Promise<void>
    {
        try
        {
            await this.setStateAsync({
                isLoading: true,
                buildingId: this.appState.buildingId,
                groupBy: criteria.nodeLevelName,
                classType: criteria.filterData.buildingClassType,
                workSpaceType: criteria.filterData.buildingWorkSpaceType,
                periodType: periodTypeCall(criteria.filterData.buildingPeriodType),
                startDate: DateTime.fromISO(criteria.periodstartDate),
                endDate: DateTime.fromISO(criteria.periodEndDate),
                floorIdOrAny: criteria.floor.toString(),
                metaBookable: criteria.metaBookable
            });
            await this.loadAll();
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    private dataGridColumns: GridColDef[] = [{
        headerName: '',
        field: "Space_Type",
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <img alt='space type' src={`/images/SpaceType_Icons/${this.state.lightModeTheme ? "Light_Theme" : "Dark_Theme"}/${imgCall(params.row.Space_Type, this.state.buildingId, this.state.workSpaceType ?? '', this.state.groupBy)}.svg`} />
    },
    {
        headerName: this.labels.HubLabelName,
        field: "Space_Name",
        minWidth: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<SpaceUtilisationData>) =>
        {
            return (this.state.groupBy == 'Building' ? params.row.Space_Type_Label : params.row.Space_Name);
        }
    },
    {
        headerName: this.labels.HublabelNoofSpaces,
        field: "Node_Level_Name",
        minWidth: 140,
        flex: 1,
        renderCell: (params) => this.getSpaces(params.row).length
    },
    {
        headerName: this.labels.HublabelSpaceUtilization,
        field: "Period_Current_Space_Value",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => <IbssDonutProgressChart
            strokeWidth={7}
            value={Math.round(params.row.Period_Current_Space_Value)}
            strokeColor={donutColor(Math.round(params.row.Period_Current_Capacity_Value))}
            fontColor={`var(--ui-text)`}
            portionColor={"#DFE2E7"}
            donutSize={50}
        />
    },
    {
        headerName: this.labels.HublabelChange,
        field: "Period_Change_Avg_Occ_Value",
        minWidth: 170,
        flex: 1,
        renderCell: (params) => <TrendChart
            percentage={Math.round(Math.round(params.row.Period_Change_Space_Value))}
            arrowSize={15}
            fontSize={1.02}
            showPercentage={true}
            size={0}
        />
    },
    {
        headerName: this.labels.HublabelMaxOccupancy,
        field: "Period_Current_Max_Occ_Value",
        minWidth: 120,
        flex: 1,
    },
    {
        headerName: this.labels.HublabelAvgOccupancy,
        field: "Period_Current_Avg_Occ_Value",
        minWidth: 140,
        flex: 1,
        renderCell: (params) => Math.round(params.row.Period_Current_Avg_Occ_Value)
    },
    {
        headerName: this.labels.HublabelAvailableCapacity,
        field: "Space_Type_Label",
        minWidth: 160,
        flex: 1,
        renderCell: (params) => this.getTotalSpaceCapacity(params.row)
    },
    {
        headerName: this.labels.HublabelCapacityUtilization,
        minWidth: 100,
        flex: 1,
        field: "Period_Previous_Capacity_Value",
        renderCell: (params) => <IbssDonutProgressChart
            strokeWidth={7}
            value={Math.round(params.row.Period_Current_Space_Value)}
            strokeColor={donutColor(Math.round(params.row.Period_Current_Capacity_Value))}
            fontColor={`var(--ui-text)`}
            portionColor={"#DFE2E7"}
            donutSize={50}
        />
    },
    {
        headerName: this.labels.HublabelChange,
        field: "Period_Change_Capacity_Value",
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <TrendChart
            percentage={Math.round(Math.round(params.row.Period_Change_Capacity_Value))}
            arrowSize={15}
            fontSize={1.02}
            showPercentage={true}
            size={0}
        />
    }];

    public render(): JSX.Element
    {
        return (
            <>
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div>
                            <div className="space-box-cont">
                                {/* Breadcrumb chip search filter */}
                                <div className="left-space-box-cont flex-row-bredcrumb">
                                    <IbssbreadcrumbLeft value={this.state.filterChips} />
                                </div>

                                <div className="right-space-box-cont">
                                    {/* Toggle Window Button*/}
                                    {!this.state.openDrawer ? (
                                        <IbssButtonRedo
                                            variant="contained"
                                            sx={{ whiteSpace: 'nowrap' }}
                                            onClick={() => this.handleSearchCriteriaShow()}
                                        >
                                            {this.labels.HubButtonEditsearchcriteria}
                                        </IbssButtonRedo>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                {/* Toggle Window */}
                                {this.state.openDrawer ? (
                                    <SearchCriteria
                                        value={{
                                            buildingNodeId: this.state.buildingId,
                                            floor: this.state.floorIdOrAny,
                                            filterData: {
                                                buildingClassType: this.state.classType,
                                                buildingWorkSpaceType: this.state.workSpaceType,
                                                buildingPeriodType: this.state.periodType.toString(),
                                            },
                                            metaBookable: this.state.metaBookable,
                                            periodstartDate: this.state.startDate.toISO(),
                                            periodEndDate: this.state.endDate.toISO(),
                                            nodeLevelName: this.state.groupBy,
                                        }}
                                        open={this.state.openDrawer}
                                        lightModeTheme={this.state.lightModeTheme}
                                        onClose={() => this.handleSearchCriteriaHide()}
                                        onSubmit={e => this.handleSearchCriteriaSubmit(e)}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box className="space-box-cont">
                                            <div className="left-space-box-cont flex-row-bredcrumb">
                                                <div className="search-results-title mb-16">
                                                    {this.labels.HublabelResults}
                                                </div>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={1}>
                                {/* Header Total Space Card */}
                                <Grid item xs={4}>
                                    <Box className="d-flex h-100">
                                        <SpaceAnalyticsLeftHeader
                                            numOfSummaries={this.state.summaries.length}
                                            spaceType={this.state.summaries[0]?.Space_Type}
                                            classType={this.state.classType}
                                            workSpaceType={this.state.workSpaceType}
                                            buildingOptions={''}
                                            floorIdOrAny={this.state.floorIdOrAny}
                                            groupBy={this.state.groupBy}
                                        />
                                    </Box>
                                </Grid>
                                {/* Header Right Section Cards*/}
                                <Grid item xs={8}>
                                    <div className="d-flex">
                                        {this.state.buildingId !== 1 ? (
                                            <SpaceAnalyticsRightHeader
                                                CurrentSpaceValue={
                                                    this.state.summaries[0]?.Period_Current_Space_Value
                                                }
                                                CurrentCapacityValue={
                                                    this.state.summaries[0]?.Period_Current_Capacity_Value
                                                }
                                                CapacityKPITarget={this.state.capacityKpiTarget?.Parameter_Value}
                                                SpaceKPITarget={this.state.spaceKpiTarget?.Parameter_Value}
                                            />
                                        ) : null}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {this.state.isLoading ?
                            <Spinner /> :
                            <Paper sx={{ padding: 2 }}>
                                <IbssDataGrid
                                    getRowId={(row: SpaceUtilisationData) => JSON.stringify(row)}
                                    rows={this.state.summaries}
                                    columns={this.dataGridColumns}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 25 } },
                                    }}
                                    pageSizeOptions={[25, 50, 100]}
                                />
                            </Paper>
                        }

                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ViewSpaceUtilisation);

export interface IProps extends RouteComponentProps<{}, {}, ILocationState>
{
}

export interface ILocationState
{
    buildingNodeId?: number;
    buildingname?: string;
    floortype?: string;
    classtype?: string;
    workspaceType?: string;
    metabookable?: number;
    periodtype?: number;
    startdate?: string;
    enddate?: string;
    groupby?: string;
}

export interface IState
{
    lightModeTheme: boolean;
    width: number;
    isLoading: boolean;
    openDrawer: boolean;
    spaceKpiTarget: { Parameter_Value?: number };
    capacityKpiTarget: { Parameter_Value?: number };
    filterChips: IBreadcrumbModel[];
    buildingId: number;
    floorIdOrAny: string;
    classType: string;
    workSpaceType: string;
    metaBookable: number;
    periodType: number;
    startDate: DateTime;
    endDate: DateTime;
    groupBy: string;
    summaries: SpaceUtilisationData[];
    allSpaces: Space[];
}
