import { Typography } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import './LayoutAndLinkedSpacesInstructions.scss'
import thing from '.Instructions.svg'
import { appContext } from '../../../../AppContext';

class LayoutAndLinkedSpacesInstructions extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div className='m-3'>
                <div style={{ textAlign: 'center' }}>
                    <img src="/images/SpaceLayoutsInstructions.svg" />
                </div>
                <Typography variant='h4' className='mt-3 mb-3'>{this.labels.funcSpaceLayoutHowTo_Message}</Typography>
                <div className='instruction-step-container'>
                    <Typography variant='body1' className='instruction-step-number'>1</Typography>
                    <Typography variant='body2' className='instruction-step-text'>{this.labels.funcSpaceLayoutInstructionPt1_Message}</Typography>
                </div>
                <div className='instruction-step-container'>
                    <Typography variant='body1' className='instruction-step-number'>2</Typography>
                    <Typography variant='body2' className='instruction-step-text'>{this.labels.funcSpaceLayoutInstructionPt2_Message}</Typography>
                </div>
                <div className='instruction-step-container'>
                    <Typography variant='body1' className='instruction-step-number'>3</Typography>
                    <Typography variant='body2' className='instruction-step-text'>{this.labels.funcSpaceLayoutInstructionPt3_Message}</Typography>
                </div>
                <div className='instruction-step-container'>
                    <Typography variant='body1' className='instruction-step-number'>4</Typography>
                    <Typography variant='body2' className='instruction-step-text'>{this.labels.funcSpaceLayoutInstructionPt4_Message}</Typography>
                </div>
            </div>
        )
    }
}

export default LayoutAndLinkedSpacesInstructions;

export interface IProps
{
}

export interface IState
{
}