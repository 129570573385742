/* eslint-disable no-loop-func */
import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import  Helper  from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { IPartialAppState, appContext } from "../../../../AppContext";
import { RouterProps, RouteComponentProps, generatePath } from "react-router";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import { SelectChangeEvent } from "@mui/material/Select";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import { Icons } from '../../../../Common/AllsvgIcons';
import { Grid, Typography } from "@mui/material";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import { IGetCateringSupplier, ISupplierView } from "../../../../Providers.Api/CateringSuppliers/CateringSupplierRepository";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { ApiError } from "../../../../Providers.Api/ApiError";
import CreateIcon from '@mui/icons-material/Create';
import { AxiosError } from "axios";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import PreparingExportDialog from "../../../../Components/Dialogs/PreparingExportDialog/PreparingExportDialog";

class ListCateringSuppliers extends IbssPage<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get session() { return appContext().sessionStorageProvider; }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            getRightApiResponseErrorLoading: false,
            isLoading: false,
            activePage: "0",
            show: false,
            buildingid: this.appState.buildingId,
            isUpdateRight: false,
            isCreateRight: false,
            isCancel: false,
            value: "",
            confirmModalshow: false,
            isCompleteLoading: false,
            searchTerm: "",
            tableData: [],
            dateDisabled: true,
            rowSelectionModel: [],
            tableMessage: "",
            noDataMessage: "",
            updates: false,
            showPreparingExportPopup: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const buildingName = this.appState.buildingName;
        this.pageTitle = this.labels.HubLabelFacilityManagementText + buildingName;
        this.setState({
            isLoading:true
        })
        this.appState.autoMap(this, i => ({ buildingid: i.buildingId }));
        const buildingId = this.props.match.params.buildingid;
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: parseInt(buildingId) });
            if (this.props.match.params.buildingid !== "0")
            {
                apis.getSpacesById(this.props.match.params.buildingid, "Building");
            } 
            else
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }
            this.setRights();
            this.loadSuppliers();
        } else
        {
            this.setRights();
            this.loadSuppliers();
        }
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void>
    {
        if (prevState.buildingid !== this.state.buildingid)
        {
            const { history } = this.props;
            history.push("/buildings/" + this.state.buildingid + "/catering-suppliers");
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ isLoading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.loadSuppliers();
        this.setState({ isLoading: false });
    }

    private setRights(): void 
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.CateringSuppliers.Create"),
            isUpdateRight: this.local.hasRight("DATAMODEL.CateringSuppliers.Update"),
        });
    }

    private async loadSuppliers(): Promise<void>
    {
        this.setState({
            isCompleteLoading: true
        });

        try
        {
            await this.apiClient.cateringSuppliers.getCateringSupplierByNodeId(this.state.buildingid);
        } 
        catch (error: any)
        {
            let errorData : AxiosError = error
            let errorMessage = this.labels.HubLabelTokenErorr;
            if (errorData.response)
            {
                if (errorData.response.statusText)
                {
                    errorMessage = errorData.response.statusText;
                    this.setState({
                        tableMessage: errorMessage,
                        isCompleteLoading: false,
                    });
                }
            }
        }

        {
            const res: IGetCateringSupplier = await this.apiClient.cateringSuppliers.getCateringSupplierByNodeId(this.state.buildingid);
            try
            {
                const suppliersView: ISupplierView[] = res.Value.map((item: ISupplierView) => ({
                    id: item.Supplier_Id,
                    Building_Name: Helper.getBuildingNameUsingFloorNodeId(item.Node_Id),
                    ...item,
                }));

                this.setState({
                    isCompleteLoading: false,
                    tableData: suppliersView,
                });
                this.setState({ isLoading: false });
            }
            catch (error: any)
            {
                this.setState({
                    isCompleteLoading: false,
                });
                let errorData : AxiosError = error
                let errorMessage = this.labels.HubLabelTokenErorr;
                if (errorData.response)
                {
                    if (errorData.response.statusText)
                    {
                        errorMessage = errorData.response.statusText;
                        this.setState({
                            tableMessage: errorMessage,
                            isCompleteLoading: false,
                        });
                    }
                }
            }
        }

        this.setState({ isLoading: false });
    }

    private filterSuppliers(searchTerm: string): ISupplierView[] 
    {
        const searchResults = this.state.tableData.filter(row =>
        {
            let key: keyof ISupplierView;
            for (key in row)
            {
                if (row[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return searchResults;
    }

    private async handleExportButtonClick(): Promise<void>
    {
        this.setState({ showPreparingExportPopup: true });

        const select = ['Supplier_Id','Description','AccountManager','Phone','Email','LeadTime','External_Id','MenuItems','Name','ConcurrencyStamp','OwnedByUserId','OwnedByUser','OwnedByRoleId','OwnedByRole','CreatedByUserId','CreatedByUser','CreatedAt','ModifiedByUserId','ModifiedByUser','ModifiedAt','TenancyId','Node_Id','Node'];
        
        try
        {
            appContext().ibssApiClientV2.v2.byNodeid.cateringSupplier.download.post({
                nodeId: this.state.buildingid,
                body: {
                    Node_Id: this.state.buildingid,
                    Select: select.join(','),
                }
            });
        }
        catch
        {
            return;
        }
    }

    private closePreparingExportDialog(): void
    {
        this.setState({ showPreparingExportPopup: false });
    }

    private selectionChanged(selection: GridRowSelectionModel): void
    {
        const selectedRows = this.filterSuppliers(this.state.searchTerm).filter(row => selection.includes(row.Supplier_Id));
        this.setState({
            rowSelectionModel: selectedRows
        })
    };

    private editCateringOrderPolicy(param:ISupplierView): React.ReactNode
    {
        if(this.state.isUpdateRight)
        {
            const { history } = this.props;
            return (
                <IbssSvgIcon onClick={() => history.push(`/buildings/${this.session.getBuildingId().toString()}/catering-suppliers/${param.Supplier_Id}/edit`)}>
                    <CreateIcon />
                </IbssSvgIcon>
            )
        } 
        else 
        {
            return <p>-</p>;
        }
    }


    public render(): JSX.Element
    {
        const { history } = this.props;
        const dataTodayColumn: GridColDef<ISupplierView>[] = [
            {
                headerName: this.labels.HubLabelSupplierNameTitle,
                field: "Name",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelDescription,
                field: "Description",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelLeadTime,
                minWidth: 120,
                flex: 1,
                field: "LeadTime",
                filterable: false,
                sortable: false
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: 120,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) => <>{this.editCateringOrderPolicy(params.row)}</>
            },
        ];

            // Action buttons
            const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    disabled: !this.state.isCreateRight,
                    onClick: () => { history.push(`/buildings/0/catering-suppliers/new`) },
                }
            ];

        const searchResults = this.filterSuppliers(this.state.searchTerm);

        return (
            <>
                <div>
                    <PreparingExportDialog open={this.state.showPreparingExportPopup} onClose={()=> this.closePreparingExportDialog()}/>
                    {this.state.isLoading ? <Spinner /> :
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0,pt:1}}>
                                    <Grid item md={12}>
                                    <Box className="table-panel-header d-flex" ml={0}>
                                        <Box className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </Box>
                                        <Box>
                                            {this.labels.HubLabelMenuCateringSuppliers}
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" className="my-0">{this.labels.HubLabelCateringSupplierSubText}</Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                            <IbssButtonRedo variant="contained" color="secondary" onClick={() => this.handleExportButtonClick()}>{this.labels.HubButtonExport}</IbssButtonRedo>
                                        </Box> 
                                    </Grid>
                                    <Grid item md={12} sx={{pt:0}} >
                                        <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                            <IbssActionButton buttons={actionButtons} />
                                        </Box>  
                                    </Grid>
                                </Grid>     
                                { this.state.isCompleteLoading && 
                                    <Spinner />
                                }
                                <Box sx={{mt:1}}>
                                <IbssDataGrid
                                    checkboxSelection
                                    rows={searchResults}
                                    columns={dataTodayColumn}
                                    onRowSelectionModelChange={(e) => this.selectionChanged(e)}
                                    initialState={{
                                        pagination: { paginationModel: {pageSize: 25} },
                                
                                    }}
                                    sx={{
                                        "& .MuiDataGrid-row:hover": {
                                          cursor: "pointer"
                                        }
                                    }}
                                    pageSizeOptions={[25,50,100]}
                                />
                                </Box>
                            </div>
                        </div>}
                </div>
            </>
        );
    }

}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
    filter: string;
}

interface IState
{
    getRightApiResponseErrorLoading: boolean;
    isLoading: boolean;
    activePage: string;
    show: boolean;
    buildingid: number;
    isUpdateRight: boolean;
    isCreateRight: boolean;
    isCancel: boolean;
    value: string;
    confirmModalshow: boolean;
    isCompleteLoading: boolean;
    searchTerm: string;
    tableData: ISupplierView[];
    dateDisabled: boolean;
    rowSelectionModel: ISupplierView[];
    tableMessage: string;
    noDataMessage: string;
    updates: boolean;
    showPreparingExportPopup: boolean;
}

export default ListCateringSuppliers;
