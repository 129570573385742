import { MenuItemKey } from "../../Layout/Sidebar/MenuItemKey";
import EditUserPreferenceWorkplacePreferences from "../../../Pages/Flex/UserPreferences/EditWorkplacePreferences/EditWorkplacePreferences";
import EditUserPreferenceNotifications from "../../../Pages/Flex/UserPreferences/EditNotifications/EditNotifications";
import EditUserPreferenceAccountSettings from "../../../Pages/Flex/UserPreferences/EditAccountSettings/EditAccountSettings";
import FlexListNotifications from "../../../Pages/Flex/Notifications/List/ListNotifications";
import CreateRecurringBookings from "../../../Pages/Flex/Bookings/CreateRecurring/CreateRecurringBookings";
import FlexHome from "../../../Pages/Flex/Shared/Home/FlexHome";
import SearchColleague from "../../../Pages/Flex/Users/SearchColleague/SearchColleague";
import EditUserPreferenceCostCodes from "../../../Pages/Flex/UserPreferences/EditCostCodes/EditCostCodes";
import ViewSchedule from "../../../Pages/Flex/Bookings/ViewSchedule/ViewSchedule";
import ListDelegates from "../../../Pages/Flex/Delegates/List/ListDelegates";
import FlexCreateManyDelegates from "../../../Pages/Flex/Delegates/CreateMany/CreateManyDelegates";
import ListTasks from "../../../Pages/Shared/Tasks/List/ListTasks";
import ListVisits from "../../../Pages/Shared/Visits/List/ListVisits";
import EditVisit from "../../../Pages/Shared/Visits/Edit/EditVisit";
import EditTask from "../../../Pages/Shared/Tasks/Edit/EditTask";
import ListBookings from "../../../Pages/Shared/Bookings/List/ListBookings";
import SearchSpaces from "../../../Pages/Shared/Spaces/Search/SearchSpaces";
import EditBooking from "../../../Pages/Shared/Bookings/Edit/EditBooking";
import ViewBookingSeries from "../../../Pages/Shared/BookingSeries/View/ViewBookingSeries";
import EditCateringOrder from "../../../Pages/Shared/CateringOrders/Edit/EditCateringOrder";
import { IRoute } from "./IbssRouter";

export default class FlexRoutes
{
    public routes: IRoute[] = [
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: '/flex/bookings/mine',
            legacyPaths: [{ path: "/flex-my-bookings" }],
            component: ListBookings,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/flex-my-bookings/:buildingid/mybooking/:bookingid/:spaceId",
            component: EditBooking,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookingsForOthers",
                "FLEX.MySchedule.*"
            ],
            path: '/flex/bookings/for-others',
            legacyPaths: [{ path: "/flex-my-bookings-for-others" }],
            component: ListBookings,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookingsForOthers",
                "FLEX.MySchedule.*"
            ],
            path: "/flex/bookings-for-others/:buildingId/booking-series/view/:seriesId",
            component: ViewBookingSeries,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/flex/bookings/primaries",
            component: ListBookings,
            menuItemId: MenuItemKey.Flex_MySchedule_PrimaryBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/flex/bookings/primaries/:buildingid/mybooking/:bookingid/:spaceId",
            component: EditBooking,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/flex/bookings/:buildingId/booking-series/view/:seriesId",
            component: ViewBookingSeries,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookingsForOthers",
                "FLEX.MySchedule.*"
            ],
            path: "/flex-my-bookings-for-others/:buildingid/mybookingother/:bookingid/:spaceId",
            component: EditBooking,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/flex-my-bookings/:buildingid/mybooking/catering/:bookingid/:cateringOrderId/edit",
            component: EditCateringOrder,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyBookings",
                "FLEX.MySchedule.*"
            ],
            path: "/create-catering-order/:buildingid/:bookingid/:spaceId",
            component: EditCateringOrder,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.SearchSpaces.Single",
                "FLEX.Search.*"
            ],
            path: "/flex-my-bookings/:buildingid/catering/:bookingid/:cateringOrderId/edit",
            component: EditCateringOrder,
            menuItemId: MenuItemKey.Flex_MySchedule_MyBookings
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyVisitors",
                "FLEX.MySchedule.*"
            ],
            path: "/flex-my-visitor/:buildingid/myvisit/:visit_Id",
            component: EditVisit,
            menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors
        },
        {
            requiredRights: [
                "FLEX.MySchedule.MyVisitors",
                "FLEX.MySchedule.*"
            ],
            path: '/flex/visits',
            legacyPaths: [{ path: "/flex-my-visitor" }],
            component: ListVisits,
            menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.Workplace",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex-user-pref-workplace",
            component: EditUserPreferenceWorkplacePreferences,
            menuItemId: MenuItemKey.Flex_Settings_Workplace
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.AccountSettings",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex-user-pref-notifications",
            component: EditUserPreferenceNotifications,
            menuItemId: MenuItemKey.Flex_Settings_Notifications
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.Notifications",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex-user-pref-account",
            component: EditUserPreferenceAccountSettings,
            menuItemId: MenuItemKey.Flex_Settings_Account
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.CostCodes",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex/settings/default-cost-codes",
            component: EditUserPreferenceCostCodes,
            menuItemId: MenuItemKey.Flex_Settings_CostCodes
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.Delegates",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex/delegates/create",
            component: FlexCreateManyDelegates,
            menuItemId: MenuItemKey.Flex_Settings_DelegatePermissions
        },
        {
            requiredRights: [
                "FLEX.UserPreferences.Delegates",
                "FLEX.UserPreferences.*"
            ],
            path: "/flex/delegates",
            component: ListDelegates,
            menuItemId: MenuItemKey.Flex_Settings_DelegateAccess
        },
        {
            requiredRights: [
                "FLEX.MyTasks.MyTasks",
                "FLEX.MyTasks.*"
            ],
            path: "/flex-my-tasks",
            component: ListTasks,
            menuItemId: MenuItemKey.Flex_MyTask_TasksRaised
        },
        {
            requiredRights: [
                "FLEX.MyTasks.MyTasks",
                "FLEX.MyTasks.*"
            ],
            path: "/flex-my-tasks/:tab",
            component: ListTasks,
            menuItemId: MenuItemKey.Flex_MyTask_TasksRaised
        },
        {
            requiredRights: [
                "FLEX.MyTasks.MyTasks",
                "FLEX.MyTasks.*"
            ],
            path: "/flex-my-tasks/:buildingid/mytask/:task_Id",
            component: EditTask,
            menuItemId: MenuItemKey.Flex_MyTask_TasksRaised
        },
        {
            requiredRights: [
                "FLEX.SearchSpaces.Single",
                "FLEX.Search.*"
            ],
            path: '/flex/spaces/search',
            legacyPaths: [{ path: '/flex-find-a-space' }],
            component: SearchSpaces,
            menuItemId: MenuItemKey.Flex_FindASpace_Single
        },
        {
            requiredRights: [
                "FLEX.SearchSpaces.Single",
                "FLEX.Search.*"
            ],
            path: "/flex-find-a-space/:buildingid/:searchaspace/:spaceId",
            component: EditBooking,
            menuItemId: MenuItemKey.Flex_FindASpace_Single
        },
        {
            requiredRights: [
                "FLEX.SearchSpaces.Single",
                "FLEX.Search.*"
            ],
            path: "/flex/schedule-view",
            component: ViewSchedule,
            menuItemId: MenuItemKey.Flex_Schedule
        },
        {
            requiredRights: [
                "FLEX.SearchSpaces.Advanced"
            ],
            path: "/flex-recurring-booking",
            component: CreateRecurringBookings,
            menuItemId: MenuItemKey.Flex_FindASpace_Recurring
        },
        {
            requiredRights: [],
            path: "/flex-home/",
            component: FlexHome,
            menuItemId: MenuItemKey.Flex_Home
        },
        {
            requiredRights: [],
            path: "/flex-my-notification",
            component: FlexListNotifications,
            menuItemId: MenuItemKey.Flex_MyNotification
        },
        {
            requiredRights: [
                "FLEX.Search.People",
                "FLEX.Search.*"
            ],
            path: "/flex/users/search-colleagues",
            component: SearchColleague,
            menuItemId: MenuItemKey.Flex_FindAColleague
        },
    ];
}
