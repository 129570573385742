import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { IPartialAppState, appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import Helper from "../../../../Common/Helper";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListMostRecentEnvironmentalDataByZone extends IbssPage<IProps, IState>
{
  private get labels() { return appContext().labels; }
  private get appState() { return appContext().state; }
  private get session() { return appContext().sessionStorageProvider; }

  constructor(props: IProps)
  {
    super(props);
    this.state =
    {
      isLoading: false,
      zoneList: [],
      selectedBuildingId: this.appState.buildingId.toString(),
      searchTerm: ''
    }
  }

  public async componentDidMount()
  {
    const { history, match, dispatch }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones });
    if (this.session.getBuildingId() !== match.params.buildingid)
    {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetZonesData();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void
  {
    if (prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.handleGetZonesData();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined)
    {
      this.setState({ selectedBuildingId: state.buildingId.toString() });
      history.push(match.path.replace(":buildingid", state.buildingId));
    }
  }

  private getAirStatus(val: number): string
  {
    let obj: any = { 1: 'Good', 2: 'Fair', 3: 'Poor', 0: 'No Data' };
    return obj[val]
  }

  private async handleGetZonesData(): Promise<void>
  {
    this.setState({ isLoading: true })
    try
    {
      const response = await apis.getFacilityZonesData(this.session.getBuildingId())
      const responseData = response.data;

      const newResponseData = responseData && responseData[0] && responseData.map((item: IZoneData) =>
      {
        let newItem = {
          zoneName: item.Env_Zone_Name,
          status: this.getAirStatus(item.IAQ_Status),
          temperature: item.Env_Temperature + `\u00b0C`,
          humidity: item.Env_Humidity + '%',
          soundLevel: item.Env_Sound_Level,
          lastUpdated: DateTime.fromISO(item.Env_Zone_LastUpdated).toLocaleDateTimeString(),
          id: item.Env_Zone_Id,
        }


        return newItem;
      })

      this.setState({ isLoading: false, zoneList: newResponseData ? newResponseData : [] });
    } catch (error: any)
    {
      this.setState({
        isLoading: false
      })
    }
  }

  private handleClickZone(item: IZoneDataView): void
  {
    const { history }: any = this.props;
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/${item.id}`)
  }


  private filterRows(searchTerm: string): IZoneDataView[] 
  {
    const filteredRows = this.state.zoneList.filter(x =>
    {
      let key: keyof IZoneDataView;
      for (key in x)
      {
        if (x[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        {
          return true;
        }
      }
      return false;
    });
    return filteredRows;
  }

  public render(): JSX.Element
  {
    const columns = [
      {
        headerName: this.labels.HubLabelName,
        field: Helper.nameOf<IZoneDataView>("zoneName"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelStatus,
        field: Helper.nameOf<IZoneDataView>("status"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelTemperature,
        field: Helper.nameOf<IZoneDataView>("temperature"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelHumidity,
        field: Helper.nameOf<IZoneDataView>("humidity"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelNoise,
        field: Helper.nameOf<IZoneDataView>("soundLevel"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelLastUpdate,
        field: Helper.nameOf<IZoneDataView>("lastUpdated"),
        flex: 1
      },

    ];

    const filteredRows = this.filterRows(this.state.searchTerm);

    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
            <TablePanelInfo
              tableHeading={this.labels.HubLabelZones}
              iconSrc="Zone"
            />
            <div>
              <IbssFilter
                searchTerm={this.state.searchTerm}
                onSearchTermChange={(event) => this.setState({ searchTerm: event.target.value })}
              />
              <IbssDataGrid className="data-grid-clickable-row" height="calc(100vh - 270px)" loading={this.state.isLoading} onRowClick={(data) => this.handleClickZone(data.row)} columns={columns} rows={filteredRows} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) =>
{
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export interface IProps
{
}
export interface IState
{
  isLoading: boolean,
  zoneList: IZoneDataView[],
  selectedBuildingId: string,
  searchTerm: string
}

export interface IZoneDataView
{
  zoneName: string,
  status: string,
  temperature: string,
  humidity: string,
  soundLevel: string,
  lastUpdated: string,
  id: string
}

export interface IZoneData
{
  Env_Zone_Name: string,
  IAQ_Status: number,
  Env_Temperature: string,
  Env_Humidity: string,
  Env_Sound_Level: string,
  Env_Zone_LastUpdated: string,
  Env_Zone_Id: string
}


export default connect(mapStateToProps)(ListMostRecentEnvironmentalDataByZone);
