import { Box, Grid } from "@mui/material";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { appContext } from "../../../../AppContext";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import Helper from "../../../../Common/Helper";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { PagedResponse } from "../../../../Providers.Api/Models";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NotificationReminders } from "./DataModels";

class ListNotificationReminders extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get localStorage() { return appContext().localStorageProvider; }

    private userCanUpdate: boolean;
    private userCanCreate: boolean;
    private userCanDelete: boolean;

    constructor(props: IProps)
    {
        super(props);

        this.userCanUpdate = this.localStorage.hasRight('DATAMODEL.NotificationReminders.Update');
        this.userCanCreate = this.localStorage.hasRight('DATAMODEL.NotificationReminders.Create');
        this.userCanDelete = this.localStorage.hasRight('DATAMODEL.NotificationReminders.Delete');

        this.state =
        {
            selectedIds: [],
            isLoading: false,
            buildingId: this.appState.buildingId,
            filterText: '',
            notificationReminders: [],
            disableActiveStatusButton: true,
            disableInActiveUpdateStatusButton: true,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.buildingChanged();
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.buildingChanged()
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ isLoading: true });
        const { history, match } = this.props;
        this.pageTitle = this.labels.funcNotificationReminders_S + ' ' + ' - ' + ' ' + this.appState.buildingName;
        await this.appState.set({ buildingId: this.state.buildingId });
        await this.loadNotificationReminders();
        history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
        this.setState({ isLoading: false });
    }

    private async loadNotificationReminders(): Promise<void>
    {
        this.setState({ isLoading: true });
        const response = await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.get<PagedResponse<NotificationReminders[]>>({
            nodeId: this.state.buildingId,
            select: NotificationReminders
        });
        const notificationReminderList = response.value.map(item => new NotificationReminderView(item));
        this.setState({ notificationReminders: notificationReminderList, isLoading: false });
        this.selectedSpacesChanged(this.state.selectedIds);
    }


    private filterSpaces(searchTerm: string): NotificationReminderView[] 
    {
        const notificationReminder = this.state.notificationReminders.filter(list =>
        {
            let key: keyof NotificationReminderView;
            for (key in list)
            {
                if (list[key]?.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase()))
                {
                    return true;
                }
            }
            return false;
        });
        return notificationReminder;
    }


    public getDurationFormat(policy: string): React.ReactNode
    {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
        const matches = policy.match(regex);
        if (matches)
        {
            const hours = matches[1] ? matches[1].padStart(2, '0') : '00';
            const minutes = matches[2] ? matches[2].padStart(2, '0') : '00';
            return `${hours}:${minutes}`;
        }
    }

    public getStatus(status: string): React.ReactNode
    {
        if (status === 'StatusActive')
        {
            return `Active`
        } else
        {
            return `Inactive`
        }
    }

    private editSpace(space: NotificationReminderView): React.ReactNode
    {
        const { history } = this.props;
        return (
            <IbssSvgIcon onClick={() => history.push(`/admin/notification-reminders/${this.state.buildingId}/${space.id}/edit`)}>
                <CreateIcon />
            </IbssSvgIcon>
        )
    }

    private selectedSpacesChanged(spacesIds: GridRowSelectionModel): void 
    {
        this.setState({ selectedIds: spacesIds });

        const selectedSpaceData = this.state.notificationReminders.filter(x => spacesIds.includes(x.id));
        const activeSpacesSelected = selectedSpaceData.some(x => x.status === 'StatusActive');
        const inactiveSpacesSelected = selectedSpaceData.some(x => x.status === 'StatusInactive');

        if (activeSpacesSelected && inactiveSpacesSelected)
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: true
            });
        } 
        else if (activeSpacesSelected)
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: false
            });
        } 
        else if (inactiveSpacesSelected)
        {
            this.setState({
                disableActiveStatusButton: false,
                disableInActiveUpdateStatusButton: true
            });
        } 
        else
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: true
            });
        }
    }


    private async updateStatus(status: string): Promise<void>
    {
        this.setState({ isLoading: true });
        const spaces = this.state.notificationReminders.filter(x => this.state.selectedIds.includes(x.id));
        const spaceIds = spaces.map(x => x.id);
        for (let i = 0; i <= spaceIds.length - 1; i++)
        {
            if (status == 'active')
            {
                await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.enable.patch({
                    body: [
                        {
                            Reminder_Id: spaceIds[i],
                        }
                    ],
                    nodeId: this.state.buildingId,
                });
            }
            else if(status == 'inactive')
            {
                await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.disable.patch({
                    body: [
                        {
                            Reminder_Id: spaceIds[i],
                        }
                    ],
                    nodeId: this.state.buildingId,
                });
            }
            else if(status == 'cancel')
            {
                await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.byId.delete({
                    nodeId: this.state.buildingId, 
                    id: spaceIds[i],
                })
            }
        }
        await this.loadNotificationReminders();
        this.setState({ isLoading: false });
    }

    public render(): JSX.Element
    {
        const columns: GridColDef<NotificationReminderView>[] =
            [
                {
                    field: Helper.nameOf<NotificationReminderView>("name"),
                    headerName: this.labels.funcCateringMenuEditName_S,
                    flex: 1
                },
                {
                    field: Helper.nameOf<NotificationReminderView>("status"),
                    headerName: this.labels.HubLabelStatus,
                    renderCell: (params) => this.getStatus(params.row.status),
                    flex: 1
                },
                {
                    field: Helper.nameOf<NotificationReminderView>("notificationDuration"),
                    headerName: this.labels.funcNotificationDuration_S,
                    renderCell: (params) => this.getDurationFormat(params.row.notificationDuration),
                    minWidth: 160,
                    flex: 1
                },
                {
                    field: Helper.nameOf<NotificationReminderView>("timeOfDay"),
                    headerName: this.labels.funcTimeOfDay_S,
                    renderCell: (params) => this.getDurationFormat(params.row.timeOfDay),
                    minWidth: 160,
                    flex: 1
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: 120,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => <>{this.editSpace(params.row)}</>
                }
            ];

        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubLabelActive,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <CheckCircleIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.updateStatus("active"),
                    disabled: this.state.disableActiveStatusButton || !this.userCanUpdate,
                },
                {
                    label: this.labels.HubLabelInActive,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <CancelIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.updateStatus("inactive"),
                    disabled: this.state.disableInActiveUpdateStatusButton || !this.userCanUpdate,
                },
                {
                    label: this.labels.HubButtonCancel,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <DeleteIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.updateStatus("cancel"),
                    disabled: this.state.selectedIds.length <= 0 || !this.userCanDelete,
                },
                {
                    label: this.labels.HubButtonAdd,
                    className: "addIcon",
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    disabled: !this.userCanCreate,
                    onClick: () => this.props.history.push(`/admin/notification-reminders/${this.state.buildingId}/new`),
                },

            ];

        const filteredSpaces = this.filterSpaces(this.state.filterText);

        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box className='d-flex'>
                                        <Box className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </Box>
                                        <Box className='table-panel-header' style={{ paddingTop: '4px' }}>
                                           {this.labels.funcNotificationReminders_S}
                                        </Box>
                                    </Box>
                                    {/* TODO: Needs to add description */}
                                    {/* <Box className="table-panel-description" mb={0}>
                                        {this.labels.HubLabelViewSpacesTableDescription}
                                    </Box> */}
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        <div>
                                            <div className='d-flex'>
                                                <IbssFilter
                                                    searchTerm={this.state.filterText}
                                                    onSearchTermChange={(event) => this.setState({ filterText: event.target.value })}
                                                />
                                            </div>
                                            <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                                <IbssActionButton buttons={actionButtons} />
                                            </Box>
                                            <IbssDataGrid
                                                loading={this.state.isLoading}
                                                className="view-booking-policies-data-grid"
                                                disableRowSelectionOnClick
                                                checkboxSelection
                                                columns={columns}
                                                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.selectedSpacesChanged(rowSelectionModel)}
                                                rows={filteredSpaces}
                                                pageSizeOptions={[100]}
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ListNotificationReminders);

export interface IQueryParams
{
    buildingid: string;
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    isLoading: boolean;
    buildingId: number;
    selectedIds: GridRowSelectionModel;
    filterText: string;
    notificationReminders: NotificationReminderView[];
    disableActiveStatusButton: boolean,
    disableInActiveUpdateStatusButton: boolean,

}
export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}
export class NotificationReminderView 
{
    public id: string;
    public filter: string;
    public notificationDuration: string;
    public timeOfDay: string;
    public status: string;
    public name: string;
    public nodeId: number;

    constructor(value: NotificationReminders) 
    {
        this.id = value.Reminder_Id;
        this.filter = value.Filter;
        this.notificationDuration = value.NotificationDuration;
        this.timeOfDay = value.TimeOfDay;
        this.status = value.Status;
        this.name = value.Name;
        this.nodeId = value.Node_Id;
    }
}