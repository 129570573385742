import { DateTime, Interval, Duration, DurationObjectUnits } from "luxon";
import { appContext } from "../AppContext";
import { dateConverter } from "./Helper";

export class DateHelper
{
    private static get localStorage() { return appContext().localStorageProvider; }
    public static get defaultZone() { return DateTime.local().zone.name; }
    public static readonly defaultFormat = "yyyy-MM-dd'T'HH:mm";

    public static today(nodeId?: number, keepZone?: boolean): DateTime
    {
        if (nodeId)
        {
            return DateTime.now().offsetTimeByNode(nodeId, keepZone).date();
        }
        else
        {
            return DateTime.now().date();
        }
    }

    public static now(nodeId?: number, keepZone?: boolean): DateTime
    {
        if (nodeId)
        {
            return DateTime.now().offsetTimeByNode(nodeId, keepZone);
        }
        else
        {
            return DateTime.now();
        }
    }

    public static fromIsoByNode(text: string, nodeId: number, keepZone?: boolean): DateTime
    {
        return DateTime.fromISO(text).offsetTimeByNode(nodeId, keepZone);
    }

    public static null(): DateTime
    {
        return DateTime.invalid("null")
    }

    public static fromLocaleDateString(text: string): DateTime
    {
        return DateTime.fromFormat(text, DateTime.parseFormatForOpts(DateTime.DATE_SHORT) ?? "");
    }

    public static fromLocaleTimeString(text: string): DateTime
    {
        return DateTime.fromFormat(text, DateTime.parseFormatForOpts(DateTime.TIME_SIMPLE) ?? "");
    }

    public static fromLocaleDateTimeString(text: string): DateTime
    {
        return DateTime.fromFormat(text, DateTime.parseFormatForOpts(DateTime.DATETIME_SHORT) ?? "");
    }

    /** @deprecated */
    public static fromIsoToJsDate(value: string): any
    {
        return DateTime.fromISO(value).toJSDate()
    }

    /** @deprecated */
    public static fromIsoToDefaultFormat(dateString: string, format?: string): string
    {
        return DateTime.fromISO(dateString).toFormatOrDefault();
    }

    public static getZoneByNode(nodeId: number): string
    {
        const nodes = this.localStorage.getNodeData();

        const nodesWithBuildingId = nodes.Regions
            .flatMap(region => region.Buildings)
            .flatMap(building =>
            {
                if(building.Floors?.length >0)
                {
                    return [
                        { nodeId: building.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ },
                        ...building.Floors.flatMap(floor =>
                        [
                            { nodeId: floor.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ },
                            ...floor.Zones?.map(zone => ({ nodeId: zone.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ })) ?? [],
                        ]),
                    ]
                }
            }
            );

        const node = nodesWithBuildingId.find(i => i?.nodeId == nodeId);
        const timeZone = (node == null ? "UTC" : node.timeZone);
        return timeZone;
    }

    /** @deprecated */
    public static fromIsoOrDateTime(value: DateTime | string): DateTime
    {
        return (typeof(value) == "string" ? DateTime.fromISO(value) : value);
    }

    /** @deprecated */
    public static fromIsoOrJsDate(value: Date | string | number): DateTime
    {
        return DateTime.fromISO(dateConverter(value));
    }

    /** @deprecated */
    public static fromIso(value: string, utcIfUnspecified?: boolean): DateTime
    {
        if (utcIfUnspecified ?? true)
        {
            const zoneOffset = /Z|([-+]\d{2}(:?\d{2})?)$/; // match the time-zone offset
            const zoneUnspecified = value.match(zoneOffset) == null;
            
            if (zoneUnspecified)
            {
                value += "Z"; // assume UTC if no offset
            }
        }
        const dateTime = DateTime.fromISO(value);
        return dateTime;
    }

    // the 'on change' event on the 'ibss date/time pickers' gives a JS date as its first parameter
    // however this is not actually a JS date at runtime
    // this method converts from the parameter to a luxon date/time
    /** @deprecated */
    public static fromPicker(value: { toISO(): string } | { toISOString(): string } | null): DateTime
    {
        if (value == null)
        {
            return DateHelper.null();
        }
        else if ('toISO' in value)
        {
            return DateTime.fromISO(value.toISO());
        }
        else if ('toISOString' in value)
        {
            return DateTime.fromISO(value.toISOString());
        }
        else
        {
            throw new Error("Not supported");
        }
    }

    public static fromUrl(value: string): DateTime
    {
        let dateTime = DateTime.fromFormat(value, 'yyyy-MM-dd_HH-mm-ss');
        if (!dateTime.isValid)
        {
            dateTime = DateTime.fromFormat(value, 'yyyy-MM-dd');
        }
        if (!dateTime.isValid)
        {
            return DateHelper.null();
        }
        return dateTime;
    }
}



