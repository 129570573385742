import { Autocomplete, Box, Paper, SelectChangeEvent, TextField } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import FilterChips from "./FilterChips";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import { appContext } from "../../../../AppContext";
import { InputOption } from "../../../../Components/Inputs/SelectList2/InputSelectBox";
import { KeyValue } from "../../../../Common/KeyValue";
import UserPicker, * as UserPickerModule from "../../../../Components/Inputs/UserPicker/UserPicker";

export class FilterDialog extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiCache() { return appContext().apiCache; }

    constructor(props: IProps)
    {
        super(props);

        let bookingStatusOptions = [
            { label: this.labels.HubLabelApproved, value: "Approved" },
            { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
            { label: this.labels.HubLabelAutoCancelledStatus, value: "Auto Cancelled" },
            { label: this.labels.HubLabelCancelled, value: "Cancelled" },
            { label: this.labels.HubLabelCompleted, value: "Completed" },
            { label: this.labels.HubLabelamended, value: "Amended" },
            { label: this.labels.HubLabelNew, value: "New" },
            { label: this.labels.HublabelEarlyCheckIn, value: "Early Check In" },
            { label: this.labels.HubLabelInProgress, value: "In Progress" },
            { label: this.labels.HublabelLateCheckIn, value: "Late Checkin" },
            { label: this.labels.HubLabelNoShow, value: "No Show" },
        ].sort((a, b) =>
        {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        bookingStatusOptions = [{ label: this.labels.HubLabelAny, value: "Any" }, ...bookingStatusOptions];

        this.state = {
            bookingStatusOptions: bookingStatusOptions,
            bookedForSearchText: this.props.bookedForEmail,
            bookedBySearchText: this.props.bookedByEmail,
            filteredSpaceOptions: [],
        };
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        const props = this.props;
        if (props.bookedForEmail != prevProps.bookedForEmail)
        {
            this.setState({ bookedForSearchText: this.props.bookedForEmail });
        }
        if (props.bookedByEmail != prevProps.bookedByEmail)
        {
            this.setState({ bookedBySearchText: this.props.bookedByEmail });
        }
    }

    private bookingStatusChanged(e: SelectChangeEvent<string | string[]>): void
    {
        const value = e.target.value;
        const selected = (typeof (value) == 'string' ? [value] : value);

        if (selected.length == 0 || (selected[selected.length - 1] == 'Any'))
        {
            this.props.onBookingStatusChange(['Any']);
            return;
        }

        const selectedWithoutAny = selected.filter(x => x != 'Any');
        this.props.onBookingStatusChange(selectedWithoutAny);
    }

    private bookingStatusDeleted(bookingStatus: string): void
    {
        const selected = this.props.selectedBookingStatuses.filter(i => i !== bookingStatus)
        this.props.onBookingStatusChange(selected);
    }

    private get selectedRelationshipLabels(): string[]
    {
        return this.props.selectedRelationships.map(key => this.props.relationshipOptions.find(option => option.value == key) ?? null).map(i => i?.label).filter(i => !!i) as string[];
    }

    private relationshipStatusChanged(e: SelectChangeEvent<string | string[]>): void
    {
        const value = e.target.value;
        const selected = (typeof (value) == 'string' ? [value] : value);

        if (selected.length == 0 || (selected[selected.length - 1] == 'Any'))
        {
            this.props.onRelationshipStatusChange(['Any']);
            return;
        }

        const selectedWithoutAny = selected.filter(x => x != 'Any')
        this.props.onRelationshipStatusChange(selectedWithoutAny);
    }

    private async relationshipStatusDeleted(relationshipLabel: string): Promise<void>
    {
        const relationship = this.props.relationshipOptions.find(i => i.label == relationshipLabel);
        const selected = this.props.selectedRelationships.filter(i => i != relationship?.value ?? '');
        this.props.onRelationshipStatusChange(selected);
    }

    private zoneChanged(e: SelectChangeEvent<string>): void
    {
        this.props.onZoneChange(e.target.value);
    }

    private async bookedForChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        this.props.onBookedForChange(newValue?.label ?? '', newValue?.email ?? '');
    }

    private async bookedByChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        this.props.onBookedByChange(newValue?.label ?? '', newValue?.email ?? '');
    }

    private spaceOptionChanged(e: React.SyntheticEvent<Element, Event>, newValue: KeyValue<string, string> | null): void
    {
        this.props.onSpaceChange(newValue?.value ?? '');
    }

    private async spaceOptionTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        const searchTerm = e.target.value;
        if (searchTerm.length < 3)
        {
            this.setState({ filteredSpaceOptions: [] });
            return;
        }

        const filteredOptions = this.props.spaceOptions.filter(i => i.value.includes(searchTerm));
        this.setState({ filteredSpaceOptions: filteredOptions });
    }

    public render(): JSX.Element
    {
        const selectedSpaceOption = this.props.spaceOptions.find(i => i.value == this.props.selectedSpace) ?? null;

        return (
            <IbssDialog
                aria-modal="true"
                aria-label="booking filter modal"
                onClose={() => this.props.onClose()}
                open={this.props.isOpen}
                header={this.labels.HubLabelFilter}
                fullWidth
                dialogContent={
                    <>
                        {this.props.isOperationalServicesBookings && <Box className="my-1">
                            {this.labels.HubSubTitleCustomFilter}
                        </Box>}
                        <Box>
                            <IbssInputDropDown
                                inputLabel={this.labels.HubLabelStatus}
                                options={this.state.bookingStatusOptions}
                                value={this.props.selectedBookingStatuses}
                                id={"statusSelection"}
                                isMultiple={this.props.isOperationalServicesBookings}
                                onChange={(e: SelectChangeEvent<string | string[]>) => this.bookingStatusChanged(e)}
                                fullWidth
                            />
                        </Box>
                        {this.props.selectedBookingStatuses.length > 1 &&
                            <Box width={'100%'} maxWidth={'100%'}>
                                <FilterChips
                                    items={this.props.selectedBookingStatuses}
                                    onDelete={bookingStatus => this.bookingStatusDeleted(bookingStatus)}
                                />
                            </Box>
                        }
                        {this.props.isOperationalServicesBookings &&
                            <Box mt={1}>
                                <IbssInputDropDown
                                    inputLabel={this.labels.funcBookingRelationshipType_S}
                                    options={this.props.relationshipOptions}
                                    value={this.props.selectedRelationships}
                                    id={"relationshipstatusSelection"}
                                    isMultiple={this.props.isOperationalServicesBookings}
                                    onChange={(e: SelectChangeEvent<string | string[]>) => this.relationshipStatusChanged(e)}
                                    fullWidth
                                />
                            </Box>
                        }
                        {this.props.selectedRelationships.length > 1 &&
                            <Box width={'100%'} maxWidth={'100%'}>
                                <FilterChips
                                    items={this.selectedRelationshipLabels}
                                    onDelete={relationshipStatuses => this.relationshipStatusDeleted(relationshipStatuses)}
                                />
                            </Box>
                        }
                        <Box mt={1}>
                            <IbssInputDropDown
                                inputLabel={this.labels.HubLabelZones}
                                options={this.props.zoneOptions}
                                value={this.props.selectedZone}
                                id={"zoneSelection"}
                                isMultiple={false}
                                onChange={(e: SelectChangeEvent<string>) => this.zoneChanged(e)}
                                fullWidth
                            />
                        </Box>

                        {!this.props.isMyBookings &&
                            <div className="row">
                                <div className="form-input-box">
                                    <label className="form-input-box-label visit-filter-label">
                                        {this.labels.HubLabelBookedFor}
                                    </label>
                                    <UserPicker
                                        width="calc(100% - 110px)"
                                        searchText={this.state.bookedForSearchText}
                                        label=""
                                        placeholder="&#8230;"
                                        alternateStyle={true}
                                        onChange={async text => this.setState({ bookedForSearchText: text })}
                                        onUserChange={user => this.bookedForChanged(user)}
                                    />
                                </div>
                            </div>
                        }

                        {!this.props.isMyBookingsForOthers &&
                            <div className="row">
                                <div className="form-input-box">
                                    <label className="form-input-box-label visit-filter-label">
                                        {this.labels.HubLabelBookedBy}
                                    </label>
                                    <UserPicker
                                        width="calc(100% - 110px)"
                                        searchText={this.state.bookedBySearchText}
                                        label=""
                                        placeholder="&#8230;"
                                        alternateStyle={true}
                                        onChange={async text => this.setState({ bookedBySearchText: text })}
                                        onUserChange={user => this.bookedByChanged(user)}
                                    />
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label visit-filter-label">
                                    {this.labels.HubLabelSpace}
                                </label>
                                <Autocomplete
                                    freeSolo
                                    className="form-input-box-autocomplete auto-text"
                                    options={this.state.filteredSpaceOptions}
                                    getOptionLabel={i => (i as KeyValue<string, string>).value}
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            style={{
                                                fontSize: "14px",
                                                fontFamily: "Source Sans Pro",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    value={selectedSpaceOption}
                                    onChange={(e, newValue) => this.spaceOptionChanged(e, newValue as KeyValue<string, string>)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="input-box model-textbox select-box-border"
                                            name="selectedSpaceOption"
                                            id="selectedSpaceOption"
                                            placeholder="&#8230;"
                                            value={selectedSpaceOption}
                                            onBlur={(e) =>
                                            {
                                                this.spaceOptionTextChanged(e);
                                            }}
                                            onChange={(e) =>
                                            {
                                                this.spaceOptionTextChanged(e);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <>
                        <IbssButtonRedo
                            onClick={() => this.props.onClose()}
                            color="secondary"
                            variant="outlined"
                            className="mr-2"
                        >
                            {this.labels.HubButtonCancel}
                        </IbssButtonRedo>
                        <IbssButtonRedo
                            color="primary"
                            variant="contained"
                            size="medium"
                            className="ml-2"
                            onClick={() => this.props.onOkayed()}
                        >
                            {this.labels.HubLabelOk}
                        </IbssButtonRedo>
                    </>
                }
            />
        );
    }
}

export interface IProps
{
    isOpen: boolean;
    buildingId: number;
    isMyBookings: boolean;
    isMyBookingsForOthers: boolean;
    isOperationalServicesBookings: boolean;
    selectedBookingStatuses: string[];
    relationshipOptions: IOption[];
    selectedRelationships: string[];
    zoneOptions: InputOption[];
    selectedZone: string;
    bookedForEmail: string;
    bookedByEmail: string;
    spaceOptions: KeyValue<string, string>[];
    selectedSpace: string;

    onBookingStatusChange: (value: string[]) => void;
    onRelationshipStatusChange: (value: string[]) => void;
    onZoneChange: (value: string) => void;
    onSpaceChange: (value: string) => void;
    onBookedForChange: (label: string, email: string) => void;
    onBookedByChange: (label: string, email: string) => void;
    onClose: () => void;
    onOkayed: () => void;
}

export interface IState
{
    bookingStatusOptions: IOption[],
    bookedForSearchText: string;
    bookedBySearchText: string;
    filteredSpaceOptions: KeyValue<string, string>[];
}

export interface IOption
{
    label: string,
    value: string;
}
