import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateV2BookingEndpoint implements IUpdateV2BookingEndpoint
{
    public async execute(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateV2BookingResponse>
    {
        try
        {
            const response = await axios.put<IUpdateV2BookingResponse>(apis.allApiv2 + nodeId + `/Bookings/` + bookingId, booking);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateV2BookingEndpoint
{
    execute(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateV2BookingResponse>;
}

export interface IUpdateV2BookingRequest
{
    SpaceId: string;
    Space_Layout?: string;
    Space_Name?: string;
    Booking_Start: string;
    Booking_End: string;
    PartyCount: number;
    Booking_Name: string;
    Booking_Description: string;
    Booking_IsPrivate: number;
    Organisation: string;
    Booking_Parties: IUpdateV2Booking_BookingParty[];
    Cost_Code_Allocation: IUpdateV2Booking_CostCodeAllocation[];
    DisableExtUpdate: boolean;
    OnBehalfOf?: string;
    UseOnBehalfOfCostCodes?: number;
    AddOnlineMeetingLink: number;
    Booking_Setup_Time?: number,
    Booking_Tear_Down_Time?: number
    Booking_Attendance?: number
}

export interface IUpdateV2Booking_BookingParty
{
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string;
    Booking_Visitor: boolean;
    Booking_Participant_Type: number;
}

export interface IUpdateV2Booking_CostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export interface IUpdateV2BookingResponse
{
    Message: string;
}
