import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public floor?: number;
    public zone?: string;
    public workType?: string;
    public spaceType?: string;
    public date?: DateTime;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'floor':
                return this.serialiseNumber(propertyValue);
            case 'date':
                return this.serialiseDate(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue);
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'floor':
                return this.deserialiseNumber(queryValue);
            case 'date':
                return this.deserialiseDate(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
