import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '../../Forms/FormControl/IbssFormControl';
import FormHelperText from '../../Forms/HelperText/IbssFormHelperText'
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '../../Forms/InputLabel/IbssInputLabel';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

class IbssInputDropDown extends React.Component<IbssInputDropDownProps> {
    render()
    {
        return (
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ width: this.props.formWidth ?? "100%" }}
            >
                <FormControl
                    size="medium"
                    sx={{
                        minWidth: this.props.minWidth ?? `100%`,
                        maxWidth: this.props.maxWidth ?? `100%`,
                        textAlign: 'left'
                    }}
                    disabled={this.props?.disabled ?? false}
                    error={this.props?.error ?? false}
                    fullWidth={this.props?.fullWidth ?? false}
                    hiddenLabel={this.props?.hiddenLabel ?? false}
                    required={this.props?.required ?? false}
                    tabIndex={1}
                    aria-label={this.props.inputLabel}
                >

                    <InputLabel
                        labelColor={this.props.labelColour}
                        htmlFor={this.props?.id}
                    >
                        {this.props?.inputLabel}
                    </InputLabel>
                    <Select
                        defaultValue={this.props.defaultValue ?? ''}
                        style={{ background: this.props.backgroundColour ?? '' }}
                        className='ibss-dropdown'
                        multiple={this.props?.isMultiple}
                        id={this.props?.id}
                        value={this.props?.value}
                        onChange={this.props?.onChange}
                        placeholder={this.props?.inputLabel}
                        autoWidth={this.props?.autoWidth}
                        label={this.props?.inputLabel}
                        disableUnderline={this.props?.selectDisableUnderline}
                        variant={this.props.selectVariant}
                    >


                        {this.props.options.map((option: ISelectOption) =>
                        {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}

                    </Select>

                    <FormHelperText>
                        {this.props?.helperText}
                    </FormHelperText>
                </FormControl>
            </Box>
        );
    }
}

export default IbssInputDropDown;

export interface IbssInputDropDownProps extends Omit<SelectProps, 'onChange'>
{
    disabled?: boolean;
    error?: boolean;
    focused?: boolean;
    fullWidth?: boolean;
    hiddenLabel?: boolean;
    required?: boolean;
    helperText?: string;
    id: string;
    onChange?: (e: SelectChangeEvent<any>) => void;
    placeholder?: string;
    value?: any;
    inputLabel?: string;
    label?: string;
    labelColor?: string;
    focusColor?: string;
    autoWidth?: boolean;
    isMultiple?: boolean;
    options: ISelectOption[];
    minWidth?: number | string;
    maxWidth?: number | string;
    backgroundColour?: string;
    labelColour?: string;
    defaultValue?: number | string;
    formWidth?: number | string;
    selectDisableUnderline?: boolean;
    selectVariant?: "standard" | "outlined" | "filled" | undefined;
}

export interface ISelectOption
{
    label: string | number;
    value: string | number;
}
