import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class VisitDetail extends ODataSelect
{
    public EventTime = '';
    public EventId = '';
    public _CreatedBy = '';
    public _CreatedAt = '';
    public ModifiedBy = null;
    public ModifiedAt = null;
    public OwnedBy = '';
    public OwnedById = '';
    public Visit_Id = '';
    public Space_Id = '';
    public Booking_Id = '';
    public Booking_Start = '';
    public Booking_End = '';
    public Booking_Name = '';
    public Node_Id = 0;
    public Space_Name = '';
    public Visitor_First_Name = '';
    public Visitor_Last_Name = '';
    public Visitor_Email = '';
    public Visitor_Company = '';
    public Visit_Host_Name = '';
    public Visit_Host_Email = '';
    public Visit_Start_Date = '';
    public Visit_End_Date = '';
    public Visit_IsCheckedIn = 0;
    public Visit_IsApproved = 0;
    public Visit_IsCancelled = 0;
    public Visit_IsNoShow = 0;
    public Visit_IsCheckedOut = 0;
    public Visit_Approval_Comments = '';
    public Visitor_Dietary_Pref = 0;
    public Visit_Status = '';
    public Visit_IsDenied = 0;
    public Visit_Save_Info = 0;
    public Meta_Ext_Visit_Id = '';
    public Visit_CheckIn_Time = null;
    public Visit_CheckOut_Time = null;
    public Visit_IsEarly_Checkin = 0;
    public Visit_IsLate_Checkin = 0;
    public Visit_Scheduled_Duration = 0;
    public Visit_Actual_Duration = 0;
    public Visit_Pacs_Id = '';
    public Visit_Approve_Time = null;
    public Visit_Deny_Time = null;
    public ID = 0;
    public ETag = '';
}

export class Space extends ODataSelect
{
    public Space_Name = '';
    public Space_Id = '';
    public Space_Custom_Info = '';
    public Booking_Policy_Id = '';
    public ImageURI = '';
    public Space_Class = '';
    public Space_Type = '';
    public Space_Work_Type = '';
    public Space_Type_Label = '';
    public Space_Capacity = 0;
    public Meta_Loc_Zone = '';
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Bookable = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Occ_Type = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Space_IsEnabled = 0;
    public Env_Zone_Id = 0;
}