import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Space extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Type = "";
    public Space_Type_Label = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public ImageURI = "";
    public Booking_Policy_Id = "";
    public Meta_Bookable = 0;
    public Meta_Occ_Type = "";
    public Meta_Ext_Occ_Count_Mode = 0;
    public Meta_Loc_Zone = "";
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Avail_Serv_Reqs = 0;
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
    public Space_Arrival_Loc = 0;
    public Space_Work_Type = "";
    public Space_Custom_Info = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
    public Env_Zone_Id = 0;
    public Meta_Ext_Catering_Space_Id = [];
    public Meta_Ext_Booking_System = 0;
    public OwnedByRoleId ="";
    public ConcurrencyStamp = "";
    public Meta_Gtwy_Type = "";
    public Space_UI_Colour = "";
    public Meta_Gtwy = "";
    public Meta_Ext_Occ_System = "";
    public Meta_Ext_Occ_Area_Id = "";
    public Meta_Ext_Occ_Sensor_Id = "";
    public Meta_Space_DurationMin_min = "";
    public Meta_Space_DurationMax_min = "";
    public Meta_Ext_Booking_Space_Id = "";
    public Meta_Ext_Catering_System = "";
}

export class Role extends ODataSelect
{
    public id = "";
    public name = "";
}