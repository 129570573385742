import { appContext } from "../AppContext";
import { INode, IZone } from "../Providers.Api/Models";

export class NodeHelper
{
    public getBuildingByNode(nodeId: number): INode | null
    {
        const buildings = appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings);
        const floors = buildings.flatMap(i => i.Floors);
        const zones = floors.flatMap(i => i.Zones).filter(i => !!i) as IZone[];

        const nodes = [
            ...buildings.map(i => ({ nodeId: i.Node_Id, parentNodeId: 0, level: 'Building' })),
            ...floors.map(i => ({ nodeId: i.Node_Id, parentNodeId: i.Node_Parent_Id, level: 'Floor' })),
            ...zones.map(i => ({ nodeId: i.Node_Id, parentNodeId: i.Node_Parent_Id, level: 'Zone' })),
        ];

        const ancestors = nodes.ancestorsOf(nodeId, i => i.nodeId, i => i.parentNodeId, true);
        const buildingId = ancestors.find(i => i.level == 'Building')?.nodeId;
        const building = buildings.find(i => i.Node_Id == buildingId);
        return building ?? null;
    }
}
