import { ReactNode } from 'react';
import { IbssComponent } from '../Core/BaseComponent/IbssComponent';
import Snackbar from '@mui/material/Snackbar';

class IbssSnackbar extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <Snackbar
                open={this.props.open}
                autoHideDuration={this.props.autoHideDuration}
                onClose={() => this.props.onClose()}
                message={this.props.message}
                action={this.props.action}
            />
        )
    }
}

export default IbssSnackbar;

export interface IProps
{
    open: boolean;
    autoHideDuration: number;
    onClose: () => void;
    message: string;
    action: ReactNode;
}

export interface IState
{
}