import { Component } from 'react';
import { Box } from '@mui/material';
import { appContext } from '../../../AppContext';
import Helper from '../../../Common/Helper';
import { DateHelper } from '../../../Common/DateHelper';
import { DateTime } from 'luxon';
import IbssDialog from '../BaseDialog/IbssDialog';
import { Typography } from '@mui/material';
import IbssButton from '../../Buttons/Button/IbssButton';
import { PagedResponse } from '../../../Providers.Api/Models';
import { ICateringOrder, ICateringMenuExpanded, ICostCodeAllocation, ICreateCateringOrder, IMenuItem, ISelectCateringOrder } from './DataModels';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides } from '@mui/material';
import { CateringOrderStatus } from '../../../Common/Enums';

class ChangeBookingDetailsDialog extends Component<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private helper = new Helper();
    private get labels(){ return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            message: '',
            scenario: '',
        };
    }

    public componentDidMount(): void
    {
        this.manageChangeBookingDetailsModal();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void 
    {
        if(this.props.bookingStart !== prevProps.bookingStart && this.props.bookingStart !== this.props.originalBookingStart)
        {
            this.manageChangeBookingDetailsModal();
            return;
        }

        if(this.props.isCancelling !== prevProps.isCancelling && this.props.isCancelling)
        {
            this.manageChangeBookingDetailsModal();
            return;
        }

        if(this.props.isSwappingSpace !== prevProps.isSwappingSpace && this.props.isSwappingSpace)
        {
            this.manageChangeBookingDetailsModal();
            return;
        }
}

    private get cateringCutOffTime(): DateTime
    {
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.props.buildingId);
        const cateringCutOffTimeRaw = building?.Pre_Book_Cat_Time ?? '';
        
        const oldBookingStart = DateTime.fromJSDate(this.props.originalBookingStart).setZoneByNode(this.props.buildingId);
        const cutOffTimeDays = cateringCutOffTimeRaw.split('.')[0];
        const cutOffTimeOfDay = cateringCutOffTimeRaw.split('.')[1]?.split(':');
        const cateringCutOffTime = cutOffTimeOfDay && oldBookingStart.date().plus({ days: -cutOffTimeDays }).set({ hour: parseInt(cutOffTimeOfDay[0]), minute: parseInt(cutOffTimeOfDay[1]) });
        return cateringCutOffTime
    }

    private manageChangeBookingDetailsModal(): void
    {
        const newBookingStart = DateTime.fromJSDate(this.props.bookingStart).setZoneByNode(this.props.buildingId);
        const oldBookingStart = DateTime.fromJSDate(this.props.originalBookingStart).setZoneByNode(this.props.buildingId);

        const cateringCutOffTime = this.cateringCutOffTime;
        const buildingNow = DateTime.now().setZoneByNode(this.props.buildingId);

        if(newBookingStart === oldBookingStart && !this.props.isCancelling && this.props.spaceId === this.props.originalSpaceId)
        {
            // if starting time has not changed AND booking is not being cancelled AND spaceId has not changed, return;
            return;
        }
        
        const { displayLocation } = this.props;

        if((buildingNow < cateringCutOffTime))
        {
            if(this.props.isCancelling)
            {
                // Scenario 3. Cancelling, Pre-Cut Off;
                const scenario = `${displayLocation}.Cancelling.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }

            if(this.props.isSwappingSpace)
            {
                // Scenario 4. Changing SpaceId, Pre-Cut Off;
                const scenario = `${displayLocation}.Space.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }

            if(newBookingStart.day !== oldBookingStart.day)
            {
                // Scenario 2. Booking Date change, Pre-Cut Off
                const scenario = `${displayLocation}.BookingDate.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
            }
            else if(newBookingStart.day === oldBookingStart.day)
            {
                // Scenario 1. Booking Time change, Pre-Cut Off;
                const scenario = `${displayLocation}.BookingTime.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
            }
        }
        else if((buildingNow >= cateringCutOffTime))
        {   
            if(this.props.isCancelling)
            {
                // Scenario 3. Cancelling, Post-Cut Off;
                const scenario = `${displayLocation}.Cancelling.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }

            if(this.props.isSwappingSpace)
            {
                // Scenario 4. Changing SpaceId, Post-Cut Off;
                const scenario = `${displayLocation}.Space.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }

            if(newBookingStart.day !== oldBookingStart.day)
                {
                    // Scenario 2. Booking Date change, Post-Cut Off
                    const scenario = `${displayLocation}.BookingDate.PostCateringCutOff`;
                    this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                }
            else if(newBookingStart.day === oldBookingStart.day)
            {
                // Scenario 1. Booking Time change, Post-Cut Off
                const scenario = `${displayLocation}.BookingTime.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario), scenario: scenario });
            }
        }
        else
        {
            return;
        }
        
    }

    private async cancelCateringOrders(cateringOrderId: string, cateringCutOffStatus: ICateringCutOffStatus): Promise<void>
    {

        if(!this.props.nodeId)
        {
            return;
        }

        const cateringCancelPayload =
        {
            Catering_Order_Status: cateringCutOffStatus === 'PreCutOff' ? CateringOrderStatus.CancelledNoCharge : CateringOrderStatus.CancelledCharged,
        };

        if(!cateringOrderId)
        {
            return;
        }
        
        try
        {
            await this.apiClient.cateringOrders.patchCateringOrder(this.props.nodeId, cateringOrderId, cateringCancelPayload);
        }
        catch
        {
            return;
        }
    }
    
    private async pendingApprovalCateringOrders(): Promise<void>
    {
        if(!this.props.nodeId)
        {
            return;
        }

        const cateringPatchPayload =
        {
            Catering_Order_Status: CateringOrderStatus.PendingApproval
        };

        if(!this.props.cateringOrderId)
        {
            return;
        }

        try
        {
            this.apiClient.cateringOrders.patchCateringOrder(this.props.nodeId, this.props.cateringOrderId, cateringPatchPayload);
        }
        catch
        {
            return;
        }
    }

    private async getCateringOrdersForBooking(): Promise<ISelectCateringOrder[]>
    {
        try
        {
            const cateringOrdersForBooking = await appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.get<PagedResponse<ISelectCateringOrder[]>>({
                nodeId: this.props.buildingId,
                filter: `Booking_Id eq '${this.props.bookingId}'`,
                recursive: true,
                top: 20,
                select: ISelectCateringOrder,
            });
            return cateringOrdersForBooking.value;
        }
        catch
        {
            return [];
        }
    }

    private async getCateringMenus(): Promise<ICateringMenuExpanded[]>
    {
        try
        {
            const cateringMenus = await appContext().ibssApiClientV2.v2.byNodeid.cateringMenu.get<PagedResponse<ICateringMenuExpanded[]>>
            ({
                nodeId: this.props.buildingId,
                recursive: true,
                top: 100,
                select: ICateringMenuExpanded,
                filter: `Status eq 'StatusActive' and Available_From le ${this.props.bookingStart} and Available_Until ge ${this.props.bookingEnd} and Menu_Spaces/any(Menu_Space: Menu_Space/Space_Id eq '${this.props.spaceId}')`
            });

            return cateringMenus.value;
        }
        catch
        {
            return [];
        }
    }

    private async handleSwapSpaceCateringOrders(): Promise<void>
    {
        const cateringOrders = await this.getCateringOrdersForBooking();
        const cateringOrdersView = cateringOrders.map(order => OrderView.fromApiTask({ ...order, Menu_Items: JSON.parse(order.Menu_Items) }));

        const menus = await this.getCateringMenus();
        // list of menus in new space
        const menusView = menus.map(menu=> MenuView.fromApiModel(menu));

        // orders with menus that can'be be matched to menus on new space - cancel
        const cateringOrderNoActiveMenus = cateringOrdersView.filter(order => !menusView.find(menu => menu.menu.menuId === order.menuItems[0].menuId));
         // other orders - patch call to change spaceId, set to status to isPending,
        const cateringOrderWithActiveMenus = cateringOrdersView.filter(order => !cateringOrderNoActiveMenus.find(cateringOrder => cateringOrder.orderId === order.orderId));

        await this.patchSwapSpaceCateringOrders(cateringOrderWithActiveMenus.map(order => order.orderId));
        await this.patchCancelOrders(cateringOrderNoActiveMenus.map(order => order.orderId));
    }

    private async patchCancelOrders(cateringOrderIds: string[]): Promise<void>
    {
        if(cateringOrderIds.length ===0)
        {
            return;
        }

        const cateringCutOffTime = this.cateringCutOffTime;
        const buildingNow = DateTime.now().setZoneByNode(this.props.buildingId);
        const cutOffStatus = buildingNow > cateringCutOffTime? ICateringCutOffStatus.PostCutOff: ICateringCutOffStatus.PreCutOff;

        for (const orderId of cateringOrderIds )
        {
            await this.cancelCateringOrders(orderId, cutOffStatus);
        }
    }

    private async patchSwapSpaceCateringOrders(cateringOrderIds: string[]): Promise<void>
    {
        if(cateringOrderIds.length === 0)
        {
            return;
        }

        for (const orderId of cateringOrderIds )
        {
            await this.patchSwapSpaceCateringOrder(orderId);
        }
    }

    private async patchSwapSpaceCateringOrder(cateringOrderId: string): Promise<void>
    {
        if(!this.props.nodeId)
        {
            return;
        }

        const cateringPatchPayload =
        {
            Catering_Order_Status: CateringOrderStatus.PendingApproval,
            Space_Id: this.props.spaceId,
        };

        if(!cateringOrderId)
        {
            return;
        }

        try
        {
            this.apiClient.cateringOrders.patchCateringOrder(this.props.nodeId, cateringOrderId, cateringPatchPayload);
        }
        catch
        {
            return;
        }
    }

    private renderButton(
        variant: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> | undefined, 
        color: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides> | undefined, 
        label: string
    ): JSX.Element
    {
        return (
            <IbssButton
                variant={variant}
                color={color}
                onClick={() => this.proceedWithUpdate()}
            >
                {label}
            </IbssButton>
        )
    }

    private get proceedButton(): JSX.Element
    {
        const { displayLocation } = this.props;

        switch(this.state.scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
            case 'FLEX.BookingDate.PreCateringCutOff':
            case 'ONELENS.BookingTime.PreCateringCutOff':
            case 'ONELENS.BookingTime.PostCateringCutOff':
            case 'ONELENS.BookingDate.PreCateringCutOff':
                return this.renderButton('contained', 'primary', this.labels.funcBookingsYesProceed_S);
            
            case 'FLEX.BookingTime.PostCateringCutOff':
            case 'FLEX.BookingDate.PostCateringCutOff':
            case `${displayLocation}.Cancelling.PreCateringCutOff`:
            case `${displayLocation}.Cancelling.PostCateringCutOff`:
            case 'ONELENS.BookingDate.PostCateringCutOff':
            case 'ONELENS.Space.PreCateringCutOff':
            case 'ONELENS.Space.PostCateringCutOff':
                return this.renderButton('contained', 'error', this.labels.funcBookingsYesProceed_S);

            default:
                return (
                    <></>
                ) 
        }
    }

    private getWarningMessage(scenario: string): string
    {
        const { displayLocation } = this.props;

        switch(scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
                return this.labels.funcFlexBookingTimeChangePreCutOff_D;
            
            case 'FLEX.BookingTime.PostCateringCutOff':
                return this.labels.funcFlexBookingTimeChangePostCutOff_D;
            
            case 'FLEX.BookingDate.PreCateringCutOff':
                return this.labels.funcFlexBookingDateChangePreCutOff_D;
            
            case 'FLEX.BookingDate.PostCateringCutOff':
                return this.labels.funcFlexBookingDateChangePostCutOff_D;
            
            case `${displayLocation}.Cancelling.PreCateringCutOff`:
            case `${displayLocation}.Cancelling.PostCateringCutOff`:
                return this.labels.funcBookingScheduleCancelSubtitle_L;

            case 'ONELENS.BookingTime.PreCateringCutOff':
                return this.labels.funcOnelensBookingTimeChangePreCutOff_D;
            
            case 'ONELENS.BookingTime.PostCateringCutOff':
                return this.labels.funcOnelensBookingTimeChangePostCutOff_D;
            
            case 'ONELENS.BookingDate.PreCateringCutOff':
                return this.labels.funcOnelensBookingDateChangePreCutOff_D;
            
            case 'ONELENS.BookingDate.PostCateringCutOff':
                return this.labels.funcOnelensBookingDateChangePostCutOff_D;

            case 'ONELENS.Space.PreCateringCutOff':
                return this.labels.funcOnelensBookingSpaceChangePreCutOff_D;

            case 'ONELENS.Space.PostCateringCutOff':
                return this.labels.funcOnelensBookingSpaceChangePostCutOff_D;

            default:
                return '';
        }
    }

    private getCateringUpdateFn(scenario: string): ()=> Promise<void>
    {
        const cateringOrderId = this.props?.cateringOrderId ?? ''
        switch(scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
                return ()=> this.pendingApprovalCateringOrders();

            case 'FLEX.BookingTime.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(cateringOrderId, ICateringCutOffStatus.PostCutOff);

            case 'FLEX.BookingDate.PreCateringCutOff':
                return ()=> this.cancelCateringOrders(cateringOrderId, ICateringCutOffStatus.PreCutOff);

            case 'FLEX.BookingDate.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(cateringOrderId, ICateringCutOffStatus.PostCutOff);

            case 'ONELENS.BookingTime.PreCateringCutOff':
                return ()=> this.pendingApprovalCateringOrders();

            case 'ONELENS.BookingTime.PostCateringCutOff':
                return ()=> this.pendingApprovalCateringOrders();

            case 'ONELENS.BookingDate.PreCateringCutOff':
                return ()=> this.cancelCateringOrders(cateringOrderId, ICateringCutOffStatus.PreCutOff);

            case 'ONELENS.BookingDate.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(cateringOrderId, ICateringCutOffStatus.PostCutOff);
            
            case 'ONELENS.Space.PreCateringCutOff':
                return ()=> this.handleSwapSpaceCateringOrders();

            case 'ONELENS.Space.PostCateringCutOff':
                return ()=> this.handleSwapSpaceCateringOrders();

            default:
                return ()=> Promise.resolve();
        }
    }

    private async proceedWithUpdate(): Promise<void>
    {
        const updateCatering =  this.getCateringUpdateFn(this.state.scenario);
        await updateCatering();
        await this.props.updateBooking();
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="change booking details warning modal"
                fullWidth
                maxWidth={'md'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelWarning}</label>
                    </>
                }
                dialogContent=
                {
                    <Box>
                        <Typography>{this.state.message}</Typography>
                    </Box>
                }
                footer=
                {
                    <div className='d-flex w-90' style={{ justifyContent: 'center', gap: '16px'}}>
                        <IbssButton
                            variant="contained"
                            color='secondary'
                            onClick={()=> this.props.onClose()}
                        >
                            {this.labels.funcBookingScheduleCancelNo_S}
                        </IbssButton>
                        {this.proceedButton}
                    </div>
                }
            />
        )
    }
}

export default ChangeBookingDetailsDialog;

export interface IProps
{
    show: boolean;
    isCancelling: boolean;
    isSwappingSpace: boolean;
    displayLocation: IBookingDetailsApplication;
    onClose: ()=> void;
    buildingId: number;
    nodeId: number;
    spaceId: string;
    updateBooking: ()=> Promise<void>;
    bookingStart: Date;
    bookingEnd: Date;
    originalBookingStart: Date;
    originalSpaceId: string;
    cateringOrderId?: string;
    bookingId?: string;
}

export interface IState
{
    message: string;
    scenario: string;
}

enum ICateringCutOffStatus
{
    PreCutOff='PreCutOff',
    PostCutOff='PostCutOff'
}

export enum IBookingDetailsApplication
{
    Flex='FLEX',
    Onelens='ONELENS',
}


export class MenuView
{
    public menu = {
        menuId: '',
        name: ''
    };

    public static fromApiModel(cateringMenu: ICateringMenuExpanded): MenuView
    {
        return {
            menu: {
                menuId: cateringMenu.Menu_Id,
                name: cateringMenu.Name,
            }
        };
    }
}

export class OrderView
{
    public orderId = "";
    public nodeId = 0;
    public menuItems = new Array<MenuItemView>();
    public cateringServiceTime = DateHelper.null();
    public cateringClearingTime = DateHelper.null();
    public cateringStatus = "";
    public cateringAttendees = "";
    public cateringNotes = "";
    public costCodeAllocation: ICostAllocation[] = [];
    public cateringOrderRestrictions = [];
    public bookingName = "";
    public bookingId = "";
    public spaceName = "";
    public spaceId = "";
    public menuId = "";

    constructor(value?: Partial<OrderView>)
    {
        if (value == null)
        {
            return;
        }
        Object.assign(this, value);
    }

    public static fromApiTask(data: ICateringOrder): OrderView
    {
        return new OrderView(
            {
                orderId: data.Order_Id,
                nodeId: data.Node_Id,
                menuItems: data.Menu_Items.map(i => MenuItemView.fromApiModel(i)),
                cateringServiceTime: DateHelper.fromIsoByNode(data.Catering_Service_Time, data.Node_Id),
                cateringClearingTime: DateHelper.fromIsoByNode(data.Catering_Clearing_Time, data.Node_Id),
                cateringStatus: data.Catering_Order_Status,
                cateringAttendees: data.Catering_Attendees,
                cateringNotes: data.Catering_Notes,
                costCodeAllocation: JSON.parse(data.Cost_Code_Allocation).map((x: { Cost_Code: string; Cost_Code_Id: string; Allocation: number }) => ({ costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation })),
                cateringOrderRestrictions: data.Catering_Order_Restrictions.length > 0 ? JSON.parse(data.Catering_Order_Restrictions) : [],
                bookingName: data.Booking_Name,
                bookingId: data.Booking_Id,
                spaceName: data.Space_Name,
                spaceId: data.Space_Id,
                menuId: data.Menu_Id
            });
    }

    public toApiOrder(spaceId: string, bookingId: string, nodeId: number, numOfAttendees: number, orderItems: string, costCodeAllocations: ICostCodeAllocation[], orderTotal: number, restrictions: ICateringRestrictionView[], orderStatus: string, cateringServiceTime: string, cateringClearingTime: string): ICreateCateringOrder
    {
        const payload: ICreateCateringOrder =
        {
            Node_Id: nodeId,
            Menu_Items: orderItems,
            Booking_Id: bookingId,
            Space_Id: spaceId,
            Catering_Service_Time: cateringServiceTime,
            Catering_Clearing_Time: cateringClearingTime,
            Catering_Order_Status: orderStatus,
            Catering_Attendees: numOfAttendees,
            Catering_Notes: this.cateringNotes,
            Cost_Code_Allocation: JSON.stringify(costCodeAllocations),
            Catering_Total_Value: orderTotal,
            Catering_Order_Restrictions: JSON.stringify(restrictions)
        };

        return payload;
    }
}

export class MenuItemView
{
    public id = "";
    public menuId = "";
    public quantityOfItems = 0;

    public static fromApiModel(data: IMenuItem): MenuItemView
    {
        return {
            id: data.Id,
            menuId: data.Menu_Id,
            quantityOfItems: data.QuantityOfItems,
        };
    }
}

export interface ICateringRestrictionView
{
    id: string;
    section: string;
    imageURI: string;
    name: string;
}

export interface ICostAllocation
{
    costCode: string;
    costCodeId: string;
    allocation: number;
    costCodeDescription: string;
}