import { Component } from 'react';
import { appContext } from '../../../AppContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IbssButton from '../../Buttons/Button/IbssButton';
import IbssDialog from '../../Dialogs/BaseDialog/IbssDialog';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import { ICreateBookingResponse } from '../../../Providers.Api/Bookings/CreateV1BookingEndpoint';
import { Box, Button, Card, Divider } from '@mui/material';
import EquipmentsDialog, { IBookedEquipment } from '../../Dialogs/EquipmentDialog/EquipmentsDialog';
import { DateTime } from 'luxon';
import apis from '../../../Providers.Api/apis';
import Spinner from '../../Navigation/LoadingSpinner/Spinner';

class ConfirmBookingDialog extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            lightModeTheme: this.appState.lightModeTheme,
            showAddEquipmentModal: false,
            equipmentType: '',
            showEquipmentUpdatedMessage: false,
            hearingAidTotal: 0,
            presentationAidTotal: 0,
            fetchingEquipmentUpdates: false,
            resourcesUpdated: false,
            bookedEquipment: []
        };
    }

    public componentWillMount(): void
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public handleCateringRoute(): void
    {
        const { history } = this.props;
        if (this.props.isScheduleView)
        {
            history.push({
                pathname: `/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${this.props.spaceId}`,
                state: { bookingId: this.props.bookingId, originRoute: `/buildings/${this.props.buildingId}/bookings/schedule-view` } // information to push via react router
            });
        }
        else
        {
            history.push(`/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${this.props.spaceId}`);
        }
    }

    public async equipmentSubmitted(): Promise<void>
    {
        this.setState({ resourcesUpdated: true, fetchingEquipmentUpdates: true });
        const bookingParties = await this.apiClient.bookingParties.getByBookingId(this.props.buildingId, this.props.bookingId);
        this.setState({
            bookedEquipment: bookingParties.map(x =>
            ({
                Record_Id: x.Record_Id,
                Booking_Participant_Email: x.Booking_Participant_Email,
                Booking_Participant_Name: x.Booking_Participant_Name,
                Booking_Participant_Organisation: x.Booking_Participant_Organisation,
                Booking_Resource_Id: x.Booking_Resource_Id,
                Booking_Visitor: x.Booking_Visitor == 1 ? true : false,
                Booking_Participant_Type: x.Booking_Participant_Type
            })
            )
        });
        let i = 0;
        let hearingAidTotal = 0;
        let presetationAidTotal = 0;
        while (i < bookingParties.length)
        {
            let details = null;
            if (bookingParties[i].Booking_Participant_Type == 3)
            {
                details = await apis.getEquipmentDetails(1, bookingParties[i].Booking_Resource_Id);
            }
            if (details && details.data.Equip_Type == 'HearingAid')
            {
                hearingAidTotal = hearingAidTotal + 1;
            }
            if (details && details.data.Equip_Type == 'PresentationAid')
            {
                presetationAidTotal = presetationAidTotal + 1;
            }
            i += 1;
        }
        this.setState({ fetchingEquipmentUpdates: false, hearingAidTotal: hearingAidTotal, presentationAidTotal: presetationAidTotal });
    }

    public handleEditBookingRoute(): void
    {
        const { history } = this.props;
        const url = window.location.href;
        const path = new URL(url).pathname;

        if (path.includes('operational-services'))
        {
            history.push(`/operational-services-bookings/${this.props.buildingId}/bookings/${this.props.bookingId}/${this.props.spaceId}`);
        }
        else
        {
            history.push(`/flex-my-bookings/${this.props.buildingId}/mybooking/${this.props.bookingId}/${this.props.spaceId}`);
        }
    }

    public continueEditingClicked(): void
    {
        const { history } = this.props;
        if(this.props.isNewBooking)
        {
            history.push(`/flex-my-bookings/${this.props.buildingId}/mybooking/${this.props.bookingId}/${this.props.spaceId}`);
        }
        else if(!this.props.isNewBooking && this.props.close)
        {
            this.props.close()
        }
    }

    public render(): JSX.Element
    {
        const bookingHasAdditionalResource = this.props.hearingAidReqs === 1 || this.props.presentationAidReqs === 1 || this.props.cateringReqs === 1;
        const showAddTags = this.props.userCanAddTags && this.props.userCanReadTags;

        return (
            <>
                <IbssDialog
                    aria-modal="true"
                    aria-label="equipment modal"
                    open={this.props.show}
                    fullWidth
                    maxWidth={(bookingHasAdditionalResource || showAddTags) && this.props.isNewBooking ? 'md' : 'sm'}
                    dialogContent=
                    {
                        <div className='d-flex justify-content-between mt-1 mb-1'>
                            {
                                !this.props.isNewBooking &&
                                <Box sx={{ flex: 1 }}>
                                    <div className="d-flex justify-content-center">
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <IbssSvgIcon sx={{ marginLeft: '-22px' }} className="font-18">{Icons.Success}</IbssSvgIcon>
                                            </div>
                                            <p style={{ paddingTop: "5px" }}>{this.labels.funcEditBookingChangesSaved_S}!</p>
                                        </div>
                                    </div>
                                </Box>
                            }
                            {
                                this.props.isNewBooking &&
                                <Box sx={{ flex: 1 }}>
                                    <div className="d-flex justify-content-center">
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <IbssSvgIcon sx={{ marginLeft: '-22px' }} className="font-18">{Icons.Success}</IbssSvgIcon>
                                            </div>
                                            <p style={{ paddingTop: "5px" }}>{this.state.resourcesUpdated ? this.labels.funcEdisBookingResourcesUpdated_S : this.labels.HubLabelAllBooked}</p>
                                        </div>
                                    </div>
                                    <Card sx={{ padding: '20px', background: 'var(--ui-line-break)' }}>
                                        <div className='mb-2'>{this.props.bookingName}</div>
                                        <Card style={{ padding: '20px' }}>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex' style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <IbssSvgIcon sx={{ paddingTop: '5px', marginRight: '10px' }} className="font-18">{Icons.StandardDesk}</IbssSvgIcon>
                                                    </div>
                                                    <div>{this.labels.HubLabelSpace}</div>
                                                </div>
                                                <div>{this.props.spaceName}</div>
                                            </div>
                                            <hr />
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex' style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <IbssSvgIcon sx={{ paddingTop: '5px', marginRight: '10px' }} className="font-18">{Icons.calenderIcon}</IbssSvgIcon>
                                                    </div>
                                                    <div>{this.labels.HubLabelDate}</div>
                                                </div>
                                                <div>{DateTime.fromISO(this.props.bookingStart).toLocaleDateString()}</div>
                                            </div>
                                            <hr />
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex' style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <IbssSvgIcon sx={{ paddingTop: '5px', marginRight: '10px' }} className="font-18">{Icons.TimeIcon}</IbssSvgIcon>
                                                    </div>
                                                    <div>{this.labels.HubLabelTime}</div>
                                                </div>
                                                <div>{DateTime.fromISO(this.props.bookingStart).toLocaleTimeString()} - {DateTime.fromISO(this.props.bookingEnd).toLocaleTimeString()}</div>
                                            </div>
                                        </Card>
                                    </Card>
                                </Box>
                            }
                            {
                                this.props.isNewBooking && (bookingHasAdditionalResource || showAddTags) &&
                                <Box sx={{ mt: 5, mb: 3, ml: 2, flex: 1 }}>
                                    <Box className='mb-1'>
                                        <div>{this.labels.HubLabelAllBookedPra1}</div>
                                        <div>{this.labels.HubLabelAllBookedPra2}</div>
                                    </Box>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                                        {this.props.presentationAidReqs === 1 && (
                                            <Button
                                                fullWidth
                                                sx={{ padding: 0 }}
                                                onClick={() => { this.setState({ equipmentType: 'PresentationAid', showAddEquipmentModal: true }) }}
                                            >
                                                <div style={{ border: '2px solid var(--ui-line-break)', padding: '15px', margin: 0 }} className="icon-text-inline d-flex justify-content-between w-100">
                                                    <div className="d-flex pointer">
                                                        <span className="space-icon-item">
                                                            <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="Presentation Aids" />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.funcEquipmentPresentationAids_S}</span>
                                                    </div>
                                                    {
                                                        this.state.fetchingEquipmentUpdates ?
                                                            <div style={{ position: 'relative', marginRight: '14px' }}>
                                                                <Spinner />
                                                            </div>
                                                            :
                                                            this.state.presentationAidTotal > 0 ?
                                                                <div className='d-flex'>
                                                                    <div className='mr-2'>{this.state.presentationAidTotal} {this.labels.HubLabelItems}</div>
                                                                    <IbssSvgIcon sx={{ paddingTop: '5px', marginRight: '10px', marginTop: '-2px', color: 'var(--ui-success)' }} className="font-18">{Icons.TickIcon}</IbssSvgIcon>
                                                                </div>
                                                                :
                                                                <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                                    }
                                                </div>
                                            </Button>
                                        )}
                                        {this.props.hearingAidReqs === 1 && (
                                            <Button
                                                fullWidth
                                                sx={{ padding: 0 }}
                                                onClick={() => { this.setState({ equipmentType: 'HearingAid', showAddEquipmentModal: true }) }}
                                            >
                                                <div style={{ border: '2px solid var(--ui-line-break)', padding: '15px' }} className="icon-text-inline d-flex justify-content-between w-100">
                                                    <div className="d-flex pointer">
                                                        <span className="space-icon-item">
                                                            <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="Hearing Aid" />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelHearingAid}</span>
                                                    </div>
                                                    {
                                                        this.state.fetchingEquipmentUpdates ?
                                                            <div style={{ position: 'relative', marginRight: '14px' }}>
                                                                <Spinner />
                                                            </div>
                                                            :
                                                            this.state.hearingAidTotal > 0 ?
                                                                <div className='d-flex'>
                                                                    <div className='mr-2'>{this.state.hearingAidTotal} {this.labels.HubLabelItems}</div>
                                                                    <IbssSvgIcon sx={{ paddingTop: '5px', marginRight: '10px', marginTop: '-2px', color: 'var(--ui-success)' }} className="font-18">{Icons.TickIcon}</IbssSvgIcon>
                                                                </div>
                                                                :
                                                                <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                                    }
                                                </div>
                                            </Button>
                                        )}
                                        {this.props.cateringReqs === 1 && (
                                            <Button
                                                fullWidth
                                                sx={{ padding: 0 }}
                                                onClick={() => this.handleCateringRoute()}
                                            >
                                                <div style={{ border: '2px solid var(--ui-line-break)', padding: '15px' }} className="icon-text-inline d-flex justify-content-between w-100">
                                                    <div className="d-flex pointer">
                                                        <span className="space-icon-item">
                                                            <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="Catering" />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelCateringTable}</span>
                                                    </div>
                                                    <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                                </div>
                                            </Button>
                                        )}
                                        {showAddTags && (
                                            <Button
                                                fullWidth
                                                sx={{ padding: 0 }}
                                                onClick={() => this.handleEditBookingRoute()}
                                            >
                                                <div style={{ border: '2px solid var(--ui-line-break)', padding: '15px' }} className="icon-text-inline d-flex justify-content-between w-100">
                                                    <div className="d-flex pointer">
                                                        <span className="space-icon-item">
                                                            <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Tag.svg`} alt="Tags" />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.funcTags_S}</span>
                                                    </div>
                                                    <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                                </div>
                                            </Button>
                                        )}
                                    </div>
                                </Box>
                            }
                        </div>
                    }
                    footer=
                    {
                        <div className='d-flex w-90' style={{ justifyContent: 'space-between' }}>
                            <IbssButton
                                variant="contained"
                                color='secondary'
                                onClick={() => this.props.onReturnHomeClicked()}
                            >
                                {this.labels.funcCreateBookingReturnHome_S}
                            </IbssButton>
                            <div>
                                {
                                    (this.props.isNewBooking || (!this.props.isNewBooking && this.props.close != undefined)) &&
                                    <IbssButton
                                        variant="contained"
                                        color='secondary'
                                        onClick={() => this.continueEditingClicked()}
                                        className='mr-2'
                                    >
                                        {this.labels.funcEditBookingContinueEditing_S}
                                    </IbssButton>
                                }
                                <IbssButton
                                    variant="contained"
                                    onClick={() => this.props.onMakeAnotherBookingClicked()}
                                >
                                    {this.labels.funcMakeAnotherBooking_S}
                                </IbssButton>
                            </div>
                        </div>
                    }
                    onClose={() => this.props.onReturnHomeClicked()}
                />
                <EquipmentsDialog
                    show={this.state.showAddEquipmentModal}
                    onClose={() => this.setState({ showAddEquipmentModal: false })}
                    bookingId={this.props.bookingId}
                    bookingName={this.props.bookingName}
                    bookingStart={this.props.bookingStart}
                    bookingEnd={this.props.bookingEnd}
                    buildingId={(this.props.buildingId).toString()}
                    equipmentType={this.state.equipmentType}
                    floorId={this.props.floorId}
                    spaceId={this.props.spaceId}
                    spaceName={this.props.spaceName}
                    equipmentSubmitted={() => this.equipmentSubmitted()}
                    bookedEquipment={this.state.bookedEquipment}
                />
            </>
        )
    }
}

export default withRouter(ConfirmBookingDialog);

export interface IProps extends RouteComponentProps
{
    isScheduleView: boolean;
    bookingName: string,
    show: boolean,
    onReturnHomeClicked: () => void,
    onMakeAnotherBookingClicked: () => void,
    cateringReqs: number,
    hearingAidReqs: number,
    presentationAidReqs: number,
    buildingId: number,
    spaceName: string,
    bookingId: string;
    spaceId: string;
    floorId: number;
    bookingStart: string,
    bookingEnd: string,
    isNewBooking: boolean;
    userCanAddTags?: boolean;
    userCanReadTags?: boolean;
    close?: () => void;
}

export interface IState
{
    lightModeTheme: boolean,
    showAddEquipmentModal: boolean,
    equipmentType: string,
    showEquipmentUpdatedMessage: boolean,
    hearingAidTotal: number,
    presentationAidTotal: number,
    fetchingEquipmentUpdates: boolean,
    resourcesUpdated: boolean,
    bookedEquipment: IBookedEquipment[]
}