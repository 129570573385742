import { Box } from "@mui/material";
import React from "react";
import { appContext } from "../../../../AppContext";
import IbssCheckBox from "../../../../Components/Inputs/CheckBox/IbssCheckBox";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

interface IProps
{
    visitorStatus: string;
}


export default class VisitorStatusChecker extends IbssComponent<IProps, {}>
{
    private get labels()
    {
        return appContext().labels;
    }

    private checkBoxList(label: string, status: number): React.ReactNode
    {
        return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:'center' }}>
                <div>
                    <img
                        src="/images/readOnlyField.svg"
                        alt=""
                        width="16px"
                        height="16px"
                    />
                    <label className="form-input-box-label ml-2">
                        {label}
                    </label>
                </div>
                <IbssCheckBox
                    disabled
                    onClicked={() => {}}
                    checked={status === 1}
                />
            </Box>
        </>
        )        
    }

    render()
    {
        return (
            <Box className="mt-3">
                {this.checkBoxList(this.labels.HubLabelApproved,this.props.visitorStatus === "Approved" ? 1 : 0)}
                <hr className="mx-5px"/>
                {this.checkBoxList(this.labels.HubLabelCancelled,this.props.visitorStatus === "Cancelled" ? 1 : 0)}
                <hr className="mx-5px" />
                {this.checkBoxList(this.labels.HubLabelDeniedLabel,this.props.visitorStatus === "Denied" ? 1 : 0)}
                <hr className="mx-5px" />
                {this.checkBoxList(this.labels.HubLabelCheckedIn,this.props.visitorStatus === "Checked In" ? 1 : 0)}
                <hr className="mx-5px" />
                {this.checkBoxList(this.labels.HubLabelCheckedOut,this.props.visitorStatus === "Checked Out" ? 1 : 0)}
                <hr className="mx-5px" />
                {this.checkBoxList(this.labels.HubLabelNoShow,this.props.visitorStatus === "No Show" ? 1 : 0)}
            </Box>
        );
    }
}
