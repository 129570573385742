import { Component } from "react";
import { appContext } from "../../../AppContext";
import IbssDialog, { DialogStyle } from "../../Dialogs/BaseDialog/IbssDialog";
import IbssButtonRedo from "../../Buttons/Button/IbssButton";

export default class AlertModal extends Component<IProps>
{
    private labels = appContext().labels;

    public render(): JSX.Element
    {
        const props = this.props;
        const showModal = (props.show && !!props.message);

        return (
            <>
                <IbssDialog
                    open={showModal}
                    styleName={props.options?.style}
                    onClose={() => props.onClose()}
                    header={props.title}
                    dialogContent=
                    {
                        <>
                            {props.options?.messageIsHtml && <span dangerouslySetInnerHTML={{ __html: props.message }}></span>}
                            {!props.options?.messageIsHtml && <span>{props.message}</span>}
                        </>
                    }
                    footer=
                    {
                        <>
                            <div className="d-flex justify-content-center w-100">
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    onClick={() => props.onClose()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </div>
                        </>
                    }
                    fullWidth
                />
            </>
        );
    }
}

export class AlertModalController
{
    public changed?: ((show: boolean, title: string, message: string, options?: IOptions) => Promise<void>);
    private onClose?: () => void;

    public show(title: string, message: string, onClose?: () => void, options?: IOptions): Promise<void>
    {
        if (!message || !this.changed)
        {
            return Promise.resolve();
        }
        this.onClose = onClose;
        return this.changed(true, title, message, options);
    }

    public async hide(): Promise<void>
    {
        try
        {
            if (!this.changed)
            {
                return Promise.resolve();
            }

            await this.changed(false, "", "");
            if (this.onClose)
            {
                this.onClose();
            }
        }
        finally
        {
            this.onClose = undefined;
        }
    }
}

export interface IProps
{
    show: boolean;
    title: string;
    message: string;
    options?: IOptions
    onClose: () => void;
}

export interface IOptions
{
    messageIsHtml?: boolean;
    style?: DialogStyle;
}
