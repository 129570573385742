import Dashboard from "../../../Pages/Public/Dashboard";
import Unauthorised from "../../../Pages/Public/Unauthorised";
import Login from "../../../Pages/Public/Login/Login";
import NoRights from "../../../Pages/Public/NoRights";
import OutOfService from "../../../Pages/Public/OutOfService/OutOfService";
import { LoginCallback } from "@okta/okta-react";
import { IRoute } from "./IbssRouter";

export default class PublicRoutes
{
    public routes: IRoute[] = [
        {
            isPublic: true,
            requiredRights: [],
            path: "/norights",
            component: NoRights,
        },
        {
            isPublic: true,
            requiredRights: [],
            path: "/out-of-service",
            component: OutOfService,
        },
        {
            isPublic: true,
            requiredRights: [],
            path: "/unauthorised",
            component: Unauthorised,
        },
        {
            isPublic: true,
            requiredRights: [],
            path: "/login",
            component: Login
        },
        {
            isPublic: true,
            requiredRights: [],
            path: "/implicit/callback",
            component: LoginCallback,
        },
        {
            isPublic: true,
            requiredRights: [],
            path: "/auth",
            component: Dashboard
        },
    ];
}
