import { ODataSelect } from "../../../Providers.Api/ODataQuery";
 
export class SystemFilter extends ODataSelect
{
    public ConcurrencyStamp? = '';
    public EventType_Id = 0;
    public Filter = '';
    public Name = '';
    public OwnedByRoleId = '';
    public Status = '';
    public Building_Bypass = false;
 
    public init(response: any, nodeId: number): SystemFilter
    {
        super.init(response, nodeId);
        return this;
    }
}
 