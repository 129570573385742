import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { handleTableSpaceError } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner"
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { appContext } from "../../../../AppContext";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IIdentityProvider } from "../../../../Providers.Api/ExternalIdentityProviders/GetIdentityProvidersEndpoint";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";

class ListIdentityProviders extends Component<RouteComponentProps, IState> 
{
  private get apiClient() { return appContext().apiClient; }
  private get labels() { return appContext().labels; }
  private get local() { return appContext().localStorageProvider; }
  
  private columns =  [
    {
      label: this.labels.HubLabelName,
      field: "name",
      sort: "",
      width: 50
    },
    {
      label: this.labels.HubLabelType,
      field: "securityProviderTypeName",
      sort: "",
      width: 50
    },
    {
      label: this.labels.HubLabelEnabled,
      field: "status",
      sort: "",
      width: 50
    },
    {
      label: this.labels.HubLabelDefault,
      field: "isDefault",
      sort: "",
      width: 50
    },
    {
      label: "",
      field: "",
      sort: "disabled",
    },
  ]
  
  constructor(props: RouteComponentProps )
  {
    super(props);
    this.state =
    {
      isLoadingToGetIdentityProviders: false,
      isLoadingToGetRightList: false,
      identityProviders: [],
      isCreateRight: false,
    }
  }
  
  public componentDidMount(): void
  {
    this.getIdentityProvidersData();
    this.getRightList();
  }

  private getIdentityProvidersData(): void
  {
    this.setState({ isLoadingToGetIdentityProviders: true, });

    this.apiClient.externalIdentityProviders.getIdentityProviders().then((res)=>
    {
      this.setState({
        identityProviders: res,
      })
      this.setState({ isLoadingToGetIdentityProviders: false, });
    }).catch((error) => 
      {
        this.setState({ isLoadingToGetIdentityProviders: false, });
        const tableError = handleTableSpaceError(this, error, this.labels.HubLabelTokenErorr);
        console.log(tableError);
      });
  }

  private getRightList(): void
  {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.IdentityProviders && res.ADMINPORTAL.IdentityProviders.indexOf("Create") > -1) 
    {
      this.setState({
        isCreateRight: true
      })
    }
  }

  private handleClick(params: IdentityProviderPresentation): void
  {
    const { history } = this.props;
    history.push("security-identity-providers/details/" + params.id);
  }

  private createRowsData(): Array<IRowData>
  {
    const { identityProviders } = this.state;

    return identityProviders.map(identityProvider => 
      (
        {
          name: identityProvider?.name ?? '',
          securityProviderTypeName: identityProvider.securityProviderTypeName,
          clickEvent: () => this.handleClick(identityProvider),
          isDefault: identityProvider.isDefault? 'Yes': 'No',
          status: identityProvider.status === 1 ? "Yes" : "No",
          Option: <button className="btn btn-success" onClick={() => this.handleClick(identityProvider)} >Edit </button>
        }
      )
    )
  }


  public render(): JSX.Element
  {
    const data = 
    {
      columns: this.columns,
      rows: this.createRowsData(),
    };

    return (
          <div className="rightPanel-main-content">
            <div className="table-panel">
              <TablePanelInfo
                tableHeading={this.labels.HubLabelIdentityproviders}
                iconSrc="IdProvider"
              />
              <div style={{ position: 'absolute', right: '16px', zIndex: 99 }}>
                    <IbssButton disabled={this.state.isCreateRight === false} color="primary" variant="contained" onClick={() => this.props.history.push('security-identity-providers/details/0')}>{this.labels.HubLabelNewProvider}</IbssButton>
              </div>
              <div className="position-relative">
                {(this.state.isLoadingToGetIdentityProviders || this.state.isLoadingToGetRightList) && <Spinner />}
                <MDBDataTable className="standardTable tablePageNoButtons tableIdentityProvider" searchLabel={this.labels.HubLabelMySearchLabel} displayEntries={false} entries={50} hover data={data} />
              </div>
            </div>
          </div>
    );
  }
}

interface IState
{
  isLoadingToGetIdentityProviders: boolean,
  isLoadingToGetRightList: boolean,
  identityProviders: Array<IdentityProviderPresentation>,
  isCreateRight: boolean,
}

interface IRowData
{
  name: string;
  securityProviderTypeName: string;
  clickEvent: () => void;
  isDefault: string;
  status: string;
  Option: JSX.Element;
}

export class IdentityProviderPresentation
{
    id: number;
    name: string | null;
    securityProviderTypeId: number;
    securityProviderTypeName: string;
    clientId: string | null;
    tenantDomain: string | null;
    tenantId: string | null;
    isDefault: boolean;
    status: number;

    constructor(identityProvider: IIdentityProvider)
    {
      this.id = identityProvider.id;
      this.name = identityProvider.name;
      this.securityProviderTypeId = identityProvider.securityProviderTypeId;
      this.securityProviderTypeName = identityProvider.securityProviderTypeName;
      this.clientId = identityProvider.clientId;
      this.tenantDomain = identityProvider.tenantDomain;
      this.tenantId = identityProvider.tenantId;
      this.isDefault = identityProvider.isDefault;
      this.status = identityProvider.status; 
    }

}

export default withRouter(ListIdentityProviders);