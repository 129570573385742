import { Box, Card, Grid, Typography } from '@mui/material';
import { Component } from 'react';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssInput from '../../../../Components/Inputs/Input/IbssInput';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { RouteComponentProps } from 'react-router';
import FormPanelInfo from '../../../../Components/Headings/FormHeading/FormPanelInfo';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';

class EditCateringSupplier extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }
    private get session()  { return appContext().sessionStorageProvider; }
    private get appState() { return appContext().state; }


    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            supplierName: '',
            supplierId: '',
            supplierDescription: '',
            supplierContact: '',
            supplierTelephone: '',
            supplierEmail: '',
            supplierLeadTime: '',
            externalId: '',
            loading: false,
            concurrencyStamp: ''
        };
    };

    public async componentDidMount(): Promise<void>
    {
        const buildingId = this.props.match.params.buildingId;
        const supplierId = this.props.match.params.supplierId;
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        if (supplierId)
        {
            this.setState({ loading: true })
            const data = await this.apiClient.cateringSuppliers.getCateringSupplierById(parseInt(buildingId), supplierId)

            this.setState({
                supplierName: data.Name,
                supplierId: data.Supplier_Id || '',
                supplierDescription: data.Description,
                supplierContact: data.AccountManager,
                supplierTelephone: data.Phone,
                supplierEmail: data.Email,
                supplierLeadTime: data.LeadTime,
                externalId: data.External_Id,
                concurrencyStamp: data.ConcurrencyStamp || ''
            })
            this.setState({ loading: false })
        }
    }

    private navigateBack(): void
    {
        this.props.history.goBack();
    }

    private async deleteSupplier(): Promise<void>
    {
        this.setState({ loading: true });
        const supplierId = this.props.match.params.supplierId;
        if (supplierId)
        {
            const buildingId = this.props.match.params.buildingId;
            await this.apiClient.cateringSuppliers.deleteCateringSupplier(parseInt(buildingId), supplierId);
        }
        this.setState({ loading: false });
        this.props.history.goBack();
    }

    private async submit(): Promise<void>
    {
        const buildingId = this.props.match.params.buildingId !== "0"  ? this.props.match.params.buildingId : this.session.getBuildingId().toString();
        const supplierId = this.props.match.params.supplierId;
        this.setState({ loading: true })
        let payload = {
            Name: this.state.supplierName,
            Description: this.state.supplierDescription,
            AccountManager: this.state.supplierContact,
            Phone: this.state.supplierTelephone,
            Email: this.state.supplierEmail,
            LeadTime: this.state.supplierLeadTime,
            External_Id: this.state.externalId,
            ConcurrencyStamp: this.state.concurrencyStamp
        }
        if (supplierId)
        {
            await this.apiClient.cateringSuppliers.updateCateringSupplier(parseInt(buildingId), supplierId, payload)
        }
        else
        {
            await this.apiClient.cateringSuppliers.createCateringSupplier(parseInt(buildingId), payload)
        }
        // TODO: route to suppliers list page when implemented
        this.setState({ loading: false })
        this.props.history.goBack();
    }

    public render(): JSX.Element
    {
        const supplierId = this.props.match.params.supplierId;
        const disableSubmit = this.state.supplierName == '' || this.state.supplierDescription == '' || this.state.supplierContact == '' || this.state.supplierLeadTime == ''
        return (
            <>
             <Box className="page-height-exct-header">
                <Box className="rightPanel-main-content">
                <Box className='form-panel-except-header'>
                    {this.state.loading && <LoadingOverlay />}
                    <div className="form-panel-form">
                        <form>
                            <Grid container spacing={2}>
                                <Grid item sm={7}>
                                    <Grid container spacing={3} sx={{mb:3}}>
                                        <Grid item sm={12}>
                                        <div className='d-flex'>
                                            <div className="mr-2">
                                                <IbssSvgIcon fontSize='large'>
                                                    {Icons.StandardDesk}
                                                </IbssSvgIcon>
                                            </div>
                                            <div className='table-panel-header' style={{ paddingTop: '4px' }}>
                                                {this.labels.HubLabelSupplierDetails}
                                            </div>
                                        </div>
                                        <Typography variant="body1" className="my-0">{this.labels.funcSupplierDetailsEnter_L}</Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField required label={this.labels.HubLabelSupplierName} onChange={e => this.setState({ supplierName: e.target.value })} value={this.state.supplierName} type='underlined' fullWidth/>
                                        </Grid>
                                        {
                                        supplierId &&
                                        <Grid item sm={12}>
                                            <IbssTextField label={this.labels.HubLabelSupplierId} value={this.state.supplierId} disabled fullWidth />
                                        </Grid>
                                        }
                                        <Grid item sm={12}>
                                            <IbssTextField required label={this.labels.HubLabelSupplierDescription} onChange={e => this.setState({ supplierDescription: e.target.value })} value={this.state.supplierDescription} fullWidth />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField required label={this.labels.HubLabelSupplierContact} onChange={e => this.setState({ supplierContact: e.target.value })} value={this.state.supplierContact} fullWidth />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField label={this.labels.HubLabelSupplierTelephone} onChange={e => this.setState({ supplierTelephone: e.target.value })} value={this.state.supplierTelephone} fullWidth />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField label= {this.labels.HubLabelSupplierEmail} onChange={e => this.setState({ supplierEmail: e.target.value })} value={this.state.supplierEmail} fullWidth/>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField required label={this.labels.HubLabelGeneralLeadTime} onChange={e => this.setState({ supplierLeadTime: e.target.value })} value={this.state.supplierLeadTime} fullWidth />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <IbssTextField label={this.labels.HublabelExternalId} onChange={e => this.setState({ externalId: e.target.value })} value={this.state.externalId} fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Box>
                <div className="rightPanel-main-button-group">
                    <div>
                        {supplierId && <IbssButton variant='contained' color='error' className='float-left' disabled={!this.local.hasRight("DATAMODEL.CateringSuppliers.Delete")}  onClick={() => this.deleteSupplier()}>{this.labels.HubButtonDelete}</IbssButton>}
                    </div>
                    <div>
                        <IbssButton className='mr-2' variant='contained' color='secondary' onClick={() => this.navigateBack()}>{this.labels.HubButtonCancel}</IbssButton>
                        <IbssButton variant='contained' color='primary' disabled={disableSubmit} onClick={() => this.submit()}>{supplierId ? this.labels.HubLabelUpdate : this.labels.HubButtonSubmit}</IbssButton>
                    </div>
                </div>
                </Box>
             </Box>
            </>
        )
    }
}

export default EditCateringSupplier;

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    supplierName: string;
    supplierId: string;
    supplierDescription: string;
    supplierContact: string;
    supplierTelephone: string;
    supplierEmail: string;
    supplierLeadTime: string;
    externalId: string;
    loading: boolean;
    concurrencyStamp: string;
}

interface IQueryParams
{
    buildingId: string;
    supplierId: string;
}