import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePickerProps } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

type IbssDatePickerProps = DatePickerProps<DateTime>

class IbssDatePicker extends React.Component<IbssDatePickerProps> {

    constructor(props: any) {
        super(props);
    };

    render(): React.ReactNode {
        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                <DesktopDatePicker
                    {...this.props}
                />
            </LocalizationProvider>
        );
    }
};

export default IbssDatePicker;
