import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public view?: 'map' | 'list';
    public workType?: string;
    public spaceType?: string;
    public floor?: string;
    public zone?: string;
    public date?: DateTime;
    public start?: DateTime;
    public end?: DateTime;
    public av?: boolean;
    public resources?: boolean;
    public hearingAids?: boolean;
    public catering?: boolean;
    public linkedSpace?: boolean;
    public layouts?: boolean;
    public capacity?: number;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'capacity':
                return this.serialiseNumber(propertyValue);
            case 'av':
            case 'resources':
            case 'hearingAids':
            case 'catering':
            case 'linkedSpace':
            case 'layouts':
                return this.serialiseBoolean(propertyValue);
            case 'date':
                return this.serialiseDate(propertyValue);
            case 'start':
            case 'end':
                return this.serialiseTime(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue) || null;
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'capacity':
                return this.deserialiseNumber(queryValue);
            case 'av':
            case 'resources':
            case 'hearingAids':
            case 'catering':
            case 'linkedSpace':
            case 'layouts':
                return this.deserialiseBoolean(queryValue);
            case 'date':
                return this.deserialiseDate(queryValue);
            case 'start':
            case 'end':
                return this.deserialiseTime(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
