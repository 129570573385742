import { KeyValue } from "./KeyValue";

declare global
{
    interface Array<T>
    {
        insert<TItem>(index: number, items: TItem[]): Array<TItem>;
        add<TItem>(items: TItem[]): Array<TItem>;
        contains(item: T): boolean;
        distinct<TKey>(keySelector?: (item: T) => TKey): Array<T>;
        ancestorsOf<TKey>(key: TKey, keySelector: (item: T) => TKey, parentKeySelector: (item: T) => TKey, includeSelf?: boolean): Array<T>;
        groupBy<TKey>(keySelector: (item: T) => TKey): Array<KeyValue<TKey, Array<T>>>;
        sum(keySelector: (item: T) => number): number;
        average(keySelector: (item: T) => number): number;
    }
}

Array.prototype.insert = function<TItem>(index: number, items: TItem[])
{
    const firstItems = this.filter((value, itemIndex) => itemIndex < index);
    const lastItems = this.filter((value, itemIndex) => itemIndex >= index);
    return [ ...firstItems, ...items, ...lastItems ];
}

Array.prototype.add = function<TItem>(items: TItem[])
{
    return [ ...this, ...items ];
}

Array.prototype.contains = function<T> (item: T)
{
    return (this.indexOf(item) != -1);
}

Array.prototype.distinct = function <T, TKey>(keySelector: (item: T) => TKey = (item => item as any))
{
    let distinctItems = new Array<T>();
    let isKeyAdded = new Map<TKey, boolean>();

    for (let i = 0; i < this.length; i++)
    {
        var item = this[i];
        var key = keySelector(item);

        if (!isKeyAdded.has(key))
        {
            isKeyAdded.set(key, true);
            distinctItems.push(item);
        }
    }

    return distinctItems;
}

Array.prototype.ancestorsOf = function <T, TKey>(key: TKey, keySelector: (item: T) => TKey, parentKeySelector: (item: T) => TKey, includeSelf: boolean = false)
{
    let ancestors = new Array<T>();

    let item = this.filter(i => keySelector(i) == key)[0];
    if (!item)
    {
        return ancestors;
    }
    if (includeSelf)
    {
        ancestors.push(item);
    }

    let parent = this.filter(i => keySelector(i) == parentKeySelector(item))[0];
    if (parent)
    {
        let ancestorsOfItem = this.ancestorsOf(keySelector(parent), keySelector, parentKeySelector, true);
        ancestors.push(...ancestorsOfItem);
    }

    return ancestors;
}

Array.prototype.groupBy = function <T, TKey>(keySelector: (item: T) => TKey)
{
    let groups = new Array<KeyValue<TKey, Array<T>>>();
    for (let i = 0; i < this.length; i++)
    {
        let item = this[i];
        let key = keySelector(item);
        let group = groups.find(i => i.key == key);

        if (group == null)
        {
            group = new KeyValue(key, new Array<T>())
            groups.push(group);
        }

        group.value.push(item);
    }
    return groups;
}

Array.prototype.sum = function <T>(keySelector: (item: T) => number)
{
    let total = 0;
    for (const item of this)
    {
        total += keySelector(item);
    }
    return total;
}

Array.prototype.average = function <T>(keySelector: (item: T) => number)
{
    if (this.length == 0)
    {
        return 0;
    }

    const average = this.sum(keySelector) / this.length;
    return average;
}
