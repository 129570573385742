import { routes } from "../Components/Navigation/Router/IbssRouter";
import { Component } from "react";
import { Location } from 'history';
import { DateTime } from "luxon";
import { DateHelper } from "./DateHelper";

export class RouteHelper
{
    public getCurrentRoute(): IRoute | null
    {
        let path = window.location.pathname.replace(/\/$/, '');
        if (!path.startsWith("/"))
        {
            path = "/" + path;
        }

        for (let i = 0; i < routes.length; i++)
        {
            let route = routes[i];
            let routePattern = '^' + route.path.replace(/:[^/]+/g, '[^/]+') + '$';
            let isMatch = new RegExp(routePattern, 'gi').test(path);

            if (isMatch)
            {
                return {
                    isPublic: route.isPublic ?? false,
                    path: route.path,
                };
            }
        }

        return null;
    }

    public getQueryParams(): Map<string, string>
    {
        const queryString = (window.location.search.charAt(0) == '?' ? window.location.search.slice(1) : window.location.search);
        if (!queryString)
        {
            return new Map<string, string>();
        }

        const params: Iterable<readonly [string, string]> = queryString.split("&").map(i => [
            i.split("=")[0].toLocaleLowerCase(),
            decodeURIComponent(i.split("=")[1].replaceAll('+', ' ')),
        ]);

        const map = new Map(params);
        return map;
    }

    public getQueryParamAsString(name: string): string | null
    {
        return this.getQueryParams().get(name) ?? null;
    }

    public getQueryParamAsNumber(name: string): number | null
    {
        var value = this.getQueryParams().get(name);
        if (value == null)
        {
            return null;
        }

        var valueAsNumber = new Number(value).valueOf();
        if (isNaN(valueAsNumber))
        {
            return null;
        }

        return valueAsNumber;
    }

    public getQueryParamAsBoolean(name: string): boolean | null
    {
        var value = this.getQueryParams().get(name);
        if (value == null)
        {
            return null;
        }

        var valueAsBoolean = new Boolean(value).valueOf();
        return valueAsBoolean;
    }

    public getQueryParamAsDateTime(name: string): DateTime | null
    {
        var value = this.getQueryParams().get(name);
        if (value == null)
        {
            return null;
        }

        var valueAsDateTime = DateHelper.fromUrl(value);
        if (!valueAsDateTime.isValid)
        {
            return null;
        }

        return valueAsDateTime;
    }
}

export interface IRoute
{
    isPublic: boolean;
    path: string;
}
