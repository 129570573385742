import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import { appContext } from '../../../AppContext';
import IbssButton from '../../Buttons/Button/IbssButton';
import IbssInputDropDown from '../../Inputs/SelectList/IbssInputDropDown';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PagedResponse } from '../../../Providers.Api/Models';
import { ITag, ITagToEventType } from './DataModels';
import TagsChips from './TagsChips';
import { EventType } from '../../../Providers.Api/EventTypes';
import { ISelectOption } from '../../Inputs/SelectList/IbssInputDropDown';
import ClickedOutsideBox from './ClickedOutsideBox';
import IbssRadioButton from '../../Inputs/RadioButton/IbssRadioButton';
class BookingTagPicker extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels }
    private get local() { return appContext().localStorageProvider; }
    tagOptions: ITagView[]; // all tags options available to the booking, 

    private canCreate: boolean;
    private canDelete: boolean;
    private canRead: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            showTagsDropdown: false,
            selectedComplexTag: null, 
            selectedTagId: '', // user's currently selected tagId when using dropdown list.
        };

        this.canCreate = this.local.hasRight('DATAMODEL.Tag2EventType.Create');
        this.canDelete = this.local.hasRight('DATAMODEL.Tag2EventType.Delete');
        this.canRead = this.local.hasRight('DATAMODEL.Tag2EventType.Read');
        this.tagOptions = [];
    }

    public async componentDidMount(): Promise<void>
    {
        if(this.props.buildingId !== 0)
        {
            await this.loadTagOptions();
        }
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.buildingId !== this.props.buildingId)
        {
            if(this.props.buildingId !== 0)
            {
                await this.loadTagOptions();
            }
        }
    }

    private addTagButtonClicked(): void
    {
        this.setState({ showTagsDropdown: !this.state.showTagsDropdown });
    }

    private addSelectedComplexTagToUI(tag: ITagView): void
    {
        // add tag to UI
        this.setState({ selectedComplexTag: tag });
    }

    private removeSelectedComplexTagFromUI(): void
    {
        // remove tag from UI
        this.setState({ selectedComplexTag: null });
    }

    private async loadTagOptions(): Promise<void>
    {
        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tags.get<PagedResponse<ITag[]>>({
                nodeId: this.props.buildingId,
                top: 1000,
                select: ITag,
                filter: `Status eq 'StatusActive'`,
            });

            const tags = response.value.map(tag => ITagView.fromTag(tag));
            this.tagOptions = tags
        }
        catch (error)
        {
            return;
        }
    }

    private async addTagToBooking(tagId: string): Promise<void>
    {

        const tag = this.tagOptions.find(tag => tag.tagId === tagId);
        if (!tag)
        {
            return;
        }
        if (tag.tagType === "TagTypeComplex" && tag.values.split(';').length > 1)
        {
            // if it's a complex tag and there are semi-colon delimited values in tag.values, do not add tag to booking straightaway.
            this.addSelectedComplexTagToUI(tag);
            return;
        }

        try
        {
            this.props.addTag({
                tagToEventTypeId: "", // don't pass this empty string to POST call to create a tagToEvenType for booking tag, this is auto generated in backend.
                tagId: tagId,
                eventTypeId: EventType.bookings.id,
                tagType: tag.tagType,
                name: tag.name,
                value: tag.values,
                icon: tag.icon,
                nodeId: this.props.nodeId,
                recordId: this.props.bookingId,
            });
        }
        catch (error)
        {
            return;
        }
        finally
        {
            // reset selected complex tag states to reset UI.
            this.setState({ selectedComplexTag: null, showTagsDropdown: false });
        }
    }

    private addComplexTagToBooking(selectedComplexTag: ITagView | null, option: string): void
    {

        if(!selectedComplexTag)
        {
            return;
        }

        const tagIcons = selectedComplexTag.icon.split(';');
        const tagValues = selectedComplexTag.values.split(';');
        const tagValueIndex = tagValues.findIndex(tagValue => tagValue === option);
        const selectedTagIcon = tagIcons[tagValueIndex];

        try
        {
            this.props.addTag({
                tagToEventTypeId: "", // don't pass this empty string to POST call to create a tagToEvenType for booking tag, this is auto generated in backend.
                tagId: selectedComplexTag.tagId,
                eventTypeId: EventType.bookings.id,
                tagType: selectedComplexTag.tagType,
                name: selectedComplexTag.name,
                value: option,
                icon: selectedTagIcon,
                nodeId: this.props.nodeId,
                recordId: this.props.bookingId,
            });
        }
        finally
        {
            // reset selected complex tag states to reset UI.
            this.setState({ selectedComplexTag: null});
        }
    }

    private async removeBookingTag(tagId: string): Promise<void>
    {

        const tagToEventType = this.props.selectedBookingTags.find(selectedBookingTag => selectedBookingTag.tagId === tagId);
        if (!tagToEventType && this.state.selectedComplexTag != null)
        {
            //if complex tag has only been added to UI but not yet added via api endpoint, remove it from UI
            this.removeSelectedComplexTagFromUI();
            return;
        }
        else if (!tagToEventType)
        {
            return;
        }

        try
        {
            const tagToEventTypeId = tagToEventType.tagToEventTypeId;
            this.props.removeTag(tagToEventTypeId, tagId);
        }
        catch (error)
        {
            return;
        }
    }

    private get filterTagOptionsBySelectedTags(): ISelectOption[]
    {
        return this.tagOptions.filter((tag) => !this.props.selectedBookingTags.find(selectedTag => selectedTag.tagId === tag.tagId)).map((tag) => ({ label: tag.name, value: tag.tagId }));
    }

    public render(): JSX.Element
    {
        return (
                <>
                {this.canRead &&
                    <>
                    <TagsChips
                        canDelete={this.canDelete}
                        onDelete={this.removeBookingTag.bind(this)}
                        selectedTags={this.props.selectedBookingTags}
                    />
                    {
                        this.state.selectedComplexTag && <Box key={'complext-tag-options-container'}>
                            <TagsChips
                                canDelete={this.canDelete}
                                onDelete={this.removeBookingTag.bind(this)}
                                selectedTags={[{
                                    nodeId: this.state.selectedComplexTag.nodeId,
                                    tagToEventTypeId: "",
                                    tagId: this.state.selectedComplexTag.tagId,
                                    tagType: this.state.selectedComplexTag.tagType,
                                    name: this.state.selectedComplexTag.name,
                                    value: this.state.selectedComplexTag.values,
                                    icon: this.state.selectedComplexTag.icon,
                                    recordId: '',
                                    eventTypeId: EventType.bookings.id,
                                }]}
                            />
                            <ClickedOutsideBox key={'clickedOutsideBox'} onClickOutside={() => this.setState({ selectedComplexTag: null })}>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    sx={{ backgroundColor: theme => theme.palette.error.light }}
                                >
                                    <IbssRadioButton
                                        id='complex-tag-radio'
                                        horizontal={true}
                                        valueChanged={(value: string) => this.addComplexTagToBooking(this.state.selectedComplexTag, value)}
                                        option={this.state.selectedComplexTag.values.split(';').map(complexTagOption => ({ label: complexTagOption, value: complexTagOption, labelplacement: 'end' }))}
                                    />
                                </Box>
                            </ClickedOutsideBox>
                        </Box>
                    }
                    {this.state.showTagsDropdown &&
                        <Box paddingTop='0.5rem'>
                            <IbssInputDropDown
                                id='tags-filters'
                                inputLabel={this.labels.funcTags_S}
                                options={this.filterTagOptionsBySelectedTags}
                                value={this.state.selectedTagId}
                                onChange={e => this.addTagToBooking(e.target.value)}
                            />
                        </Box>
                    }
                    {this.canCreate && !this.state.showTagsDropdown &&
                        <Box padding='1rem 0rem'>
                            <IbssButton variant='contained' size='large' onClick={() => this.addTagButtonClicked()}>
                                <Typography paddingRight='0.5rem'>
                                    {this.labels.HubLabelAddTag_S}
                                </Typography>
                                <AddCircleOutlineIcon />
                            </IbssButton>
                        </Box>
                    }
                </>
                }
            </>
        )
    }
}

export default BookingTagPicker;

export interface IProps
{
    bookingId: string;
    buildingId: number;
    nodeId: number;
    selectedBookingTags: TagToEventTypeView[];
    addTag: (tag: TagToEventTypeView) => void;
    removeTag: (tagToEventTypeId: string, tagId: string) => void;
}

export interface IState
{
    showTagsDropdown: boolean;
    selectedComplexTag: ITagView | null;
    selectedTagId: string;
}

export class ITagView
{
    public tagId = "";
    public tagType = "";
    public values = "";
    public icon = "";
    public nodeId = 0;
    public name = "";

    public static fromTag(tag: ITag): ITagView
    {
        return {
            tagId: tag.Tag_Id,
            tagType: tag.TagType,
            values: tag.Values,
            icon: tag.Icon,
            nodeId: tag.Node_Id,
            name: tag.Name,
        }
    }
}

export class TagToEventTypeView
{
    public nodeId = 0;
    public tagToEventTypeId = "";
    public tagId = "";
    public tagType = "";
    public name = "";
    public value = "";
    public icon = "";
    public recordId = "";
    public eventTypeId = 0;

    public static fromTagToEventType(tagToEventType: ITagToEventType): TagToEventTypeView
    {
        return {
            nodeId: parseInt(tagToEventType.Node_Id),
            tagToEventTypeId: tagToEventType.Tag2EventType_Id,
            tagId: tagToEventType.Tag_Id,
            tagType: tagToEventType.TagType,
            name: tagToEventType.Name,
            value: tagToEventType.Value,
            icon: tagToEventType.Icon,
            recordId: tagToEventType.Record_Id,
            eventTypeId: tagToEventType.EventType_Id,
        }
    }
}