import { ODataSelect } from "../../../Providers.Api/ODataQuery";

export class SpaceInfo extends ODataSelect
{
    public ActionURI = '';
    public ImageURI = '';
    public Available_From = '';
    public Expiry_Date = '';
    public Has_Image = 0;
    public Node_Id = 0;
    public Space_Info_Id = 0;
    public Space_Info_Title = '';
    public Space_Info_Message = '';
    public Space_Info_Short_Message = '';
}

export class BookingPolicyData extends ODataSelect
{
    public Booking_Policy_Description = '';
}


export class Space extends ODataSelect
{
    public Space_Name?: string | null = '';
    public Space_Type_Label?: string | null = '';
    public Space_Capacity?: number | null = 0;
    public Meta_Loc_Zone?: string | null = '';
    public ImageURI?: string | null = '';
    public Space_Custom_Info?: string | null = '';
}
