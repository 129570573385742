import { SxProps, TextField, Theme } from "@mui/material";
import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldProps, TextFieldVariants } from "@mui/material/TextField";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import { JSX } from "react/jsx-runtime";

export class IbssLuxonDateTimePicker extends IbssComponent<IProps, IState>
{
    private get defaultSx(): SxProps<Theme>
    {
        return (this.props.label ? {} : { '& legend': { display: 'none' }, '& fieldset': { top: 0 } });
    }

    private valueChanged(value: (DateTime | null))
    {   
        this.props.onChange(value ?? DateHelper.null());
    }

    public render(): JSX.Element
    {
        // note: the order of props is important because it allows this component to set default values but also allows the consuming page to override them
        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                <DateTimePicker
                    renderInput={(props: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) =>
                        <TextField
                            {...props}
                            sx={this.defaultSx}
                            fullWidth
                            error={false}
                            {...this.props.input}
                        />}
                    {...this.props}
                    onChange={(value) => this.valueChanged(value as unknown as DateTime)}
                />
            </LocalizationProvider>
        );
    }
}

export interface IProps extends Omit<DateTimePickerProps<DateTime>, "renderInput" | "onChange">
{
    renderInput?: (props: TextFieldProps) => React.ReactElement;
    onChange: (value: DateTime) => void;
    input?: TextFieldProps;
}

export interface IState
{
}
