import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import { Typography, Box, SvgIcon, Divider, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactComponent as SpacesIcon } from '../../../../Components/Layout/Sidebar/icons/Spaces.svg';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IMeetingSpace } from './BookingCriteria';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { SpacePicker } from '../../../../Components/Lists/SpacePicker/SpacePicker';
import { TypeHelper } from '../../../../Common/TypeHelper';
import ChangeBookingDetailsDialog from '../../../../Components/Dialogs/ChangeBookingDetailsDialog/ChangeBookingDetailsDialog';
import { IBookingDetailsApplication } from '../../../../Components/Dialogs/ChangeBookingDetailsDialog/ChangeBookingDetailsDialog';

class SwapSpace extends Component<IProps, IState>
{
    private get api() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            start: new Date(),
            end: new Date(),
            isLoading: false,
            selectedSpaceId: this.props.space.spaceId,
            selectedSpaceHasCatering: (this.props.space.cateringReqs == 1),
            showChangeBookingDetailsPopup: false,
        };
    }

    public componentDidMount(): void 
    {
        const startTime = DateTime.fromISO(this.props.bookingStart).toJSDate();
        const endTime = DateTime.fromISO(this.props.bookingEnd).toJSDate();
        this.setState({start: startTime, end: endTime});
    }

    private async modifyBooking(): Promise<void>
    {
        this.setState({ isLoading: true });

        try
        {
            if (this.hasV2Rights)
            {
                await this.props.patchBooking(this.state.selectedSpaceId, DateTime.fromISO(this.props.bookingStart), DateTime.fromISO(this.props.bookingEnd));
            }
            else 
            {
                await this.props.updateBooking(this.state.selectedSpaceId);
            }
        }
        finally
        {
            await this.closeBooking();
        }
    }

    private get interruptUpdateWithChangeBookingDetailsPopup(): boolean
    {
        if(this.props.bookingId==='' || this.props.cateringOrderId==='')
        {
            return false;
        }

        if(this.state.selectedSpaceId === this.props.bookingSpaceId)
        {
            return false;
        }

        return true;
    }

    private showChangeBookingDetailsPopup(show: boolean): void
    {
        this.setState({showChangeBookingDetailsPopup: show});
    }

    private async handleSubmit(): Promise<void>
    {
        if(this.interruptUpdateWithChangeBookingDetailsPopup)
        {
            this.showChangeBookingDetailsPopup(true);
            return;
        }

        await this.modifyBooking();
    }

    private async closeBooking(): Promise<void>
    {
        // close the swap and bookingCriteria, get new bookings data.
        // refresh bookings data
        await this.props.getBookings();
        // hide bookingCriteria slider.
        this.props.showBookingCriteria(false);
        this.setState({ isLoading: false });
        this.props.setOpen(false);
    }

    private closeChangeBookingTimePopup(): void
    {
        this.setState({ showChangeBookingDetailsPopup: false, isLoading: false });
    }

    private handleSpaceSelect(spaceId: string): void
    {
        this.setState({ selectedSpaceId: spaceId });
    }

    public render(): JSX.Element
    {
        const props = this.props;
        const state = this.state;
        const bookingOnLinkedSpace = this.props.bookingSpaceId.includes(';');

        return (
            <div className="flexMySearch-filter-criteria m-3 ml-5 mr-5">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', mt: '3rem' }}>
                    <div className="flexMySearch-filter-criteria-title">
                        <Typography variant="h4" sx={{ fontFamily: 'Source Sans Pro', fontWeight: '700', mb: '0.5rem', color: (theme) => theme.palette.text.primary }}>
                            {this.labels.funcSwapSpaceSwap_S}
                        </Typography>
                    </div>
                    <IbssSvgIcon cursor='pointer' fontSize='large' sx={{ color: (theme) => theme.palette.text.primary }} onClick={() => this.props.setOpen(false)}>
                        {Icons.RightArrowIcon}
                    </IbssSvgIcon>
                </Box>

                <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', mb: '1.5rem', color: (theme) => theme.palette.text.secondary }}>
                    {this.labels.funcBookingScheduleSelectSpace_S}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'end', mb: '0.5rem' }}>
                    <SvgIcon component={SpacesIcon} inheritViewBox sx={{ height: '34px', width: '34px', marginRight: '1rem', color: (theme) => theme.palette.text.primary }} />
                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: '700', color: (theme) => theme.palette.text.primary }}>
                        {this.props.bookingSpaceName}
                    </Typography>
                </Box>
                <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
                    {this.labels.funcSwapSpaceCurrentSpace_S.toLocaleUpperCase()}
                </Typography>
                <Divider sx={{ mt: '1.5rem', mb: '1.5rem' }} />
                <Box>
                    {bookingOnLinkedSpace &&
                        <Box sx={{ padding: '0.8rem', mb: '1rem', backgroundColor: (theme) => theme.palette.error.light }} borderRadius={'8px'}>
                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.error.main }}>
                                {this.labels.funcSwapSpaceLinkedWarning_L}
                            </Typography>
                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.error.main }}>
                                {this.labels.funcSwapSpaceLinkedWarning_D}
                            </Typography>
                        </Box>
                    }
                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
                        {/* {`Based on your original room requirements and attendees, the following alterantive spaces are available to swap spaces with.`} */}
                        {this.labels.funcSwapSpaceAlternativeSpaces_D}
                    </Typography>
                    <FormControl sx={{ width: '100%', margin: '8px 0' }}>
                        <SpacePicker
                            swapSpace={true}
                            buildingId={props.buildingId}
                            floorId={props.space.floorId}
                            spaceTypeId={props.space.spaceType}
                            minCapacity={TypeHelper.toNumber(props.space.capacity) ?? 0}
                            lowestMinCapacity={2}
                            requiresCatering={TypeHelper.toBoolean(props.space.cateringReqs) ?? false}
                            requiresAudioVisual={TypeHelper.toBoolean(props.space.requiresAV) ?? false}
                            requiresPresentationAids={TypeHelper.toBoolean(props.space.presentationAidReqs) ?? false}
                            requiresHearingAids={TypeHelper.toBoolean(props.space.hearingAidReqs) ?? false}
                            availableFrom={DateTime.fromISO(props.bookingStart)}
                            availableTo={DateTime.fromISO(props.bookingEnd)}
                            onSpaceChange={(id) => this.handleSpaceSelect(id)}
                        />
                    </FormControl>
                </Box>
                <IbssButton
                    variant='contained'
                    fullWidth
                    sx={{ mt: '1rem', padding: '0.75rem' }}
                    onClick={async () => await this.handleSubmit()}
                    disabled={this.props.space.spaceId === this.state.selectedSpaceId || this.state.isLoading}
                >
                    {this.labels.funcSwapSpaceConfirmSwap_S}
                </IbssButton>
                <ChangeBookingDetailsDialog
                    show={this.state.showChangeBookingDetailsPopup}
                    onClose={()=> this.closeChangeBookingTimePopup()}
                    displayLocation={IBookingDetailsApplication.Onelens}
                    bookingId={this.props.bookingId}
                    buildingId={this.props.buildingId}
                    isCancelling={false}
                    isSwappingSpace={this.state.selectedSpaceId !== this.props.bookingSpaceId}
                    nodeId={this.props.nodeId}
                    spaceId={this.state.selectedSpaceId}
                    updateBooking={()=> this.modifyBooking()}
                    bookingStart={this.state.start}
                    bookingEnd={this.state.end}
                    originalBookingStart={this.state.start}
                    originalSpaceId={this.props.bookingSpaceId}
                    cateringOrderId={this.props.cateringOrderId}  
                />
            </div>
        )
    }
}

export default SwapSpace;

export interface IProps
{
    open: boolean;
    bookingStart: string;
    bookingEnd: string;
    bookingId: string;
    bookingSpaceId: string;
    bookingSpaceName: string;
    buildingId: number;
    nodeId: number;
    space: IMeetingSpace;
    cateringOrderId: string;
    setOpen: (val: boolean) => void;
    patchBooking: (spaceId: string, startTime: DateTime, endTime: DateTime) => Promise<void>;
    updateBooking: (spaceId: string) => Promise<void>;
    showBookingCriteria: (val: boolean) => void;
    getBookings: () => Promise<void>;
}

export interface IState
{
    isLoading: boolean;
    selectedSpaceId: string;
    selectedSpaceHasCatering: boolean;
    showChangeBookingDetailsPopup: boolean;
    start: Date;
    end: Date;
}