import React from "react";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import { appContext } from "../../../../AppContext";
import { CateringOrderStatus } from "../../../../Common/Enums";

class GetCateringStatus extends React.Component<Props> {
    private get labels() { return appContext().labels; }
    
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public renderTaskOverAllStatus(taskStatus: string): React.ReactNode {
        switch(taskStatus) {
            case CateringOrderStatus.PendingApproval:
                return <IbssChip label={this.labels.HubLabelPendingApproval} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text) !important",
                }} />;
            case CateringOrderStatus.NotApproved:
                return <IbssChip label={this.labels.HubLabelNotApproved} sx={{
                    backgroundColor: 'var(--ui-warn-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.Approved:
                return <IbssChip label={this.labels.HubLabelRequestApproved} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.ReadyForPrep:
                return <IbssChip label={this.labels.HubLabelReadyForPrep} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.ReadyForDelivery:
                return <IbssChip label={this.labels.funcReadyforDelivery_S} sx={{
                    backgroundColor: 'var(--ui-primary-pastel )',
                    color: "var(ui-text)",
                }} />;
            case CateringOrderStatus.Delivered:
                return <IbssChip label={this.labels.HubLabelDelivered} sx={{
                    backgroundColor: 'var(--ui-primary-pastel )',
                    color: "var(ui-text)",
                }} />;
            case "Cleared":
                return <IbssChip label={this.labels.HubLabelCleared} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.Completed:
                return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.CancelledCharged:
                return <IbssChip label={this.labels.HubLabelCancelledCharged} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text) !important",
                }} />;
            case CateringOrderStatus.CancelledNoCharge:
                return <IbssChip label={this.labels.HubLabelCancelledNoCharge} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case CateringOrderStatus.CancellationPending:
                return <IbssChip label={this.labels.funcCancellationPending_S} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text) !important",
                }} />;
            default:
                return <p>-</p>;
        }
    }

    public renderTaskOverAllStatusText(taskStatus: string): string {
        switch (taskStatus) {
            case CateringOrderStatus.PendingApproval:
                return this.labels.HubLabelPendingApproval;
            case CateringOrderStatus.NotApproved:
                return this.labels.HubLabelNotApproved;
            case CateringOrderStatus.Approved:
                return this.labels.HubLabelRequestApproved;
            case CateringOrderStatus.ReadyForPrep:
                return this.labels.HubLabelReadyForPrep;
            case CateringOrderStatus.ReadyForDelivery:
                return this.labels.funcReadyforDelivery_S;
            case CateringOrderStatus.Delivered:
                return this.labels.HubLabelDelivered;
            case CateringOrderStatus.Cleared:
                return this.labels.HubLabelCleared;
            case CateringOrderStatus.Completed:
                return this.labels.HubLabelCompleted;
            case CateringOrderStatus.CancelledCharged:
                return this.labels.HubLabelCancelledCharged;
            case CateringOrderStatus.CancelledNoCharge:
                return this.labels.HubLabelCancelledNoCharge;
            case CateringOrderStatus.CancellationPending:
                return this.labels.funcCancellationPending_S;
            default:
                return "-";
        }
    }
    
}

export { GetCateringStatus };

interface Props {}