import IbssDialog from '../BaseDialog/IbssDialog';
import { MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import IbssTextField from '../../Inputs/TextField/IbssTextField';
import IbssButton from '../../Buttons/Button/IbssButton';
import { appContext } from '../../../AppContext';
import Helper from '../../../Common/Helper';
import IbssSwitchLabel from '../../Inputs/Switch/IbssSwitchLabel';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import { IListItem } from '../../Data/TransferList/IbssTransferList';
import IbssInputDropDown from '../../Inputs/SelectList/IbssInputDropDown';

class SpaceFilterDialog extends IbssComponent<IProps, IState>
{
    private defaultWorkType = 'FormalMeeting';
    private defaultSpaceType = 'MeetingRoom';
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            floorId: 0,
            workTypeId: '',
            spaceTypeId: '',
            rawMinCapacity: '',
            requiresCatering: false,
            requiresEquipment: false,
            requiresAudioVisual: false,
            requiresPresentationAids: false,
            requiresHearingAids: false,
            selectedBuildingOption: this.props.buildingId,
            buildingOptions: [],
            floorOptions: [],
            workTypeOptions: [],
            spaceTypeOptions: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.populateBuildings(this.state.selectedBuildingOption);
        this.loadFloors(this.state.selectedBuildingOption);
        this.loadWorkTypes(this.state.selectedBuildingOption);
        this.loadSpaceTypes(this.state.selectedBuildingOption);

        if (this.props.open)
        {
            this.initaliseState();
        }
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.buildingId != this.props.buildingId)
        {
            this.populateBuildings(this.props.buildingId);
            this.loadFloors(this.props.buildingId);
            this.loadWorkTypes(this.props.buildingId);
            this.loadSpaceTypes(this.props.buildingId);
        }
        if (prevProps.open != this.props.open && this.props.open)
        {
            this.initaliseState();
        }
    }

    private populateBuildings(selectedValue: number): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options, selectedBuildingOption: selectedValue });
    }

    private async buildingChanged(event: SelectChangeEvent<number>): Promise<void> 
    {
        const selectedBuildingId = event.target.value as number;
        this.setState({
            selectedBuildingOption: selectedBuildingId,
        });
        this.loadFloors(selectedBuildingId);
        this.loadWorkTypes(selectedBuildingId);
        this.loadSpaceTypes(selectedBuildingId);
    }

    private loadFloors(selectedBuildingId: number): void
    {
        const floors = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ id: i.Node_Id, name: i.Node_Name }))
            .sort((a, b) => (a.name < b.name ? - 1 : 1));

        options.unshift({ id: 0, name: this.labels.HubLabelAny });
        this.setState({ floorOptions: options });
    }

    private loadWorkTypes(selectedBuildingId: number): void
    {
        const workTypes = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workTypes
            .filter(i => i.Name != null)
            .map(i => ({ id: i.Name, name: i.Label }))
            .sort((a, b) => (a.name < b.name ? - 1 : 1));

        options.unshift({ id: 'Any', name: this.labels.HubLabelAny });
        this.setState({ workTypeOptions: options });
    }

    private loadSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ id: i.Name, name: i.Label }))
            .sort((a, b) => (a.name < b.name ? - 1 : 1));

        options.unshift({ id: 'Any', name: this.labels.HubLabelAny });
        this.setState({ spaceTypeOptions: options });
    }

    private initaliseState(): void
    {
        const props = this.props;
        this.setState({
            floorId: this.state.floorOptions.find(i => i.id == props.floorId) ? props.floorId : 0,
            workTypeId: props.workTypeId ?? 'Any',
            spaceTypeId: props.spaceTypeId,
            rawMinCapacity: this.minCapacityAsString(props.minCapacity),
            requiresCatering: props.requiresCatering,
            requiresEquipment: props.requiresEquipment ?? false,
            requiresAudioVisual: props.requiresAudioVisual ?? false,
            requiresPresentationAids: props.requiresPresentationAids ?? false,
            requiresHearingAids: props.requiresHearingAids ?? false,
        });
    }

    private handleWorkTypeChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const state = this.state;
        const workTypeId = e.target.value;
        let spaceTypeId = this.state.spaceTypeId;
        const bothAny = (workTypeId == 'Any' && spaceTypeId == 'Any');
        const bothSomething = (workTypeId != 'Any' && spaceTypeId != 'Any');
        const hasDefault = state.spaceTypeOptions.some(i => i.id == this.defaultSpaceType);

        if (bothAny)
        {
            spaceTypeId = (hasDefault ? this.defaultSpaceType : state.spaceTypeOptions[1].id);
        }
        else if (bothSomething)
        {
            spaceTypeId = 'Any';
        }
        this.setState({ workTypeId: workTypeId, spaceTypeId: spaceTypeId });
    }

    private handleSpaceTypeChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const state = this.state;
        const spaceTypeId = e.target.value;
        let workTypeId = this.state.workTypeId;
        const bothAny = (workTypeId == 'Any' && spaceTypeId == 'Any');
        const bothSomething = (workTypeId != 'Any' && spaceTypeId != 'Any');
        const hasDefault = state.workTypeOptions.some(i => i.id == this.defaultWorkType);

        if (bothAny)
        {
            workTypeId = (hasDefault ? this.defaultWorkType : this.state.workTypeOptions[1].id);
        }
        else if (bothSomething)
        {
            workTypeId = 'Any';
        }
        this.setState({ workTypeId: workTypeId, spaceTypeId: spaceTypeId });
    }

    private handleSubmit(): void
    {
        const props = this.props;
        const state = this.state;

        props.onSubmit({
            selectedBuildingId: this.state.selectedBuildingOption,
            floor: (state.floorOptions.find(i => i.id == state.floorId) as IFloor),
            workType: (props.hideWorkType || state.workTypeId == 'Any' ? null : state.workTypeOptions.find(i => i.id == state.workTypeId) as IWorkType),
            spaceType: (state.spaceTypeId == 'Any' ? null : state.spaceTypeOptions.find(i => i.id == state.spaceTypeId) as ISpaceType),
            minCapacity: this.minCapacityAsNumber(state.rawMinCapacity) ?? 0,
            requiresCatering: state.requiresCatering,
            requiresEquipment: state.requiresEquipment,
            requiresAudioVisual: state.requiresAudioVisual,
            requiresPresentationAids: state.requiresPresentationAids,
            requiresHearingAids: state.requiresHearingAids,
        });
    }

    private get isValid(): boolean
    {
        return (this.minCapacityError == '');
    }

    private get minCapacityError(): string
    {
        const minCapacity = this.minCapacityAsNumber(this.state.rawMinCapacity);
        if (minCapacity == null)
        {
            return this.labels.funcMustBeNumber_S;
        }
        else if (minCapacity < this.props.lowestMinCapacity)
        {
            return this.labels.funcMustBeMoreThanError_L.replace("{0}", this.props.lowestMinCapacity.toString());
        }
        else
        {
            return '';
        }
    }

    private minCapacityAsString(value: number | null): string
    {
        return value?.toString() ?? '';
    }

    private minCapacityAsNumber(value: string): number | null
    {
        return (value.trim() == '' || isNaN(Number(value)) ? null : Number(value));
    }

    public render(): JSX.Element
    {
        const labels = this.labels;
        const props = this.props;
        const state = this.state;

        return (
            <IbssDialog
                open={props.open}
                onClose={() => props.onClose()}
                fullWidth
                header={labels.HubLabelFilter}
                dialogContent=
                {
                    <>

                        <Typography className="row mb-3">{labels.HubMessageScheduleFilters}</Typography>
                        {
                            this.props.swapSpace &&
                            <div className="row mb-3">
                                <IbssInputDropDown
                                    id="buildingSelection"
                                    inputLabel={this.labels.HubLabelBuilding}
                                    fullWidth={true}
                                    options={this.state.buildingOptions}
                                    value={this.state.selectedBuildingOption}
                                    onChange={(e: SelectChangeEvent<number>) => this.buildingChanged(e)}
                                />
                            </div>
                        }
                        <div className="row mb-3">
                            <IbssTextField
                                id="floorSelection"
                                select={true}
                                label={labels.HubLabelFloor}
                                fullWidth
                                value={state.floorId}
                                variant={'outlined'}
                                onChange={e => this.setState({ floorId: parseInt(e.target.value) })}
                            >
                                {state.floorOptions.map(floor => (<MenuItem key={floor.id} value={floor.id}>{floor.name}</MenuItem>))}
                            </IbssTextField>
                        </div>
                        {!props.hideWorkType &&
                            <div className="row mb-3">
                                <IbssTextField
                                    id="workTypeSelection"
                                    select={true}
                                    label={labels.HubLabelworkType}
                                    fullWidth
                                    value={state.workTypeId}
                                    variant={'outlined'}
                                    onChange={e => this.handleWorkTypeChange(e)}
                                >
                                    {state.workTypeOptions.map(workType => (<MenuItem key={workType.id} value={workType.id}>{workType.name}</MenuItem>))}
                                </IbssTextField>
                            </div>
                        }
                        <div className="row mb-3">
                            <IbssTextField
                                id="spaceTypeSelection"
                                select={true}
                                label={labels.HubLabelSpaceType}
                                fullWidth
                                value={state.spaceTypeId}
                                variant={'outlined'}
                                onChange={e => this.handleSpaceTypeChange(e)}
                            >
                                {state.spaceTypeOptions.map(spaceType => (<MenuItem key={spaceType.id} value={spaceType.id}>{spaceType.name}</MenuItem>))}
                            </IbssTextField>
                        </div>
                        <div className="row mb-3">
                            <IbssTextField
                                id="capacitySelection"
                                label={labels.HubLabelCapacity}
                                fullWidth
                                type='number'
                                value={state.rawMinCapacity}
                                variant={'outlined'}
                                error={!!this.minCapacityError}
                                helperText={this.minCapacityError}
                                onChange={e => this.setState({ rawMinCapacity: e.target.value })}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            >
                            </IbssTextField>
                        </div>
                        <div className="row mb-3">
                            <IbssSwitchLabel
                                id="cateringSelection"
                                label={
                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {labels.HubLabelHasCatering}
                                    </Typography>
                                }
                                checked={state.requiresCatering}
                                onChange={e => this.setState({ requiresCatering: e.target.checked })}
                            />
                        </div>
                        {!props.hideEquipment &&
                            <div className="row mb-3">
                                <IbssSwitchLabel
                                    id="equipmentSelection"
                                    label={
                                        <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {labels.HubLabelHasEquipment}
                                        </Typography>
                                    }
                                    checked={state.requiresEquipment}
                                    onChange={e => this.setState({ requiresEquipment: e.target.checked })}
                                />
                            </div>
                        }
                        {!props.hideAudioVisual &&
                            <div className="row mb-3">
                                <IbssSwitchLabel
                                    id="equipmentSelection"
                                    label={
                                        <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {labels.funcHasAudioVisualEquipment_L}
                                        </Typography>
                                    }
                                    checked={state.requiresAudioVisual}
                                    onChange={e => this.setState({ requiresAudioVisual: e.target.checked })}
                                />
                            </div>
                        }
                        {!props.hidePresentationAids &&
                            <div className="row mb-3">
                                <IbssSwitchLabel
                                    id="equipmentSelection"
                                    label={
                                        <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {labels.funcHasPresentationAids_L}
                                        </Typography>
                                    }
                                    checked={state.requiresPresentationAids}
                                    onChange={e => this.setState({ requiresPresentationAids: e.target.checked })}
                                />
                            </div>
                        }
                        {!props.hideHearingAids &&
                            <div className="row mb-3">
                                <IbssSwitchLabel
                                    id="equipmentSelection"
                                    label={
                                        <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {labels.funcHasHearingAids_L}
                                        </Typography>
                                    }
                                    checked={state.requiresHearingAids}
                                    onChange={e => this.setState({ requiresHearingAids: e.target.checked })}
                                />
                            </div>
                        }
                    </>
                }
                footer=
                {
                    <>
                        <IbssButton
                            onClick={() => props.onClose()}
                            color="secondary"
                            variant="outlined"
                        >
                            {labels.HubButtonCancel}
                        </IbssButton>
                        <IbssButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            disabled={!this.isValid}
                            onClick={() => this.handleSubmit()}
                        >
                            {labels.HubLabelOk}
                        </IbssButton>
                    </>
                }
            />
        )
    }
}

export default SpaceFilterDialog;

export interface IProps
{
    open: boolean;
    buildingId: number;
    floorId: number;
    hideWorkType?: boolean;
    workTypeId?: string;
    spaceTypeId: string;
    minCapacity: number | null;
    lowestMinCapacity: number;
    requiresCatering: boolean;
    hideEquipment?: boolean;
    requiresEquipment?: boolean;
    hideAudioVisual?: boolean;
    requiresAudioVisual?: boolean;
    hidePresentationAids?: boolean;
    requiresPresentationAids?: boolean;
    hideHearingAids?: boolean;
    requiresHearingAids?: boolean;
    onClose: () => void;
    onSubmit: (filter: ISpaceFilter) => void;
    swapSpace: boolean;
}

export interface IState
{
    floorId: number;
    workTypeId: string;
    spaceTypeId: string;
    rawMinCapacity: string;
    requiresCatering: boolean;
    requiresEquipment: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
    floorOptions: IFloor[];
    workTypeOptions: IWorkType[];
    spaceTypeOptions: ISpaceType[];
    buildingOptions: Array<IListOption<number>>,
    selectedBuildingOption: number,
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface IFloor
{
    id: number;
    name: string;
}

export interface IWorkType
{
    id: string;
    name: string;
}

export interface ISpaceType
{
    id: string;
    name: string;
}

export interface ISpaceFilter
{
    selectedBuildingId: number;
    floor: IFloor | null;
    workType: IWorkType | null;
    spaceType: ISpaceType | null;
    minCapacity: number;
    requiresCatering: boolean;
    requiresEquipment: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
}
