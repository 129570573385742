import { Component } from 'react';

export default class HumidityIcon extends Component {
    render() {
        return (
            <svg width="26" height="26" viewBox="0 0 34 34" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0859 13.5303C22.3788 13.8232 22.3788 14.2981 22.0859 14.591L11.591 25.0859C11.2981 25.3788 10.8232 25.3788 10.5303 25.0859C10.2374 24.793 10.2374 24.3181 10.5303 24.0252L21.0252 13.5303C21.3181 13.2374 21.793 13.2374 22.0859 13.5303Z" fill="inherit"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 17.5C13.8284 17.5 14.5 16.8284 14.5 16C14.5 15.1716 13.8284 14.5 13 14.5C12.1716 14.5 11.5 15.1716 11.5 16C11.5 16.8284 12.1716 17.5 13 17.5ZM13 19C14.6569 19 16 17.6569 16 16C16 14.3431 14.6569 13 13 13C11.3431 13 10 14.3431 10 16C10 17.6569 11.3431 19 13 19Z" fill="inherit"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 24.5C20.8284 24.5 21.5 23.8284 21.5 23C21.5 22.1716 20.8284 21.5 20 21.5C19.1716 21.5 18.5 22.1716 18.5 23C18.5 23.8284 19.1716 24.5 20 24.5ZM20 26C21.6569 26 23 24.6569 23 23C23 21.3431 21.6569 20 20 20C18.3431 20 17 21.3431 17 23C17 24.6569 18.3431 26 20 26Z" fill="inherit"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9657 0.465237C16.2574 0.178435 16.725 0.178228 17.017 0.464772L26.5677 9.83997C26.5678 9.84003 26.5677 9.83991 26.5677 9.83997C30.648 13.8428 31.8735 19.8704 29.6639 25.1091C27.4553 30.3456 22.2562 33.75 16.5 33.75C10.7438 33.75 5.5447 30.3456 3.3361 25.1091C1.12661 19.8706 2.3517 13.8434 6.43161 9.8406M6.43161 9.8406L15.9657 0.465237L6.43161 9.8406ZM16.492 2.05141L7.48333 10.9101C3.83837 14.4857 2.74988 19.8595 4.7182 24.5262C6.68752 29.1953 11.3344 32.25 16.5 32.25C21.6656 32.25 26.3125 29.1953 28.2818 24.5262C30.2501 19.8595 29.1623 14.4864 25.5173 10.9108L16.492 2.05141Z" fill="inherit"/>
            </svg>

        )
    }
};