import { DateTime, WeekdayNumbers } from "luxon";
import { IDateRange } from "./BookableSpaces";
import { DateHelper } from "../../../Common/DateHelper";

export class ExcludedDatesAdapter implements IDateRange
{
    public bookingPolicyId = '';
    public start = DateHelper.null();
    public end = DateHelper.null();

    public static fromBookingPolicies(bookingPolicies: IBookingPolicy[]): ExcludedDatesAdapter[]
    {
        let excludedDates = bookingPolicies
            .flatMap(policy => policy.Booking_Policy.BookingSlots.ExcludedDates.map(date => ({ policy: policy, value: date })))
            .map(excludedDate =>
            {
                const startDate = DateTime.fromISO(excludedDate.value.StartDate?.substring(0, 10) ?? '');
                const startTime = DateTime.fromISO(excludedDate.value.StartTime ?? '');
                const start = startDate.set({ hour: startTime.hour, minute: startTime.minute, second: startTime.second, millisecond: startTime.millisecond });

                const endDate = DateTime.fromISO(excludedDate.value.EndDate?.substring(0, 10) ?? '');
                const endTime = DateTime.fromISO(excludedDate.value.EndTime ?? '');
                const end = endDate.set({ hour: endTime.hour, minute: endTime.minute, second: endTime.second, millisecond: endTime.millisecond });

                return { bookingPolicyId: excludedDate.policy.Booking_Policy_Id, start: start, end: end };
            })
            .filter(i => i.start.isValid && i.end.isValid);

        return excludedDates;
    }
}

export interface IBookingPolicy
{
    Booking_Policy_Id: string;
    Booking_Policy: IBookingPolicyObject;
}

export interface IBookingPolicyObject 
{
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots
{
    ExcludedDates: IExcludedDate[];
}

export interface IExcludedDate
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}
