import { IconButton } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import AddIcon from '@mui/icons-material/Add';
import { appContext } from '../../../../AppContext';

class AddLayoutBox extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div onClick={() => this.props.onClick()} className='d-flex justify-content-between' style={{ cursor: 'pointer', borderRadius: '8px', border: '1px dashed #A6A6A6', padding: '15px' }}>
                <div style={{ alignContent: 'center' }}>{this.labels.funcAddLayout_S}</div>
                    <IconButton disableRipple>
                        <AddIcon />
                    </IconButton>
            </div>
        )
    }
}

export default AddLayoutBox;

export interface IProps
{
    onClick: () => void
}

export interface IState
{
}