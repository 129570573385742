import React, { Component } from 'react';

export default class InfoOutlineIcon extends Component {
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8450_89585)">
                <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#7A7B82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 12V9" stroke="#7A7B82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 6H9.0075" stroke="#7A7B82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_8450_89585">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        )
    }
};