import { ODataSelect } from "../../../Providers.Api/ODataQuery";

export class Space extends ODataSelect
{
    public Space_Name = '';
    public Space_Id = '';
    public Space_Custom_Info = '';
    public Booking_Policy_Id = '';
    public ImageURI = '';
    public Space_Class = '';
    public Space_Type = '';
    public Space_Work_Type = '';
    public Space_Type_Label = '';
    public Space_Capacity = 0;
    public Meta_Loc_Zone = '';
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Bookable = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Occ_Type = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Space_IsEnabled = 0;
    public Env_Zone_Id = 0;
}