import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import "./spaces-search.scss"
import { connect } from "react-redux";
import Helper from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { RouteComponentProps, generatePath, withRouter } from "react-router-dom";
import momentBusiness from 'moment-business-days';
import MapButton from "../../../../Components/Buttons/MapButton/MapButton";
import ListButton from "../../../../Components/Buttons/ListButton/ListButton";
import FloorPlan, { IFloorPlanSpace as IFloorPlanSpace, IMapUrl, IPagedFloorPlanSpaces } from "../../../../Components/Data/FloorPlan/FloorPlan";
import { RouterProps } from "react-router-dom";
import { appContext } from "../../../../AppContext";
import SpaceCard, { Props as SpaceCardProps } from "../../../../Components/Cards/SpaceCard/SpaceCard";
import Alert from "../../../../Components/Miscellaneous/Alert/Alert";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { DateTime, MinuteNumbers } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { Box, Grid, Paper } from "@mui/material";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import React from "react";
import Guid from "../../../../Common/Guid";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { IFloor, ISearchConfigItem, PagedResponse } from "../../../../Providers.Api/Models";
import { AvailableSpace, FloorZones, ISpaceZone } from "./DataModels";
import { QueryParams } from "./QueryParams";
import { SearchSpacesHelper } from "./SearchSpacesHelper";
import { ISearchRecurring } from "../../../../Providers.IbssApiClientV2/IbssApiClientV2";
import SearchSpaceFilter, { ISearchFilterResult, IProps as ISearchFilterProps } from "./SearchSpaceFilter";
import { TypeHelper } from "../../../../Common/TypeHelper";
import { WorkTypeSpaceTypeHelper } from "../../../../Common/WorkTypeSpaceTypeHelper";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";

class SearchSpaces extends IbssPage<IProps, IState, QueryParams>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; };
    private get apiCache() { return appContext().apiCache };
    private get isOneLens() { return this.area == 'onelens'; }
    private cachedFloors: IFloor[] = [];
    private cachedSpaces: Space[] = [];

    constructor(props: IProps)
    {
        super(props, new QueryParams());
        this.cachedFloors = this.local.getNodeData().Regions.flatMap(region => region.Buildings).flatMap(building => building.Floors);
        const mapUrls = this.cachedFloors.map(i => ({ floorId: i.Node_Id, url: i.Floor_MapURI }));

        this.state =
        {
            isLoading: false,
            spaces: [],
            skipToken: null,
            openDrawer: false,
            view: View.List,
            mapUrls: mapUrls,
            loadMap: Guid.empty,
            mapFailedToLoad: false,
            searchCriteriaChanged: false,

            // search criteria
            buildingId: -1,
            workType: null,
            spaceType: null,
            spaceTypeLabel: null,
            floorId: null,
            zone: { name: '', id: '' },
            startTime: DateHelper.null(),
            endTime: DateHelper.null(),
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            minCapacity: null,
            selected: '',
        }
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevQueryParams: QueryParams): Promise<void>
    {
        this.setState({ isLoading: true });
        const queryParams = this.queryParams;
        const userPrefs = appContext().localStorageProvider.getUserPreferences();

        // building
        const rootNode = this.local.getNodeData();
        const buildings = rootNode.Regions.flatMap(i => i.Buildings);
        const defaultBuilding = buildings.find(i => i.Node_Id == userPrefs.SearchPrefs.DefaultBuilding);
        const building = buildings.find(i => i.Node_Id == queryParams.building) ?? defaultBuilding ?? rootNode;
        const buildingId = building.Node_Id;
        const buildingName = ('Name' in building ? building.Name : building.Node_Name);
        this.pageTitle = (this.isOneLens ? `${this.labels.HubLabelFacilityManagementText} ${buildingName}` : this.labels.HubLabelSingleBooking);

        if (!building)
        {
            return this.props.history.push('/flex-home');
        }
        if (queryParams.building != buildingId)
        {
            return this.pushQueryParams({ building: buildingId }, true);
        }

        // floor
        const floors = buildings.flatMap(i => i.Floors);
        const defaultFloorId = userPrefs.Nodes.find(building => building.NodeId == buildingId)?.DefaultFloor;
        const defaultFloor = floors.find(i => i.Node_Id == defaultFloorId);
        const floor = (queryParams.floor == 'Any' ? undefined : floors.find(i => i.Node_Id == +(queryParams.floor ?? '0')) ?? defaultFloor);
        const floorId = floor?.Node_Id ?? null;

        if (queryParams.floor != (floorId?.toString() ?? 'Any'))
        {
            return this.pushQueryParams({ floor: floorId?.toString() || 'Any' }, true);
        }

        // zone
        const defaultZoneId = userPrefs.Nodes.find(building => building.NodeId == buildingId)?.DefaultZone;
        
        let zoneId = 'Any';
        let zoneName = 'Any';

        if(floorId)
        {
            const zones = await this.getZones(floorId);
            const defaultZone = zones.find(i => i.Space_Zone_Id == defaultZoneId);
            const zone = (queryParams.zone == 'Any' ? undefined : zones.find(i => i.Meta_Loc_Zone == queryParams.zone) ?? defaultZone);
            zoneId = (zone?.Space_Zone_Id)?.toString() ?? 'Any';
            zoneName = zone?.Meta_Loc_Zone ?? 'Any';

            if (zoneName != queryParams.zone)
            {
                // if zone has changed relative to queryParamas zone, push new zone to queryParams. (can include 'Any')
                return this.pushQueryParams({ zone: zoneName }, true);
            }
        }
        else
        {           
            if (!queryParams.zone)
            {
                // if queryParams zone isn't set, push zone 'Any' to queryParams.
                return this.pushQueryParams({ zone: 'Any' }, true);
            }
        }

        // work type / space type
        const workTypeSpaceTypeHelper = new WorkTypeSpaceTypeHelper();
        const workTypeSpaceTypeResult = workTypeSpaceTypeHelper.calculateNewWorkTypeAndSpaceType(buildingId, queryParams.workType ?? '', queryParams.spaceType ?? '');
        const spaceTypes = Helper.getSpaceTypesByNodeId(buildingId);
        const spaceType = spaceTypes.result.find(i => i.Name == queryParams.spaceType);

        if (spaceTypes.error)
        {
            return this.alert.show(this.labels.HubLabelSetyourpreferences, this.labels.HubLabelSetUserPrefenceError, () => this.redirectToUserPrefPage());
        }
        if (workTypeSpaceTypeResult.newWorkType != queryParams.workType || workTypeSpaceTypeResult.newSpaceType != queryParams.spaceType)
        {
            return this.pushQueryParams({ workType: workTypeSpaceTypeResult.newWorkType, spaceType: workTypeSpaceTypeResult.newSpaceType }, true);
        }

        // capacity
        if (!queryParams.capacity)
        {
            return this.pushQueryParams({ capacity: 1 }, true);
        }

        // dates / times
        const buildingTime = DateHelper.now(buildingId);
        const every5Mins = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((minute) => minute as MinuteNumbers);
        const dateOrDefault = (queryParams.date ?? buildingTime);
        let startTime = (queryParams.start ? queryParams.start.set({ year: dateOrDefault.year, month: dateOrDefault.month, day: dateOrDefault.day, second: 0 }) : dateOrDefault.snapToMinute(every5Mins, { direction: 1 }));
        let endTime = (queryParams.end ? queryParams.end.set({ year: dateOrDefault.year, month: dateOrDefault.month, day: dateOrDefault.day, second: 0 }) : startTime.plus({ hours: 1 }));

        if(startTime < buildingTime)
        {
            // new start time is the next available multiple of 5 minute. ( .plus(minutes: 1) ensures its the next one, rather than current one, which could be in the past. )
            startTime = buildingTime.plus({minutes: 1}).snapToMinute(every5Mins, { direction: 1 });
        }
        if(startTime >= endTime)
        {
            endTime = startTime.plus({hours: 1});
        }

        if (queryParams.date?.toISODate() != dateOrDefault.toISODate())
        {
            return this.pushQueryParams({ date: dateOrDefault }, true);
        }
        if (queryParams.start?.toISOTime() != startTime.toISOTime())
        {
            return this.pushQueryParams({ start: startTime }, true);
        }
        if (queryParams.end?.toISOTime() != endTime.toISOTime())
        {
            return this.pushQueryParams({ end: endTime }, true);
        }

        // map / list view
        this.cachedSpaces = await this.apiCache.getSpacesByBuilding(buildingId);
        this.spaceCardsById = new Map();
        const view = (queryParams.view == 'map' ? View.Map : View.List);
        const viewAsString = View[view].toLowerCase() as ('map' | 'list');

        if (queryParams.view != viewAsString)
        {
            return this.pushQueryParams({ view: viewAsString }, true);
        }

        // set state
        await this.setStateAsync({
            spaces: [],
            skipToken: null,
            loadMap: Guid.new(),
            mapFailedToLoad: false,
            view: view,
            isLoading: false,

            startTime: startTime,
            endTime: endTime,

            buildingId: buildingId,
            floorId: floorId,
            zone: { name: zoneName, id: zoneId.toString()},
            workType: queryParams.workType ?? null,
            spaceType: queryParams.spaceType ?? null,
            spaceTypeLabel: spaceType?.Label ?? null,
            minCapacity: queryParams.capacity,

            audioVisual: queryParams.av ?? false,
            presentationAids: queryParams.resources ?? false,
            hearingAids: queryParams.hearingAids ?? false,
            catering: queryParams.catering ?? false,
            linkedSpace: queryParams.linkedSpace ?? false,
            layouts: queryParams.layouts ?? false,
            searchCriteriaChanged: false,
        });

        this.session.setFlexSpaceSearchCriteria(startTime, endTime,queryParams.capacity);
        if (this.state.view != View.Map) // floor-plan takes care of its own loading
        {
            await this.loadNextPageOfSpaces();
        }
    }

    private redirectToUserPrefPage(): void
    {
        const { history } = this.props;
        history.push(`/flex-user-pref-workplace`);
    }

    private async showMapView(): Promise<void>
    {
        this.pushQueryParams({ view: 'map' });
    }

    private async showListView(): Promise<void>
    {
        this.pushQueryParams({ view: 'list' });
    }

    private async floorPlanSpacesRequested(skipToken: string, floorId: number): Promise<IPagedFloorPlanSpaces>
    {
        const pageOfSpaces = await this.loadNextPageOfSpaces();

        const pagedSpacesForMap = {
            skipToken: pageOfSpaces.skipToken,
            spaces: pageOfSpaces.value.map(i => ({
                id: i.Space_Id,
                colour: "",
                getColourFromData: true,
                periodCurrentSpaceValue: 0,
            })),
        };
        return pagedSpacesForMap;
    }

    private async loadNextPageOfSpaces(): Promise<PagedResponse<AvailableSpace[]>>
    {
        const nodeId = this.state.floorId ?? this.state.buildingId;

        if (this.state.skipToken == '')
        {
            return { skipToken: '', value: [] };
        }
        try
        {
            this.setState({ isLoading: (this.state.view != View.Map) });

            const endpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.spaces.search.post',
                options: {
                    nodeId: nodeId,
                    top: 25,
                    skipToken: this.state.skipToken ?? '',
                    suppressErrorPopup: true,
                    body: {
                        Floor_Id: this.state.floorId ?? undefined,
                        Meta_Loc_Zone: (this.state.zone.id && this.state.zone.name != 'Any' ? this.state.zone.name : undefined),
                        Booking_Dates: [{
                            Start_Time: this.state.startTime.toUtcByNode(this.state.buildingId).toISO(),
                            End_Time: this.state.endTime.toUtcByNode(this.state.buildingId).toISO(),
                        }],
                        Space_Type: (this.state.spaceType && this.state.spaceType != 'Any' ? this.state.spaceType : undefined),
                        Space_Work_Type: (this.state.workType && this.state.workType != 'Any' ? this.state.workType : undefined),
                        Meta_Serv_Reqs_AV: this.state.audioVisual ? 1 : undefined,
                        Meta_Serv_Reqs_Catering: this.state.catering ? 1 : undefined,
                        Meta_Serv_Reqs_Hearing: this.state.hearingAids ? 1 : undefined,
                        Meta_Serv_Reqs_Presentation: this.state.presentationAids ? 1 : undefined,
                        Space_Setup: (this.state.linkedSpace ? 5 : (this.state.layouts ? 1 : undefined)),
                        Space_Capacity: this.state.minCapacity ?? 1,
                    },
                }
            };

            const pageOfSpaces = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<AvailableSpace[]>>(endpoint.options)
            );

            await this.setStateAsync({
                spaces: [...this.state.spaces, ...pageOfSpaces.value],
                skipToken: pageOfSpaces.skipToken,
            });
            return pageOfSpaces;
        }
        catch
        {
            return { skipToken: '', value: [] };
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async getZones(floorId: number): Promise<ISpaceZone[]>
    {
        if (floorId == 0)
        {
            return [];
        }
        try
        {
            const endpoint = {
                name: 'api.spaceZones.getMultiple',
                args: [floorId, true],
            };
            const zones = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().apiClient.spaceZones.getMultiple(...endpoint.args as [number, boolean])
            );
            return zones;
        }
        catch
        {
            return [];
        }
    }

    private async mapViewFloorDropdownChange(floorId: number): Promise<void>
    {
        this.pushQueryParams({ floor: floorId.toString() });
    }

    private hideSearchCritera(): void
    {
        this.setState({ openDrawer: false });
    }

    private handleOpenSearchFilter(): void
    {
        this.setState({ openDrawer: true });
    }

    private navigateToSpaceDetails(spaceId: string): void
    {
        this.props.history.push(`/flex-find-a-space/${this.state.buildingId}/searchaspace/${spaceId}`);
    }

    private mapFailedToLoad(): void
    {
        this.setState({ isLoading: false, mapFailedToLoad: true });
    }

    private async handleUpdateSearchFilter(result: ISearchFilterResult): Promise<void>
    {
        this.pushQueryParams({
            building: result.building,
            floor: result.floor || 'Any',
            zone: result.zone?.name || 'Any',
            spaceType: result.spaceType || 'Any',
            workType: result.workType || 'Any',
            start: result.startTimeOfDay,
            end: result.endTimeOfDay,
            date: result.date,
            capacity: result.minCapacity,
            av: result.audioVisual || undefined,
            resources: result.presentationAids || undefined,
            hearingAids: result.hearingAids || undefined,
            catering: result.catering || undefined,
            linkedSpace: result.linkedSpace || undefined,
            layouts: result.layouts || undefined,
        });
    }

    private keyPressed(event: React.KeyboardEvent<HTMLDivElement>): void
    {
        if (event.key === 'Enter') 
        {
            event.preventDefault();
            return;
        }
        else if (!this.spaceCards) 
        {
            return;
        }

        let selectedIndex = this.spaceCards.findIndex(space => space.spaceId === this.state.selected);
        let nextSpaceId;

        switch (event.key)
        {
            case 'ArrowLeft': {
                nextSpaceId = this.spaceCards[selectedIndex - 1]?.spaceId;
                break;
            }
            case 'ArrowRight': {
                nextSpaceId = this.spaceCards[selectedIndex + 1]?.spaceId;
                break;
            }
            case 'ArrowUp': {
                const { x: currentXUp, y: currentYUp } = this.getLocation(this.state.selected);
                for (let i = selectedIndex - 1; i >= 0; i--)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.spaceCards[i].spaceId);
                    if (currentXUp === positionX && currentYUp >= positionY) 
                    {
                        nextSpaceId = this.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case 'ArrowDown': {
                const { x: currentXDown, y: currentYDown } = this.getLocation(this.state.selected);
                for (let i = selectedIndex + 1; i < this.spaceCards.length; i++)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.spaceCards[i].spaceId);
                    if (currentXDown === positionX && currentYDown <= positionY) 
                    {
                        nextSpaceId = this.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case ' ': {
                this.navigateToSpaceDetails(this.state.selected);
                return;
            }
            default: {
                return;
            }
        }

        if (nextSpaceId) 
        {
            this.setState({ selected: nextSpaceId });
        }
    }

    private spaceCardFocussed(id: string): void
    {
        this.setState({ selected: id });
    }

    private getLocation(id: string): ILocation
    {
        const el = document.getElementById(id);
        if (!el) 
        {
            return { el: null, x: 0, y: 0 };
        }
        const location = el.getBoundingClientRect();
        const x = location.left + window.scrollX;
        const y = location.top + window.scrollY;
        return { el, x, y };
    }

    private spaceCardsById: Map<string, SpaceCardProps> = new Map();
    private get spaceCards(): SpaceCardProps[]
    {
        const cardsById = this.spaceCardsById;

        const cards = this.state.spaces
            .map(space =>
            {
                if (!cardsById.has(space.Space_Id))
                {
                    const cachedSpace = this.cachedSpaces.find(i => i.Space_Id == space.Space_Id);
                    if (!cachedSpace)
                    {
                        return null;
                    }
                    const spaceCard = SpaceCardProps.fromSpace(cachedSpace);
                    cardsById.set(space.Space_Id, spaceCard);
                }
                const card = cardsById.get(space.Space_Id) ?? null;
                return card;
            })
            .filter(i => !!i) as SpaceCardProps[];

        return cards;
    }

    public get searchResults(): SearchResults
    {
        if (this.state.searchCriteriaChanged)
        {
            return SearchResults.SearchCriteriaChanged;
        }
        else if (this.state.view == View.Map && this.state.mapFailedToLoad)
        {
            return SearchResults.MapFailedToLoad;
        }
        else if (this.state.view == View.Map)
        {
            return SearchResults.Map;
        }
        else if (this.state.spaces.length == 0)
        {
            return SearchResults.NoResults;
        }
        else
        {
            return SearchResults.List;
        }
    }

    private handleSearchFilterChange(props: Partial<ISearchFilterProps>, searchFilterIsMounting: boolean): void
    {
        if (props.date != undefined)
        {
            const startTime = this.state.startTime.set({ year: props.date.year, month: props.date.month, day: props.date.day });
            this.setState({ startTime: startTime });
        }
        if (props.startTimeOfDay != undefined)
        {
            const startTime = this.state.startTime.set({ hour: props.startTimeOfDay.hour, minute: props.startTimeOfDay.minute });
            this.setState({ startTime: startTime });
        }
        if (props.endTimeOfDay != undefined) { this.setState({ endTime: props.endTimeOfDay }); }
        if (props.building != undefined) { this.setState({ buildingId: props.building }); }
        if (props.floor != undefined) { this.setState({ floorId: TypeHelper.toNumber(props.floor) }); }
        if (props.zone != undefined) { this.setState({ zone: props.zone }); }
        if (props.workType != undefined) { this.setState({ workType: props.workType }); }
        if (props.spaceType != undefined) { this.setState({ spaceType: props.spaceType }); }
        if (props.minCapacity != undefined) { this.setState({ minCapacity: props.minCapacity }); }
        if (props.audioVisual != undefined) { this.setState({ audioVisual: props.audioVisual }); }
        if (props.presentationAids != undefined) { this.setState({ presentationAids: props.presentationAids }); }
        if (props.hearingAids != undefined) { this.setState({ hearingAids: props.hearingAids }); }
        if (props.catering != undefined) { this.setState({ catering: props.catering }); }
        if (props.linkedSpace != undefined) { this.setState({ linkedSpace: props.linkedSpace }); }
        if (props.layouts != undefined) { this.setState({ layouts: props.layouts }); }

        const hasFilterChanged = (props.date != undefined || props.startTimeOfDay != undefined || props.endTimeOfDay != undefined ||
            props.building != undefined || props.floor != undefined || props.zone != undefined ||
            props.workType != undefined || props.spaceType != undefined || props.minCapacity != undefined ||
            props.audioVisual != undefined || props.presentationAids != undefined || props.hearingAids != undefined ||
            props.catering != undefined || props.linkedSpace != undefined || props.layouts != undefined);

        if (!searchFilterIsMounting && hasFilterChanged)
        {
            this.setState({ searchCriteriaChanged: true });
        }
    }

    public render(): JSX.Element
    {

        const listButtonActive = (this.state.view === View.List);
        const mapButtonActive = (this.state.view === View.Map);

        return (
            <>
                <link rel="stylesheet" href="/src/pages/Flex/Search/SearchComponent.css"></link>
                {this.state.isLoading && <Spinner />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content" style={{ paddingBottom: "10px" }} >
                    <Paper elevation={0} sx={{p:1}}>
                            {this.state.buildingId > 0 &&
                                <SearchSpaceFilter
                                    date={this.state.startTime}
                                    startTimeOfDay={this.state.startTime}
                                    endTimeOfDay={this.state.endTime}
                                    building={this.state.buildingId}
                                    floor={this.state.floorId == null ? "Any" : this.state.floorId.toString()}
                                    zone={this.state.zone}
                                    workType={this.state.workType ?? "Any"}
                                    spaceType={this.state.spaceType ?? "Any"}
                                    minCapacity={this.state.minCapacity ?? 0}
                                    audioVisual={this.state.audioVisual}
                                    presentationAids={this.state.presentationAids}
                                    hearingAids={this.state.hearingAids}
                                    catering={this.state.catering}
                                    linkedSpace={this.state.linkedSpace}
                                    layouts={this.state.layouts}
                                    onOpen={() => this.handleOpenSearchFilter()}
                                    onUpdate={result => this.handleUpdateSearchFilter(result)}
                                    onPropsChange={(props, componentIsMounting) => this.handleSearchFilterChange(props, componentIsMounting)}
                                />
                            }
                        </Paper>
                        <Grid container className="space-box-cont">
                            <Grid sm={8} mb={1} className="left-space-box-cont flex-row-bredcrumb">
                                <div className="search-results-title">{this.labels.HubLabelSearchResults}</div>
                            </Grid>
                            <Grid sm={4} spacing={2} mb={1} className="btn-right-aligned">
                                <MapButton onClick={() => this.showMapView()} active={mapButtonActive} style={{ marginRight: "10px" }} />
                                <ListButton onClick={() => this.showListView()} active={listButtonActive} />
                            </Grid>
                            <Grid sm={12} className={"search-results-height mt-0 " + this.resultsCssClass(this.searchResults)}>
                                {this.renderResults(this.searchResults)}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }

    private renderResults(searchResults: SearchResults): JSX.Element
    {
        const showLoadMore = (this.state.skipToken != '');
        const cardContainerHeight = (showLoadMore ? 'calc(100vh - 392px)' : 'calc(100vh - 340px)');

        switch (searchResults)
        {
            case SearchResults.NoResults:
                return (!this.state.isLoading ? <Alert key="noResults" title={this.labels.HubLabelNoSpacesAvailable} text={this.labels.HubLabelFlexSearchCriteriaNoSpaces} /> : <></>);

            case SearchResults.MapFailedToLoad:
                return (<Alert key="mapFailedToLoad" title={this.labels.HubmapFailedToLoad} text={this.labels.HubLabelUsingTheListView} />);

            case SearchResults.SearchCriteriaChanged:
                return (<Alert key="searchCriteriaChanged" title={this.labels.funcYouHaveChangedYourSearchCriteria_D} text={this.labels.funcClickSearchToSeeAnUpdatedListOfResults_D} className='search-criteria-changed-alert' />);

            case SearchResults.Map:
                return (
                    <FloorPlan
                        key="map"
                        mapUrls={this.state.mapUrls}
                        onFloorSelected={(floorId: number) => this.mapViewFloorDropdownChange(floorId)}
                        displayFloorDropdown={true}
                        loadSpaces={this.state.loadMap}
                        onRequestSpaces={(skipToken, floorId) => this.floorPlanSpacesRequested(skipToken, floorId)}
                        spaceModalClicked={spaceId => this.navigateToSpaceDetails(spaceId)}
                        mapFailedToLoad={() => this.mapFailedToLoad()}
                        floorId={this.state.floorId ?? 0}
                        startTime={this.state.startTime}
                        endTime={this.state.endTime}
                        enableSpaceClick={true}
                    />);

            case SearchResults.List:
                return (
                    <>
                        <div
                            key="list"
                            className="space-card-container"
                            // style={{ height: cardContainerHeight }}
                            onKeyDown={e => this.keyPressed(e)}
                        >
                            {
                                this.spaceCards.map(props => (
                                    <SpaceCard
                                        key={props.spaceId}
                                        pointer={true}
                                        {...props}
                                        onClick={spaceId => this.navigateToSpaceDetails(spaceId)}
                                        buildingId={this.state.buildingId}
                                        onCardFocused={id => this.spaceCardFocussed(id)}
                                        focus={props.spaceId === this.state.selected}
                                    />))
                            }
                        </div>
                        {showLoadMore &&
                            <Box
                                className="text-center my-2"
                                sx={{
                                    position: 'fixed',
                                    bottom: 0,
                                    width: 'calc(100% - 260px)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <IbssButton
                                    size="small"
                                    variant="contained"
                                    onClick={() => this.loadNextPageOfSpaces()}
                                >
                                    {this.labels.HubButtonLoadMore}
                                </IbssButton>
                            </Box>
                        }
                    </>
                );

            default:
                return (<></>);
        }
    }

    private resultsCssClass(searchResults: SearchResults): string
    {
        switch (searchResults)
        {
            case SearchResults.NoResults:
            case SearchResults.MapFailedToLoad:
                return "search-results-cont--alert";

            case SearchResults.Map:
                return "search-results-cont--map";

            case SearchResults.List:
                return "search-results-cont--list";

            default:
                return "";
        }
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria
    };
};

export default withRouter(connect(mapStateToProps)(SearchSpaces) as any);

enum View
{
    Map,
    List,
}

enum SearchResults
{
    NoResults,
    MapFailedToLoad,
    SearchCriteriaChanged,
    Map,
    List,
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState
{
}

interface IState
{
    isLoading: boolean;
    spaces: AvailableSpace[];
    skipToken: string | null;
    openDrawer: boolean;
    view: View;
    mapUrls: IMapUrl[];
    loadMap: Guid;
    mapFailedToLoad: boolean;
    searchCriteriaChanged: boolean;

    // search criteria
    startTime: DateTime;
    endTime: DateTime;
    buildingId: number;
    floorId: (number | null);
    zone: IZone,
    workType: (string | null);
    spaceType: (string | null);
    spaceTypeLabel: (string | null);
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    minCapacity: (number | null);
    // end of search criteria

    selected: string;
}

interface ISearchCriteriaValue
{
    src: string;
    value: string;
}

interface IMatchParams
{
    buildingid: string;
}

interface ILocation 
{
    el: HTMLElement | null;
    x: number;
    y: number;
}

interface ISpaceConfig
{
    id: string;
    colour: string;
    getColourFromData: boolean,
    periodCurrentSpaceValue: number,
}

interface ISpaceTypesResult
{
    result: ISearchConfigItem[];
    error: boolean;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface IZone
{
    name: string,
    id: string,
}