import React, { Component } from 'react';
import { Space } from '../../../Providers.Api/Spaces/SpaceRepository';
import IbssDialog from '../BaseDialog/IbssDialog';
import { appContext } from '../../../AppContext';
import './Styles.scss';
import { Card } from "react-bootstrap";
import { Box, CardContent, CardMedia, Chip, Divider, Grid, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import LinkIcon from '../../Icons/LinkIcon';
import parse from 'html-react-parser';
import { getFloorUsingFloorId } from '../../../Common/Helper';
import { BookingPolicyData, SpaceInfo, Space as SpaceDataModel } from './DataModels';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';

export default class SpaceInformationDialog extends Component<IProps, IState>
{

    private get apiCache() { return appContext().apiCache; }
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            spaceBgImage: "",
            bookingPolicyDescription: "",
            additionalInformation: "",
            spaceName: "",
            hasPresentationAids: false,
            hasHearingAids: false,
            hasCatering: false,
            hasLayout: false,
            spaceInfo: [],
            selectedSpaceGuide: null,
            isSpaceGuideActive: false,
            spaceDetails: null,
            floorName: ''
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.initialise();
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (prevProps.isOpen !== this.props.isOpen)
        {
            if(this.props.isOpen)
            {
                await this.initialise();
            }
        }
    }

    private async initialise(): Promise<void>
    {
        if (!this.props.isOpen)
        {
            return;
        }

        this.reset();
        await this.loadCachedSpaces();
        await this.loadSpaceDetails();
        await this.loadSpaceBookingPolicy();

    }

    private reset(): void
    {
        this.setState({
            bookingPolicyDescription: "",
            additionalInformation: "",
            spaceName: "",
            hasPresentationAids: false,
            hasHearingAids: false,
            hasCatering: false,
            hasLayout: false,
            spaceInfo: []
        });
    }

    private async loadCachedSpaces(): Promise<void>
    {
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.props.buildingId);
            const spacesView = response.map(space => SpaceView.fromSpace(space));
            const spaceView = spacesView.find(space => space.spaceId === this.props.spaceId);

            if(spaceView)
            {
                this.setState({
                    additionalInformation: spaceView.spaceCustomInfo,
                    spaceName: spaceView.spaceId,
                    hasPresentationAids: !!spaceView.metaServReqsPresentation,
                    hasHearingAids: !!spaceView.metaServReqsHearing,
                    hasCatering: !!spaceView.metaServReqsCatering,
                    hasLayout: !!spaceView.spaceLayout,
                });
            }
        }
        catch
        {
            return;
        }
    }
    
    private async loadSpaceDetails(): Promise<void>
    {
        try
        {
            const spaceDetails = await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.get<SpaceDataModel>({
                nodeId: this.props.nodeId,
                id: this.props.spaceId,
                select: SpaceDataModel,
            });
            const floor = getFloorUsingFloorId(this.props.nodeId);
            await this.setState({ spaceDetails: spaceDetails, floorName: floor });
        }
        catch
        {
            return;
        }

        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.spaces.bySpaceid.spaceInfo.get<SpaceInfo[]>({
                nodeId: this.props.nodeId,
                spaceid: this.props.spaceId,
                suppressErrorPopup: true
            });
            this.setState({ spaceInfo: response });
        }
        catch
        {
            return;
        }
    }

    private async loadSpaceBookingPolicy(): Promise<void>
    {
        try
        {
            const bookingPolicy = await appContext().ibssApiClientV2.v2.byNodeid.spaces.bySpaceid.bookingPolicy.get<BookingPolicyData>({
                nodeId: this.props.nodeId,
                spaceid: this.props.spaceId,
            });

            const bookingPolicyView = BookingPolicyView.fromBookingPolicy(bookingPolicy);

            this.setState({ bookingPolicyDescription: bookingPolicyView.bookingPolicyDescription || this.labels.HubLabelBookingPolicyDescriptionNotAvailable });
        }
        catch
        {
            return;
        }
    }

    private dialogClosed(): void
    {
        this.props.onClose();
    }

    private setSpaceGuide(space: SpaceInfo): void
    {
        this.setState({ selectedSpaceGuide: space, isSpaceGuideActive: true });
    }

    private backSpaceGuide(): void
    {
        this.setState({ isSpaceGuideActive: false, selectedSpaceGuide: null });
    }

    private getImageURL(): string
    {
        let imageURL = '';
        if(this.state.isSpaceGuideActive)
        {
            imageURL = this.state.selectedSpaceGuide?.ImageURI ?? '';
        }
        else
        {
            imageURL = this.state.spaceDetails?.ImageURI && this.state.spaceDetails.ImageURI !=='' ? this.state.spaceDetails?.ImageURI : "/images/spaceImages/space_desk.png";
        }
        return imageURL;
    }

    private isURLValid(url: string): boolean
    {
        let isValid = false;
        if(url !== '')
        {
            isValid = /^(|http:\/\/|https:\/\/)([^: ]*)$/.test(url);
        }
        return isValid;
    }


    public render(): JSX.Element
    {
        const { isOpen } = this.props;

        return (
            <IbssDialog className='spaceInfoDialog'
                open={isOpen}
                onClose={() => this.dialogClosed()}
                customClass='spaceinfo-container'
                maxWidth='sm'
                fullWidth
                dialogContent={
                    <Box>
                        <Box className="spaceinfo-top-img">
                            <Card.Img className="img-fluid" variant="top" alt='Common Space' src={this.getImageURL()} />
                        </Box>
                        <Box className="space-info-content common-space-info-card-h" my={1} p={5}>
                        { this.state.isSpaceGuideActive ? 
                            <>
                            <Box className="pointer" display={'flex'} my={2} onClick={()=> this.backSpaceGuide()}> <Link color="inherit" mr={1}>&lt; {this.labels.HubButtonBack}</Link>  /   <Typography ml={1}>{this.state.selectedSpaceGuide?.Space_Info_Title}</Typography></Box>
                            <Typography component="div" fontWeight={'bold'} variant="h5">
                                    {this.state.selectedSpaceGuide?.Space_Info_Title}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{ color: 'text.secondary' }} my={2}>
                                    {parse(this.state.selectedSpaceGuide?.Space_Info_Message ?? '') }
                            </Typography>
                            </> : 
                            <>
                            <Typography variant='body1'>{this.state.spaceDetails?.Space_Name}</Typography>
                            <Typography variant='h5' py={2}>{this.state.spaceDetails?.Space_Type_Label}</Typography>
                            <Box display={'flex'} flexWrap={'wrap'}>
                            <Box my={0.5}>
                            <Chip icon={<div style={{ fontSize: '13px', marginRight: '4px', marginLeft: '13px' }}><IbssSvgIcon fontSize='inherit'>{Icons.UserIcon}</IbssSvgIcon></div>} label={`Capacity: ${this.state.spaceDetails?.Space_Capacity ?? ''}`} className='borderRadius-1' /></Box>
                            <Box my={0.5}><Chip icon={<div style={{ fontSize: '13px', marginRight: '4px', marginLeft: '13px' }}><IbssSvgIcon fontSize='inherit'>{Icons.FloorIcon}</IbssSvgIcon></div>} label={`${this.labels.HubLabelFloors} ${this.state.floorName ?? ''}`} className='borderRadius-1 mx-1' /></Box>
                            {this.state.spaceDetails?.Meta_Loc_Zone && <Box my={0.5}><Chip icon={<div style={{ fontSize: '13px', marginRight: '4px', marginLeft: '13px' }}><IbssSvgIcon fontSize='inherit'>{Icons.ZoneIcon}</IbssSvgIcon></div>} label={`${this.state.spaceDetails?.Meta_Loc_Zone ?? ''}`} className='borderRadius-1' /></Box>}</Box>
                            <Typography variant='h6' py={2}>{this.labels.HubMenuSpaceDetails}</Typography>
                            <Divider />
                            <Typography variant='body1' fontWeight={'bold'} pt={2} pb={1}>{this.labels.funcSpaceInfoSpaceInformation_S}</Typography>
                            <Typography variant='body1' pb={2}>{this.state.spaceDetails?.Space_Custom_Info || this.state.spaceDetails?.Space_Custom_Info !== '' ? this.state.spaceDetails?.Space_Custom_Info : this.labels.HubLabelSpaceInfoDescriptionNotAvailable}</Typography>
                            <Typography variant='body1' fontWeight={'bold'} mt={1} pt={2} pb={1}>{this.labels.funcAdvancedBookingPolicyDescription_L}</Typography>
                            <Typography variant='body1' pb={2}>{this.state.bookingPolicyDescription}</Typography>
                            {this.state.spaceInfo.length > 0 && <Box>
                                <Typography variant='h6' py={2}>{this.labels.funcSpaceInfoSpaceGuides_S}</Typography>
                                <Divider />
                                { this.state.spaceInfo.map((space, index) => 
                                    <>
                                    <Box my={2} tabIndex={index}>
                                        <Card className={`space-guide-card ${space.Space_Info_Message ? 'pointer' : ''}`} onClick={space.Space_Info_Message ? () => this.setSpaceGuide(space) : undefined}>
                                            <Grid container>
                                                <Grid sm={8}>
                                                <CardContent>
                                                <Typography className='d-flex' component="div" fontWeight={'bold'} variant="body1">
                                                    {space.Space_Info_Title} 
                                                    {this.isURLValid(space.ActionURI) &&  <Box component="span" ml={1} className='pointer' onClick={() => window.open(space.ActionURI)}>
                                                        <LinkIcon/>
                                                    </Box>}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    component="div"
                                                    sx={{ color: 'text.secondary' }}  className='text-wrap-2' my={2}>
                                                    {space.Space_Info_Short_Message}
                                                </Typography>
                                                {space.Space_Info_Message && <Link onClick={()=> this.setSpaceGuide(space)}  color="inherit">
                                                    {this.labels.funcSpaceInfoReadSpaceGuide_S}
                                                </Link>}
                                                </CardContent>
                                                </Grid>
                                                <Grid sm={4}>
                                                    <CardMedia
                                                    component="img"
                                                    width={'100%'}
                                                    height={'100%'}
                                                    image={space.ImageURI}
                                                    alt="Live from space album cover"
                                                    sx={{borderRadius:'0px 8px 8px 0px'}}
                                                />
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Box>
                                    </>) 
                                }
                            </Box>}
                            </>
                        }
                        </Box>
                        
                    </Box>
                }
            />
        );
    }
}

interface IProps
{
    isOpen: boolean;
    buildingId: number;
    nodeId: number;
    spaceId: string;
    onClose: () => void;
}

interface IState
{
    spaceBgImage: string;
    bookingPolicyDescription: string;
    additionalInformation: string;
    spaceName: string;
    hasPresentationAids: boolean;
    hasHearingAids: boolean;
    hasCatering: boolean;
    hasLayout: boolean;
    spaceDetails: SpaceDataModel | null;
    selectedSpaceGuide: SpaceInfo | null;
    isSpaceGuideActive: boolean;
    spaceInfo: SpaceInfo[];
    floorName: string;
}

class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public bookingPolicyId = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceNameLabel = "";
    public spaceCustomInfo = "";

    public static fromSpace(space: Space): SpaceView
    {
        return{
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            bookingPolicyId: space.Booking_Policy_Id,
            metaServReqsCatering: space.Meta_Serv_Reqs_Catering, 
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceNameLabel: space.Space_Name_Label,
            spaceCustomInfo: space.Space_Custom_Info,
        }
    }
}

class BookingPolicyView
{
    public bookingPolicyDescription = '';

    public static fromBookingPolicy(bookingPolicy: BookingPolicyData): BookingPolicyView
    {
        return{
            bookingPolicyDescription: bookingPolicy.Booking_Policy_Description,
        }
    }
}