import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DateTime } from "luxon";

export class PatchCateringOrderEndpoint implements IPatchCateringOrderEndpoint
{
    public async execute(nodeId: number, orderId: string, payload: IPatchCateringOrder): Promise<string>
    {
        try
        {
            const utcPayload = {
                ...payload,
                Catering_Service_Time: payload.Catering_Service_Time ? DateTime.fromISO(payload.Catering_Service_Time).toUTC().toISO() : undefined,
                Catering_Clearing_Time: payload.Catering_Clearing_Time ? DateTime.fromISO(payload.Catering_Clearing_Time).toUTC().toISO() : undefined,
            };
            const response = await axios.patch(apis.allApiv2 + nodeId + '/catering-order/' + orderId, utcPayload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IPatchCateringOrderEndpoint
{
    execute(nodeId: number, orderId: string, payload: IPatchCateringOrder): Promise<string>;
}

export interface IPatchCateringOrder
{
    Space_Id?: string;
    Catering_Service_Time?: string;
    Catering_Clearing_Time?: string;
    Catering_Order_Status?: string;
}
