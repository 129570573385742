import React, { Component } from 'react';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssUserPrefNotificationUI from './IbssUserPrefNotificationUI';
import { appContext } from '../../../../AppContext';
import { PageHelper } from './PageHelper';

class TaskNotificationState
{
    public TaskNotification = PageHelper.getUserPrefrenceJson();
    public taskRoamer = false;
    public taskPush = false;
    public taskEmail = false;
};

class TaskNotification extends Component<any, TaskNotificationState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: any)
    {
        super(props)
        this.state = new TaskNotificationState();
    };

    componentDidMount()
    {
        const response = this.local.getUserPreferences();
        this.setState({ TaskNotification: response });
        this.makeData(response);
    };

    public makeData = (response: any) =>
    {
        //convert number to binary               
        let tasks = Number(response?.NotificationPrefs?.Tasks && response?.NotificationPrefs?.Tasks);
        this.setState({
            taskRoamer: tasks !== 0 ? true : false,
            taskEmail: (tasks === 3 || tasks === 7) ? true : false,
            taskPush: (tasks === 5 || tasks === 7) ? true : false,
        })
    };

    public change = async (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (e.target.name === "taskRoamer")
        {
            await this.setState({ taskRoamer: e.target.checked });
        } else if (e.target.name === "taskEmail")
        {
            await this.setState({ taskEmail: e.target.checked });
        } else if (e.target.name === "taskPush")
        {
            await this.setState({ taskPush: e.target.checked });
        }

        if (this.state.taskEmail || this.state.taskPush)
        {
            this.setState({
                taskRoamer: true
            });
        }
    };

    public getInteger = (roamer: boolean, push: boolean, email: boolean) =>
    {
        let integerValue = 0
        if (push && !email)
        {
            integerValue = 5;
        }
        if (!push && email)
        {
            integerValue = 3;
        }
        if (push && email && roamer)
        {
            integerValue = 7;
        }
        if (roamer && !push && !email)
        {
            integerValue = 1;
        }
        return integerValue
    };

    public submit = () =>
    {
        this.setState({
            TaskNotification: {
                ...this.state.TaskNotification,
                NotificationPrefs: {
                    ...this.state.TaskNotification.NotificationPrefs,
                    Tasks: this.getInteger(this.state.taskRoamer, this.state.taskPush, this.state.taskEmail),
                },
            }
        }, async () =>
        {
            try
            {
                await this.userPreferences.update(this.state.TaskNotification);
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
            }
            catch { }
        });
    };

    render()
    {
        const labelsList = [
            {
                label: this.labels.funcInAppNotifications_S,
                checked: this.state.taskRoamer,
                onChange: (e: any) => this.change(e),
                name: "taskRoamer"
            },
            {
                label: this.labels.HubLabelEmailNotifications,
                checked: this.state.taskEmail,
                onChange: (e: any) => this.change(e),
                name: "taskEmail"
            },

        ];
        return (
            <>

                <IbssUserPrefNotificationUI labelsList={labelsList} />

                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => { this.submit() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
}

export default TaskNotification;