import { DateHelper } from "../../../../Common/DateHelper";
import { IFilter } from "../../../../Providers.Api/ODataFilter";

export class BookingFilter implements IFilter
{
    public statuses: string[] = [];
    public bookingOwnerEmail = '';

    constructor(value: Partial<BookingFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
		const statusFilter: string[] = [];
		this.statuses.forEach(value =>
        {
            switch (value)
            {
                case 'Approved':
                    statusFilter.push(`((Booking_Status eq 'New' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)) or (Booking_Status eq 'Amended' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)))`);
                    break;
                case 'Awaiting Approval':
                    statusFilter.push(`(Booking_Status eq 'New' and Booking_IsApproved eq 0) or (Booking_Status eq 'Amended' and Booking_IsApproved eq 0)`);
                    break;
                default:
                    statusFilter.push(`(Booking_Status eq '${value}')`);
            }
		});

        const filterParts: string[] = [];
        filterParts.push(this.statuses.length > 0 ? `(${statusFilter.join(' or ')})` : '');
        filterParts.push(this.bookingOwnerEmail == '' ? '' : `Booking_Owner_Email eq '${this.bookingOwnerEmail.toLocaleLowerCase()}'`);
        return filterParts.filter(i => i != '').join(' and ');
    }
}
