import "./Alert.scss";
import { Component } from "react";

export default class Alert extends Component<IProps, State>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            iconUrl: props.iconUrl ?? "/images/Group 1273.svg",
            title: props.title,
            text: props.text,
            width: (3.5 * props.text.length) + "px",
        };
    }

    public render(): JSX.Element
    {
        const cssClassName = `iconics-alert ${this.props.className}`;

        return (
            <div className={cssClassName}>
                <div className="iconics-alert-icon">
                    <img src={this.state.iconUrl} alt="icon" />
                </div>
                <div className="iconics-alert-title">
                    <span>{this.state.title}</span>
                </div>
                <div className="iconics-alert-text" style={{ width: this.state.width }} >
                    <span>{this.state.text}</span>
                </div>
            </div>
        );
    }
}

export interface IProps
{
    className?: string;
    iconUrl?: string;
    title: string;
    text: string;
}

export class State
{
    iconUrl = "";
    title = "";
    text = "";
    width = "";
}
