import React from 'react';
import { Box } from '@mui/material';
import { appContext } from '../../../../AppContext';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { IVisitorDetail } from './EditVisit';

export default class EditVisitorDetails extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    render() 
    {
        return (
            <>
                <Box display={'flex'} sx={{ '& > :not(style)': { my: 1, mr: 1 }, }}>
                    <IbssTextField
                        error={this.props.visitorDetails.visitorFirstName == "" ? true : false}
                        fullWidth
                        required
                        name="visitorFirstName"
                        label={this.labels.HubLabelFirstName}
                        value={this.props.visitorDetails.visitorFirstName}
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onFirstNameChanged(event)}
                        helperText={this.props.visitorDetails.visitorFirstName == "" ? this.labels.HubLabelFirstNamePlaceholder : ""}
                    />
                    <IbssTextField
                        fullWidth
                        required
                        name="visitorLastName"
                        label={this.labels.HubLabelLastName}
                        value={this.props.visitorDetails.visitorLastName}
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onLastNameChanged(event)}
                        error={this.props.visitorDetails.visitorLastName == "" ? true : false}
                        helperText={this.props.visitorDetails.visitorLastName == "" ? this.labels.HubLabelLastNamePlaceholder : ""}
                    />
                </Box>
                <IbssTextField
                    fullWidth
                    required
                    name="company"
                    className='mt-3'
                    label={this.labels.HubLabelCompany}
                    value={this.props.visitorDetails.company}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onCompanyChanged(event)}
                    error={this.props.visitorDetails.company == "" ? true : false}
                    helperText={this.props.visitorDetails.company == "" ? this.labels.HubLabelCompanyNamePlaceholder : ""}
                />
                <IbssTextField
                    fullWidth
                    required
                    name="visitorEmail"
                    label={this.labels.funcDelegateEmail_S}
                    value={this.props.visitorDetails.visitorEmail}
                    className='mt-3'
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onEmailChanged(event)}
                    error={this.props.visitorDetails.visitorEmail == "" ? true : false}
                    helperText={this.props.visitorDetails.visitorEmail == "" ? this.labels.HubLabelInvalidEmailError : ""}
                />
                <IbssTextField
                    fullWidth
                    name="pacsId"
                    label={this.labels.HubLabelPacs}
                    value={this.props.visitorDetails.pacsId}
                    variant="outlined"
                    className='mt-3'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onPacsChanged(event)}
                />
            </>
        )
    }
}


interface IProps
{
    visitorDetails: IVisitorDetail;
    onFirstNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onLastNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCompanyChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onEmailChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPacsChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IState { }