import { DateTime } from "luxon";
import { IGetManyByPeriodEndpoint, ISpaceUtilisationSummary as IGetManyByPeriodResponse } from "./GetManyByPeriodEndpoint";

export class SpaceUtilisationSummaryRepository implements ISpaceUtilisationSummaryRepository
{
    private getManyByPeriodEndpoint: IGetManyByPeriodEndpoint;

    constructor(
        getManyByPeriodEndpoint: IGetManyByPeriodEndpoint,
    )
    {
        this.getManyByPeriodEndpoint = getManyByPeriodEndpoint;
    }

    public getManyByPeriod(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<IGetManyByPeriodResponse[]>
    {
        return this.getManyByPeriodEndpoint.execute(nodeId, periodType, periodStart, periodEnd, floorNodeId);
    }
}

export interface ISpaceUtilisationSummaryRepository
{
    getManyByPeriod(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<IGetManyByPeriodResponse[]>;
}
