import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';

class BuildingLocationForm extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div>
                <IbssTextField multiline rows={4} className='mt-1' variant='outlined' label={this.labels.HubLabelAddress} fullWidth value={this.props.address} onChange={(e) => this.props.addressChanged(e.target.value)} />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingCity_S} fullWidth value={this.props.city} onChange={(e) => this.props.cityChanged(e.target.value)}/>
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingCountry_S} fullWidth value={this.props.country} onChange={(e) => this.props.countryChanged(e.target.value)} />
            </div>
        )
    }
}

export default BuildingLocationForm;

export interface IProps
{
    address: string;
    addressChanged: (value: string) => void;
    city: string;
    cityChanged: (value: string) => void;
    country: string;
    countryChanged: (value: string) => void;
}

export interface IState
{
}