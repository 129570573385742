import React, { Component } from 'react';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { appContext } from '../../../../AppContext';

class FloorOverviewForm extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    public render(): JSX.Element
    {
        return (
            <div>
                <IbssTextField
                    fullWidth
                    label={this.labels.HubLabelDisplayName}
                    value={this.props.floorId}
                    onChange={(e) => this.props.floorIdChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcFloorName_S}
                    value={this.props.displayName}
                    onChange={(e) => this.props.displayNameChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcDisplayOrder_S}
                    value={this.props.displayOrder}
                    onChange={(e) => this.props.displayOrderChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcOccupier_S}
                    value={this.props.occupier}
                    onChange={(e) => this.props.occupierChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcOperator_S}
                    value={this.props.operator}
                    onChange={(e) => this.props.operatorChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcOwner_S}
                    value={this.props.owner}
                    onChange={(e) => this.props.ownerChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcCapacity_S}
                    value={this.props.capacity}
                    onChange={(e) => this.props.capacityChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcFloorSize_S}
                    value={this.props.floorSize}
                    onChange={(e) => this.props.floorSizeChanged(e.target.value)}
                    margin="normal"
                />
                <IbssTextField
                    fullWidth
                    label={this.labels.funcFloorMap_S}
                    value={this.props.mapUri}
                    disabled
                    margin="normal"
                />
            </div>
        );
    }
}

export default FloorOverviewForm;

interface IProps 
{
    displayName: string;
    floorId: string;
    displayOrder: string;
    occupier: string;
    operator: string;
    owner: string;
    capacity: string;
    floorSize: string;
    mapUri: string;
    displayNameChanged: (value: string) => void;
    floorIdChanged: (value: string) => void;
    displayOrderChanged: (value: string) => void;
    occupierChanged: (value: string) => void;
    operatorChanged: (value: string) => void;
    ownerChanged: (value: string) => void;
    capacityChanged: (value: string) => void;
    floorSizeChanged: (value: string) => void;
}

interface IState
{
}