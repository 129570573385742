import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Space extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Class = "";
    public Space_Type = "";
    public Booking_Policy_Id = "";
    public Meta_Loc_Zone = "";
    public Space_IsEnabled = 0;
}

