import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class BuildingBookingPolicy extends ODataSelect
{
    public Booking_Policy_Id = "";
    public Booking_Policy_Name = "";
    public Booking_Policy: IBookingPolicy = 
    { 
        PolicyType: "", 
        Booking_Policy_Description: ""
    };

}

export interface IBookingPolicy
{
    PolicyType: string
    Booking_Policy_Description: string
}