import React from "react";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { appContext } from "../../../../AppContext";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Helper from "../../../../Common/Helper";
import { IFloor, INode, ISearchConfigItem } from "../../../../Providers.Api/Models";
import { IFile } from "../../../../Components/DialogLaunchers/MediaSelector/MediaSelector";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import SpaceConfiguration from "./SpaceConfiguration";
import SpaceType from "./SpaceType";
import PropertiesAndThresholds from "./PropertiesAndThresholds";
import Environmental from "./Environmental";
import Location from "./Location";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { Role, Space } from "./DataModels";
import AccessPermissions from "./AccessPermissions";

class EditAdvancedSpace extends IbssPage<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private floorId: number;
    private spaceId: string;


    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            isLoading: false,
            buildingName: "",
            buildingId: 0,
            spaceDetail: {} as SpaceView,
            selectedSpaceClass: "",
            workSpaceTypeOptions: [],
            selectedWorkSpaceType: "",
            spaceTypeOptions: [],
            selectedSpaceType: "",
            floorTypeOptions: [],
            selectedFloor: 0,
            zoneOptions: [],
            selectedZone: "",
            capacity: 0,
            allBookingPolicies: [],
            selectedPolicy: "",
            images: [],
            imageUrl: "",
            xCoordinates: 0,
            yCoordinates: 0,
            bookable: null,
            occType: null,
            thresholdsMin: 0,
            signType: null,
            spaceTypeLabel: "",
            tempRange: null,
            spaceOwnership: "",
            soundRange: null,
            zoneId: 0,
            spaceName: "",
            spaceCustomInfo: "",
            selectedBuildingOption: "",
            buildingOptions: [],
            newSpaceId: "",
            spaceNameLabel: "",
            concurrencyStamp: "",
            spaceClasses:
                [
                    {
                        label: this.labels.HubLabelNone,
                        value: "None"
                    },
                    {
                        label: this.labels.HubLabelWork,
                        value: "Work",
                    },
                    {
                        label: this.labels.HublabelSupport,
                        value: "Support"
                    },
                    {
                        label: this.labels.HubLabelAmenity,
                        value: "Amenity"
                    },
                    {
                        label: this.labels.HubLabelAncillary,
                        value: "Ancillary"
                    },
                    {
                        label: this.labels.HubLabelZone,
                        value: "Zone"
                    }
                ],
            spaceStatus:
                [
                    {
                        label: this.labels.HubLabelNotBookable,
                        value: 0
                    },
                    {
                        label: this.labels.HublabelBookableLabel,
                        value: 1
                    },
                    {
                        label: this.labels.HublabelAdminBookableOnly,
                        value: 4
                    },
                    {
                        label: this.labels.HubLabelAutoCheckIn,
                        value: 5
                    },
                ],
            occTypes:
                [
                    {
                        label: this.labels.HublabelManualDataCollection,
                        value: 'Manual'
                    },
                    {
                        label: this.labels.HublabelAutomaticDataCollection,
                        value: 'Automatic'
                    },
                ],
            signTypes:
                [
                    {
                        label: this.labels.HubLabelDesk,
                        value: 0
                    },
                    {
                        label: this.labels.HubLabelRoom,
                        value: 1
                    },
                    {
                        label: this.labels.HublabelAlternativeworkspace,
                        value: 2
                    },
                ],
            tempRanges:
                [
                    {
                        label: this.labels.HubLabelCooler,
                        value: -1
                    },
                    {
                        label: this.labels.HubLabelNormal,
                        value: 0
                    },
                    {
                        label: this.labels.HubLabelWarmer,
                        value: 1
                    }
                ],
            soundRanges:
                [
                    {
                        label: this.labels.HubLabelSilent,
                        value: -1
                    },
                    {
                        label: this.labels.HubLabelQuiet,
                        value: 0
                    },
                    {
                        label: this.labels.HubLabelNormal,
                        value: 1
                    }
                ],
            sensorTypes:
                [
                    {
                        label: this.labels.HubLabelOccupancyLabel,
                        value: 0
                    },
                    {
                        label: this.labels.HubLabelTrafficLine,
                        value: 1
                    }
                ],
            selectedSensor: null,
            activeSpace: false,
            isCreate: true,
            spaceNameError: false,
            capacityLabelError: false,
            selectedBuildingOptionError: false,
            bookingPolicyOptionError: false,
            selectedSpaceClassOptionError: false,
            selectedSpaceTypeOptionError: false,
            selectedWorkTypeOptionError: false,
            selectedBookableSettingOptionError: false,
            spaceIdError: false,
            floorError: false,
            spaceNameLabelError: false,
            spaceTypeLabelError: false,
            spaceOwnershipOptions: [],
            metaAvailServReqs: 0,
            spaceArrivalLoc: 0,
        };
        this.floorId = parseInt(this.props.match.params.floorid);
        this.spaceId = this.props.match.params.spaceid;
    }

    public async componentDidMount(): Promise<void>
    {
        try 
        {
            this.pageTitle = this.labels.HubMenuManage + ' - ' + this.labels.HubMenuSpaces;
            if (this.spaceId)
            {
                const getBuildingId = Helper.getBuildingIdUsingFloorNodeId(this.floorId);
                const buildingId = parseInt(getBuildingId);
                this.setState({
                    isLoading: true,
                    isCreate: false,
                    buildingId: buildingId
                })
                const getSpaceDetail = await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.get<Space>({
                    nodeId: this.floorId,
                    spaceId: this.spaceId,
                    select: Space,
                });
                const spaceView = SpaceView.fromSpace(getSpaceDetail);
                const getBuildingName = Helper.getBuildingNameUsingBuildingId(buildingId);
                if (spaceView)
                {
                    this.setState({
                        spaceDetail: spaceView,
                        buildingName: getBuildingName,
                        spaceName: spaceView.spaceName,
                        spaceCustomInfo: spaceView.spaceCustomInfo,
                        capacity: spaceView.spaceCapacity,
                        imageUrl: spaceView.imageURI,
                        xCoordinates: spaceView.metaLocXCoordinates,
                        yCoordinates: spaceView.metaLocYCoordinates,
                        bookable: spaceView.metaBookable,
                        occType: spaceView.metaOccType,
                        thresholdsMin: spaceView.metaSolThreshold,
                        signType: spaceView.metaSignType,
                        tempRange: spaceView.envTemRang,
                        soundRange: spaceView.envSoundRang,
                        selectedSensor: spaceView.metaOccCountMode,
                        selectedSpaceClass: spaceView.spaceClass,
                        selectedZone: spaceView.metaLocZone,
                        selectedWorkSpaceType: spaceView.spaceWorkType,
                        selectedSpaceType: spaceView.spaceType,
                        selectedPolicy: spaceView.bookingPolicyId,
                        spaceTypeLabel: spaceView.spaceTypeLabel,
                        spaceNameLabel: spaceView.spaceNameLabel,
                        zoneId: spaceView.envZoneId,
                        activeSpace: spaceView.spaceIsEnable === 0 ? false : true,
                        spaceOwnership: spaceView.ownerByRoleId,
                        concurrencyStamp: spaceView.concurrencyStamp,
                        metaAvailServReqs: spaceView.metaAvailServReqs,
                        spaceArrivalLoc: spaceView.spaceArrivalLoc,
                    })
                }
                this.setState({
                    isLoading: false,
                })
                this.populateWorkSpaces(buildingId);
                this.populateSpaceTypes(buildingId);
                this.populateFloors(buildingId);
                this.populateBookingPolicy(buildingId);
            }
            this.populateRoles();
            this.getImages();
            this.populateBuildings();
        }
        catch (error) 
        {
            this.setState({
                isLoading: false,
            })
        }

    }

    private populateBuildings(): void
    {
        const buildings: INode[] = Helper.getAllBuildingsData();
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options });
    }


    private async getImages(): Promise<void>
    {
        try 
        {
            const responseData = await this.apiClient.files.getFiles('r/SpaceImages');
            const newResponseData = responseData.map((item: IFile) =>
            {
                let newItem = { ...item }
                newItem.path = `https://storage.ibss.${Helper.environment}/images/r/SpaceImages/${item.name}`;
                return newItem
            })
            this.setState({ images: newResponseData });
        } catch (error) 
        {
        }
    }

    private async populateBookingPolicy(selectedBuilding: number): Promise<void>
    {
        try 
        {
            const bookingPolicies = await this.apiClient.bookingPolicies.getBookingPolicies(selectedBuilding, 200, 0);
            const options = bookingPolicies.value.map(i => ({ label: i.Booking_Policy_Name, value: i.Booking_Policy_Id ? i.Booking_Policy_Id : "" }));
            if (options)
            {
                this.setState({
                    allBookingPolicies: options,
                })
            }
        } catch (error) 
        {
        }
    }

    private populateWorkSpaces(selectedBuilding: number): void
    {
        const workSpaces: ISearchConfigItem[] = Helper.getWorkSpaceTypesByNodeId(selectedBuilding);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));
        this.setState({ workSpaceTypeOptions: options });
    }

    private populateSpaceTypes(selectedBuilding: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuilding);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        this.setState({ spaceTypeOptions: options });
    }

    private populateFloors(selectedBuilding: number): void
    {
        const floors = Helper.getFloorsByBuildingId(selectedBuilding);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));


        const selectedFloor = options.find((item) => item.value === this.state.spaceDetail.nodeId);
        this.setState({
            floorTypeOptions: options
        })
        if (selectedFloor)
        {
            this.setState({ selectedFloor: selectedFloor.value });
            this.populateZones(selectedFloor.value)
        }
    }

    private async populateZones(selectedFloor: number): Promise<void>
    {
        try 
        {
            const zonesResponse = await this.apiClient.spaceZones.getMultiple(selectedFloor, true);
            const zones = zonesResponse;
            const options = zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }));
            this.setState({ zoneOptions: options });
        } catch (error) 
        {
        }
    }

    private async populateRoles(): Promise<void>
    {
        try 
        {
            const roles = await appContext().webEntryApiClientV1.api.webentry.v1.security.roles.get<Role[]>({})
            const options = roles.sort((a, b) => (a.name < b.name ? - 1 : 1)).map(i => ({ label: i.name, value: i.id }));
            this.setState({ spaceOwnershipOptions: options });
        } catch (error) 
        {
        }
    }

    private workSpaceChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedWorkSpaceType: event.target.value });
    }

    private spaceNameChanged(event: SelectChangeEvent): void
    {
        this.setState({ spaceName: event.target.value });
    }

    private spaceIdChanged(event: SelectChangeEvent): void
    {
        this.setState({ newSpaceId: event.target.value });
    }

    private spaceNameLabelChanged(event: SelectChangeEvent): void
    {
        this.setState({ spaceNameLabel: event.target.value });
    }

    private spaceClassChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedSpaceClass: event.target.value });
    }

    private spaceCustomInfoChanged(event: SelectChangeEvent): void
    {
        this.setState({ spaceCustomInfo: event.target.value });
    }

    private spaceTypeChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedSpaceType: event.target.value });
    }

    private floorChanged(event: SelectChangeEvent): void
    {
        this.populateZones(Number(event.target.value));
        this.setState({
            selectedFloor: Number(event.target.value),
            selectedZone: ""
        });
        this.floorId = parseInt(event.target.value);
    }

    private zoneChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedZone: event.target.value });
    }

    private capacityChanged(event: SelectChangeEvent): void
    {
        this.setState({ capacity: Number(event.target.value) })
    }

    private xCoordinatesChanged(event: SelectChangeEvent): void
    {
        this.setState({ xCoordinates: Number(event.target.value) })
    }

    private yCoordinatesChanged(event: SelectChangeEvent): void
    {
        this.setState({ yCoordinates: Number(event.target.value) })
    }

    private bookableChanged(event: SelectChangeEvent): void
    {
        this.setState({ bookable: Number(event.target.value) })
    }

    private occTypeChanged(event: SelectChangeEvent): void
    {
        this.setState({ occType: event.target.value })
    }

    private thresholdsMinsChanged(event: SelectChangeEvent): void
    {
        this.setState({ thresholdsMin: Number(event.target.value) })
    }

    private digitalSignChanged(event: SelectChangeEvent): void
    {
        this.setState({ signType: Number(event.target.value) })
    }

    private sensorChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedSensor: Number(event.target.value) })
    }

    private tempRangeChanged(event: SelectChangeEvent): void
    {
        this.setState({ tempRange: Number(event.target.value) })
    }

    private spaceOwnershipChanged(event: SelectChangeEvent): void
    {
        this.setState({ spaceOwnership: event.target.value })
    }

    private soundRangeChanged(event: SelectChangeEvent): void
    {
        this.setState({ soundRange: Number(event.target.value) })
    }

    private zoneIdChanged(event: SelectChangeEvent): void
    {
        this.setState({ zoneId: Number(event.target.value) })
    }

    private orderPolicyChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedPolicy: event.target.value })
    }

    private buildingChanged(event: SelectChangeEvent): void
    {
        const buildingValue = Number(event.target.value);
        this.populateWorkSpaces(buildingValue);
        this.populateSpaceTypes(buildingValue);
        this.populateFloors(buildingValue);
        this.populateBookingPolicy(buildingValue);
        this.setState({
            selectedBuildingOption: event.target.value,
        });
    }

    private spaceTypeLabelChanged(event: SelectChangeEvent): void
    {
        this.setState({ spaceTypeLabel: event.target.value })
    }


    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        await this.apiClient.files.uploadFile(file, 'r/SpaceImages/' + filename, processAsMap);
        this.getImages();
    }

    private activeSpaceChange(event: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({
            activeSpace: event.target.checked
        })
    }

    private imageSelectedChange(file: IFileType): void
    {
        this.setState({ imageUrl: file.url })
    }

    private async handleUpdate(): Promise<void>
    {
        const { newSpaceId, capacity, bookable, selectedBuildingOption, selectedSpaceClass, selectedSpaceType, selectedWorkSpaceType, selectedPolicy, spaceName, spaceNameLabel, selectedFloor, selectedZone, spaceTypeLabel, isCreate } = this.state;
        const errors = {
            selectedBuildingOptionError: isCreate ? selectedBuildingOption === "" : false,
            bookingPolicyOptionError: selectedPolicy === "",
            selectedSpaceClassOptionError: selectedSpaceClass === "",
            selectedSpaceTypeOptionError: selectedSpaceType === "",
            selectedWorkTypeOptionError: selectedWorkSpaceType === "",
            selectedBookableSettingOptionError: bookable === null,
            spaceNameError: spaceName === "",
            spaceIdError: isCreate ? newSpaceId === "" : this.spaceId === "",
            floorError: selectedFloor === 0,
            capacityLabelError: capacity < 0,
            spaceNameLabelError: spaceNameLabel === "",
            spaceTypeLabelError: spaceTypeLabel === "",
        };

        this.setState(errors);

        if (!(Object.values(errors).includes(true)))
        {
            const payload =
            {

                Space_Id: this.state.isCreate ? this.state.newSpaceId : this.spaceId,
                Space_Name_Label: this.state.spaceNameLabel,
                Space_Name: this.state.spaceName,
                Space_Custom_Info: this.state.spaceCustomInfo,
                Booking_Policy_Id: this.state.selectedPolicy,
                ImageURI: this.state.imageUrl,
                Space_Class: this.state.selectedSpaceClass,
                Space_Type: this.state.selectedSpaceType,
                Space_Work_Type: this.state.selectedWorkSpaceType,
                Space_Type_Label: this.state.spaceTypeLabel,
                Space_Capacity: this.state.capacity,
                Meta_Loc_Zone: this.state.selectedZone,
                Meta_Loc_X_m: this.state.xCoordinates,
                Meta_Loc_Y_m: this.state.yCoordinates,
                Meta_Bookable: this.state.bookable || 0,
                Meta_Occ_Type: this.state.occType == 'Automatic' ? 1 : 0,
                Meta_Dig_Sign_Type: this.state.signType || 0,
                Meta_Sol_Thld_Min: this.state.thresholdsMin,
                Env_Temperature_Range: this.state.tempRange || 0,
                Env_Sound_Level_Range: this.state.soundRange || 0,
                Meta_Ext_Occ_Count_Mode: this.state.selectedSensor || 0,
                Space_IsEnabled: this.state.activeSpace ? 1 : 0,
                Meta_Avail_Serv_Reqs: this.state.metaAvailServReqs,
                Space_Arrival_Loc: this.state.spaceArrivalLoc,
                Env_Zone_Id: this.state.zoneId,
                OwnedByRoleId: this.state.spaceOwnership,
                ConcurrencyStamp: this.state.concurrencyStamp,
                Node_Id: this.floorId,
                Meta_Gtwy_Type: 0,
                Space_UI_Colour: '',
                Space_Setup: 0,
                Space_Layout: '',
                Meta_Gtwy: '',
                Meta_Ext_Occ_System: 0,
                Meta_Ext_Occ_Area_Id: '',
                Meta_Ext_Occ_Sensor_Id: '',
                Meta_Space_DurationMin_min: 0,
                Meta_Space_DurationMax_min: 0,
                Meta_Ext_Catering_Space_Id: '',
                Meta_Ext_Booking_Space_Id: '',
                Meta_Ext_Catering_System: '',
                Meta_Serv_Reqs_Catering: 0,
                Meta_Serv_Reqs_AV: 0,
            }
            try
            {
                this.setState({ isLoading: true });

                if (this.spaceId) 
                {
                    await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.put({
                        nodeId: this.floorId,
                        spaceId: this.spaceId,
                        body: payload
                    });
                    this.props.history.goBack();
                } else if (newSpaceId && selectedBuildingOption && spaceName && selectedFloor !== 0) 
                {
                    await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.post({
                        nodeId: this.floorId,
                        body: payload
                    });
                    this.props.history.goBack();
                }
            } catch (error) 
            {
            }
            finally 
            {
                this.setState({ isLoading: false });
            }
        }
    }

    public render(): JSX.Element
    {
        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    {this.state.isLoading && <Spinner />}
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container>
                            <SpaceConfiguration
                                isCreate={this.state.isCreate}
                                spaceNameError={this.state.spaceNameError}
                                spaceNameLabelError={this.state.spaceNameLabelError}
                                spaceIdError={this.state.spaceIdError}
                                selectedBuildingOptionError={this.state.selectedBuildingOptionError}
                                bookingPolicyOptionError={this.state.bookingPolicyOptionError}
                                buildingName={this.state.buildingName}
                                spaceId={this.spaceId}
                                activeSpace={this.state.activeSpace}
                                spaceName={this.state.spaceName}
                                onSpaceNameChange={(event) => this.spaceNameChanged(event)}
                                onSpaceCustomInfoChange={(event) => this.spaceCustomInfoChanged(event)}
                                spaceCustomInfo={this.state.spaceCustomInfo}
                                onOrderPolicyChange={(event) => this.orderPolicyChanged(event)}
                                selectedPolicy={this.state.selectedPolicy}
                                allBookingPolicies={this.state.allBookingPolicies}
                                images={this.state.images}
                                getImages={() => this.getImages()}
                                onActiveSpaceChange={(event) => this.activeSpaceChange(event)}
                                imageSelectedChange={(file) => this.imageSelectedChange(file)}
                                onUploadImageChange={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                imageUrl={this.state.imageUrl}
                                buildingOptions={this.state.buildingOptions}
                                selectedBuildingOption={this.state.selectedBuildingOption}
                                onBuildingChange={(event) => this.buildingChanged(event)}
                                newSpaceId={this.state.newSpaceId}
                                onSpaceIdChange={(event) => this.spaceIdChanged(event)}
                                spaceNameLabel={this.state.spaceNameLabel}
                                onSpaceNameLabelChange={(event) => this.spaceNameLabelChanged(event)}
                            />
                            <Grid xs={8}>
                                <SpaceType
                                    isCreate={this.state.isCreate}
                                    spaceClasses={this.state.spaceClasses}
                                    selectedSpaceClass={this.state.selectedSpaceClass}
                                    onSpaceClassChange={(event) => this.spaceClassChanged(event)}
                                    spaceTypeOptions={this.state.spaceTypeOptions}
                                    selectedSpaceType={this.state.selectedSpaceType}
                                    onSpaceTypeChange={(event) => this.spaceTypeChanged(event)}
                                    spaceTypeLabel={this.state.spaceTypeLabel}
                                    onSpaceTypeLabelChange={(event) => this.spaceTypeLabelChanged(event)}
                                    workSpaceTypeOptions={this.state.workSpaceTypeOptions}
                                    selectedWorkSpaceType={this.state.selectedWorkSpaceType}
                                    selectedBuildingOption={this.state.selectedBuildingOption}
                                    onWorkSpaceChange={(event) => this.workSpaceChanged(event)}
                                    capacity={this.state.capacity}
                                    onCapacityChange={(event) => this.capacityChanged(event)}
                                    selectedSpaceClassOptionError={this.state.selectedSpaceClassOptionError}
                                    selectedSpaceTypeOptionError={this.state.selectedSpaceTypeOptionError}
                                    selectedWorkTypeOptionError={this.state.selectedWorkTypeOptionError}
                                    capacityLabelError={this.state.capacityLabelError}
                                    spaceTypeLabelError={this.state.spaceTypeLabelError}
                                />
                                <Location
                                    isCreate={this.state.isCreate}
                                    floorTypeOptions={this.state.floorTypeOptions}
                                    selectedFloor={this.state.selectedFloor}
                                    onFloorChange={(event) => this.floorChanged(event)}
                                    zoneOptions={this.state.zoneOptions}
                                    selectedZone={this.state.selectedZone}
                                    onZoneChange={(event) => this.zoneChanged(event)}
                                    xCoordinates={this.state.xCoordinates}
                                    onXCoordinatesChange={(event) => this.xCoordinatesChanged(event)}
                                    yCoordinates={this.state.yCoordinates}
                                    onYCoordinatesChange={(event) => this.yCoordinatesChanged(event)}
                                    selectedBuildingOption={this.state.selectedBuildingOption}
                                    floorError={this.state.floorError}
                                    floorSelected={this.state.selectedFloor != 0}
                                />
                                <PropertiesAndThresholds
                                    spaceStatus={this.state.spaceStatus}
                                    bookable={this.state.bookable}
                                    selectedBookableSettingOptionError={this.state.selectedBookableSettingOptionError}
                                    onBookableChange={(event) => this.bookableChanged(event)}
                                    occType={this.state.occType}
                                    onOccTypeChange={(event) => this.occTypeChanged(event)}
                                    thresholdsMin={this.state.thresholdsMin}
                                    onThresholdsMinsChange={(event) => this.thresholdsMinsChanged(event)}
                                    signType={this.state.signType}
                                    onDigitalSignChange={(event) => this.digitalSignChanged(event)}
                                    occTypes={this.state.occTypes}
                                    signTypes={this.state.signTypes}
                                    sensorTypes={this.state.sensorTypes}
                                    selectedSensor={this.state.selectedSensor}
                                    onSensorChange={(event) => this.sensorChanged(event)}

                                />
                                <Environmental
                                    isCreate={this.state.isCreate}
                                    tempRange={this.state.tempRange}
                                    tempRanges={this.state.tempRanges}
                                    onTempRangeChange={(event) => this.tempRangeChanged(event)}
                                    soundRanges={this.state.soundRanges}
                                    soundRange={this.state.soundRange}
                                    onSoundRangeChange={(event) => this.soundRangeChanged(event)}
                                    zoneId={this.state.zoneId}
                                    onZoneIdChange={(event) => this.zoneIdChanged(event)}
                                />
                                <AccessPermissions
                                    spaceOwnership={this.state.spaceOwnership}
                                    onSpaceOwnershipChange={(event) => this.spaceOwnershipChanged(event)}
                                    spaceOwnershipOptions={this.state.spaceOwnershipOptions}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Box display={'flex'} justifyContent={'end'} mt={2}>
                                    <IbssButton className="btn-theme-secondary mr-2" variant="contained" size="medium" onClick={() => this.props.history.goBack()}>
                                        {this.labels.HubButtonCancel}
                                    </IbssButton>
                                    <IbssButton
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        onClick={() => this.handleUpdate()}
                                    >
                                        {this.state.isCreate ? this.labels.HubLabelCreate : this.labels.HubLabelSaveUpdates}
                                    </IbssButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}

export default withRouter(EditAdvancedSpace);

export interface IQueryParams
{
    floorid: string;
    spaceid: string;
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    isLoading: boolean;
    buildingName: string;
    buildingId: number;
    spaceDetail: SpaceView;
    workSpaceTypeOptions: IFilterOptions[];
    selectedWorkSpaceType: string;
    spaceTypeOptions: IFilterOptions[];
    selectedSpaceType: string;
    floorTypeOptions: IFilterOptionsNumber[];
    selectedFloor: number;
    zoneOptions: IFilterOptions[];
    selectedZone: string;
    capacity: number;
    allBookingPolicies: IFilterOptions[];
    selectedPolicy: string;
    images: IFile[];
    imageUrl: string;
    xCoordinates: number,
    yCoordinates: number,
    bookable: number | null;
    occType: string | null;
    thresholdsMin: number;
    signType: number | null;
    tempRange: number | null;
    spaceOwnership: string | null;
    soundRange: number | null;
    spaceName: string;
    spaceCustomInfo: string;
    spaceClasses: IFilterOptions[];
    selectedSpaceClass: string;
    spaceTypeLabel: string;
    activeSpace: boolean;
    selectedBuildingOption: string;
    buildingOptions: IFilterOptionsNumber[];
    newSpaceId: string;
    isCreate: boolean;
    spaceNameLabel: string;
    zoneId: number;
    spaceNameError: boolean;
    spaceNameLabelError: boolean;
    spaceTypeLabelError: boolean;
    capacityLabelError: boolean;
    bookingPolicyOptionError: boolean;
    selectedBuildingOptionError: boolean;
    selectedSpaceClassOptionError: boolean;
    selectedSpaceTypeOptionError: boolean;
    selectedBookableSettingOptionError: boolean;
    selectedWorkTypeOptionError: boolean;
    spaceIdError: boolean;
    floorError: boolean;
    selectedSensor: number | null;
    spaceStatus: IFilterOptionsNumber[];
    occTypes: IFilterOptions[];
    signTypes: IFilterOptionsNumber[];
    tempRanges: IFilterOptionsNumber[];
    soundRanges: IFilterOptionsNumber[];
    sensorTypes: IFilterOptionsNumber[];
    spaceOwnershipOptions: IFilterOptions[];
    concurrencyStamp: string;
    metaAvailServReqs: number;
    spaceArrivalLoc: number;
}

export interface IFilterOptions
{
    label: string;
    value: string;
}

export interface IFilterOptionsNumber
{
    label: string;
    value: number;
}

export interface IFileType
{
    name: string;
    url: string;
}

export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCustomInfo = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public imageURI = "";
    public bookingPolicyId = "";
    public metaBookable = 0;
    public metaOccType = "";
    public metaOccCountMode = 0;
    public metaLocZone = "";
    public spaceWorkType = "";
    public metaLocXCoordinates = 0;
    public metaLocYCoordinates = 0;
    public metaSolThreshold = 0;
    public metaSignType = 0;
    public envTemRang = 0;
    public envSoundRang = 0;
    public spaceNameLabel = "";
    public envZoneId = 0;
    public spaceIsEnable = 0;
    public ownerByRoleId = "";
    public concurrencyStamp = "";
    public metaAvailServReqs = 0;
    public spaceArrivalLoc = 0;


    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCustomInfo: space.Space_Custom_Info,
            spaceCapacity: space.Space_Capacity,
            imageURI: space.ImageURI,
            metaLocXCoordinates: space.Meta_Loc_X_m,
            metaLocYCoordinates: space.Meta_Loc_Y_m,
            metaAvailServReqs: space.Meta_Avail_Serv_Reqs,
            metaBookable: space.Meta_Bookable,
            metaOccType: space.Meta_Occ_Type,
            metaOccCountMode: space.Meta_Ext_Occ_Count_Mode,
            metaSolThreshold: space.Meta_Sol_Thld_Min,
            metaSignType: space.Meta_Dig_Sign_Type,
            envTemRang: space.Env_Temperature_Range,
            envSoundRang: space.Env_Sound_Level_Range,
            spaceClass: space.Space_Class,
            metaLocZone: space.Meta_Loc_Zone,
            spaceWorkType: space.Space_Work_Type,
            spaceType: space.Space_Type,
            bookingPolicyId: space.Booking_Policy_Id,
            spaceTypeLabel: space.Space_Type_Label,
            spaceNameLabel: space.Space_Name_Label,
            envZoneId: space.Env_Zone_Id,
            spaceArrivalLoc: space.Space_Arrival_Loc,
            spaceIsEnable: space.Space_IsEnabled,
            ownerByRoleId: space.OwnedByRoleId,
            concurrencyStamp: space.ConcurrencyStamp
        };
    }
}