import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Autocomplete, Box, Divider, Drawer, Grid, Paper, SelectChangeEvent, Step, StepLabel, Stepper, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { RouterProps } from 'react-router';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DateTime } from 'luxon';
import { IPartialAppState, appContext } from '../../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { IDispatch, IPropsFromState } from '../../../../redux/Interfaces';
import Helper, { dateConverter } from '../../../../Common/Helper';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import IbssPageHeader, { StartOrEnd } from '../../../../Components/Forms/DateRange/IbssPageHeader';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import { DateHelper } from '../../../../Common/DateHelper';
import { ComponentHelper } from '../../../../Common/ComponentHelper';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import CreateIcon from '@mui/icons-material/Create';
import { CateringOrdersFilter } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { toggleButtonListObject } from '../../../../Components/Inputs/ToggleButtons/IbssToggleButtons';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IMenuItem } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import CateringOrderDrawer from './CateringOrderDrawer';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';
import { GetCateringStatus } from './CateringStatus';
import PreparingExportDialog from '../../../../Components/Dialogs/PreparingExportDialog/PreparingExportDialog';
import { CateringOrderStatus } from '../../../../Common/Enums';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { QueryParams } from './QueryParams';
import { DateRangeHelper } from '../../../../Components/Core/BasePage/DateRangeHelper';
import { StringHelper } from '../../../../Common/StringHelper';
import { Constants } from '../../../../Common/Constants';
import { CateringOrder, IUser } from './DataModels';
import { PagedResponse } from '../../../../Providers.Api/Models';
import { ApiConstants } from '../../../../Providers.Api/ApiConstants';

export default class ListCateringOrders extends IbssPage<IProps, IState, QueryParams>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private string = new StringHelper();
    private debounceTimer?: NodeJS.Timeout;

    constructor(props: IProps)
    {
        super(props, new QueryParams());

        this.state =
        {
            loading: false,
            filteredCateringOrders: [],
            cateringCompletedOrders: [],
            upcomingCateringOrdersToday: [],
            isCreateRight: false,
            isAssignRight: false,
            isCancelRight: false,
            isUpdateRight: false,
            isSetInProgressRight: false,
            buildingId: this.appState.buildingId,
            startDate: DateHelper.now(),
            endDate: DateHelper.now(),
            openUserModal: false,
            openMessageModal: false,
            message: "",
            selectedTaskIds: [],
            selectedOrderIds: [],
            actionType: "",
            daysFilter: this.labels.HubLabelToday,
            dateDisabled: true,
            searchTerm: "",
            openFilterModal: false,
            statusValue: this.labels.HubLabelAny,
            disableCancel: true,
            disableAssign: true,
            disableApprove: true,
            disableDecline: true,
            approveCateringRightInBuilding: false,
            selectedUser: { label: "", userName: "" },
            listViewType: "allList",
            isDrawerOpen: false,
            stepperInfo:
                [
                    this.labels.funcInApproval_S,
                    this.labels.HubLabelReadyForPrep,
                    this.labels.funcReadyforDelivery_S,
                    this.labels.HubLabelDelivered,
                    this.labels.HubLabelCleared,
                    this.labels.HubLabelCompleted,
                    this.labels.HubLabelCancelled,
                ],
            activeStep: -1,
            orderDetails:
            {
                nodeId: 0,
                orderId: "",
                OwnedBy: "",
                spaceName: "",
                cateringStatus: "",
                timeForDelivery: "",
                bookingStart: "",
                menuItems: [],
                bookingId: ""
            },
            hostSearchText: "",
            hostEmail: '',
            showPreparingExportPopup: false
        }
    }

    public async componentDidMount(): Promise<void>
    {
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevParams: QueryParams): Promise<void>
    {
        const queryParams = this.queryParams;
        const userPrefs = appContext().localStorageProvider.getUserPreferences();

        // building
        const rootNode = this.local.getNodeData();
        const buildings = rootNode.Regions.flatMap(i => i.Buildings);
        const defaultBuilding = buildings.find(i => i.Node_Id == userPrefs.SearchPrefs.DefaultBuilding);
        const building = buildings.find(i => i.Node_Id == queryParams.building) ?? defaultBuilding ?? rootNode;
        const buildingId = building.Node_Id;
        const buildingName = ('Name' in building ? building.Name : building.Node_Name);

        if (buildingId != queryParams.building)
        {
            return this.pushQueryParams({ building: buildingId }, true);
        }

        await this.setStateAsync({ buildingId: buildingId });
        this.pageTitle = (buildingName ? this.labels.HubLabelFacilityManagementText + buildingName : this.labels.HubLabelFacilityManagement);

        const dateRange = new DateRangeHelper(buildingId)
            .addToday()
            .addTomorrow()
            .addThisWeek()
            .addNextWeek()
            .addThisMonth()
            .addNextMonth()
            .getDateRange(queryParams.period, this.labels.HubLabelToday, queryParams.from, queryParams.to);

        if (dateRange.isPeriodCustom && (queryParams.period != undefined || queryParams.from != dateRange.start || queryParams.to != dateRange.end))
        {
            return this.pushQueryParams({ period: undefined, from: dateRange.start, to: dateRange.end }, true);
        }
        else if (!dateRange.isPeriodCustom && (queryParams.period != dateRange.period || queryParams.from != undefined || queryParams.to != undefined))
        {
            return this.pushQueryParams({ period: dateRange.period, from: undefined, to: undefined }, true);
        }

        const defaultStatus = this.statusOptions[0]; // any
        const status = this.statusOptions.find(i => i.value == queryParams.status) ?? defaultStatus;
        if (queryParams.status != status.value)
        {
            return this.pushQueryParams({ status: status.value }, true);
        }

        let hosts: IUser[] = [];
        if (queryParams.host)
        {
            const getUserEndpoint = {
                name: 'ibssApiClientV1.v1.users.byName.get',
                options: { name: queryParams.host }
            };
            hosts = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(getUserEndpoint),
                () => appContext().ibssApiClientV1.v1.users.byName.get<IUser[]>(getUserEndpoint.options)
            );
        }

        const hostEmail = hosts[0]?.email?.toLowerCase() ?? null;
        const queryHostEmail = queryParams.host?.toLowerCase() ?? null;
        if (queryHostEmail != hostEmail)
        {
            return this.pushQueryParams({ host: hostEmail || undefined }, true);
        }

        await this.setStateAsync({
            openFilterModal: false,
            daysFilter: dateRange.period,
            startDate: dateRange.start,
            endDate: dateRange.end,
            dateDisabled: !dateRange.isPeriodCustom,
            searchTerm: queryParams.filter ?? '',
            statusValue: status.value,
            hostSearchText: hostEmail,
            hostEmail: hostEmail,
        });

        const payload: IFilter = {
            buildingId: this.state.buildingId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            statusValue: this.state.statusValue == 'Any' ? undefined : this.state.statusValue,
            owner: this.state.hostEmail || undefined,
        };
        this.setRights();
        const cateringOrders = await this.loadTasks(payload);
        const filteredCateringOrders = this.filterCateringOrders(cateringOrders, this.state.searchTerm);
        const approveCateringRightInBuilding = this.local.permissionAppliesToBuilding('API.Catering.Approve', this.state.buildingId);

        this.setStateAsync({
            filteredCateringOrders: filteredCateringOrders,
            approveCateringRightInBuilding: approveCateringRightInBuilding,
        });
    }

    private getCateringApprovalRight()
    {
        return this.local.hasRight("API.Catering.Approve") && this.state.approveCateringRightInBuilding;
    }

    private setRights(): void
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.CateringOrders.Create"),
            isUpdateRight: this.local.hasRight("DATAMODEL.CateringOrders.Update"),
            isCancelRight: this.local.hasRight("DATAMODEL.CateringOrders.Delete"),
        });
    }

    private async loadTasks(payload: IFilter, forceRefresh?: boolean): Promise<CateringOrderView[]>
    {
        try
        {
            this.setState({ loading: true });

            const filterParts: string[] = [];
            filterParts.push(`Catering_Service_Time ge datetime'${payload.startDate.date().toUtcByNode(payload.buildingId).toISO()}'`);
            filterParts.push(`Catering_Service_Time lt datetime'${payload.endDate.plus({ days: 1 }).date().toUtcByNode(payload.buildingId).toISO()}'`);
            filterParts.push(payload.statusValue ? `Catering_Order_Status eq '${payload.statusValue}'` : '');
            filterParts.push(payload.owner ? `OwnedBy eq '${payload.owner}'` : '');
            const filter = filterParts.filter(i => i != "").join(" and ");

            const endpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.cateringOrder.get',
                options: {
                    nodeId: payload.buildingId,
                    recursive: true,
                    top: ApiConstants.largeODataTop,
                    filter: filter,
                    select: CateringOrder,
                }
            };

            if(forceRefresh)
            {
                appContext().inMemoryCache.clear(JSON.stringify(endpoint))
            }

            const pageOfCateringOrders = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                JSON.stringify(endpoint),
                () => appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.get<PagedResponse<CateringOrder[]>>(endpoint.options)
            );

            const cateringOrders = pageOfCateringOrders.value;
            const cateringOrderViews = cateringOrders.map(i => new CateringOrderView(i));

            if (this.state.listViewType === "todayList")
            {
                const completedCateringList = cateringOrderViews.filter((item) => item.status === "Cleared");
                const restCateringOrderList = cateringOrderViews.filter((item) => item.status !== "Cleared");

                this.setState({
                    cateringCompletedOrders: completedCateringList,
                    upcomingCateringOrdersToday: restCateringOrderList,
                    loading: false,
                });
            }

            return cateringOrderViews;
        }
        catch
        {
            return [];
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private async refreshCateringOrdersList(): Promise<void> 
    {
        const forceRefresh = true;
        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            statusValue: this.state.statusValue == 'Any' ? undefined : this.state.statusValue,
            owner: this.state.hostEmail || undefined,
        };

        const cateringOrders = await this.loadTasks(filterObject , forceRefresh);
        const filteredCateringOrders = this.filterCateringOrders(cateringOrders, this.state.searchTerm);
        this.setState({ filteredCateringOrders: filteredCateringOrders });
    }

    private filterCateringOrders(cateringOrders: CateringOrderView[], searchTerm: string): CateringOrderView[]
    {
        if (!searchTerm)
        {
            return cateringOrders;
        }

        const lowerSearchTerm = searchTerm.toLocaleLowerCase();
        const filteredRows = cateringOrders
            .filter(cateringOrder =>
            {
                let key: keyof CateringOrderView;
                for (key in cateringOrder) 
                {
                    const column = this.dataGridColumns.find(i => i.field.toLocaleLowerCase() == key.toLocaleLowerCase());
                    const value = column?.valueGetter?.({ row: cateringOrder } as any) ?? cateringOrder[key]?.toString();

                    if (column && value && value.toLocaleLowerCase().includes(lowerSearchTerm))
                    {
                        return true;
                    }
                }
                return false;
            });

        return filteredRows;
    }

    private handleDateRangeChange(e: SelectChangeEvent<string>): void
    {
        const period = e.target.value;
        this.setState({ daysFilter: period });
        const periodIsCustom = (period == this.labels.HubLabelCustom);

        this.pushQueryParams({
            period: (periodIsCustom ? undefined : period),
            from: (periodIsCustom ? this.state.startDate : undefined),
            to: (periodIsCustom ? this.state.endDate : undefined),
        });
    }

    private handleStartDateChange(date: DateTime): void
    {
        clearTimeout(this.debounceTimer);
        this.setState({ startDate: date });
        if (!date.isValid)
        {
            return;
        }
        this.debounceTimer = setTimeout(() => this.pushQueryParams({ from: date }), Constants.longDebounceTimeInMilliseconds);
    }

    private handleEndDateChange(date: DateTime): void
    {
        clearTimeout(this.debounceTimer);
        this.setState({ endDate: date });
        if (!date.isValid)
        {
            return;
        }
        this.debounceTimer = setTimeout(() => this.pushQueryParams({ to: date }), Constants.longDebounceTimeInMilliseconds);
    }

    private handleSearchTermChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        clearTimeout(this.debounceTimer);
        const text = event.target.value;
        this.setState({ searchTerm: text });
        this.debounceTimer = setTimeout(() => this.pushQueryParams({ filter: text || undefined }), Constants.longDebounceTimeInMilliseconds);
    }

    private async handleApproveOrDeclineClick(type: string): Promise<void>
    {

        try 
        {
            const payload =
            {
                Catering_Order_Status: type === "approve" ? CateringOrderStatus.Approved : CateringOrderStatus.NotApproved
            }
            for (const id of this.state.selectedOrderIds) 
            {
                const orderNodeId = this.state.filteredCateringOrders.filter(x => x.orderId == id)[0].nodeId;
                await this.apiClient.cateringOrders.update(orderNodeId, String(id), payload);
            }
            this.refreshCateringOrdersList();
        }
        catch (error) 
        {
        }
    }

    private handleFilterModalClose(): void
    {
        this.setState({
            openFilterModal: !this.state.openFilterModal,
            statusValue: this.labels.HubLabelAssigned,
        });
        this.setState({ loading: false });
    }

    private handleFilterModalSubmit(): void
    {
        this.setState({ openFilterModal: false });
        this.pushQueryParams({ status: this.state.statusValue, host: this.state.hostEmail || undefined });
    }

    private handleSelectTasks(selection: GridRowSelectionModel): void
    {
        this.setState({ disableDecline: true, disableApprove: true });

        const selectedOrders: CateringOrderView[] = [];
        selection.forEach((id) =>
        {
            const orderData = this.state.filteredCateringOrders.find(i => i.id === id);
            if (orderData)
            {
                selectedOrders.push(orderData)
            }
        });
        if (selectedOrders) 
        {
            const orderId = selectedOrders.map((item) => item?.orderId).filter((orderId) => orderId != null);
            this.setState({
                selectedOrderIds: orderId
            })
        }

        const selectedOrderStatuses = selectedOrders.map(x => x?.status)

        // enable the Approval button
        if (selectedOrderStatuses.length > 0 && selectedOrderStatuses.every(status => status == "PendingApproval") && this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            this.setState({ disableApprove: false });
        }
        // enable the In Deny button
        if (selectedOrderStatuses.length > 0 && selectedOrderStatuses.every(status => status == "PendingApproval") && this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            this.setState({ disableDecline: false });
        }
    }

    private async handleTaskOwnerChange(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        this.setState({ hostEmail: newValue?.email?.toLowerCase() ?? '' });
    };

    private async handleExportButtonClick(): Promise<void>
    {
        this.setState({ showPreparingExportPopup: true });
        const fromUtc = this.state.startDate.date().toUtcByNode(this.state.buildingId);
        const toUtc = this.state.endDate.plus({ day: 1 }).date().toUtcByNode(this.state.buildingId);
        let filterValues = [`Catering_Service_Time ge datetime'${fromUtc}'`, `Catering_Service_Time lt datetime'${toUtc}'`];

        if (this.state.statusValue != 'Any')
        {
            filterValues.push(`Catering_Order_Status eq '${this.state.statusValue}'`)
        }
        if (this.state.hostEmail)
        {
            filterValues.push(`OwnedBy eq '${this.state.hostEmail}'`)
        }

        appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.download.post({
            nodeId: this.state.buildingId,
            body: {
                Node_Id: this.state.buildingId,
                Filter: filterValues.length == 0 ? "" : filterValues.join(' and '),
            }
        });
    }

    private getActiveStep(status: string): void
    {
        const statusMap: Record<string, number> =
        {
            "Approved": 0,
            "ReadyForPrep": 1,
            "ReadyForDelivery": 2,
            "Delivered": 3,
            "Cleared": 4,
            "Completed": 5,
            "CancelledNoCharge": 6,
            "CancelledCharged": 6
        };

        const activeStep = statusMap[status] !== undefined ? statusMap[status] : -1;

        this.setState({
            activeStep: activeStep
        });
    }


    private async cateringMenuItems(cateringOrder: CateringOrderView, buildingId: number, menuId: string, value: boolean): Promise<void>
    {
        const menuItems: IMenu_Items[] = [];
        const cateringMenu = await this.apiClient.cateringMenus.getById(buildingId, menuId, true, true);
        const cateringMenuItems = cateringMenu.Menu_MenuItems.map((item) => item.MenuItem);

        cateringMenuItems.forEach(catering =>
        {
            const orderItem = cateringOrder.menuItem.find(order => order.Id === catering.MenuItem_Id);
            if (orderItem)
            {
                menuItems.push({
                    id: catering.MenuItem_Id,
                    name: catering.Name,
                    classification: catering.Classification,
                    quantity: orderItem.QuantityOfItems
                });
            }
        });

        this.getActiveStep(cateringOrder.status);
        this.setState({
            isDrawerOpen: value,
            orderDetails:
            {
                nodeId: cateringOrder.nodeId,
                orderId: cateringOrder.orderId,
                OwnedBy: cateringOrder.CreatedBy,
                spaceName: cateringOrder.spaceName,
                timeForDelivery: DateTime.fromISO(cateringOrder.cateringServiceTime).toLocaleDateTimeString(),
                cateringStatus: cateringOrder.status,
                menuItems: menuItems,
                bookingStart: cateringOrder.bookingStart.toString(),
                bookingId: cateringOrder.bookingId
            }
        })
    }

    private openCateringDrawer(cateringOrders: CateringOrderView, value: boolean): React.ReactNode
    {
        const menuId = cateringOrders.menuItem.map((item) => item.Menu_Id)[0];
        if (this.state.isUpdateRight && this.getCateringApprovalRight())
        {
            return (
                <IbssSvgIcon onClick={() =>
                {
                    this.cateringMenuItems(cateringOrders, cateringOrders.nodeId, menuId, value)
                }}>
                    <CreateIcon />
                </IbssSvgIcon>
            )
        }
        else 
        {
            return <p>-</p>
        }
    }

    private async deleteClicked(): Promise<void> 
    {
        try 
        {
            for (const id of this.state.selectedOrderIds) 
            {
                const orderNodeId = this.state.filteredCateringOrders.filter(x => x.orderId == id)[0].nodeId
                try
                {
                    await this.apiClient.cateringOrders.delete(orderNodeId, String(id));
                } catch (error)
                {
                }
            }
        }
        catch (error) 
        {
        }
       
        await this.refreshCateringOrdersList(); 
    }

    public checkLayout(layout: string): void
    {
        if (layout === "todayList")
        {
            const filterObject: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: DateHelper.now(this.state.buildingId),
                endDate: DateHelper.now(this.state.buildingId),
            };
            this.loadTasks(filterObject);
        }
        else if (layout === "allList")
        {
            const filterObject: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            };
            this.loadTasks(filterObject);
        }
    }

    public changeLayout(layout: string | null): void 
    {
        if (layout !== null)
        {
            this.setState({
                listViewType: layout,
            });
            this.checkLayout(layout);
        }
    }

    private async statusChangedClicked(type: string): Promise<void>   
    {
        const payload =
        {
            Catering_Order_Status: type
        }
        try 
        {
            this.setState({
                isDrawerOpen: false,
            })
            await this.apiClient.cateringOrders.update(this.state.orderDetails.nodeId, this.state.orderDetails.orderId, payload);
            await this.refreshCateringOrdersList();
            this.checkLayout(this.state.listViewType);
        } catch (error) 
        {
        }
    }

    private editCateringOrder(): void
    {
        this.props.history.push(`/operational-services-catering/${this.state.buildingId}/catering/${this.state.orderDetails.bookingId}/${this.state.orderDetails.orderId}/edit`)
    }

    private _statusOptions?: IStatusOption[];
    private get statusOptions(): IStatusOption[]
    {
        if (!this._statusOptions)
        {
            this._statusOptions = [
                { label: this.labels.HubLabelPendingApproval, value: CateringOrderStatus.PendingApproval },
                { label: this.labels.HubLabelNotApproved, value: CateringOrderStatus.NotApproved },
                { label: this.labels.HubLabelRequestApproved, value: CateringOrderStatus.Approved },
                { label: this.labels.HubLabelReadyForPrep, value: CateringOrderStatus.ReadyForPrep },
                { label: this.labels.funcReadyforDelivery_S, value: CateringOrderStatus.ReadyForDelivery },
                { label: this.labels.HubLabelDelivered, value: CateringOrderStatus.Delivered },
                { label: this.labels.HubLabelCleared, value: CateringOrderStatus.Cleared },
                { label: this.labels.HubLabelCompleted, value: CateringOrderStatus.Completed },
                { label: this.labels.HubLabelCancelledCharged, value: CateringOrderStatus.CancelledCharged },
                { label: this.labels.HubLabelCancelledNoCharge, value: CateringOrderStatus.CancelledNoCharge },
                { label: this.labels.funcCancellationPending_S, value: CateringOrderStatus.CancellationPending },
            ].sort((a, b) =>
            {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            this._statusOptions = [{ label: this.labels.HubLabelAny, value: "Any" }, ...this._statusOptions]
        }
        return this._statusOptions;
    }

    private cateringStatus = new GetCateringStatus({});
    private dataGridColumns: GridColDef<CateringOrderView>[] = [
        {
            headerName: this.labels.HubLabelBookingName,
            field: Helper.nameOf<CateringOrderView>("bookingName"),
            minWidth: 250,
        },
        {
            headerName: this.labels.HubLabelDeliverHost,
            field: Helper.nameOf<CateringOrderView>("CreatedBy"),
            minWidth: 250,
        },
        {
            headerName: this.labels.HubLabelLocation,
            field: Helper.nameOf<CateringOrderView>("spaceName"),
            minWidth: 250,
        },
        {
            headerName: this.labels.HubLabelDateTimeDelivery,
            field: Helper.nameOf<CateringOrderView>("cateringServiceTime"),
            valueGetter: (params) => DateTime.fromISO(params.row.cateringServiceTime).toLocaleDateTimeString(),
            minWidth: 250,
        },
        {
            headerName: this.labels.funcTimeForCleanUp_S,
            field: Helper.nameOf<CateringOrderView>("cateringClearingTime"),
            valueGetter: (params) => DateTime.fromISO(params.row.cateringClearingTime).toLocaleDateTimeString(),
            minWidth: 250,
        },
        {
            headerName: this.labels.HubLabelStatus,
            field: Helper.nameOf<CateringOrderView>("status"),
            minWidth: 250,
            renderCell: (params) => this.cateringStatus.renderTaskOverAllStatus(params.row.status)
        },
        {
            headerName: this.labels.HubLabelAction,
            minWidth: 120,
            field: "",
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params) => <>{this.openCateringDrawer(params.row, true)}</>
        },
    ];

    public render(): JSX.Element
    {
        // Action buttons
        const actionButtons: IActionButton[] = [
            {
                label: this.labels.HubButtonDelete,
                icon: (
                    <IbssSvgIcon>
                        <DeleteOutlinedIcon />
                    </IbssSvgIcon>
                ),
                color: "error",
                onClick: () => this.deleteClicked(),
                disabled: this.state.selectedOrderIds.length > 0 && this.state.isCancelRight ? false : true
            },
            {
                label: this.labels.HubButtonDeny,
                icon: (
                    <IbssSvgIcon>
                        <CancelOutlinedIcon />
                    </IbssSvgIcon>
                ),
                color: "info",
                onClick: () => this.handleApproveOrDeclineClick("decline"),
                disabled: this.state.disableDecline,
            },
            {
                label: this.labels.HubButtonApprove,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleOutlineTwoToneIcon />
                    </IbssSvgIcon>
                ),
                color: "info",
                onClick: () => this.handleApproveOrDeclineClick("approve"),
                disabled: this.state.disableApprove
            },
        ];

        const layoutOptions: toggleButtonListObject[] =
            [
                { value: 'allList', icon: <FormatListBulletedIcon />, showFilterFields: false },
                { value: 'todayList', icon: <CalendarTodayIcon />, showFilterFields: true },
            ];

        return (
            <>
                <div className="page-container">
                    <PreparingExportDialog open={this.state.showPreparingExportPopup} onClose={() => this.setState({ showPreparingExportPopup: false })} />
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.openFilterModal}
                        onClose={() => this.handleFilterModalClose()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        dialogContent=
                        {
                            <>
                                <div className="row">
                                    <IbssInputDropDown
                                        options={this.statusOptions}
                                        id="statusSelection"
                                        value={this.state.statusValue}
                                        inputLabel={this.labels.HubLabelStatus}
                                        fullWidth
                                        onChange={event => this.setState({ statusValue: event.target.value })}
                                    />
                                </div>
                                <div className="row">
                                    <UserPicker
                                        width="100%"
                                        label={this.labels.HubLabelHost}
                                        searchText={this.state.hostSearchText}
                                        onChange={async text => this.setState({ hostSearchText: text })}
                                        onUserChange={user => this.handleTaskOwnerChange(user)}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.handleFilterModalClose()}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.handleFilterModalSubmit()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <CateringOrderDrawer
                        isDrawerOpen={this.state.isDrawerOpen}
                        onClose={() => this.setState({ isDrawerOpen: false })}
                        orderDetails={this.state.orderDetails}
                        activeStep={this.state.activeStep}
                        stepperInfo={this.state.stepperInfo}
                        statusChangedClicked={(type) => this.statusChangedClicked(type)}
                        editCateringOrder={() => this.editCateringOrder()}
                        orderStatus={this.cateringStatus.renderTaskOverAllStatus(this.state.orderDetails.cateringStatus)}
                    />
                    <div className="rightPanel">
                        <div className="page-height-exct-header">
                            {this.state.loading && <Spinner />}
                            <div className="rightPanel-main-content">
                                <IbssPageHeader
                                    title={this.state.listViewType === "allList" ? this.labels.HubMenuCateringOrders : this.labels.HubMenuCateringOrdersForToday}
                                    selectedPreset={this.state.daysFilter}
                                    presetChanged={(e) => this.handleDateRangeChange(e)}
                                    onLayoutChange={(newLayout) => this.changeLayout(newLayout)}
                                    layout={this.state.listViewType}
                                    toggleButtonList={layoutOptions}
                                    startDate={this.state.startDate.toJSDate()}
                                    endDate={this.state.endDate.toJSDate()}
                                    datesDisabled={this.state.dateDisabled}
                                    onStartDateChange={date => this.handleStartDateChange(date)}
                                    onEndDateChange={date => this.handleEndDateChange(date)}
                                    presets={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                />
                                {this.state.listViewType === "allList" &&
                                    <>
                                        <IbssFilter
                                            showExport={true}
                                            searchTerm={this.state.searchTerm}
                                            onSearchTermChange={event => this.handleSearchTermChange(event)}
                                            onFilterButtonClick={() => this.setState({ openFilterModal: true })}
                                            onExportButtonClick={() => { this.handleExportButtonClick() }}
                                        />
                                        <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                            <IbssActionButton
                                                buttons={actionButtons}
                                            />
                                        </Box>
                                    </>
                                }
                                {this.state.listViewType === "allList" &&
                                    <Box sx={{ mt: 1 }} className="table-panel">
                                        <IbssDataGrid
                                            checkboxSelection
                                            columns={this.dataGridColumns}
                                            disableRowSelectionOnClick
                                            rows={this.state.filteredCateringOrders}
                                            onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.handleSelectTasks(rowSelectionModel)}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 25 } },
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                        />
                                    </Box>
                                }
                                {this.state.listViewType === "todayList" &&
                                    <Box mt={2}>
                                        <Box className="table-panel">
                                            <Typography variant='h6'>{this.labels.HubMenuTodaysUpcomingOrders}</Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <IbssDataGrid
                                                    className='clickable-data-grid-row'
                                                    rows={this.state.upcomingCateringOrdersToday}
                                                    columns={this.dataGridColumns}
                                                    disableRowSelectionOnClick
                                                    hideFooter
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 3 }} className="table-panel">
                                            <Typography variant='h6'>{this.labels.HubMenuTodaysCompleteOrders}</Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <IbssDataGrid
                                                    className='clickable-data-grid-row'
                                                    rows={this.state.cateringCompletedOrders}
                                                    columns={this.dataGridColumns}
                                                    disableRowSelectionOnClick
                                                    hideFooter
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

export interface ICustomOrderDetails
{
    nodeId: number,
    orderId: string,
    OwnedBy: string,
    spaceName: string,
    cateringStatus: string,
    menuItems: IMenu_Items[],
    timeForDelivery: string,
    bookingStart: string,
    bookingId: string
}


interface IState
{
    loading: boolean;
    filteredCateringOrders: CateringOrderView[];
    cateringCompletedOrders: CateringOrderView[];
    upcomingCateringOrdersToday: CateringOrderView[];
    isCreateRight: boolean;
    isAssignRight: boolean;
    isUpdateRight: boolean;
    isCancelRight: boolean;
    isSetInProgressRight: boolean;
    buildingId: number;
    startDate: DateTime;
    endDate: DateTime;
    openUserModal: boolean;
    openMessageModal: boolean;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    selectedOrderIds: string[];
    actionType: string;
    daysFilter: string;
    dateDisabled: boolean;
    searchTerm: string;
    openFilterModal: boolean;
    statusValue: string;
    disableCancel: boolean;
    disableAssign: boolean;
    disableApprove: boolean;
    disableDecline: boolean;
    approveCateringRightInBuilding: boolean;
    selectedUser: { label?: string, userName: string },
    listViewType: string;
    isDrawerOpen: boolean;
    stepperInfo: string[];
    activeStep: number;
    orderDetails: ICustomOrderDetails;
    hostSearchText: string;
    hostEmail: string;
    showPreparingExportPopup: boolean;
}

export interface IFilter
{
    buildingId: number;
    startDate: DateTime;
    endDate: DateTime;
    statusValue?: string;
    owner?: string;
}

export interface IMenu_Items
{
    id: string;
    name: string;
    classification: string;
    quantity: number;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}


export class CateringOrderView
{
    public nodeId: number;
    public id: number;
    public bookingName: string;
    public CreatedBy: string;
    public status: string;
    public spaceName: string;
    public spaceId: string;
    public cateringServiceTime: string;
    public cateringClearingTime: string;
    public bookingStart: string;
    public bookingEnd: string;
    public orderId: string;
    public menuItem: IMenuItem[];
    public bookingId: string;
    public ownerEmail: string;
    public ownerId: string;

    constructor(value: CateringOrder)
    {
        this.nodeId = value.Node_Id;
        this.id = value.ID;
        this.bookingName = value.Booking_Name;
        this.CreatedBy = value.CreatedBy;
        this.spaceName = value.Space_Name;
        this.spaceId = value.Space_Id;
        this.cateringServiceTime = value.Catering_Service_Time.toISO();
        this.cateringClearingTime = value.Catering_Clearing_Time.toISO();
        this.bookingStart = value.Booking_Start.toISO();
        this.bookingEnd = value.Booking_End.toISO();
        this.status = value.Catering_Order_Status;
        this.orderId = value.Order_Id;
        this.menuItem = value.Menu_Items;
        this.bookingId = value.Booking_Id;
        this.ownerEmail = value.OwnedBy;
        this.ownerId = value.OwnedById;
    }
}

export interface IStatusOption
{
    value: string;
    label: string;
}
