import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class AddNewOrderWidget extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div onClick={() => this.props.onClick()} style={{ width: '100%', height: '100%', border: '1px dashed grey', borderRadius: '8px', cursor: 'pointer' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <div style={{ height: 28 }} className='mr-1'>
                        <AddCircleOutlineIcon />
                    </div>
                    {this.labels.funcEditCateringOrderAddNewOrder_S}
                </div>
            </div>
        )
    }
}

export default AddNewOrderWidget;

export interface IProps
{
    onClick: () => void;
}

export interface IState
{
}