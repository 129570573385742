import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card } from "@mui/material";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { appContext } from "../../../../AppContext";
import IbssTransferList from "../../../../Components/Data/TransferList/IbssTransferList";
import React from "react";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import apis from "../../../../Providers.Api/apis";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { Modal } from 'react-bootstrap';
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssInputDropDown, { ISelectOption } from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { ComponentHelper } from "../../../../Common/ComponentHelper";
import { AxiosResponse } from "axios";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";

class AssignBookingPolicyToSpace extends Component<RouteComponentProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get apiCache() {return appContext().apiCache;}

    private transferListRef: React.RefObject<IbssTransferList>;
    private userCanUpdate: boolean;
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.userCanUpdate = this.localStorage.hasRight('DATAMODEL.BookingPolicies.Update');
        this.state =
        {
            allSpaces: [],
            loading: false,
            showFilterModal: false,
            spaceTypeValue: null,
            floorValue: null,
            zoneValue: null,
            spaceLists: [],
            filter:
            {
                searchText: null,
                spaceType: null,
                floor: null,
                zone: null
            }
        };
        this.transferListRef = React.createRef();
    }

    public async componentDidMount(): Promise<void>
    {
        const spaces = await this.apiCache.getSpacesByBuilding(this.appState.buildingId);
        this.setState({
            spaceLists: spaces
        })
        this.getAllSpaces();
    }

    private getAllSpaces(): void
    {
        const availableSpaces = this.localStorage.getNodeData();
        let buildingData = {} as IBuildingData;
        const buildings = availableSpaces.Regions.map(x => x.Buildings);

        for (let i = 0; i < buildings.length; i++)
        {
            const building = buildings[i].filter(building => building.Node_Id === this.appState.buildingId);
            if (building.length > 0)
            {
                buildingData = building[0];
                break;
            }
        }

        const buildingFloorIds = buildingData.Floors.map(floor => floor.Node_Id);
        const buildingSpaces = this.state.spaceLists.filter(space => buildingFloorIds.includes(space.Node_Id));

        const spacesWithDetails = buildingSpaces.map(space =>
        ({
            id: space.Space_Id,
            bookingPolicyId: space.Booking_Policy_Id,
            spaceType: space.Space_Type,
            spaceName: space.Space_Name,
            buildingName: buildingData.Name,
            metaLocZone: space.Meta_Loc_Zone,
            floorName: buildingData.Floors.filter(x => x.Node_Id === space.Node_Id)[0].Node_Name,
        }) as ISpaceDetails);

        this.setState({
            allSpaces:spacesWithDetails
        })
        
    }

    private formatSpacesForList(spaces: ISpaceDetails[]): IListItem[]
    {
        return spaces.map(space =>
        ({
            primaryText: `${space.spaceType}, ${space.spaceName}`,
            secondaryText: `${space.buildingName} | ${space.floorName} ${space.metaLocZone ? '|' : ''} ${space.metaLocZone}`,
            disabled: space.bookingPolicyId == (this.props.match.params as IQueryParams).bookingPolicyId
        }) as IListItem);
    }

    private async saveClicked(): Promise<void>
    {
        const transferListState = this.transferListRef.current?.state;
        const transferListRight = transferListState?.right.map(x => x.primaryText);
        const selectedIds = this.state.allSpaces.filter(x => transferListRight?.includes(`${x.spaceType}, ${x.spaceName}`)).map(x => x.id);
        this.setState({ loading: true });
        const bookingPolicyId = (this.props.match.params as IQueryParams).bookingPolicyId;

        await this.apiClient.bookingPolicies.assignBookingPolicyToSpaces(this.appState.buildingId, bookingPolicyId, selectedIds);

        // todo: cache spaces as Booking_Policy_Id needs updating
        const spaceList = await this.apiCache.getSpacesByBuilding(this.appState.buildingId);

        const spaces = spaceList.map(item =>
        {
            item.Space_Type_Label = (item.Space_Type_Label === "" ? item.Space_Type : item.Space_Type_Label);
            return item;
        });

        this.setState({
            spaceLists:spaces
        })

        this.setState({ loading: false });
        this.apiCache.clearSpacesByBuildingId(this.appState.buildingId);
        this.props.history.push("/booking-policies/" + this.appState.buildingId);
    }

    private getDropDownValues(values: string[]): ISelectOption[]
    {
        let uniqueValues = values.filter((element: string, index: number) => (values.indexOf(element) === index));
        let filterOptions = uniqueValues.map(x => ({ value: x, label: x }))
        filterOptions = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterOptions]
        return filterOptions;
    };

    private async searchFilterChanged(value: string): Promise<void>
    {
        await this.setStateAsync({ filter: { ...this.state.filter, searchText: value } });
        this.filterResults()
    }

    private async filterResults(): Promise<void>
    {
        const { spaceTypeValue, floorValue, zoneValue } = this.state
        const transferListState = this.transferListRef;

        await this.setStateAsync({ filter: { ...this.state.filter, spaceType: spaceTypeValue === 'Any' ? null : spaceTypeValue, floor: floorValue === 'Any' ? null : floorValue, zone: zoneValue === 'Any' ? null : zoneValue } })
        let spaces = this.state.allSpaces;

        const filteredSpaces = spaces.filter(x =>
            (this.state.filter.spaceType ? x.spaceType == this.state.filter.spaceType : true)
            && (this.state.filter.floor ? x.floorName == this.state.filter.floor : true)
            && (this.state.filter.zone ? x.metaLocZone == this.state.filter.zone : true)
            && (this.state.filter.searchText ? x.spaceName.includes(this.state.filter.searchText) : true)
        );

        this.setState({ showFilterModal: false });
        const transferListRight = transferListState.current?.state.right.map(x => x.primaryText);
        transferListState.current?.setState({ left: this.formatSpacesForList(filteredSpaces.filter(x => !transferListRight?.includes(`${x.spaceType}, ${x.spaceName}`))), checked: [] });
    }


    public render(): JSX.Element
    {
        const defaultUnselectedSpaces = this.state.allSpaces.filter(space => space.bookingPolicyId != (this.props.match.params as IQueryParams).bookingPolicyId);
        const defaultSelectedSpaces = this.state.allSpaces.filter(space => space.bookingPolicyId == (this.props.match.params as IQueryParams).bookingPolicyId);

        return (
            <div className="d-flex justify-content-center align-items-center page-height-exct-header">
                {
                    this.state.loading ?
                    <div className="rightPanel-main-content">
                        <div className="text-ui-md">{this.labels.HubLabelAssigningSpacesMessage}</div>
                        <div style={{ position: 'relative', top: '40px'}}><Spinner/></div>
                    </div>
                        :
                        <div className="rightPanel-main-content w-100">
                            <Card className="table-panel">
                                <div>
                                    <div className='d-flex'>
                                        <div className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </div>
                                        <div className='table-panel-header' style={{ paddingTop: '4px' }}>
                                            {this.labels.HubLabelAssignPolicy}
                                        </div>
                                    </div>
                                    <div className="table-panel-description">
                                        {this.labels.HubLabelAssignPolicySubText}
                                    </div>
                                    <div className='d-flex mb-1'>
                                        <IbssTextField size="small" label={this.labels.HubLabelMySearchLabel} onChange={e => this.searchFilterChanged(e.target.value)} value={this.state.filter.searchText} />
                                        <div style={{ alignSelf: 'center', paddingLeft: '8px' }}>
                                            <IbssButton fullWidth={false} style={{ minWidth: '100px', height: '40px' }} variant="contained" onClick={() => this.setState({ showFilterModal: true })}>{this.labels.HubLabelFilter}</IbssButton>
                                        </div>
                                    </div>
                                    <IbssTransferList
                                        ref={this.transferListRef}
                                        left={this.formatSpacesForList(defaultUnselectedSpaces)}
                                        right={this.formatSpacesForList(defaultSelectedSpaces)}
                                        listWidth='auto'
                                        listHeight='60vh'
                                    />
                                </div>
                            </Card>
                            <div style={{ float: 'right', padding: '10px 0' }}>
                                <div>
                                    <IbssButton disabled={this.state.loading} style={{ minWidth: '100px' }} className='mr-2' variant="contained" color='secondary' onClick={() => this.props.history.push("/booking-policies/" + this.appState.buildingId)}>{this.labels.HubButtonCancel}</IbssButton>
                                    {this.userCanUpdate && <IbssButton disabled={this.state.loading} style={{ minWidth: '100px' }} variant="contained" onClick={() => this.saveClicked()}><div>{this.labels.HubButtonSave}</div></IbssButton>}
                                </div>
                            </div>
                            <Modal show={this.state.showFilterModal} onHide={() => this.setState({ showFilterModal: false })}>
                                <Modal.Header>
                                    <Modal.Title>{this.labels.HubLabelFilter}</Modal.Title>
                                    <button type="button" className="close" onClick={() => this.setState({ showFilterModal: false })} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Modal.Header>
                                <div style={{ paddingTop: '5px', paddingLeft: '58px' }} className="mb-4">
                                    {this.labels.HubLabelAssignPolicyFilterText}
                                </div>
                                <div style={{ paddingLeft: '59px' }}>
                                    <IbssFormControl style={{ width: '500px', paddingBottom: '8px' }} >
                                        <IbssInputDropDown
                                            onChange={e => { this.setState({ spaceTypeValue: e.target.value }) }}
                                            inputLabel={this.labels.HubLabelSpaceType}
                                            id='spaceType'
                                            options={this.getDropDownValues(this.state.allSpaces.map(x => x.spaceType).sort())}
                                            defaultValue={this.state.filter.spaceType ?? ''}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl style={{ width: '500px', paddingBottom: '8px' }} >
                                        <IbssInputDropDown
                                            onChange={e => { this.setState({ floorValue: e.target.value }) }}
                                            inputLabel={this.labels.HublabelFloor}
                                            id='floor'
                                            options={this.getDropDownValues(this.state.allSpaces.map(x => x.floorName))}
                                            defaultValue={this.state.filter.floor ?? ''}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl style={{ width: '500px', paddingBottom: '8px' }} >
                                        <IbssInputDropDown
                                            onChange={e => { this.setState({ zoneValue: e.target.value }) }}
                                            inputLabel={this.labels.HubLabelZone}
                                            id='zone'
                                            options={this.getDropDownValues(this.state.allSpaces.map(x => x.metaLocZone))}
                                            defaultValue={this.state.filter.zone ?? ''}
                                        />
                                    </IbssFormControl>
                                </div>
                                <Modal.Footer>
                                    <div style={{ textAlign: 'center' }}>
                                        <IbssButton
                                            style={{ height: '45px', minWidth: '100px' }}
                                            variant="contained"
                                            onClick={() => this.filterResults()}
                                        >
                                            {this.labels.HubLabelOk}
                                        </IbssButton>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                }
            </div>
        )
    }
}

export default AssignBookingPolicyToSpace;

export interface IState
{
    allSpaces: ISpaceDetails[],
    spaceTypeValue: string | null,
    floorValue: string | null,
    zoneValue: string | null,
    filter: IFilter,
    loading: boolean,
    showFilterModal: boolean
    spaceLists: Space[],
}

interface IListItem
{
    primaryText: string,
    secondaryText: string,
}

interface ISpaceDetails
{
    id: string,
    bookingPolicyId: string,
    spaceType: string,
    spaceName: string,
    buildingName: string,
    metaLocZone: string,
    floorName: string,
}

interface IBuildingData
{
    Node_Id: number,
    Name: string,
    Floors: IFloors[],
}

interface IFloors
{
    Node_Id: number,
    Node_Name: string
}

interface IFilter
{
    searchText: string | null,
    spaceType: string | null,
    floor: string | null,
    zone: string | null,
}

interface IQueryParams
{
    bookingPolicyId: string;
}
