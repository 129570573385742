import { DateHelper } from "../../../Common/DateHelper";
import { ODataSelect } from "../../../Providers.Api/ODataQuery";
 
export class SystemFilter extends ODataSelect
{
    public ConcurrencyStamp? = '';
    public CreatedByUserId? = '';
    public CreatedAt = '';
    public EventType_Id = 0;
    public Filter = '';
    public Filter_Id? = '';
    public ModifiedByUserId? = '';
    public ModifiedAt = '';
    public Name = '';
    public Node_Id = 0;
    public OwnedByRoleId = '';
    public OwnedByUserId = '';
    public Status = '';
    public TenancyId? = '';
    public Building_Bypass = false;
}