import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IbssRequestConfig } from "../ApiClient";

export class GetFileAsBlobEndpoint implements IGetFileAsBlobEndpoint
{
    public async execute(path: string, suppressErrorPopup: boolean): Promise<Blob>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup, responseType: "blob" };
            const response: AxiosResponse<BlobPart> = await axios.get(`${apis.dataEntryWebApi}/file/Download/${path}`, axiosConfig);
            let blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
            return blob;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetFileAsBlobEndpoint
{
    execute(path: string, suppressErrorPopup: boolean): Promise<Blob>;
}
