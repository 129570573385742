import { Box, FormControl, Grid, TextField } from '@mui/material'
import React, { Component } from 'react'
import { appContext } from '../../../AppContext'
import IbssButtonRedo from '../../Buttons/Button/IbssButton'

export default class IbssFilter extends Component<ISearchFilterProps>
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        const url = window.location.href;
        const path = new URL(url).pathname;

        return (
            <>
                <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                    <Grid item xs={6}>
                    <Box
                        sx={{
                            '& > :not(style)': { mr: 2,my:1,alignItems:"center" }
                        }}
                    >
                        <FormControl variant="outlined" sx={{mr:2}}>
                            <TextField
                                type="text"
                                size="small"
                                placeholder={this.labels.HubLabelSearch}
                                value={this.props.searchTerm}
                                onChange={(event) =>
                                    this.props.onSearchTermChange(event)
                                }
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            />
                        </FormControl>
                        {this.props.onFilterButtonClick && (
                            <IbssButtonRedo
                                onClick={this.props.onFilterButtonClick}
                                variant="contained"
                            >
                                {this.labels.HubLabelFilter}
                            </IbssButtonRedo>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right',pt:'0' }}>
                <Box
                    sx={{
                        '& > :not(style)': {my:1,alignItems:"center" },
                    }}
                >
                {this.props.onExportButtonClick && this.props.showExport === true &&
                        <IbssButtonRedo
                            onClick={this.props.onExportButtonClick}
                            variant="contained"
                            color="secondary"
                        >
                            {this.labels.HubButtonExport}
                        </IbssButtonRedo>
                }
                </Box>
                </Grid>
                </Grid>
            </>
        )
    }
};

export interface ISearchFilterProps
{
    showExport?: boolean;
    searchTerm: string;
    onSearchTermChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onFilterButtonClick?: () => void;
    onExportButtonClick?: () => void;

}
