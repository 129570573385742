import { appContext } from '../../../../AppContext';
import IbssPageHeader, { StartOrEnd } from '../../../../Components/Forms/DateRange/IbssPageHeader';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, CircularProgress, SelectChangeEvent, Typography } from '@mui/material';
import { BookingFilter } from './BookingFilter';
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';
import { DateHelper } from '../../../../Common/DateHelper';
import { Filter } from '../../../../Providers.Api/Users/UsersRepository';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DisketteSave from '../../../../Components/Icons/DisketteSave';
import AddUser from '../../../../Components/Icons/AddUser';

class OrphanedBookingService 
{
    public async execute(floorNodeId: number, bookingID: string, payload: IOrphanedBooking, hasV2Rights: boolean): Promise<void>
    {
        if (hasV2Rights)
        {
        return await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.patch
        ({
            nodeId: floorNodeId,
            bookingid: bookingID,
            body: payload as any // todo: remove 'any' and fix payload
        });

        } else
        {
        return await appContext().ibssApiClientV1.v1.byNodeid.bookings.byBookingId.put
        ({
            nodeId: floorNodeId,
            bookingId: bookingID,
            body: payload
        });
        }
    }

}
class ListDeletedUsers extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }
    private get services() { return appContext().services; }
    private get hasDeleteRights() { return appContext().localStorageProvider.hasRight("DATAMODEL.Bookings.Delete"); }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }
    private orphanedBookingService = new OrphanedBookingService();

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingid: this.appState.buildingId,
            loading: false,
            startDate: DateTime.now().startOf('month').minus({ months: 1 }),
            endDate: DateTime.now().startOf('month'),
            dateDisabled: true,
            searchTerm: '',
            disableDelete: true,
            deletedInValue: this.labels.funcOrphanedUsersLastMonth_S,
            tableRowData: [],
            showUserBookingModal: false,
            fetchingUserBookings: false,
            selectedUser: {
                id: '',
                displayName: '',
                email: '',
                deletedAt: DateTime.now()
            },
            userBookingsRowData: [],
            selectedBookingIds: [],
            layout:'',
            searchUser: {} as ISearchUser,
            newOwner: {} as INewOwner,
            deleteInProgress: false,
            currentBookingId: ''
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcOrphanedBookings_S;

        this.getDeletedUsers();
    }

    private async getDeletedUsers(): Promise<void>
    {
        this.setState({ loading: true });
        const filter = new Filter({ deletedAfter: this.state.startDate, deletedBefore: this.state.endDate })
        try
        {
            const deletedUsers = await this.apiClient.users.getDeletedUsers(filter);
            const rowData = deletedUsers.map(x => ({
                id: x.Email,
                displayName: x.DisplayName,
                email: x.Email,
                deletedAt: DateTime.fromISO(x.DeletedAt).offsetTimeByNode(this.state.buildingid, true),
            }))
            this.setState({ loading: false, tableRowData: rowData });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private filterUsers(searchTerm: string): IDeletedUserView[]
    {
        const filteredTasks = this.state.tableRowData.filter(user =>
        {
            let key: keyof IDeletedUserView;
            for (key in user)
            {
                if (user[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async showUserBookingsModal(selectedUser: IDeletedUserView): Promise<void>
    {
        this.setState({ showUserBookingModal: true, fetchingUserBookings: true, selectedUser: selectedUser });

        try
        {
            var query = new ODataQuery
                ({
                    nodeId: this.state.buildingid,
                    top: 100,
                    filter: new BookingFilter
                    ({
                        bookingOwnerEmail: selectedUser.email,
                        statuses: ['Approved', 'Awaiting Approval']
                    })
                });
                const userBookings = await this.services.bookings.getBookings(
                    query,
                    selectedUser.deletedAt.setZoneByNode(this.state.buildingid, true),
                    undefined
                );
            const bookingsRowData = userBookings.value.map(x =>
            {
                return (
                    {
                        id: x.Booking_Id,
                        spaceName: x.Space_Name,
                        bookingId: x.Booking_Id,
                        bookingName: x.Booking_Name,
                        bookingStart: x.Booking_Start.offsetTimeByNode(this.state.buildingid, true),
                        bookingParties: x.Booking_Parties,
                        floorNodeId: x.Node_Id,
                        isDeleted: '',
                        isOwnerChanged: ''
                    }
                )
            })
            this.setState({ fetchingUserBookings: false, userBookingsRowData: bookingsRowData });
        } catch (error)
        {
            this.setState({ fetchingUserBookings: false });
        }
    }

    private closeUserBookingsModal(): void
    {
        this.setState({ showUserBookingModal: false, selectedBookingIds: [], layout: '' })
    }

    private async deleteBookings(): Promise<void>
    {
        this.setState({deleteInProgress: true });
    
        const selectedData: IBooking[]  = [];
        for(let i = 0; i<= this.state.selectedBookingIds.length -1; i++)
        {
            const booking = this.state.userBookingsRowData.find(x => x.bookingId === this.state.selectedBookingIds[i]);
            if(booking)
            {
                selectedData.push(booking);
            }
        }

        if(selectedData.length > 0)
        {
            for (let index = 0; index <= selectedData.length-1; index++) 
            {
                try 
                {
                    await this.services.bookings.delete(Number(selectedData[index]["floorNodeId"]), selectedData[index]["bookingId"], true);
                    this.updateBookingsRowData(selectedData[index]["bookingId"], 'success');
                } 
                catch (error) 
                {
                    this.updateBookingsRowData(selectedData[index]["bookingId"], 'error');
                }
            }
        }

        this.setState({deleteInProgress:false})
    }

    private updateBookingsRowData(bookingId: string, status: string): void 
    {
        const updatedBookingsRowData = this.state.userBookingsRowData.map(booking => {
            if (booking.bookingId === bookingId) 
            {
                if (this.state.layout === 'owner') 
                {
                    return { ...booking, isownerChanged: status };
                } 
                else 
                {
                    return { ...booking, isDeleted: status };
                }
            }
            return booking;
        });

        this.setState({ userBookingsRowData: updatedBookingsRowData });
    }

    private async dateRangeDropdownChanged(selectedOption: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ deletedInValue: selectedOption.target.value });

        if (selectedOption.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month'),
                    endDate: DateHelper.now().startOf('month').plus({ months: 1 }),
                    dateDisabled: true,
                });
        }
        if (selectedOption.target.value === this.labels.funcOrphanedUsersLastMonth_S)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month').minus({ months: 1 }),
                    endDate: DateHelper.now().startOf('month'),
                    dateDisabled: true,
                });
        }
        if (selectedOption.target.value === this.labels.HubLabelCustom)
        {
            await this.setState({ dateDisabled: false });
        }

        this.getDeletedUsers();
    }

    private async startDateChanged(date: DateTime): Promise<void>
    {
        await this.setStateAsync({ startDate: date });
        this.getDeletedUsers();
    }

    private async endDateChanged(date: DateTime): Promise<void>
    {
        await this.setStateAsync({ endDate: date });
        this.getDeletedUsers();
    }

    private  async selectNewOwner(newValue: UserPickerModule.IUser | null, bookingId: string): Promise<void>
    {
        this.setState(prevState => ({
            newOwner: {
              ...prevState.newOwner,
              [bookingId]: newValue
            }
          }));
    }

    private  async searchUser(text: string, bookingId: string): Promise<void>
    {
        this.setState(prevState => ({
            searchUser: {
              ...prevState.searchUser,
              [bookingId]: text
            }
          }));
    }

    private async changeOwner(bookingID: string, floorNodeId: number): Promise<void> 
    {
        this.setState({ deleteInProgress: true, currentBookingId:bookingID})

        const newOwner = this.state.newOwner[bookingID];
        const newOwnerEmail =  newOwner? newOwner.email : '';

        const payload: IOrphanedBooking = {
            OnBehalfOf: newOwnerEmail,
        };       

        try 
        {
            await this.orphanedBookingService.execute (floorNodeId, bookingID, payload, this.hasV2Rights);
           
            this.updateBookingsRowData(bookingID, 'success');
        } 
        catch (error) 
        {
            this.updateBookingsRowData(bookingID, 'error');
        }

        this.setState({ deleteInProgress: false})
    }

    private changeLayout (layout:string): void
    {
        const { userBookingsRowData, selectedBookingIds } = this.state;

       
        const updatedUserBookingsRowData = userBookingsRowData.filter(
            booking => !selectedBookingIds.includes(booking.bookingId)
        );
    
    
        this.setState({
            userBookingsRowData: updatedUserBookingsRowData,
            layout: layout
        });
    }

    private shouldDisableDeleteButton(): boolean 
    {
        return this.state.selectedBookingIds.every(id =>
            this.state.userBookingsRowData.some(booking =>
                booking.bookingId === id && booking.isDeleted !== ''
            )
        );
    }
    
    public render(): JSX.Element 
    {

        const dataGridColumns: GridColDef[] = 
        [
            {
                headerName: this.labels.HubLabelName,
                field: "displayName",
                minWidth: 150,
                flex: 1,
            },
            {
                headerName: this.labels.funcOrphanedUsersFutureBookings_S,
                field: "FutureBooking",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => <div onClick={() => this.showUserBookingsModal(params.row)} style={{ textDecoration: 'underline', color: '#3f51b5c4', cursor: 'pointer' }}>{this.labels.funcOrphanedUsersView_S}</div>
            },
            {
                headerName: this.labels.HubLabelDeletedAt,
                field: "deletedAt",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => params.row.deletedAt.toLocaleString(DateTime.DATE_SHORT)
            },
        ];

        const userBookingsDataGridColumns: GridColDef[] = 
        [
            {
                headerName: this.labels.HubLabelName,
                field: "bookingName",
                minWidth: 150,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelDate,
                field: "bookingStart",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => params.row.bookingStart.toLocaleString(DateTime.DATE_SHORT)
            },
            {
                headerName: this.labels.HubLabelSpaceName,
                field: "spaceName",
                minWidth: 150,
                flex: 1
            },
            {
                headerName: this.labels.HubLabelAttendees,
                field: "attendees",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    
                  const attendeesCount = params.row.bookingParties.filter((x: { Booking_Participant_Type: number; }) => x.Booking_Participant_Type == 1 || x.Booking_Participant_Type == 2).length == 0 ? '-' : params.row.bookingParties.length
                  const shouldShowIcon = params.row.isDeleted;
                  const showCircularProgress = this.state.deleteInProgress && this.state.selectedBookingIds.includes(params.row.bookingId);

                  return (
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                        <Box>
                            {attendeesCount}
                        </Box>
                        <Box>
                            {shouldShowIcon === 'success' && (
                                <CheckCircleIcon color="success" sx={{ marginTop: '10px' }} />
                            )}
                            {shouldShowIcon === 'error' && (
                                <ErrorIcon color="error" sx={{ marginTop: '10px' }} />
                            )}
                        </Box>

                        {(showCircularProgress && !shouldShowIcon) &&
                            <CircularProgress size={20} />
                        }
                    </Box>
                  );
                },
            }
        ];   

        if (this.state.layout === "owner")
        {
            userBookingsDataGridColumns.push(
                {
                    headerName: this.labels.funcNewOwner_S,
                    field: "NewOwner",
                    minWidth: 300,
                    flex: 1,
                    renderCell: (params) => {
                        const bookingId = params.row.bookingId;
                        const searchText = this.state.searchUser[bookingId];
                        const newOwner = this.state.newOwner[bookingId];
                        const shouldShowIcon = params.row.isownerChanged;
                        const shouldShowProgress = this.state.deleteInProgress && bookingId === this.state.currentBookingId;
                        return ( 
                            <Box display="flex" width={"100%"} my={1} alignItems={'center'}>                          
                                <UserPicker
                                    disabled = {params.row.isownerChanged === 'success'}
                                    searchText={searchText}
                                    onChange={async (text) => this.searchUser(text, bookingId)}
                                    onUserChange={(user) => this.selectNewOwner(user, bookingId)}
                                    width={"100%"}
                                />
                                <Box ml={1} my={1}>
                                    <IbssButton 
                                        variant='contained'
                                        onClick={() => this.changeOwner(bookingId, params.row.floorNodeId)} size='small'
                                        startIcon={<DisketteSave />}
                                        disabled={!newOwner|| shouldShowIcon === 'success'}  
                                    />
                                    
                                </Box>
                                <Box ml={1} my={1}>
                                    {shouldShowIcon === 'success' && 
                                        <CheckCircleIcon color="success"/>
                                    }
                                    {shouldShowIcon === 'error' && 
                                        <ErrorIcon color="error" sx={{ marginTop: '10px' }} />
                                    }
                                    {shouldShowProgress &&                         
                                        <CircularProgress size={20} />
                                    }
                                </Box>
                            </Box>
                        );
                    
                    },
                },)
        }

        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => this.deleteBookings(),
                    disabled:  !this.hasDeleteRights || this.shouldDisableDeleteButton()
                },
                {
                    label: this.labels.funcChangeOwner_S,
                    icon: (
                        <IbssSvgIcon>
                            <AddUser />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => this.changeLayout("owner"),
                    disabled: !this.shouldDisableDeleteButton()
                },
            ];

        const filteredRowData = this.filterUsers(this.state.searchTerm);
        
        return (
            <>
                <div className="page-container">                   
                    <div className="rightPanel">
                        <div className="rightPanel-main-content">               
                            <div className="table-panel">
                                <IbssPageHeader
                                    title={this.labels.funcOrphanedUsers_S}
                                    presetsLabel={this.labels.funcOrphanedUsersDeletedIn_S}
                                    selectedPreset={this.state.deletedInValue}
                                    excludeToday={true}
                                    presetChanged={(e) => this.dateRangeDropdownChanged(e)}
                                    startDate={this.state.startDate.toJSDate()}
                                    endDate={this.state.endDate.toJSDate()}
                                    datesDisabled={this.state.dateDisabled}
                                    onStartDateChange={date => this.startDateChanged(date)}
                                    onEndDateChange={date => this.endDateChanged(date)}
                                    presets={[this.labels.funcOrphanedUsersLastMonth_S, this.labels.HubLabelThisMonth]}
                                />
                                <IbssFilter
                                    searchTerm={this.state.searchTerm}
                                    onSearchTermChange={(event) => this.setState({ searchTerm: event.target.value })}
                                />
                                <div className='mt-1 mb-5'>
                                    <Typography className="table-panel-description">{this.labels.funcOrphanedUsersRecords_Message}</Typography>
                                </div>
                                <div>
                                    <IbssDataGrid
                                        columns={dataGridColumns}
                                        disableRowSelectionOnClick
                                        rows={filteredRowData}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },
                                        }}
                                        pageSizeOptions={[25, 50, 100]}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>                      
                    </div>
                    {
                        this.state.showUserBookingModal &&
                        <IbssDialog
                            maxWidth='xl'
                            fullWidth
                            open={this.state.showUserBookingModal}
                            header={this.state.selectedUser.displayName + ' - ' + this.labels.HubLabelBookings}
                            onClose={() => this.closeUserBookingsModal()}
                            dialogContent={
                                <>
                                    {
                                        this.state.fetchingUserBookings ?
                                            <div style={{ height: '500px' }}>
                                                <Spinner />
                                            </div>
                                            :
                                            <div>
                                                {
                                                    this.state.userBookingsRowData.length > 0 ?
                                                        <div>
                                                            <Box my={2}>
                                                            <Typography variant='body1' className='ui-text-light'>{this.labels.funcOrphanedUsersBookingsInSystem_M}</Typography>
                                                            {
                                                                this.state.layout !== "owner" && 
                                                                <IbssActionButton buttons={actionButtons} />
                                                            }
                                                            </Box>
                                                            <IbssDataGrid
                                                                rowHeight={72}
                                                                height="calc(100vh - 420px)"
                                                                checkboxSelection={this.state.layout !== "owner"}
                                                                columns={userBookingsDataGridColumns}
                                                                disableRowSelectionOnClick
                                                                rows={this.state.userBookingsRowData}
                                                                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.setState({ selectedBookingIds: rowSelectionModel })}
                                                                rowSelectionModel={this.state.selectedBookingIds}
                                                                initialState={{
                                                                    pagination: { paginationModel: { pageSize: 25 } },
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]}
                                                                isRowSelectable={(params) => params.row.isDeleted === ''}                                             
                                                            />
                                                        </div>
                                                        :
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                                                            <div className="noResultsHeading">{this.labels.funcOrphanedUsersNoBookingsFound_M}</div>
                                                        </div>
                                                }
                                            </div>
                                    }
                                </>
                            }
                            footer=
                            {
                                <div className="d-flex justify-content-center">
                                    <IbssButton variant="contained" color="secondary" className="mr-2 modal-button" onClick={() => this.closeUserBookingsModal()}>{this.labels.HubLabelClose}</IbssButton>
                                </div>
                            }
                        />
                    }
                  
                </div>
            </>
        )
    }
}

export default ListDeletedUsers;

export interface IProps
{
}

export interface IState
{
    buildingid: number;
    loading: boolean;
    startDate: DateTime;
    endDate: DateTime;
    dateDisabled: boolean;
    searchTerm: string;
    disableDelete: boolean;
    deletedInValue: string;
    showUserBookingModal: boolean;
    fetchingUserBookings: boolean;
    selectedUser: IDeletedUserView;
    userBookingsRowData: IBooking[];
    tableRowData: IDeletedUserView[];
    selectedBookingIds: GridRowId[];
    layout: string;
    deleteInProgress: boolean;
    currentBookingId: string;
    searchUser:ISearchUser
    newOwner: INewOwner;
          
}

export interface IDeletedUserView
{
    id: string
    displayName: string,
    email: string,
    deletedAt: DateTime,
}

export interface IBooking
{
    spaceName: string;
    bookingId: string;
    bookingName: string;
    bookingStart: DateTime;
    bookingParties: string;
    isDeleted: string,
    isOwnerChanged: string
    floorNodeId?: number;
}

export interface IOrphanedBooking 
{
    OnBehalfOf: string
}

export type INewOwner = Record<string, UserPickerModule.IUser | null>;

export type ISearchUser = Record<string, string>;