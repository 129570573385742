import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';

class EditLayoutForm extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        const disableSave = this.props.nameAlreadyExists || this.props.name == '' || this.props.capacity < 1;
        return (
            <>
                <div>
                    <IbssTextField
                        error={this.props.nameAlreadyExists || this.props.name == ''}
                        helperText={this.props.nameAlreadyExists ? this.labels.funcSpaceLayoutsNameAlreadyExists_Message : this.labels.Hub_SpaceLayouts_LinkedSpaceConfigName}
                        className='mt-2'
                        variant='standard'
                        fullWidth
                        label={this.labels.funcSpaceLayoutName_S}
                        value={this.props.name}
                        onChange={e => this.props.onNameChange(e.target.value)}
                    />
                    <IbssTextField
                        error={this.props.capacity < 1}
                        helperText={this.props.capacity < 1 ? this.labels.funcSpaceLayoutMinimumCapacity_Message : ''}
                        InputProps={{ inputProps: { min: 1 } }}
                        className='mt-2'
                        variant='standard'
                        fullWidth
                        label={this.labels.HubLabelCapacity}
                        value={this.props.capacity}
                        type='number'
                        onChange={e => this.props.onCapacityChange(e.target.value)}
                    />
                    <IbssTextField
                        className='mt-2'
                        variant='standard'
                        fullWidth
                        label={this.labels.funcSpaceLayoutSetUpTime_S}
                        value={this.props.setup}
                        type='number'
                        onChange={e => this.props.onSetupChange(e.target.value)}
                    />
                    <IbssTextField
                        className='mt-2'
                        variant='standard'
                        fullWidth
                        label={this.labels.funcSpaceLayoutBreakdownTime_S}
                        value={this.props.breakdown}
                        type='number'
                        onChange={e => this.props.onBreakdownChange(e.target.value)}
                    />
                </div>
                <div className='d-flex justify-content-between mt-2'>
                    <IbssButton sx={{ width: '48%' }} color='secondary' variant='contained' onClick={() => this.props.onCancel()}>{this.labels.HubButtonCancel}</IbssButton>
                    {
                        this.props.newLayout ?
                            <IbssButton disabled={disableSave} sx={{ width: '48%' }} color='primary' variant='contained' onClick={() => this.props.onAddLayout()}>{this.labels.funcAddLayout_S}</IbssButton>
                            :
                            <IbssButton disabled={disableSave} sx={{ width: '48%' }} color='primary' variant='contained' onClick={() => this.props.onUpdateLayout()}>{this.labels.funcUpdateLayout_S}</IbssButton>
                    }

                </div>
            </>
        )
    }
}

export default EditLayoutForm;

export interface IProps
{
    newLayout: boolean;
    name: string;
    capacity: number;
    setup: number;
    breakdown: number;
    onNameChange: (value: string) => void;
    onCapacityChange: (value: string) => void;
    onSetupChange: (value: string) => void;
    onBreakdownChange: (value: string) => void;
    onCancel: () => void;
    onAddLayout: () => void;
    onUpdateLayout: () => void;
    nameAlreadyExists: boolean;
}

export interface IState
{
}