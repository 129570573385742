import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import { appContext } from '../../../../AppContext';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { Card } from '@mui/material';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmModal from '../../../../Components/Dialogs/ConfirmDialog/ConfirmModal';
import { RouteComponentProps } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { Building } from './DataModels';
import { PagedResponse } from '../../../../Providers.Api/Models';
import { GridColDef } from '@mui/x-data-grid';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import Helper from '../../../../Common/Helper';

class ListBuildings extends IbssPage<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            showDeleteConfirmationModal: false,
            selectedBuildingIds: [],
            loading: false,
            fetchingBuldingData: false,
            buildingData: []
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcBuildingSetup_S;
        await this.loadBuildingData();
    }

    private async loadBuildingData(): Promise<void>
    {
        this.setState({ fetchingBuldingData: true });
        try
        {
            const buildingData = await appContext().ibssApiClientV2.v2.configuration.nodes.get<Building[]>({
                top: 100,
                filter: `Level eq 'Building'`,
                select: Building
            });
            this.setState({ buildingData: buildingData, fetchingBuldingData: false });

        } catch (error)
        {
            this.setState({ fetchingBuldingData: false });
        }
    }

    private async deleteBuildings(): Promise<void>
    {
        this.setState({ showDeleteConfirmationModal: false, loading: true });
        try
        {
            let i = 0;
            while (i < this.state.selectedBuildingIds.length)
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.delete({ nodeId: parseInt(this.state.selectedBuildingIds[i]) });
                i = i + 1;
            }
            await this.loadBuildingData();
            this.setState({ loading: false, selectedBuildingIds: [] });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async disableBuildings(): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            let i = 0;
            while (i < this.state.selectedBuildingIds.length)
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.disable.patch({ nodeId: parseInt(this.state.selectedBuildingIds[i]) });
                i = i + 1;
            }
            await this.loadBuildingData();
            this.setState({ loading: false, selectedBuildingIds: [] });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async enableBuildings(): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            let i = 0;
            while (i < this.state.selectedBuildingIds.length)
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.enable.patch({ nodeId: parseInt(this.state.selectedBuildingIds[i]) });
                i = i + 1;
            }
            await this.loadBuildingData();
            this.setState({ loading: false, selectedBuildingIds: [] });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private renderStatus(building: Building): JSX.Element
    {
        let backgroundColor: string;
        switch (building.IsEnabled)
        {
            case 1:
                backgroundColor = '--ui-success-pastel';
                break;
            case 0:
                backgroundColor = '--ui-error-pastel';
                break;
            default:
                backgroundColor = '--ui-mid-tone';
                break;
        }

        return <IbssChip label={building.IsEnabled ? this.labels.HubLabelActive : this.labels.HubLabelDisabled}
            sx={{
                backgroundColor: `var(${backgroundColor})`,
                color: "var(ui-text)",
            }} />;
    }

    public render(): JSX.Element
    {
        const disableDisableButton = this.state.selectedBuildingIds.length == 0 || !this.localStorage.hasRight("DATAMODEL.Nodes.Update");
        const disableEnableButton = this.state.selectedBuildingIds.length == 0 || !this.localStorage.hasRight("DATAMODEL.Nodes.Update");
        const disableDeleteButton = this.state.selectedBuildingIds.length == 0 || !this.localStorage.hasRight("DATAMODEL.Nodes.Delete");
        const disableAddButton = !this.localStorage.hasRight("DATAMODEL.Nodes.Create");
        let buttons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: <IbssSvgIcon sx={{ marginTop: '2px' }}>{Icons.Bin}</IbssSvgIcon>,
                    color: 'error',
                    onClick: () => this.setState({ showDeleteConfirmationModal: true }),
                    disabled: disableDeleteButton
                },
                {
                    label: this.labels.HubLabelEnable,
                    icon: <CheckCircleIcon />,
                    color: 'primary',
                    onClick: () => this.enableBuildings(),
                    disabled: disableEnableButton
                },
                {
                    label: this.labels.HubLabelDisable,
                    icon: <CancelIcon />,
                    color: 'error',
                    onClick: () => this.disableBuildings(),
                    disabled: disableDisableButton
                },
                {
                    className: 'addIcon',
                    label: this.labels.HubLabelAddButton,
                    icon: <IbssSvgIcon>{Icons.AddIcon}</IbssSvgIcon>,
                    color: 'primary',
                    disabled: disableAddButton,
                    onClick: () => this.props.history.push(`/admin/buildings/new`),
                }
            ];

        const columns: GridColDef[] =
            [
                {
                    field: 'DisplayName',
                    headerName: this.labels.funcBuildingName_S,
                    width: 200,
                    flex: 1
                },
                {
                    field: 'ID',
                    headerName: this.labels.HubLabelId,
                    width: 200,
                    flex: 1
                },
                {
                    field: 'floors',
                    headerName: this.labels.HubMenuFloorLabel,
                    width: 200,
                    flex: 1,
                    renderCell: params => Helper.getFloorsByBuildingId(params.row.ID).length ?? 0
                },
                {
                    field: 'IsEnabled',
                    headerName: this.labels.HubLabelStatus,
                    width: 200,
                    flex: 1,
                    renderCell: params => this.renderStatus(params.row),
                },
            ];

        return (
            <>
                {
                    this.state.loading &&
                    <LoadingOverlay />
                }
                <Card className='m-3'>
                    <div className="rightPanel-main-content">
                        <div className='d-flex'>
                            <div className="mr-2" style={{ background: 'var(--ui-mid-tone)', borderRadius: '8px', padding: '5px 5px 2px 5px' }}>
                                <IbssSvgIcon fontSize='large'>
                                    {Icons.BuildingSetup}
                                </IbssSvgIcon>
                            </div>
                            <div className='table-panel-header' style={{ paddingTop: '4px' }}>
                                {this.labels.HubMenuBuildings}
                            </div>
                        </div>
                        <IbssActionButton buttons={buttons} />
                        <IbssDataGrid
                            className="pointer"
                            onRowClick={e => this.props.history.push(`/admin/buildings/${e.id}/edit`)}
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={e => this.setState({ selectedBuildingIds: e as string[] })}
                            rowSelectionModel={this.state.selectedBuildingIds}
                            checkboxSelection
                            columns={columns}
                            rows={this.state.buildingData}
                            loading={this.state.fetchingBuldingData}
                            getRowId={row => row.ID}
                        />
                    </div>
                </Card>
                <ConfirmModal
                    show={this.state.showDeleteConfirmationModal}
                    modalHeading={this.labels.funcDeleteBuildings_S}
                    modalMessage={this.labels.funcDeleteBuildingConfirm_Message}
                    handleModal={() => this.setState({ showDeleteConfirmationModal: false })}
                    okButton={() => this.deleteBuildings()}
                    cancelButtonText={this.labels.funcSpaceLayoutsNo_S}
                    confirmButtonColor={'error'}
                    confirmButtonText={this.labels.funcSpaceLayoutsYes_S}
                />
            </>
        )
    }
}

export default ListBuildings;

export interface IProps extends RouteComponentProps
{
}

export interface IState
{
    showDeleteConfirmationModal: boolean;
    selectedBuildingIds: string[];
    loading: boolean;
    fetchingBuldingData: boolean;
    buildingData: Building[];
}