import { DateTime } from "luxon";

export class Cache implements ICache
{
    private quickExpiryInSeconds = 60;
    private garbageCollectionIntervalInSeconds = 300;

    private async getDatabase(): Promise<IDBDatabase>
    {
        return new Promise((resolve, reject) =>
        {
            const openRequest = window.indexedDB.open("cache", 1);

            openRequest.addEventListener("upgradeneeded", e =>
            {
                const db: IDBDatabase = (e.target as any).result;
                const objectStore = db.createObjectStore("items", { keyPath: "key" });
                objectStore.createIndex("key", "key", { unique: true });
                objectStore.createIndex("value", "value", { unique: false });
                objectStore.createIndex("expires", "expires", { unique: false });
            });

            openRequest.addEventListener("success", () =>
            {
                const db = openRequest.result;
                resolve(db);
            });

            openRequest.addEventListener("error", () =>
            {
                reject(openRequest.error);
            });
        });
    }

    private async getItems(): Promise<IDBObjectStore>
    {
        const db = await this.getDatabase();
        const transaction = db.transaction("items", "readwrite");
        return transaction.objectStore("items");
    }

    public async lazyGet<TValue>(key: string, expires: DateTime, refresh: () => Promise<TValue>): Promise<TValue>
    {
        let value = await this.get<TValue>(key);
        if (value == null)
        {
            const refreshedValue = await refresh();
            await this.set(key, refreshedValue, expires);
            value = await this.get<TValue>(key) as Awaited<TValue>; // re-get the cached value so that we get rid of the prototype
        }
        return value;
    }

    public async get<TValue>(key: string): Promise<TValue | null>
    {
        const items = await this.getItems();
        const request = items.get(key);

        return new Promise((resolve, reject) =>
        {
            request.addEventListener("success", e =>
            {
                const item: (IItem<TValue> | null) = (e.target as any).result;
                if (item == null)
                {
                    resolve(null);
                }
                else
                {
                    const expiresTime = DateTime.fromISO(item.expires);
                    if (DateTime.now() >= expiresTime)
                    {
                        resolve(null);
                    }
                    else
                    {
                        resolve(item.value);
                    }
                }
            });

            request.addEventListener("error", e =>
            {
                reject(request.error);
            });
        });
    }

    public async set<TValue>(key: string, value: TValue, expires: DateTime): Promise<void>
    {
        const items = await this.getItems();
        const newItem: IItem<TValue> = { key: key, value: value, expires: expires.toISO() };
        const request = items.put(newItem);

        return new Promise((resolve, reject) =>
        {
            request.addEventListener("success", e =>
            {
                resolve();
            });

            request.addEventListener("error", e =>
            {
                reject(request.error);
            });
        });
    }

    public async clear(key: string): Promise<void>
    {
        const items = await this.getItems();
        const request = items.delete(key);

        return new Promise((resolve, reject) =>
        {
            request.addEventListener("success", e =>
            {
                resolve();
            });

            request.addEventListener("error", e =>
            {
                reject(request.error);
            });
        });
    }

    public async clearAll(): Promise<void>
    {
        const items = await this.getItems();
        const request = items.getAllKeys();

        return new Promise((resolve, reject) =>
        {
            request.addEventListener("success", e =>
            {
                const keys: string[] = (e.target as any).result;
                Promise.all(keys.map(i => this.clear(i))).then(() => resolve()).catch(i => reject(i));
            });

            request.addEventListener("error", e =>
            {
                reject(request.error);
            });
        });
    }

    public async clearExpired(): Promise<void>
    {
        const itemsStore = await this.getItems();
        const getRequest = itemsStore.getAll();

        return new Promise((resolve, reject) =>
        {
            getRequest.addEventListener("success", e =>
            {
                const target = (e.target as IDBRequest<IItem<unknown>[] | null>);
                const items = target.result;

                if (items == null)
                {
                    resolve();
                }
                else
                {
                    for (const item of items)
                    {
                        const expiresTime = DateTime.fromISO(item.expires);
                        if (DateTime.now() >= expiresTime)
                        {
                            itemsStore.delete(item.key);
                        }
                    }
                }
            });
            getRequest.addEventListener("error", e =>
            {
                reject(getRequest.error);
            });
        });
    }

    public startGarbageCollection(): void
    {
        setInterval(() => this.clearExpired(), 1000 * this.garbageCollectionIntervalInSeconds);
    }
}

interface IItem<TValue>
{
    key: string;
    value: TValue;
    expires: string;
}

export interface ICache
{
    lazyGet<TValue>(key: string, expires: DateTime, refresh: () => Promise<TValue>): Promise<TValue>;
    get<TValue>(key: string): Promise<TValue | null>;
    set<TValue>(key: string, value: TValue, expires: DateTime): Promise<void>;
    clear(key: string): Promise<void>;
    clearAll(): Promise<void>;
    clearExpired(): Promise<void>;
    startGarbageCollection(): void
}
