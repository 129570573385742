import AccessDenied from "../../../Pages/Shared/AccessDenied";
import Home from "../../../Pages/Shared/Home/Home";
import { IRoute } from "./IbssRouter";

export default class SharedRoutes
{
    public routes: IRoute[] = [
        {
            requiredRights: [],
            path: "/access-denied",
            component: AccessDenied
        },
        {
            requiredRights: [],
            path: "/",
            component: Home
        },
    ];
}
