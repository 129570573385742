import { Component } from 'react';

export default class KiskoIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 36 36" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 4C5.25 1.92893 6.92893 0.25 9 0.25H24C26.0711 0.25 27.75 1.92893 27.75 4V34H26.25V4C26.25 2.75736 25.2426 1.75 24 1.75H9C7.75736 1.75 6.75 2.75736 6.75 4V34H5.25V4Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3.25H24.75V22.75H8.25V3.25ZM9.75 4.75V21.25H23.25V4.75H9.75Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 24.25H24.75V28.75H8.25V24.25ZM9.75 25.75V27.25H23.25V25.75H9.75Z" fill="inherit" />
            </svg>
        )
    }
};