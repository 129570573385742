import { appContext } from "../../../../AppContext";
import { RouterProps } from "react-router";
import Booking from "./homeBookings.png";
import Catering from "./homeCatering.png";
import Visitors from "./homeVisitor.png";
import Space from "./homeBookSpace.png";
import { Box, Grid } from "@mui/material";
import {Typography} from "@mui/material";
import { IUserRights } from "../../../../Providers.Api/Models.UserApi";
import IbssHomeCard from "../../../../Components/Cards/HomePageCard/IbssHomeCard";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { SearchSpacesHelper } from "../../../Shared/Spaces/Search/SearchSpacesHelper";

class OnelensHome extends IbssPage<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; } 
    private get labels() { return appContext().labels; }
    private rights: IUserRights;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingId: this.appState.buildingId,           
        }

        this.rights = this.local.getUserDetails().userRights;
    }

    public componentDidMount(): void
    {
        this.pageTitle = this.labels.HubMenuHome;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    }

    public render(): JSX.Element
    {
        const displayName = this.local.getUserDetails().displayName;
        const buildingName = this.appState.buildingName;

        const callToActions: ICallToAction[] = [
            {
                id: '1',
                title: this.labels.funcBookingHomeSpace_S,
                description: this.labels.funcBookingHomeSpace_L,
                buttonLabel: this.labels.funcBookingHomeSpaceStart_S,
                src: Space,
                imageAlt: this.labels.funcBookingHomeSpace_S,
                route: `/one-lens/spaces/search?${SearchSpacesHelper.buildQuery(this.state.buildingId)}`,
                permissions: this.rights?.FLEX?.Search?.includes?.("Spaces") ?? false,
            },
            {
                id: '2',
                title: this.labels.HubMenuVisitors,
                description: this.labels.funcVisitorsHomeView_L,
                buttonLabel: this.labels.funcVisitorsHomeView_S,
                src: Visitors,
                imageAlt: this.labels.HubMenuVisitors,
                route:`/operational-services-visitors/${this.state.buildingId}`,
                permissions: this.rights && this.rights.ONELENS360 && this.rights.ONELENS360.OperationalServices && this.rights.ONELENS360.OperationalServices.indexOf("Visitors") > -1,
            },
            {
                id: '3',
                title: this.labels.HubLabelBookings,
                description: this.labels.funcBookingHomeView_L,
                buttonLabel: this.labels.funcBookingHomeView_S,
                src: Booking,
                imageAlt: this.labels.HubLabelBookings,
                route:`/operational-services-bookings/${this.state.buildingId}/0`,
                permissions: this.rights && this.rights.ONELENS360 && this.rights.ONELENS360.OperationalServices && this.rights.ONELENS360.OperationalServices.indexOf("Bookings") > -1,
            },
            {
                id: '4',
                title: this.labels.HubLabelCateringTable,
                description: this.labels.funcCateringOrdersHomeView_L,
                buttonLabel: this.labels.funcCateringOrdersHomeView_S,
                src: Catering,
                imageAlt: this.labels.HubLabelCateringTable,
                route:`/catering-orders/${this.state.buildingId}`,
                permissions: this.rights && this.rights.ONELENS360 && this.rights.ONELENS360.OperationalServices && this.rights.ONELENS360.OperationalServices.indexOf("Catering") > -1,
            },
            
        ]

        return (
            <div className="rightPanel-main-content">
                <Box 
                    sx={theme => 
                        ({
                            [theme.breakpoints.up(1800)]: { // media query for screen sizes above 1800 pixels width
                                mt: 7,
                                mb: 7,
                            },
                            [theme.breakpoints.down(1800)]: {
                                mt: 0,
                                mb: 2,
                            },
                        })
                    }
                >
                    <Typography 
                        sx={{
                            fontFamily: 'Source Sans Pro',
                            typography: { xs: "h6",  sm: "h6", md: "h6", lg: "h6", xl: "h4" },
                            fontWeight: 500, 
                            color: (theme)=> theme.palette.text.primary
                        }}
                    >
                        {`${this.labels.funcWelcomeToHomeLabel_S} ${buildingName}, ${displayName}`}
                    </Typography>
                </Box>
                <Box sx={{width:'100%', overflow: 'auto'}}>
                    <Grid 
                        container 
                        spacing={3}
                        sx={theme => 
                            ({ 
                                minWidth: '1435px', 
                                maxWidth: '1435px',
                                [theme.breakpoints.up(1000)]: {
                                    pb: 2,
                                },
                                [theme.breakpoints.down(1000)]: { 
                                    pb: 0,
                                },
                            })
                            }
                    >     
                    {callToActions.filter(i => i.permissions === true).map((item) => 
                        <Grid 
                            item 
                            xs={3}
                            key={item.id}
                        >
                            <IbssHomeCard           
                                title={item.title}
                                description={item.description}
                                buttonLabel={item.buttonLabel}
                                src={item.src}
                                imageAlt={item.imageAlt}
                                route={item.route}
                                redirect={() => this.props.history.push(item.route)}
                            />
                        </Grid>
                    )}
                    </Grid>
                </Box>
            </div>
        );
    }
}

interface IProps extends RouterProps
{
}

interface IState
{
    buildingId: number;
}

export interface ICallToAction
{
    id: string;
    title: string;
    description: string;
    buttonLabel: string;
    src: string;
    imageAlt: string;
    route: string;
    permissions: boolean;
}

export default OnelensHome;
