import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateCateringOrderEndpoint implements ICreateCateringOrderEndpoint
{
    public async execute(nodeId: number, payload: ICreateCateringOrder): Promise<string>
    {
        try
        {
            const response = await axios.post(apis.allApiv2 + nodeId + '/catering-order', payload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateCateringOrderEndpoint
{
    execute(nodeId: number, payload: ICreateCateringOrder): Promise<string>;
}

export interface ICreateCateringOrder
{
    Node_Id: number;
    Menu_Items: string;
    Booking_Id: string;
    Space_Id: string;
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Attendees: number;
    Catering_Notes: string;
    Cost_Code_Allocation: string;
    Catering_Total_Value: number;
    Catering_Order_Restrictions: string;
    Catering_Order_Status: string;
}
