import { Box, SelectChangeEvent, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFilterOptions } from './EditAdvancedSpace.tsx';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';


export default class AccessPermissions extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }


    public render(): JSX.Element
    {

        return (
            <Box mt={2} className="tablePanel" sx={{ '& > :not(style)': { my: 1 } }}>
                <Box mt={2} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'} sx={{ '& > :not(style)': { mt: 1, mb: 1 }, }}>
                        <Box className="icon-bg-dark" mr={1} pt={2} pl={2}>
                            <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                {Icons.LockIcon}
                            </IbssSvgIcon>
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 'regular' }}>{this.labels.funcAccessPermissions_S}</Typography>
                    </Box>
                </Box>
                <Box>
                    <IbssInputDropDown
                        options={this.props.spaceOwnershipOptions}
                        id="Space Ownership"
                        value={this.props.spaceOwnership != null ? this.props.spaceOwnership : ''}
                        inputLabel={this.labels.funcSpaceOwnership_S}
                        fullWidth
                        onChange={(event: SelectChangeEvent<string>) => this.props.onSpaceOwnershipChange(event)}
                    />
                </Box>
            </Box>
        )
    }
}

interface IState
{
}

interface IProps
{
    spaceOwnership: string | null;
    onSpaceOwnershipChange: (event: SelectChangeEvent<string>) => void;
    spaceOwnershipOptions: IFilterOptions[];
}

