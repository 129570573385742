import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';

class UnsavedChangesPopup extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                dialogContent=
                {
                    <div>{this.labels.funcEditCateringOrderUnsavedChanges_Message}</div>
                }
                header={this.labels.HubLabelModalConfirmTitle}
                footer=
                {
                    <div className='d-flex w-90' style={{ justifyContent: 'space-between' }}>
                        <IbssButton
                            variant="contained"
                            color='secondary'
                            onClick={() => this.props.onCancelClicked()}
                        >
                            {this.labels.HubButtonCancel}
                        </IbssButton>
                        <IbssButton
                            variant="contained"
                            onClick={() => this.props.onContinueClicked()}
                        >
                            {this.labels.HubButtonContinue}
                        </IbssButton>
                    </div>
                }
                fullWidth
            />
        )
    }
}

export default UnsavedChangesPopup;

export interface IProps
{
    open: boolean;
    onClose: () => void;
    onCancelClicked: () => void;
    onContinueClicked: () => void;
}

export interface IState
{
}