import React, { Component } from 'react';
import { List, Typography, Avatar } from '@mui/material';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as AnyIcon } from './Any.svg';
import { ReactComponent as ApprovedIcon } from './Approved.svg';
import { ReactComponent as AutoCancelledIcon } from './AutoCancelled.svg';
import { ReactComponent as AwaitingApprovalIcon } from './AwaitingApproval.svg';
import { ReactComponent as CancelledIcon } from './Cancelled.svg';
import { ReactComponent as CompletedIcon } from './Completed.svg';
import { ReactComponent as EarlyCheckInIcon } from './EarlyCheckIn.svg';
import { ReactComponent as InProgressIcon } from './InProgress.svg';
import { ReactComponent as LateCheckInIcon } from './LateCheckIn.svg';
import { ReactComponent as NewIcon } from './New.svg';
import { ReactComponent as NoShowIcon } from './NoShow.svg';
import { ReactComponent as AmendedIcon } from './Amended.svg';

class FilterChips extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    private getIcon(item: string): React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    {
        switch(item)
        {
            case "Any":
                return AnyIcon;
            case "Amended":
                return AmendedIcon;
            case "Approved":
                return ApprovedIcon;
            case "Auto Cancelled":
                return AutoCancelledIcon;
            case "Awaiting Approval":
                return AwaitingApprovalIcon;
            case "Cancelled":
                return CancelledIcon;
            case "Completed":
                return CompletedIcon;
            case "Early Check In":
                return EarlyCheckInIcon;
            case "In Progress":
                return InProgressIcon;
            case "Late Checkin":
                return LateCheckInIcon;
            case "New":
                return NewIcon;
            case "No Show":
                return NoShowIcon;
            default:
                return AnyIcon;
        }
    }

    public render(): JSX.Element
    {
        return (
            <List sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                {this.props.items.map(item => 
                    <IbssChip
                        key={item}
                        avatar={
                            <Avatar 
                                alt={'work space'} 
                                sx={{backgroundColor: 'white'}}
                            >
                                <SvgIcon viewBox='0 0 20 20' fontSize='small' component={this.getIcon(item)} sx={{color: (theme)=> theme.palette.text.primary}}></SvgIcon>
                            </Avatar>
                        }
                        label={<Typography sx={{fontFamily: 'Source Sans Pro', fontWeight:'bold'}}>{item}</Typography>} 
                        onDelete={()=> this.props.onDelete(item)}
                        sx={{height: '40px', width: 'auto'}}
                        variant={'filled'}
                    />
                )}
            </List>
        )
    }
}

export default FilterChips;

export interface IProps
{
    items: string[];
    onDelete: (item: string) => void;
}

export interface IState
{
}