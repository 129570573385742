import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import IbssTextField from "../../../Components/Inputs/TextField/IbssTextField";
import IbssFormControl from "../../../Components/Forms/FormControl/IbssFormControl";
import IbssAutocomplete from '../../Inputs/AutoComplete/IbssAutocomplete';
import { DataEntryRole } from '../../../Providers.Api/Roles/RoleRepository';
import { Box, SelectChangeEvent } from '@mui/material';
import IbssInputDropDown from '../../Inputs/SelectList/IbssInputDropDown';
import { SystemFilter } from './DataModels';
import IbssDialog from '../BaseDialog/IbssDialog';
import { InputOption } from '../../Inputs/SelectList2/InputSelectBox';
import IbssCheckBox from '../../Inputs/CheckBox/IbssCheckBox';

class AddSystemFilterModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; };

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            addSystemFilterName: '',
            addSystemFilterQuery: '',
            searchText: null,
            selectedRole: '',
            recordTypeOption: [
                {
                    label: 'Booking',
                    value: '8'
                },
                {
                    label: 'Visits',
                    value: '37'
                }
            ],
            recordTypeId: null,
            conCurrencyStamp: '',
            statusOption: [
                {
                    label: 'Disabled',
                    value: '0'
                },
                {
                    label: 'Enabled',
                    value: '1'
                },
                {
                    label: 'Default',
                    value: '2'
                }
            ],
            statusId: null,
            roleOptions: [],
            buildingBypass: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const { filterId, roles } = this.props;
        if (filterId) 
        {
            this.setFilterDetails();
        }
        await this.setState({ roleOptions: roles.map(i => new InputOption(i.name, i.id ?? '')) });
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState): Promise<void>
    {
        if ( this.props.show && prevProps.show != this.props.show)
        {
            this.setFilterDetails();
            await this.setState({ roleOptions: this.props.roles.map(i => new InputOption(i.name, i.id ?? '')) });
        }
    }

    private async setFilterDetails(): Promise<void>
    {
        const { filterId } = this.props;
        if (filterId)
        {
            const systemFilter = await appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.get<SystemFilter>({
                nodeId: this.props.nodeId,
                filterid: filterId,
                select: SystemFilter,
            });
            const selectedRoleLabel = this.state.roleOptions.find((role) => role.value === systemFilter.OwnedByRoleId)
            await this.setState({
                addSystemFilterName: systemFilter.Name,
                addSystemFilterQuery: systemFilter.Filter,
                recordTypeId: systemFilter.EventType_Id.toString(),
                selectedRole: selectedRoleLabel?.value ?? '',
                conCurrencyStamp: systemFilter.ConcurrencyStamp ? systemFilter.ConcurrencyStamp : '',
                searchText: selectedRoleLabel ?? null,
                buildingBypass: systemFilter.Building_Bypass

            })
            await this.setStatus(systemFilter.Status);
        }
        else
        {
            this.setState({
                addSystemFilterName: '',
                addSystemFilterQuery: '',
                recordTypeId: '',
                selectedRole: '',
                conCurrencyStamp: '',
                searchText: null
            })
            await this.setStatus(null);
        }
    }

    private getStatus(): string
    {
        const status = this.state.statusId ? parseInt(this.state.statusId) : 0;
        switch (status) 
        {
            case 0:
                return 'StatusInactive'
                break;
            case 1:
                return 'StatusActive'
                break;
            case 2:
                return 'StatusActiveDefault'
                break;
            default:
                return 'StatusInactive'
                break;
        }
    }

    private async setStatus(status: string | null): Promise<void>
    {
        switch (status) 
        {
            case 'StatusInactive':
                await this.statusChanged('0');
                break;
            case 'StatusActive':
                await this.statusChanged('1');
                break;
            case 'StatusActiveDefault':
                await this.statusChanged('2');
                break;
            default:
                await this.statusChanged(null);
                break;
        }
    }

    private async addFilterModalSubmitted(): Promise<void>
    {
        const payload = {
            Name: this.state.addSystemFilterName,
            Node_Id: this.props.nodeId,
            Filter: this.state.addSystemFilterQuery,
            EventType_Id: this.state.recordTypeId ? parseInt(this.state.recordTypeId) : 0,
            OwnedByRoleId: this.state.selectedRole,
            Status: this.getStatus(),
            Building_Bypass: this.state.buildingBypass,
        };
        if (this.props.filterId) 
        {
            try
            {
                const patchPayload = { ...payload, ConcurrencyStamp: this.state.conCurrencyStamp }
                await appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.patch<void>({
                    nodeId: this.props.nodeId,
                    filterid: this.props.filterId,
                    body: patchPayload
                });
            }
            catch { }
        }
        else
        {
            try
            {
                await appContext().ibssApiClientV2.v2.byNodeid.filters.post<void>({
                    nodeId: this.props.nodeId,
                    body: payload
                });
            }
            catch { }
        }
        this.props.onSystemFilterCreated();
        this.props.onHide();
        this.setState(
            {
                addSystemFilterName: '',
                addSystemFilterQuery: '',
                searchText: null,
                selectedRole: '',
                statusId: null,
                recordTypeId: null,
            }
        );
    }

    private async roleChanged(e: React.SyntheticEvent<Element, Event>, role: InputOption | null): Promise<void> {
      await this.setState({
          searchText: role,
          selectedRole: role?.value ?? ''
        });
      }


    private async recordTypeChanged(id: string): Promise<void>
    {
        this.setState({ recordTypeId: id });
    }

    private async statusChanged(id: string | null): Promise<void>
    {
       await this.setState({ statusId: id });
    }


    private buildingBypassChanged(e: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({ buildingBypass: e.target.checked });
    };

    public render(): JSX.Element
    {
        const { show, onHide, filterId } = this.props;

        const isDisable = this.state.addSystemFilterName === '' ||  this.state.addSystemFilterQuery === '' || this.state.selectedRole === '' || this.state.recordTypeId === null

        return (
            <IbssDialog
                open={show}
                onClose={() => onHide()}
                header={filterId ? this.labels.HubLabelEditFilter : this.labels.HubLabelAddSystemFilter}
                dialogContent={
                    <div className="m-4 text-center">
                        <div className='m-4 text-left'>
                            {this.labels.HubLabelAddSystemFilterText}
                        </div>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssInputDropDown
                                inputLabel={this.labels.HubLabelRecordTypeLabel}
                                options={this.state.recordTypeOption}
                                value={this.state.recordTypeId ? this.state.recordTypeId : ''}
                                id={"recordTypeSelection"}
                                onChange={(e: SelectChangeEvent<string>) => this.recordTypeChanged(e.target.value)}
                                fullWidth
                                required
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth>
                            <IbssTextField
                                label={this.labels.HubLabelName}
                                type='text'
                                variant='outlined'
                                fullWidth
                                value={this.state.addSystemFilterName}
                                onChange={e => this.setState({ addSystemFilterName: e.target.value })}
                                required
                            />
                        </IbssFormControl>

                        <Box>
                            <IbssCheckBox
                                label={this.labels.funcCrossBuildingFilter_S}
                                checked={this.state.buildingBypass}
                                onClicked={(e: React.ChangeEvent<HTMLInputElement>) => this.buildingBypassChanged(e)}
                            />
                        </Box>
                        <Box my={2}>
                            <IbssFormControl fullWidth>
                                <IbssTextField
                                    onChange={e => this.setState({ addSystemFilterQuery: e.target.value })}
                                    label={this.labels.HubLabelFilterQueryText}
                                    value={this.state.addSystemFilterQuery}
                                    multiline
                                    fullWidth
                                    rows={4}
                                    required
                                    helperText={<>{this.labels.HubLabelFilterQueryHelperText} <a target='_blank' href='http://ibssanswers.iconics.cloud/manual-filters'>{this.labels.HubLabelFilterQueryHelperLink}</a></>}
                                />
                            </IbssFormControl>
                        </Box>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssAutocomplete
                                freeSolo
                                options={this.state.roleOptions}
                                value={this.state.searchText}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, role: InputOption | null) => this.roleChanged(e, role)}
                                renderInput={params =>
                                    <IbssTextField
                                        {...params}
                                        label={this.labels.HubLabelOwnerRoleLabel}
                                        placeholder={this.labels.HubLabelOwnerRoleLabel}
                                        variant="outlined"
                                        required
                                    />}                             
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth>
                            <IbssInputDropDown
                                inputLabel={this.labels.HubLabelStatus}
                                options={this.state.statusOption}
                                value={this.state.statusId ?? ''}
                                id={"statusSelection"}
                                onChange={(e: SelectChangeEvent<string>) => this.statusChanged(e.target.value)}
                                fullWidth
                                required
                            />
                        </IbssFormControl>
                    </div>
                }
                footer={
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <IbssButton
                                style={{ height: '45px', minWidth: '100px' }}
                                variant="contained"
                                onClick={() => this.addFilterModalSubmitted()}
                                disabled={isDisable}
                            >
                                {this.labels.HubButtonSave}
                            </IbssButton>
                        </div>
                    </>
                }
            />
        )
    }
}

export default AddSystemFilterModal;

export interface IState
{
    addSystemFilterName: string;
    addSystemFilterQuery: string;
    searchText: InputOption | null;
    selectedRole: string;
    recordTypeOption: InputOption[];
    recordTypeId: string | null;
    conCurrencyStamp: string;
    statusOption: InputOption[];
    statusId: string | null;
    roleOptions: InputOption[];
    buildingBypass: boolean;
}

export interface IProps
{
    show: boolean;
    onHide: () => void;
    roles: DataEntryRole[];
    nodeId: number;
    onSystemFilterCreated: () => Promise<void>;
    filterId: string | null;
}