import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public period?: string;
    public from?: DateTime;
    public to?: DateTime;
    public filter?: string;
    public status?: string;
    public host?: string;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'from':
            case 'to':
                return this.serialiseDate(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue);
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'from':
            case 'to':
                return this.deserialiseDate(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
