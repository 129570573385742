import { Component } from 'react';

export default class CheckListIcon extends Component
{
    render()
    {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.117676 1.88238C0.117676 0.907762 0.907762 0.117676 1.88238 0.117676H14.1177C15.0923 0.117676 15.8824 0.907762 15.8824 1.88238V14.1177C15.8824 15.0923 15.0923 15.8824 14.1177 15.8824H1.88238C0.907762 15.8824 0.117676 15.0923 0.117676 14.1177V1.88238ZM1.88238 0.823558C1.29761 0.823558 0.823558 1.29761 0.823558 1.88238V14.1177C0.823558 14.7024 1.29761 15.1765 1.88238 15.1765H14.1177C14.7024 15.1765 15.1765 14.7024 15.1765 14.1177V1.88238C15.1765 1.29761 14.7024 0.823558 14.1177 0.823558H1.88238Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76465 3.76468C5.76465 3.56976 5.92267 3.41174 6.11759 3.41174H13.1764C13.3713 3.41174 13.5294 3.56976 13.5294 3.76468C13.5294 3.95961 13.3713 4.11763 13.1764 4.11763H6.11759C5.92267 4.11763 5.76465 3.95961 5.76465 3.76468Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76465 8.00004C5.76465 7.80511 5.92267 7.64709 6.11759 7.64709H13.1764C13.3713 7.64709 13.5294 7.80511 13.5294 8.00004C13.5294 8.19496 13.3713 8.35298 13.1764 8.35298H6.11759C5.92267 8.35298 5.76465 8.19496 5.76465 8.00004Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76465 12.2353C5.76465 12.0403 5.92267 11.8823 6.11759 11.8823H13.1764C13.3713 11.8823 13.5294 12.0403 13.5294 12.2353C13.5294 12.4302 13.3713 12.5882 13.1764 12.5882H6.11759C5.92267 12.5882 5.76465 12.4302 5.76465 12.2353Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29412 3.17646C2.96924 3.17646 2.70588 3.43983 2.70588 3.7647C2.70588 4.08957 2.96924 4.35293 3.29412 4.35293C3.61899 4.35293 3.88235 4.08957 3.88235 3.7647C3.88235 3.43983 3.61899 3.17646 3.29412 3.17646ZM2 3.7647C2 3.04998 2.5794 2.47058 3.29412 2.47058C4.00884 2.47058 4.58824 3.04998 4.58824 3.7647C4.58824 4.47942 4.00884 5.05882 3.29412 5.05882C2.5794 5.05882 2 4.47942 2 3.7647Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29412 7.41181C2.96924 7.41181 2.70588 7.67518 2.70588 8.00005C2.70588 8.32492 2.96924 8.58829 3.29412 8.58829C3.61899 8.58829 3.88235 8.32492 3.88235 8.00005C3.88235 7.67518 3.61899 7.41181 3.29412 7.41181ZM2 8.00005C2 7.28533 2.5794 6.70593 3.29412 6.70593C4.00884 6.70593 4.58824 7.28533 4.58824 8.00005C4.58824 8.71477 4.00884 9.29417 3.29412 9.29417C2.5794 9.29417 2 8.71477 2 8.00005Z" fill="#263238" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29412 11.647C2.96924 11.647 2.70588 11.9104 2.70588 12.2353C2.70588 12.5602 2.96924 12.8235 3.29412 12.8235C3.61899 12.8235 3.88235 12.5602 3.88235 12.2353C3.88235 11.9104 3.61899 11.647 3.29412 11.647ZM2 12.2353C2 11.5206 2.5794 10.9412 3.29412 10.9412C4.00884 10.9412 4.58824 11.5206 4.58824 12.2353C4.58824 12.95 4.00884 13.5294 3.29412 13.5294C2.5794 13.5294 2 12.95 2 12.2353Z" fill="#263238" />
            </svg>
        )
    }
};