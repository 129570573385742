import "../../../../styles/css/table.scss";
import "../../../../App.css";
import apis from "../../../../Providers.Api/apis";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { appContext } from "../../../../AppContext";
import { RouterProps, RouteComponentProps } from "react-router";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import EditIcon from "../../../../Components/Icons/EditIcon";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import { ICateringRestriction } from "../../../../Providers.Api/CateringRestrictions/CateringRestrictionsRepository";
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import MediaSelector from "../../../../Components/DialogLaunchers/MediaSelector/MediaSelector";
import { IFile } from "../../../../Providers.Api/Files/FilesRepository";
import Helper from "../../../../Common/Helper";
import CreateIcon from '@mui/icons-material/Create';
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListDietaryTerms extends IbssPage<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            buildingid: this.appState.buildingId,
            searchTerm: '',
            isLoading: false,
            tableData: [],
            selectedRowIds: [],
            showFilterModal: false,
            selectedType: 'Any',
            rows: [],
            showDeleteModal: false,
            images: [],
            showCreateEditModal: false,
            isEdit: false,
            name: '',
            type: '',
            imageUrl: '',
            concurrencyStamp: '',
            isCreateRight: false,
            isUpdateRight: false,
            isDeleteRight: false
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingid: i.buildingId }));
        const buildingId = this.props.match.params.buildingid;
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: parseInt(buildingId) });
            if (this.props.match.params.buildingid !== "0")
            {
                apis.getSpacesById(this.props.match.params.buildingid, "Building");
            }
            else
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }
        }
        this.loadData();
        this.getImages();
        this.setRights();
    }

    private setRights(): void 
    {
        if (this.local.hasRight("DATAMODEL.CateringRestrictions.Create"))
        {
            this.setState({ isCreateRight: true });
        }
        if (this.local.hasRight("DATAMODEL.CateringRestrictions.Update"))
        {
            this.setState({ isUpdateRight : true });
        }
        if (this.local.hasRight("DATAMODEL.CateringRestrictions.Delete"))
        {
            this.setState({ isDeleteRight : true });
        }
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void>
    {
        if (prevState.buildingid !== this.state.buildingid)
        {
            const { history } = this.props;
            history.push("/catering-dietary-terms/" + this.state.buildingid);
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ isLoading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.loadData();
        this.setState({ isLoading: false });
    }

    private async loadData(): Promise<void>
    {
        this.setState({ isLoading: true });
        const data = await this.apiClient.cateringRestrictions.getCateringRestrictions(this.state.buildingid);
        const restrictions: ICateringRestriction[] = data.Value.map(restriction => ({
            id: restriction.Restriction_Id,
            ...restriction,
        }));
        this.setState({ tableData: restrictions, isLoading: false, rows: restrictions });
    }

    private filterResults(searchTerm: string): void
    {
        this.setState({ searchTerm: searchTerm })
        const searchResults = this.state.tableData.filter(row =>
        {
            let key: keyof ICateringRestriction;
            for (key in row)
            {
                if (row[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) && (this.state.selectedType == 'Any' || row['Section'] == this.state.selectedType))
                {
                    return true;
                }
            }
            return false;
        });
        this.setState({ rows: searchResults, showFilterModal: false });
    }

    private async deleteClicked(): Promise<void>
    {
        this.setState({ showDeleteModal: false, isLoading: true });
        let index = 0;
        while (index < this.state.selectedRowIds.length)
        {
            await this.apiClient.cateringRestrictions.deleteCateringRestriction(this.state.buildingid, this.state.selectedRowIds[index].toString());
            index += 1;
        }
        this.loadData();
    }

    private async getImages(): Promise<void>
    {
        const responseData = await this.apiClient.files.getFiles('r/Catering');
        const newResponseData = responseData.map((item: IFile) =>
        {
            let newItem = { ...item };
            newItem.path = `${Helper.getStorageUrl()}/images/r/Catering/${item.name}`;
            return newItem;
        })
        this.setState({ images: newResponseData });
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        await this.apiClient.files.uploadFile(file, 'r/Catering/' + filename, processAsMap);
        this.getImages();
    }

    public addClicked(): void
    {
        this.setState({ showCreateEditModal: true, isEdit: false, name: '', type: '', imageUrl: '', concurrencyStamp: '' });
    }

    public async updateClicked(): Promise<void>
    {
        this.setState({ isLoading: true, showCreateEditModal: false });
        const payload = {
            Restriction_Id: this.state.selectedRowIds[0].toString(),
            Section: this.state.type,
            ImageURI: this.state.imageUrl,
            Name: this.state.name,
            ConcurrencyStamp: this.state.concurrencyStamp
        }
        try
        {
            await this.apiClient.cateringRestrictions.editCateringRestriction(this.state.buildingid, payload.Restriction_Id, payload)
        } catch (error)
        {
            this.setState({ isLoading: false })
        }
        await this.loadData()
    }

    public async saveClicked(): Promise<void>
    {
        this.setState({ isLoading: true, showCreateEditModal: false });
        const payload = {
            Section: this.state.type,
            ImageURI: this.state.imageUrl,
            Name: this.state.name,
        }
        try
        {
            await this.apiClient.cateringRestrictions.createCateringRestriction(this.state.buildingid, payload)
        } catch (error)
        {
            this.setState({ isLoading: false })
        }
        await this.loadData()
    }

    private editDietaryTerm(space: ICateringRestriction): React.ReactNode
    {
        if (this.state.isUpdateRight)
        {
            return (
                <>
                    <IbssSvgIcon
                        onClick={() =>
                        {
                            this.setState({
                                selectedRowIds: space?.Restriction_Id ? [space.Restriction_Id] : [],
                                showCreateEditModal: true,
                                isEdit: true,
                                name: space.Name,
                                type: space.Section,
                                imageUrl: space.ImageURI,
                                concurrencyStamp: space.ConcurrencyStamp ?? ''
                            });
 
                        }}>
                        <CreateIcon />
                    </IbssSvgIcon>
                </>
            )
        } else
        {
            <p>-</p>
        }
    }

    public render(): JSX.Element
    {
        const dataTodayColumn: GridColDef<ICateringRestriction>[] = [
            {
                headerName: this.labels.HubLabelName,
                field: "Name",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelType,
                field: "Section",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: 120,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) => this.editDietaryTerm(params.row)
            }
        ];

        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => { this.setState({ showDeleteModal: true }) },
                    disabled: this.state.selectedRowIds.length > 0 && this.state.isDeleteRight ? false : true
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    disabled: !this.state.isCreateRight,
                    onClick: () => { this.addClicked() },
                }
            ];


        return (
            <>
                <div>
                    <div className="rightPanel">
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0,pt:1}}>
                                    <Grid item sm={12} sx={{pt:0}}>
                                        <Box className='table-panel-header' sx={{ml:0}}>
                                            {this.labels.funcCateringDietaryTermsDietary_S}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item md={5} mt={2}>
                                <IbssFilter
                                    searchTerm={this.state.searchTerm}
                                    onSearchTermChange={(e) => this.filterResults(e.target.value)}
                                    onFilterButtonClick={() => this.setState({ showFilterModal: true })}
                                />
                                </Grid>
                                <Grid item md={12}>
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                    <IbssActionButton buttons={actionButtons} />
                                </Box>
                                </Grid>
                                {this.state.isLoading &&
                                    <Spinner />
                                }
                                <Box sx={{ mt: 1 }}>
                                    <IbssDataGrid
                                        checkboxSelection
                                        rows={this.state.rows}
                                        columns={dataTodayColumn}
                                        onRowSelectionModelChange={(e) => this.setState({ selectedRowIds: e })}
                                        disableRowSelectionOnClick
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },

                                        }}
                                        sx={{
                                            "& .MuiDataGrid-row:hover": {
                                                cursor: "pointer"
                                            }
                                        }}
                                        pageSizeOptions={[25, 50, 100]}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.showFilterModal}
                        onClose={() => this.setState({ showFilterModal: false })}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        dialogContent=
                        {
                            <>
                                <Typography className="mb-3">{this.labels.HubCateringFilterSubTitle}</Typography>
                                <IbssFormControl style={{ width: '350px', paddingBottom: '8px' }} >
                                    <IbssInputDropDown
                                        onChange={(e: SelectChangeEvent) => this.setState({ selectedType: e.target.value })}
                                        inputLabel={this.labels.HubLabelType}
                                        id='policiesDropdown'
                                        options={[{ label: 'Any', value: 'Any' }, { label: 'Allergen', value: 'Allergen' }, { label: 'Dietary', value: 'Dietary' }]}
                                        defaultValue={''}
                                    />
                                </IbssFormControl>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.setState({ showFilterModal: false })}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterResults(this.state.searchTerm)}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/* Delete Modal */}
                    <IbssDialog
                        open={this.state.showDeleteModal}
                        onClose={() => this.setState({ showDeleteModal: false })}
                        fullWidth
                        header={this.labels.funcCateringDietaryTermsDeleteDietaryTerms_L}
                        dialogContent=
                        {
                            <>
                                <Typography className="mb-3">{this.labels.funcCateringDietaryTermsDeleteDietaryTerms_D}</Typography>
                            </>
                        }
                        footer=
                        {
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                <IbssButtonRedo
                                    onClick={() => this.setState({ showDeleteModal: false })}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubLabelClose}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="error"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.deleteClicked()}
                                >
                                    {this.labels.HubButtonDelete}
                                </IbssButtonRedo>
                            </div>
                        }
                    />
                    {/* Create/Edit Modal */}
                    <IbssDialog
                        open={this.state.showCreateEditModal}
                        onClose={() => this.setState({ showCreateEditModal: false })}
                        fullWidth
                        header={`${this.state.isEdit ? this.labels.funcCateringDietaryTermsEdit_L : this.labels.funcCateringDietaryTermsAdd_L}`}
                        dialogContent=
                        {
                            <>
                                <Typography className="mb-3">{`${this.state.isEdit ? this.labels.funcCateringDietaryTermsEdit_D : this.labels.funcCateringDietaryTermsAdd_D}`}</Typography>
                                <IbssFormControl style={{ width: '350px', paddingBottom: '8px' }} >
                                    <IbssTextField
                                        onChange={e => this.setState({ name: e.target.value })}
                                        label={this.labels.HubLabelName}
                                        id='nameInput'
                                        value={this.state.name}
                                    />
                                </IbssFormControl>
                                <IbssFormControl style={{ width: '350px', paddingBottom: '8px' }} >
                                    <IbssInputDropDown
                                        onChange={(e: SelectChangeEvent) => this.setState({ type: e.target.value })}
                                        inputLabel={this.labels.HubLabelType}
                                        id='policiesDropdown'
                                        options={[{ label: 'Allergen', value: 'Allergen' }, { label: 'Dietary', value: 'Dietary' }]}
                                        defaultValue={this.state.type}
                                    />
                                </IbssFormControl>
                                <MediaSelector
                                    options={this.state.images.map(x => { return { name: x.name, url: x.path } })}
                                    imageSelected={(file: { name: string, url: string }) => this.setState({ imageUrl: file.url })}
                                    uploadFile={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                    defaultSelectedImage={this.state.imageUrl}
                                />
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disabled={this.state.name == '' || this.state.type == ''}
                                    onClick={() => { this.state.isEdit ? this.updateClicked() : this.saveClicked() }}
                                >
                                    {this.state.isEdit ? this.labels.HubLabelUpdate : this.labels.HubButtonSave}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                </div>
            </>
        );
    }

}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>
{
}

interface IMatchParams
{
    buildingid: string;
}

interface IState
{
    buildingid: number;
    searchTerm: string;
    isLoading: boolean;
    tableData: ICateringRestriction[];
    selectedRowIds: GridRowSelectionModel;
    showFilterModal: boolean;
    selectedType: string;
    rows: ICateringRestriction[];
    showDeleteModal: boolean;
    images: IFile[];
    showCreateEditModal: boolean;
    isEdit: boolean;
    name: string;
    type: string;
    imageUrl: string;
    concurrencyStamp: string;
    isCreateRight: boolean;
    isUpdateRight: boolean;
    isDeleteRight: boolean;
}

export default ListDietaryTerms;
