import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { GetCateringStatus } from '../../../OneLens/CateringOrders/List/CateringStatus'; // todo: shouldn't be referncing different page
import { DateTime } from 'luxon';
import "./OrderSummaryWidget.scss";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { appContext } from '../../../../AppContext';

class OrderSummaryWidget extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        const cateringStatus = new GetCateringStatus({});
        const unavailableMenuClass = `${this.props.hasUnavailableMenu? 'use-unavailable-menu': ''}`;
        const containerClass = `order-summary-container ${unavailableMenuClass} ${this.props.selected ? 'selected' : ''}`

        return (
            <div className={containerClass}>
                <div className='summary-header'>
                    <div className='order-name'>{this.props.orderName}</div>
                    {cateringStatus.renderTaskOverAllStatus(this.props.status)}
                </div>
                <div className='order-details-container'>
                    <div className='order-details-container'>
                        <div style={{ width: 9, height: 17, position: 'relative' }}>
                            <IbssSvgIcon fontSize="small">{Icons.TimeIcon}</IbssSvgIcon>
                        </div>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                            <div style={{ fontSize: 14 }}>{this.props.start.toLocaleTimeString()}</div>
                            <div style={{ fontSize: 14 }}>•</div>
                            <div style={{ fontSize: 14 }}>{this.props.end.toLocaleTimeString()}</div>
                        </div>
                    </div>
                    <div className='order-details-container'>
                        <div style={{ width: 8, height: 13, position: 'relative' }}>
                            <IbssSvgIcon fontSize="small">{Icons.CheckListIcon}</IbssSvgIcon>
                        </div>
                        <div style={{ fontSize: 14 }}>{this.props.itemTotal} {this.labels.HubLabelItems}</div>
                    </div>
                </div>
                <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'flex-start', gap: 16, display: 'inline-flex' }}>
                    <div style={{ width: 71, fontSize: 14, wordWrap: 'break-word' }}>{this.props.currency}{this.props.costTotal.toFixed(2)}</div>
                    <div style={{ flex: '1 1 0', textAlign: 'right', color: '#F44336', fontSize: 10 }}>{this.props.hasUnsavedChanges ? this.labels.funcEditCateringOrderUnsavedChanges_S : ''}</div>
                </div>
            </div>
        )
    }
}

export default OrderSummaryWidget;

export interface IProps
{
    orderName: string;
    status: string;
    start: DateTime;
    end: DateTime;
    itemTotal: number;
    costTotal: number;
    hasUnsavedChanges: boolean;
    selected: boolean;
    currency: string;
    hasUnavailableMenu: boolean;
}

export interface IState
{
}