import { Component } from 'react';

export default class BuildingSetupIcon extends Component
{
    render()
    {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.8 20.8251L8.725 18.6751L4.25 20.4501C3.96667 20.6001 3.6875 20.5918 3.4125 20.4251C3.1375 20.2584 3 20.0084 3 19.6751V5.7251C3 5.50843 3.0625 5.31676 3.1875 5.1501C3.3125 4.98343 3.475 4.85843 3.675 4.7751L8.225 3.1751C8.39167 3.1251 8.55833 3.1001 8.725 3.1001C8.89167 3.1001 9.05833 3.1251 9.225 3.1751L15.3 5.3001L19.75 3.5251C20.0333 3.39176 20.3125 3.40426 20.5875 3.5626C20.8625 3.72093 21 3.96676 21 4.3001V18.4251C21 18.6084 20.9375 18.7668 20.8125 18.9001C20.6875 19.0334 20.5333 19.1334 20.35 19.2001L15.8 20.8251C15.6333 20.8751 15.4667 20.9001 15.3 20.9001C15.1333 20.9001 14.9667 20.8751 14.8 20.8251ZM14.45 19.1251V6.5001L9.55 4.8501V17.4751L14.45 19.1251Z" fill="#263238" />
            </svg>
        )
    }
};
