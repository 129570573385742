import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { IPartialAppState, appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListSensors extends IbssPage<IProps, IState> {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;

  constructor(props: IProps)
  {
      super(props);
      this.state =
      {
        isLoading: false,
        sensorsData: [],
        facilityDataSort: { columnName: "", direction: "" },
        sensorsList: [],
        selectedBuildingId: this.appState.buildingId.toString(),
        searchTerm: ''
      }
  }

  public async componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsSensors });
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetsensorsData();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if (prevState.selectedBuildingId != this.state.selectedBuildingId) {
      this.handleGetsensorsData();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void> {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined) {
      this.setState({ selectedBuildingId: state.buildingId.toString() });
      history.push(match.path.replace(":buildingid", state.buildingId));
    }
  }

  getAirStatus(val: number): string {
    let obj: any = { 1: 'Good', 2: 'Fair', 3: 'Poor', 0: 'No Data' };
    return obj[val]
  }

  handleGetsensorsData = async () => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFacilitySensorsData(this.session.getBuildingId())
      const responseData = response.data;
      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }

        newItem.table_Env_Sensor_Name = item.Env_Sensor_Name;
        newItem.table_IAQ_Status = this.getAirStatus(item.IAQ_Status);
        newItem.table_Env_Temperature = `${item.Env_Temperature}\u00b0C`;
        newItem.table_Env_Humidity = `${item.Env_Humidity}%`;
        newItem.table_Env_Sound_Level = `${item.Env_Sound_Level}dB` ;
        newItem.id = item.Env_Sensor_Id;
        return newItem
      })

      this.setState({ isLoading: false, sensorsList: newResponseData ? newResponseData : [] })
      this.setState({ isLoading: false, sensorsData: responseData ?? [] })
    } catch (error: any) {
      this.setState({
        isLoading: false
      })
    }
  }

  editNotification = (item: any) => {
    const { history }: any = this.props;
    history.push(`/sustainability-analytics-sensors/${this.session.getBuildingId()}/${item.Env_Sensor_Id}`)
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.sensorsList, e.column, e.direction)
    this.setState({ sensorsList: newArray })
  }

  private filterRows(searchTerm: string): ISensorData[] 
  {
      const filteredRows = this.state.sensorsList.filter(x =>
      {
          let key: keyof ISensorData;
          for (key in x)
          {
              if (x[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
              {
                  return true;
              }
          }
          return false;
      });
      return filteredRows;
  }

  render() {

      const columns = [
        {
          headerName: this.labels.HubLabelName,
          field: "table_Env_Sensor_Name",
          flex: 1
        },
        {
          headerName: this.labels.HubLabelStatus,
          field: "table_IAQ_Status",
          flex: 1
        },
        {
          headerName: this.labels.HubLabelTemperature,
          field: "table_Env_Temperature",
          flex: 1
        },
        {
          headerName: this.labels.HubLabelHumidity,
          field: "table_Env_Humidity",
          flex: 1
        },
        {
          headerName: this.labels.HubLabelNoise,
          field: "table_Env_Sound_Level",
          flex: 1
        },
      ]

    const filteredRows = this.filterRows(this.state.searchTerm);

    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
            <TablePanelInfo
              tableHeading={this.labels.HubLabelSensor}
              iconSrc="Sensor"
            />
            <IbssFilter
              searchTerm={this.state.searchTerm}
              onSearchTermChange={(event) => this.setState({ searchTerm: event.target.value })}
            />
            <IbssDataGrid className="data-grid-clickable-row" height="calc(100vh - 270px)" onRowClick={(data) => this.editNotification(data.row)} columns={columns} rows={filteredRows} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export interface IProps
{

}
export interface IState
{
  isLoading: boolean,
  sensorsData: [],
  facilityDataSort: { columnName: "", direction: "" },
  sensorsList: ISensorData[],
  selectedBuildingId: string,
  searchTerm: string
}

export interface ISensorData
{
  id: string;
  name: string;
  status: string;
  temperature: string;
  humidity: string;
  soundLevel: string;
}

export default connect(mapStateToProps)(ListSensors);
