import { Component } from 'react';
import { appContext } from '../../../AppContext';
import { Backdrop, CircularProgress } from '@mui/material';
class LoadingOverlay extends Component<IProps>
{
  private get labels() { return appContext().labels}
  
  public render(): JSX.Element
  {
    return (
      <Backdrop 
        className='loadingOverlay'
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
          <div className='text-center'>
              <CircularProgress color="primary" role="status" />
              <p className="sr-only">{this.labels.HubLabelLoadingText}</p>
              <p className='text-white'>
                  {this.props?.text ?? ''}
              </p>
          </div>
      </Backdrop>
    )
  }
}

export default LoadingOverlay;

export interface IProps{
  text?: string;
}