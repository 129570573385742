import { ODataSelect } from "../../../../Providers.Api/ODataQuery";


export class TagDetails extends ODataSelect
{
    Tag_Id = "";
    Node_Id = 0;
    TagType = "";
    Values = "";
    Icon = "";
    Description = "";
    Status = "";
    Name = "";
    ConcurrencyStamp = "";
}