import { Component } from "react";
import { Modal } from 'react-bootstrap';
import { appContext } from "../../../AppContext";
import IbssButton from "../../Buttons/Button/IbssButton";
import IbssTimePicker from "../../Inputs/TimePicker/IbssTimePicker";
import TextField from '@mui/material/TextField';
import IbssChip from "../../Navigation/Chip/IbssChip";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../Common/AllsvgIcons";
import moment from "moment";
import { format } from "date-fns";
import { DateTime } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";

export default class TimeSelectList extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            timePicker: DateTime.now(),
            selectedTimes: (this.props.selectedTimes ?? []),
        }
    }

    private timeChanged(newTime: Date | null): void
    {
        this.setState({ timePicker: (newTime == null ? DateTime.now() : DateTime.fromISO(newTime.toISOString())) });
    }

    private includeButtonClicked(): void
    {
        this.setState({ selectedTimes: [ ...this.state.selectedTimes, this.state.timePicker ] })
    }

    private chipDeleted(time: DateTime): void
    {
        this.setState({ selectedTimes: this.state.selectedTimes.filter(x => x.toFormat("HH:mm") != time.toFormat("HH:mm")) })
    }

    public render(): JSX.Element
    {
        const { closeButtonClicked, okButtonClicked, title } = this.props;
        const timeAsString = this.state.timePicker.toFormat('HH:mm');
        const includeButtonDisabled = this.state.selectedTimes.map(i => i.toFormat('HH:mm')).includes(timeAsString) || !this.state.timePicker.isValid;

        return (
            <Modal show={this.props.show} onHide={() => closeButtonClicked()}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeButtonClicked()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <div style={{ paddingTop: '5px', paddingLeft: '58px', display: 'flex' }} className="mb-4">
                    <div style={{ width: '35%' }}>
                        <IbssTimePicker
                            className='ibss-timepicker'
                            value={this.state.timePicker}
                            onChange={e => this.timeChanged(e?.toJSDate() as Date)}
                            minutesStep={1}
                            slotProps={{ textField: { error: !this.state.timePicker.isValid } }}
                        />
                    </div>
                    <IbssButton
                        style={{ height: '45px', minWidth: '55px', margin: '19px 10px' }}
                        variant="contained"
                        onClick={() => this.includeButtonClicked()}
                        disabled={includeButtonDisabled}
                    >
                        {this.labels.HubLabelInclude}
                    </IbssButton>
                    <div style={{ width: '45%', marginTop: '19px' }}>
                        {this.state.selectedTimes.map(time =>
                        {
                            const timeAsString = time.toFormat("HH:mm");
                            return (
                                <IbssChip key={timeAsString} avatar={<IbssSvgIcon sx={{ paddingTop: '4px' }}>{Icons.TimeIcon}</IbssSvgIcon>} className='m-1' id={timeAsString} label={timeAsString} onDelete={() => this.chipDeleted(time)} />
                            )
                        })}
                    </div>

                </div>
                <Modal.Footer>
                    <div style={{ textAlign: 'center' }}>
                        <IbssButton
                            style={{ height: '45px', minWidth: '100px' }}
                            variant="contained"
                            onClick={() =>
                                okButtonClicked(this.state.selectedTimes)}
                        >
                            {this.labels.HubLabelOk}
                        </IbssButton>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export interface IProps
{
    show: boolean;
    title: string;
    selectedTimes?: DateTime[];
    closeButtonClicked: () => void;
    okButtonClicked: (selectedTimes: DateTime[]) => void;
}

interface IState
{
    timePicker: DateTime;
    selectedTimes: DateTime[];
}