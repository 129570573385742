import { DateHelper } from "../../../../Common/DateHelper";

export class BookingFilter
{
	public nodeId = 1;
	public from = DateHelper.null();
	public to = DateHelper.null();
	public systemFilter = '';
	public statuses: string[] = [];
	public relationships: string[] = [];
	public zone = '';
	public bookedFor = '';
	public bookedBy = '';
	public space = '';

	constructor(value: Partial<BookingFilter>)
	{
		Object.assign(this, value);
	}

	public toString(): string
	{
		// status
		const statusFilterParts: string[] = [];
		this.statuses.filter(i => i.toLocaleLowerCase() != 'any').forEach(value =>
		{
			switch (value)
			{
				case 'Approved':
					statusFilterParts.push(`((Booking_Status eq 'New' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)) or (Booking_Status eq 'Amended' and (Booking_IsApproved eq 3 or Booking_IsApproved eq 4)))`);
					break;
				case 'Awaiting Approval':
					statusFilterParts.push(`(Booking_Status eq 'New' and Booking_IsApproved eq 0) or (Booking_Status eq 'Amended' and Booking_IsApproved eq 0)`);
					break;
				default:
					statusFilterParts.push(`(Booking_Status eq '${value}')`);
			}
		});
		const statusFilter = statusFilterParts.join(' or ');

		// relationships
		const relationFilterParts: string[] = [];
		this.relationships.filter(i => i.toLocaleLowerCase() != 'any').forEach(value =>
		{
			relationFilterParts.push(`(Parent_Booking_Relation_Type eq ${value})`);
		})
		const relationFilter = relationFilterParts.join(' or ');

		// rest
		const filterParts: string[] = [];
		filterParts.push(this.from.isValid ? `Booking_Start ge datetime'${this.from.toUtcByNode(this.nodeId).toISO()}'` : '');
		filterParts.push(this.to.isValid ? `Booking_End lt datetime'${this.to.toUtcByNode(this.nodeId).toISO()}'` : '');
		filterParts.push(this.systemFilter ? `(${this.systemFilter})` : '');
		filterParts.push(statusFilter ? `(${statusFilter})` : '');
		filterParts.push(relationFilter ? `(${relationFilter})` : '');
		filterParts.push(this.zone && this.zone.toLocaleLowerCase() !== 'any' ? `Meta_Loc_Zone eq '${this.zone}'` : '');
		filterParts.push(this.bookedFor ? `Booking_Owner_Email eq '${this.bookedFor.toLocaleLowerCase()}'` : '');
		filterParts.push(this.bookedBy ? `CreatedBy eq '${this.bookedBy.toLocaleLowerCase()}'` : '');
		filterParts.push(this.space ? `Space_Name eq '${this.space}'` : '');
		const filter = filterParts.filter(i => i != '').join(' and ');

		// done
		return filter;
	}

}

