export class StringHelper
{
    public incrementLastCharacter(value: string): string
    {
        return value.substring(0, value.length - 1) + String.fromCharCode(value.slice(-1).charCodeAt(0) + 1);
    }

    public splitWords(value: string): string[]
    {
        const words = value
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/[^a-zA-Z0-9]+/g, ' ')
            .trim()
            .toLocaleLowerCase()
            .split(/\s+/);
        return words;
    }

    public toCamelCase(value: string): string
    {
        const words = this.splitWords(value);
        const capitalisedWords = words.map((word, index) => (index == 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)));
        const pascalCase = capitalisedWords.join('');
        return pascalCase;
    }

    public toPascalCase(value: string): string
    {
        const words = this.splitWords(value);
        const capitalisedWords = words.map(i => i.charAt(0).toUpperCase() + i.slice(1));
        const pascalCase = capitalisedWords.join('');
        return pascalCase;
    }

    public toTitleCase(value: string): string
    {
        const words = this.splitWords(value);
        const capitalisedWords = words.map(i => i.charAt(0).toUpperCase() + i.slice(1));
        const pascalCase = capitalisedWords.join(' ');
        return pascalCase;
    }

    public toKebabCase(value: string): string
    {
        const words = this.splitWords(value);
        const kebabCase = words.join('-');
        return kebabCase;
    }

    public toSnakeCase(value: string): string
    {
        const words = this.splitWords(value);
        const kebabCase = words.join('_');
        return kebabCase;
    }

    public toConcatenatedLowercase(value: string): string
    {
        const words = this.splitWords(value);
        const lowercase = words.join('').toLocaleLowerCase();
        return lowercase;
    }

    public toConcatenatedUpperrcase(value: string): string
    {
        const words = this.splitWords(value);
        const uppercase = words.join('').toLocaleUpperCase();
        return uppercase;
    }
}
