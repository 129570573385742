import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import { DateTime } from 'luxon';
import { DateHelper } from '../../../../Common/DateHelper';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import { Box, Link, Typography } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IGetV2BookingResponse_BookingParty } from '../../../../Providers.Api/Bookings/GetV2BookingEndpoint';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as AttendeeIcon } from './Attendee.svg';
import { ReactComponent as CalendarIcon } from './Calendar.svg';
import { ReactComponent as ClockIcon } from './Clock.svg';
import { ReactComponent as HostIcon } from './Host.svg';
import "./Styles.scss";

class ViewBookingDialog extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =  
        {
            bookingParties: [],
        };
    }

    public componentDidMount(): void
    {
        this.setBookingParties();
        
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if(this.props.bookingId !== prevProps.bookingId)
        {
            this.setBookingParties();
        }
    }

    private setBookingParties(): void
    {
        if(this.props.bookingParties)
        {
            try
            {
                const bookingParties: IGetV2BookingResponse_BookingParty[] = JSON.parse(this.props.bookingParties);
                const bookingPartiesView = bookingParties.map(i => BookingPartyView.fromBookingParty(i));
                this.setState({bookingParties: bookingPartiesView});
            }
            catch
            {
                // if JSON.parse() does not work on this.props.bookingParties, it's the v2 response, so type of props.bookingParties isn't string, as assserted by the endpoint.
                const bookingParties = this.props.bookingParties as unknown as IGetV2BookingResponse_BookingParty[];
                const bookingPartiesView = bookingParties.map(i => BookingPartyView.fromBookingParty(i));
                this.setState({bookingParties: bookingPartiesView});
            }
        }
    }

    private bookingHasExpired(): boolean
    {
        // current time is later than booking end time
        const buildingTimezone = DateHelper.getZoneByNode(this.props.buildingId);
        return DateTime.now().setZone(buildingTimezone) > this.props.endTime;
    }

    private get ownBooking(): boolean
    {
        return this.local.getUserDetails().email.toLowerCase() === this.props.bookingOwnerEmail.toLowerCase();
    }

    public render(): JSX.Element
    {
        const attendees = this.state.bookingParties.filter(i => i.bookingParticipantType===1 || i.bookingParticipantType===2).length; 
        
        // unless its their own booking (in which case show booking owner email), if user do not want to share their booking location, do not display their identity next to Host: field, display 'Anonymous' instead.
        const bookingHost = this.props.bookingShareLocation === 1 || this.ownBooking ? this.props.bookingOwnerEmail : this.labels.HubLabelAnonymous;

        // url for the view hypertext
        // only show the link if booking belongs to user and user has enabled share booking location. e.g. not enabled for dev admin
        const viewLinkURL = `/flex-my-bookings/${this.props.buildingId}/mybooking/${this.props.bookingId}/${this.props.spaceId}`
        
        // show a generic 'Booking Name' as the subject if it does not exist.
        const bookingName = this.props.bookingName? this.props.bookingName : this.labels.HubLabelBookingName;
        
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="view booking modal"
                fullWidth
                maxWidth={'sm'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <div></div>
                    </>
                }
                dialogContent=
                {
                    <>
                        <Box 
                            display='flex'
                            flexDirection='column'
                            sx={{padding: '1em'}}
                        >
                            <Box>
                                <Box sx={{borderRadius: '8px', border: `2px solid ${this.props.bookingColour}`, borderLeft: `5px solid ${this.props.bookingColour}`, mb: '1em'}}>
                                    <Typography sx={{fontSize: '2em', fontFamily: 'Source Sans Pro', fontWeight: 'bold', padding: '0.5em 0.5em'}}>
                                        {bookingName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='schedule-dialog-row'>
                                        <SvgIcon component={HostIcon} sx={{fontSize: '2.5em'}}></SvgIcon>
                                        <Typography className='schedule-dialog-info'>{`${this.labels.HubLabelHost}: ${bookingHost}`}</Typography>
                                    </Box>
                                    <Box className='schedule-dialog-row'>
                                        <SvgIcon component={CalendarIcon} sx={{fontSize: '2.5em'}}></SvgIcon>
                                        <Typography className='schedule-dialog-info'>{`${this.props.startTime.toLocaleDateString()}`}</Typography>
                                    </Box>
                                    <Box className='schedule-dialog-row'>
                                        <SvgIcon component={ClockIcon} sx={{fontSize: '2.5em'}}></SvgIcon>
                                        <Typography className='schedule-dialog-info'>{`${this.props.startTime.toLocaleTimeString()} - ${this.props.endTime.toLocaleTimeString()}`}</Typography>
                                    </Box>
                                    
                                    
                                    <Box className='schedule-dialog-row'>
                                        <SvgIcon component={AttendeeIcon} sx={{fontSize: '2.5em'}}></SvgIcon>
                                        <Typography className='schedule-dialog-info'>{`${this.props.bookingAttendees} ${this.labels.HubLabelAttendees}`}</Typography>
                                        {
                                        // only show the link if booking belongs to user and user has enabled share booking location. e.g. not enabled for dev admin
                                        this.props.bookingShareLocation === 1 && this.ownBooking && <Link href={viewLinkURL} sx={{pt: '0.1em', fontSize: '1.2em', fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>{this.labels.HubLabelView}</Link>
                                        }
                                    </Box>                                    
                                </Box>
                            </Box>
                            {this.ownBooking &&
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '0.5em'}}>
                                    <IbssButton
                                        variant={'contained'}
                                        size={'large'}
                                        disabled={this.bookingHasExpired()}
                                        onClick={()=> this.props.clickEditBooking(this.props.bookingOwnerEmail, this.props.bookingId, this.props.spaceId)}
                                    >
                                        {/* Edit */ }
                                        <Typography sx={{fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>
                                            {this.labels.HubButtonEdit}
                                        </Typography>
                                    </IbssButton>
                                    <IbssButton
                                        size={'large'}
                                        disabled={this.bookingHasExpired()}
                                        // same url routing as clicking on edit button of an event.
                                        onClick={()=> this.props.clickEditBooking(this.props.bookingOwnerEmail, this.props.bookingId, this.props.spaceId)}
                                    >
                                        {/* Delete */ }
                                        <Typography sx={{fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>
                                            {this.labels.HubButtonDelete}
                                        </Typography>
                                    </IbssButton>
                                </Box>
                            }
                        </Box>
                    </>
                }

            />
        )
    }
}

export default ViewBookingDialog;

export interface IProps
{
    show: boolean;
    onClose: () => void;
    bookingId: string;
    bookingColour: string;
    bookingName: string;
    bookingOwnerEmail: string;
    bookingParties: string;
    bookingShareLocation: number;
    buildingId: number;
    clickEditBooking: (bookingOwnerEmail: string, bookingId: string, spaceId: string) => void;
    spaceId: string;
    startTime: DateTime;
    endTime: DateTime;
    bookingAttendees: number;
}

export interface IState
{
    bookingParties: Array<BookingPartyView>;
}

export class BookingPartyView
{
    public bookingParticipantEmail = '';
    public bookingParticipantName = '';
    public bookingParticipantOrganisation = '';
    public bookingResourceId = '';
    public bookingVisitor = false;
    public bookingParticipantType = 0;

    public static fromBookingParty(bookingParty: IGetV2BookingResponse_BookingParty): BookingPartyView
    {
        return {
            bookingParticipantEmail: bookingParty.Booking_Participant_Email,
            bookingParticipantName: bookingParty.Booking_Participant_Name,
            bookingParticipantOrganisation: bookingParty.Booking_Participant_Organisation,
            bookingResourceId: bookingParty?.Booking_Resource_Id ?? '',
            bookingVisitor: bookingParty.Booking_Visitor,
            bookingParticipantType: bookingParty.Booking_Participant_Type,
        }
    }
}