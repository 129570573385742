import { Typography } from '@mui/material';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import IbssDialog from '../BaseDialog/IbssDialog';
import IbssButton from '../../Buttons/Button/IbssButton';
import { appContext } from '../../../AppContext';

class PreparingExportDialog extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                fullWidth
                header={this.labels.funcPreparingExport_S}
                dialogContent=
                {
                    <>
                        <Typography variant='subtitle1' className="mb-3">{this.labels.funcPreparingExport_Message}</Typography>
                    </>
                }
                footer=
                {
                    <>
                        <IbssButton
                            onClick={() => this.props.onClose()}
                            color="secondary"
                            variant="contained"
                        >
                            {this.labels.HubLabelOk}
                        </IbssButton>
                    </>
                }
            />
        )
    }
}

export default PreparingExportDialog;

export interface IProps
{
    open: boolean;
    onClose: () => void;
}

export interface IState
{
}