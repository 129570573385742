import { Component } from 'react';
import "./BookingDetailsWidget.scss"
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFloor } from './DataModels';
import { ISpace } from './DataModels';
import Helper from '../../../../Common/Helper';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { Box } from '@mui/material';

class BookingDetailsWidget extends Component<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider}
    private canApproveCatering: boolean;

    constructor(props: IProps)
    {
        super(props);

        this.canApproveCatering = this.local.permissionAppliesToBuilding("API.Catering.Approve", this.props.buildingId);
        this.state =
        {
            selectedSpaceId: '',
            selectedFloorId: 0,
            spaceOptions: [],
            floorOptions: [],
        };
    }

    public componentDidMount(): void 
    {
        this.setState({selectedSpaceId: this.props.spaceId});
        this.populateFloors(this.props.buildingId);
        this.populateSpaceOptions();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void 
    {
        if(this.props.spaceId !== prevProps.spaceId && this.props.spaceId)
        {
            this.setState({
                selectedSpaceId: this.props.spaceId,
                selectedFloorId: this.props.spaces.find(space => space.Space_Id === this.props.spaceId)?.Node_Id ?? 0,
            });
        }

        if(this.props.buildingId !== prevProps.buildingId && this.props.buildingId)
        {
            this.populateFloors(this.props.buildingId);
        }

        if(this.props.spaces.length !== prevProps.spaces.length)
        {
            this.populateSpaceOptions();
        }
    }

    private populateFloors(selectedBuildingId: number): void
    {
        const floors = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name

        this.setState({ floorOptions: options });
    }

    private populateSpaceOptions(): void
    {
        const spaceOptions = this.props.spaces.map(space => ({label: space.Space_Name, value: space.Space_Id, nodeId: space.Node_Id }));
        this.setState({ spaceOptions: spaceOptions });
    }

    private changeFloor(floorId: number): void
    {
        this.setState({selectedFloorId: floorId})
    }
    
    private changeSpace(spaceId: string): void
    {
        this.setState({selectedSpaceId: spaceId});
    }

    private confirmChangeSpace(): void
    {
        const selectedSpace = this.state.spaceOptions.filter(space => space.value === this.state.selectedSpaceId)[0];
        const { nodeId, label, value } = selectedSpace;
        this.props.changeCateringSpace(nodeId, value, label);
    }

    private get visibleSpaceOptions(): ISpaceOption[]
    {
        return this.state.spaceOptions.filter(space => space.nodeId === this.state.floorOptions.filter(floor => floor.value === this.state.selectedFloorId)[0]?.value)
    }

    public render(): JSX.Element
    {
        return (
            <div className='bookingDetailsContainer'>
                {
                    this.props.spaceImageUrl && this.props.spaceImageUrl.length > 0 ?
                        <img style={{ width: 75, height: 75, borderRadius: 4 }} src={this.props.spaceImageUrl} />
                        :
                        <div style={{ width: 75, height: 75, borderRadius: 4, background: 'grey' }}></div>
                }
                <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                    <div style={{ fontWeight: '600' }}>{this.props.spaceName}</div>
                    <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                            <div style={{ fontSize: 17, height: 18 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.calenderIcon}
                                </IbssSvgIcon>
                            </div>
                            <div className='font-subheading'>{this.props.start.toLocaleString(DateTime.DATE_SHORT)}</div>
                        </div>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                            <div style={{ fontSize: 17, height: 15 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.TimeIcon}
                                </IbssSvgIcon>
                            </div>
                            <div className='font-subheading'>{this.props.start.toLocaleString(DateTime.TIME_24_SIMPLE)} - {this.props.end.toLocaleString(DateTime.TIME_SIMPLE)}</div>
                        </div>
                    </div>
                    <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex' }}>
                            <div style={{ fontSize: 11, height: 15 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.UserIcon}
                                </IbssSvgIcon>
                            </div>
                            {
                                this.props.host != "" &&
                                <div>
                                    <div className='font-subheading'>{this.labels.HubLabelHost}: {this.props.host}</div>
                                    <div className='font-subheading'>•</div>
                                </div>
                            }
                            <div className='font-subheading'>{this.labels.HubLabelOwner}: {this.props.owner}</div>
                        </div>
                    </div>
                    {(this.props.userHasCateringRights && this.canApproveCatering && !this.props.newOrder) ?
                        <>
                            <div style={{ display: 'flex', gap: 10, paddingTop: '1rem', width: '100%' }}>
                                <IbssInputDropDown
                                    inputLabel={this.labels.HubLabelFloor}
                                    options={this.state.floorOptions}
                                    value={this.state.selectedFloorId}
                                    id={"floorSelection"}
                                    onChange={e => this.changeFloor(e.target.value)}
                                />

                                <IbssInputDropDown
                                    inputLabel={this.labels.HubLabelSpace}
                                    options={this.visibleSpaceOptions}
                                    value={this.state.selectedSpaceId}
                                    id={"spaceSelection"}
                                    onChange={e => this.changeSpace(e.target.value)}
                                />
                            </div>
                            <Box sx={{ alignSelf: 'flex-end' }}>
                                <IbssButton
                                    className='mr-2'
                                    variant={'contained'}
                                    onClick={() => this.props.viewBooking()}
                                >
                                    {this.labels.funcViewBooking_S}
                                </IbssButton>
                                <IbssButton
                                    disabled={this.props.spaceId === this.state.selectedSpaceId}
                                    variant={'contained'}
                                    onClick={() => this.confirmChangeSpace()}
                                >
                                    {this.labels.funcSwapSpaceSwap_S}
                                </IbssButton>
                            </Box>
                        </>
                        :
                        <Box sx={{ alignSelf: 'flex-end' }}>
                            <IbssButton
                                variant={'contained'}
                                onClick={() => this.props.viewBooking()}
                            >
                                {this.labels.funcViewBooking_S}
                            </IbssButton>
                        </Box>
                    }
                </div>
            </div>
        )
    }
}

export default BookingDetailsWidget;

export interface IProps
{
    buildingId: number;
    spaceName: string;
    spaceImageUrl: string;
    spaceId: string;
    spaces: ISpace[];
    start: DateTime;
    end: DateTime;
    host: string;
    owner: string;
    changeCateringSpace: (nodeId: number, spaceId: string, spaceName: string) => void;
    userHasCateringRights: boolean;
    newOrder: boolean;
    viewBooking: () => void;
}

export interface IState
{
    selectedSpaceId: string;
    selectedFloorId: number;
    spaceOptions: ISpaceOption[];
    floorOptions: IFloorOption[];
}

interface ISpaceOption
{
    label: string;
    value: string;
    nodeId: number;
}

interface IFloorOption
{
    label: string;
    value: number;
}