import { DateHelper } from "../../../../Common/DateHelper";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export interface IUser
{
    email: string,
}

export class CateringOrder extends ODataSelect
{
    public Node_Id = 0;
    public ID = 0;
    public Booking_Name = '';
    public CreatedBy = '';
    public Space_Name = '';
    public Space_Id = '';
    public Booking_Id = '';
    public Catering_Service_Time = DateHelper.null();
    public Catering_Clearing_Time = DateHelper.null();
    public Booking_Start = DateHelper.null();
    public Booking_End = DateHelper.null();
    public Order_Id = '';
    public Menu_Items: IMenuItem[] = [];
    public OwnedBy = '';
    public OwnedById = '';
    public Catering_Order_Status = '';

    public init(response: any, nodeId: number): CateringOrder
    {
        super.init(response, nodeId);
        this.Catering_Service_Time = DateHelper.fromIsoByNode(response.Catering_Service_Time, nodeId);
        this.Catering_Clearing_Time = DateHelper.fromIsoByNode(response.Catering_Clearing_Time, nodeId);
        this.Booking_Start = DateHelper.fromIsoByNode(response.Booking_Start, nodeId);
        this.Booking_End = DateHelper.fromIsoByNode(response.Booking_End, nodeId);
        
        try
        {
            this.Menu_Items = JSON.parse(response.Menu_Items);
        }
        catch (error)
        {
            this.Menu_Items = [];
        }
        return this;
    }
}

export interface IMenuItem
{
    Id: string;
    Menu_Id: string;
    Description?: string;
    QuantityOfItems: number;
}
