import Desk from '../Components/Icons/SpaceTypeIcons/desk';
import BuildingIcon from '../Components/Icons/BuildingIcon';
import CalendarIcon from '../Components/Icons/CalendarIcon';
import DataPrivacyIcon from '../Components/Icons/DataPrivacyIcon';
import NotificationIcon from '../Components/Icons/NotificationIcon';
import SearchResultIcon from '../Components/Icons/SearchResultIcon';
import SecurityIcon from '../Components/Icons/SecurityIcon';
import Bar from '../Components/Icons/SpaceTypeIcons/bar';
import Benchseating from '../Components/Icons/SpaceTypeIcons/benchseating';
import Bikestore from '../Components/Icons/SpaceTypeIcons/bikestore';
import Boardroom from '../Components/Icons/SpaceTypeIcons/boardroom';
import Café from '../Components/Icons/SpaceTypeIcons/café';
import Changing from '../Components/Icons/SpaceTypeIcons/changing';
import Cleaningstation from '../Components/Icons/SpaceTypeIcons/cleaningstation';
import Coatstorage from '../Components/Icons/SpaceTypeIcons/coatstorage';
import Collaborationspace from '../Components/Icons/SpaceTypeIcons/collaborationspace';
import Drink from '../Components/Icons/SpaceTypeIcons/drink';
import Equipmentroom from '../Components/Icons/SpaceTypeIcons/equipmentroom';
import Fault from '../Components/Icons/SpaceTypeIcons/fault';
import Firstaid from '../Components/Icons/SpaceTypeIcons/firstaid';
import Focusbooth from '../Components/Icons/SpaceTypeIcons/focusbooth';
import Gym from '../Components/Icons/SpaceTypeIcons/gym';
import Height from '../Components/Icons/SpaceTypeIcons/height';
import Indoorgarden from '../Components/Icons/SpaceTypeIcons/indoorgarden';
import Informallounge from '../Components/Icons/SpaceTypeIcons/informallounge';
import Informalmeeting from '../Components/Icons/SpaceTypeIcons/informalmeeting';
import Kitchenette from '../Components/Icons/SpaceTypeIcons/kitchenette';
import Lecturetheatre from '../Components/Icons/SpaceTypeIcons/lecturetheatre';
import Liftlobby from '../Components/Icons/SpaceTypeIcons/liftlobby';
import Lightsoff from '../Components/Icons/SpaceTypeIcons/lightsoff';
import Lightson from '../Components/Icons/SpaceTypeIcons/lightson';
import Lockers from '../Components/Icons/SpaceTypeIcons/lockers';
import Meetingbooth from '../Components/Icons/SpaceTypeIcons/meetingbooth';
import Meetingroom from '../Components/Icons/SpaceTypeIcons/meetingroom';
import Office from '../Components/Icons/SpaceTypeIcons/office';
import Pantry from '../Components/Icons/SpaceTypeIcons/pantry';
import Parcellocker from '../Components/Icons/SpaceTypeIcons/parcellocker';
import Parkingspace from '../Components/Icons/SpaceTypeIcons/parkingspace';
import Phonebooth from '../Components/Icons/SpaceTypeIcons/phonebooth';
import Plantroom from '../Components/Icons/SpaceTypeIcons/plantroom';
import Postroom from '../Components/Icons/SpaceTypeIcons/postroom';
import Prayer from '../Components/Icons/SpaceTypeIcons/prayer';
import Prayerroom from '../Components/Icons/SpaceTypeIcons/prayerroom';
import Presentation from '../Components/Icons/SpaceTypeIcons/presentation';
import Printroom from '../Components/Icons/SpaceTypeIcons/printroom';
import Privateroom from '../Components/Icons/SpaceTypeIcons/privateroom';
import Quickstop from '../Components/Icons/SpaceTypeIcons/quickstop';
import Quietroom from '../Components/Icons/SpaceTypeIcons/quietroom';
import Reception from '../Components/Icons/SpaceTypeIcons/reception';
import Restaurant from '../Components/Icons/SpaceTypeIcons/restaurant';
import Security from '../Components/Icons/SpaceTypeIcons/security';
import Sitstanddesk from '../Components/Icons/SpaceTypeIcons/sitstanddesk';
import Specialistdesk from '../Components/Icons/SpaceTypeIcons/specialistdesk';
import Stationery from '../Components/Icons/SpaceTypeIcons/stationery';
import Storage from '../Components/Icons/SpaceTypeIcons/stationery';
import Temperature from '../Components/Icons/SpaceTypeIcons/temperature';
import Toilets from '../Components/Icons/SpaceTypeIcons/toilets';
import Toiletsaccessible from '../Components/Icons/SpaceTypeIcons/toiletsaccessible';
import Toiletsfemale from '../Components/Icons/SpaceTypeIcons/toiletsfemale';
import Toiletsmale from '../Components/Icons/SpaceTypeIcons/toiletsmale';
import Touchdown from '../Components/Icons/SpaceTypeIcons/touchdown';
import Trainingroom from '../Components/Icons/SpaceTypeIcons/trainingroom';
import Treatmentroom from '../Components/Icons/SpaceTypeIcons/treatmentroom';
import Vcroom from '../Components/Icons/SpaceTypeIcons/vcroom';
import Waitinglounge from '../Components/Icons/SpaceTypeIcons/waitinglounge';
import Waterpoint from '../Components/Icons/SpaceTypeIcons/waterpoint';
import Wellbeing from '../Components/Icons/SpaceTypeIcons/wellbeing';
import TaskIcon from '../Components/Icons/TaskIcon';
import WorkingDayIcon from '../Components/Icons/WorkingDayIcon';
import RightArrowIcon from '../Components/Icons/RightArrowIcon';
import CloseIcon from '../Components/Icons/CloseIcon';
import DownAngleIcon from '../Components/Icons/DownAngleIcon';
import UpAngleIcon from '../Components/Icons/UpAngleIcon';
import IdProvider from '../Components/Icons/IdProviderIcon';
import UserIcon from '../Components/Icons/UserIcon';
import OrganisationIcon from '../Components/Icons/OrganisationIcon';
import RegionIcon from '../Components/Icons/RegionIcon';
import FloorIcon from '../Components/Icons/FlooorIcon';
import ZoneIcon from '../Components/Icons/ZoneIcon';
import MobileIcon from '../Components/Icons/MobileIcon';
import TabletIcon from '../Components/Icons/TabletIcon';
import KiskoIcon from '../Components/Icons/KiskoIcon';
import MobileDeviceIcon from '../Components/Icons/MobileDevicesIcon';
import DrinkIcon from '../Components/Icons/DrinkIcon';
import VisitorIcon from '../Components/Icons/VisitorIcon';
import OverviewIcon from '../Components/Icons/OverviewIcon';
import SensorIcon from '../Components/Icons/SensorIcon';
import Success from '../Components/Icons/Success';
import TimeIcon from '../Components/Icons/TimeIcon';
import StandardDesk from '../Components/Icons/StandarDesk';
import Bin from '../Components/Icons/Bin';
import AssignSpacesIcon from '../Components/Icons/AssignSpacesIcon';
import AddIcon from '../Components/Icons/AddIcon';
import WarningIcon from '../Components/Icons/WarningIcon';
import EditIcon from '../Components/Icons/EditIcon';
import TimerIcon from '../Components/Icons/TimerIcon';
import TickIcon from '../Components/Icons/TickIcon'
import CostCodeIcon from '../Components/Icons/CostCodeIcon';
import AddAttendeeIcon from '../Components/Icons/AddAttendeeIcon';
import HearingAidIcon from '../Components/Icons/HearingAidIcon';
import PresentationAidIcon from '../Components/Icons/PresentationAidIcon';
import CateringIcon from '../Components/Icons/CateringIcon';
import RepeatIcon from '../Components/Icons/RepeatIcon';
import SeatingArrangementIcon from '../Components/Icons/SeatingArrangement';
import SpaceLayoutIcon from '../Components/Icons/SpaceLayoutIcon';
import LockIcon from '../Components/Icons/LockIcon';
import GearIcon from '../Components/Icons/GearIcon';
import SpaceBuildingIcon from '../Components/Icons/SpaceBuildingIcon';
import KeyIcon from '../Components/Icons/KeyIcon';
import LocationIcon from '../Components/Icons/LocationIcon';
import SettingIcon from '../Components/Icons/SettingIcon';
import SpacesIcon from '../Components/Icons/SpacesIcon';
import DeviceIcon from '../Components/Icons/DeviceIcon';
import MapPinIcon from '../Components/Icons/MapPinIcon';
import InfoIcon from '../Components/Icons/InfoIcon';
import DisketteSave from '../Components/Icons/DisketteSave';
import AddUser from '../Components/Icons/AddUser';
import CheckListIcon from '../Components/Icons/CheckListIcon';
import LinkIcon from '../Components/Icons/LinkIcon';
import BuildingSetupIcon from '../Components/Icons/BuildingSetup';
import BuildingOverviewIcon from '../Components/Icons/BuildingOverviewIcon';
import SoundLevelIcon from '../Components/Icons/SoundLevel';
import EnvironmentIcon from '../Components/Icons/Environment';
import HumidityIcon from '../Components/Icons/humidity';
import InfoOutlineIcon from '../Components/Icons/InfoOutlineIcon';

export const Icons =
{
    building: <BuildingIcon />,
    bar: <Bar />,
    benchSeating: <Benchseating />,
    bikeStore: <Bikestore />,
    boardroom: <Boardroom />,
    cafe: <Café />,
    changing: <Changing />,
    cleaningStation: <Cleaningstation />,
    coatStorage: <Coatstorage />,
    collaborationSpace: <Collaborationspace />,
    desk: <Desk />,
    drink: <Drink />,
    euipmentRoom: <Equipmentroom />,
    fault: <Fault />,
    firstaid: <Firstaid />,
    focusbooth: <Focusbooth />,
    gym: <Gym />,
    height: <Height />,
    indoorGarden: <Indoorgarden />,
    InformalLounge: <Informallounge />,
    informalMeeting: <Informalmeeting />,
    kitchenette: <Kitchenette />,
    lectureTheater: <Lecturetheatre />,
    liftLobby: <Liftlobby />,
    lightsOff: <Lightsoff />,
    lightsOn: <Lightson />,
    lockers: <Lockers />,
    meetingbooth: <Meetingbooth />,
    meetingRoom: <Meetingroom />,
    office: <Office />,
    pantry: <Pantry />,
    parcelLoceker: <Parcellocker />,
    parkingSpace: <Parkingspace />,
    phoneBooth: <Phonebooth />,
    plantRoom: <Plantroom />,
    postRoom: <Postroom />,
    prayer: <Prayer />,
    prayerRoom: <Prayerroom />,
    presentation: <Presentation />,
    printRoom: <Printroom />,
    privateRoom: <Privateroom />,
    quickStop: <Quickstop />,
    quiteRoom: <Quietroom />,
    reception: <Reception />,
    restaurant: <Restaurant />,
    security: <Security />,
    sitStandDesk: <Sitstanddesk />,
    specialistDesk: <Specialistdesk />,
    stationaery: <Stationery />,
    storage: <Storage />,
    temperature: <Temperature />,
    toilets: <Toilets />,
    toiletsAccessible: <Toiletsaccessible />,
    toiletsFemale: <Toiletsfemale />,
    toiletsMale: <Toiletsmale />,
    touchDown: <Touchdown />,
    trainingRoom: <Trainingroom />,
    treatmentRoom: <Treatmentroom />,
    vcRoom: <Vcroom />,
    waitingLounge: <Waitinglounge />,
    waterPoint: <Waterpoint />,
    wellbeing: <Wellbeing />,
    calenderIcon: <CalendarIcon />,
    dataPrivacyIcon: <DataPrivacyIcon />,
    notificationIcon: <NotificationIcon />,
    searchResultIcon: <SearchResultIcon />,
    securityIcon: <SecurityIcon />,
    taskIcon: <TaskIcon />,
    workingDayIcon: <WorkingDayIcon />,
    RightArrowIcon: <RightArrowIcon />,
    IdProvider: <IdProvider />,    CloseIcon: <CloseIcon />,
    DownAngleIcon: <DownAngleIcon />,
    upAngleIcon: <UpAngleIcon />,

    UserIcon: <UserIcon />,
    OrganisationIcon: < OrganisationIcon />,
    RegionIcon: <RegionIcon />,
    FloorIcon: <FloorIcon />,
    ZoneIcon: <ZoneIcon />,
    MobileIcon: <MobileIcon />,
    TabletIcon: <TabletIcon />,
    KiskoIcon: <KiskoIcon />,
    MobileDevice: <MobileDeviceIcon />,
    DrinkIcon: <DrinkIcon />,
    VisitorIcon: <VisitorIcon />,
    OverviewIcon: <OverviewIcon />,
    SensorIcon: <SensorIcon />,
    Success:<Success />,
    TimeIcon:<TimeIcon />,
    StandardDesk:<StandardDesk />,
    Bin:<Bin />,
    AssignSpacesIcon:<AssignSpacesIcon />,
    AddIcon: <AddIcon />,
    WarningIcon: <WarningIcon />,
    EditIcon: <EditIcon />,
    TimerIcon: <TimerIcon />,
    TickIcon: <TickIcon/>,
    CostCodeIcon: <CostCodeIcon />,
    AddAttendeeIcon: <AddAttendeeIcon />,
    HearingAidIcon: <HearingAidIcon />,
    PresentationAidIcon: <PresentationAidIcon />,
    CateringIcon: <CateringIcon />,
    RepeatIcon: <RepeatIcon />,
    SeatingArrangementIcon: <SeatingArrangementIcon />,
    SpaceLayoutIcon: <SpaceLayoutIcon />,
    LockIcon: <LockIcon />,
    GearIcon: <GearIcon />,
    SpaceBuildingIcon: <SpaceBuildingIcon /> ,   
    KeyIcon: <KeyIcon /> ,   
    SpacesIcon: <SpacesIcon /> ,   
    LocationIcon: <LocationIcon /> ,   
    SettingIcon: <SettingIcon /> ,   
    DeviceIcon: <DeviceIcon />,
    MapPinIcon: <MapPinIcon />,
    InfoIcon: <InfoIcon />,
    DisketteSave:<DisketteSave />,
    AddUser: <AddUser/>,
    CheckListIcon: <CheckListIcon/>,
    LinkIcon: <LinkIcon />,
    BuildingSetup: <BuildingSetupIcon />,
    BuildingOverview: <BuildingOverviewIcon />,
    SoundLevelIcon: <SoundLevelIcon />,
    EnvironmentIcon: <EnvironmentIcon />,
    HumidityIcon: <HumidityIcon/>,
    InfoOutlineIcon: <InfoOutlineIcon />
}
