import * as React from 'react';
import { Button, ButtonProps, Grid, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';

const IbsssActionButtonRedo = styled(Button)<ButtonProps>(({ theme }) => (
{
    '& MuiButtonBase-root': {
        "&.MuiButtonBase-root-MuiButton-root:hover:disabled": {
            backgroundColor: "rgba(244, 67, 54, 0.04)",
        },
    },
}));

export default class IbssActionButton extends React.Component<IProps>
{
    public render(): JSX.Element
    {
        return (
            <>
                {this.props.title && <Grid item sx={{ display: 'flex', alignItems: 'center', mb: 1, mr: 0 }}>
                    <Typography variant="body1">{this.props.title}</Typography>
                </Grid>}
                <Grid item sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', mb: 1, mr: 0 }}>
                    {this.props.buttons.map(item =>
                        item.className === "addIcon" ? <React.Fragment key={item.label}>
                            <Box sx={{ borderLeft: "1px solid var(--ui-line-break)", height: "30px", marginRight: "16px", display: item.display }}></Box>
                            <IbsssActionButtonRedo 
                                key={item.label} 
                                className={item.className} 
                                disabled={item.disabled} 
                                sx={{ mr: 2, display: item.display}} 
                                startIcon={item.icon} 
                                variant="text" 
                                color={item.color}
                                onClick={item.onClick} 
                                children={<div>{item.label}</div>} />
                        </React.Fragment>
                            :
                            <div key={item.label} style={{ color: item.labelColor && item.color == 'inherit' ? item.labelColor : '' }}>
                                <IbsssActionButtonRedo 
                                    key={item.label} 
                                    className={item.className} 
                                    disabled={item.disabled} 
                                    sx={{ mr: 2, display: item.display}} 
                                    startIcon={item.icon} 
                                    variant="text" 
                                    color={item.color} 
                                    onClick={item.onClick} 
                                    children={<div>{item.label}</div>} />
                            </div>
                    )}
                </Grid>
            </>
        )
    }
}

export interface IProps
{
    buttons: IActionButton[];
    title?: string;
}

export interface IActionButton
{
    label: string;
    className?: string;
    icon: React.ReactNode;
    color: "primary" | "error" | "inherit" | "secondary" | "success" | "info" | "warning";
    labelColor?: string;
    onClick: () => void;
    disabled?: boolean;
    display?: string;
}
