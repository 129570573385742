import { WeekdayNumbers } from "luxon";
import { IBookableSlot } from "./BookableSpaces";

export class BookableSlotAdapter implements IBookableSlot
{
    public bookingPolicyId = '';
    public dayOfWeek: WeekdayNumbers = 1;
    public startTimeOfDay = '';
    public endTimeOfDay = '';

    public static fromBookingPolicies(bookingPolicies: IBookingPolicy[]): BookableSlotAdapter[]
    {
        const bookableSlots = bookingPolicies
            .flatMap(policy => policy.Booking_Policy.BookingSlots.BookableTime.map(time => ({ policy: policy, value: time })))
            .flatMap(bookableTime => bookableTime.value.Days.map(day => ({ policy: bookableTime.policy, day: day, time: bookableTime.value })))
            .map(bookableSlot => ({
                bookingPolicyId: bookableSlot.policy.Booking_Policy_Id,
                dayOfWeek: parseInt(bookableSlot.day.toString()) as WeekdayNumbers,
                startTimeOfDay: bookableSlot.time.StartTime,
                endTimeOfDay: bookableSlot.time.EndTime,
            }))
            .filter(i => !isNaN(i.dayOfWeek) && i.startTimeOfDay.match(/^\d\d:\d\d$/) && i.endTimeOfDay.match(/^\d\d:\d\d$/));

        // normalise 23:59 end time to 24:00
        bookableSlots.filter(i => i.endTimeOfDay == '23:59').forEach(i => i.endTimeOfDay = '24:00');

        return bookableSlots;
    }
}

export interface IBookingPolicy
{
    Booking_Policy_Id: string;
    Booking_Policy: IBookingPolicyObject;
}

export interface IBookingPolicyObject 
{
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots
{
    BookableTime: IBookableTime[],
}

export interface IBookableTime
{
    Days: number[];
    StartTime: string;
    EndTime: string;
}
