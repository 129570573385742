import { Component } from 'react'
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import IbssNotificationCard from '../../../../Components/Cards/NotificationCard/IbssNotificationCard'
import { NotificationListProps } from './FlexNotificationProps'
import NoNotificationPage from './NoNotificationPage';

type myNotificationList = NotificationListProps[]

export default class YourNotifications extends Component<{ myNotificationList: myNotificationList, setNotificationDetails: (value: NotificationListProps) => void, ChipList: any }> {
    render() {
        const { myNotificationList, ChipList } = this.props;
        return (
            <div>
                {ChipList.map((item: any) =>
                    <IbssChip
                        key={item.label}
                        label={item.label}
                        onClick={() => item.onClick("yourAlert")}
                        className={item.className}
                        variant="filled"
                        tabIndex={0} 
                        sx={{
                            outline: 'none',
                            '&:focus': {
                                outline: '2px solid var(--ui-primary-light)',
                            }
                        }}
                    />
                )}
                {myNotificationList.length > 0 && myNotificationList?.map((item: NotificationListProps) => (
                    <IbssNotificationCard
                        key={item.Notification_Id}
                        imageSrc={item.ImageURI}
                        notificationHeading={item.Notification_Title}
                        notificationSubClassification={item.Subclassification}
                        notificationSubDescription={item.Notification_Short_Message}
                        priority={item.Notification_Priority}
                        availableFrom={item.Available_From}
                        read={item.read}
                        elevation={0}
                        onClick={() => this.props.setNotificationDetails({
                            ...item,
                            read: true
                        })}
                    />
                ))}
                {myNotificationList.length === 0 && (
                    <NoNotificationPage />
                )}
            </div>
        )
    }
}
