import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import Helper, { handleSortTableDataDivWrapProperyArray } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { Link, RouteComponentProps, RouterProps, generatePath } from "react-router-dom";
import { IPartialAppState, appContext } from "../../../../AppContext";
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import AddIcon from "../../../../Components/Icons/AddIcon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import EditIcon from "@mui/icons-material/Edit";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import IbssPageHeader from "../../../../Components/Forms/DateRange/IbssPageHeader";
import { IUserNotifications } from "../../../../Providers.Api/UserNotifications/GeManyEndpoint";
import { UserNotificationsFilter } from "../../../../Providers.Api/UserNotifications/UserNotificationsRepository";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListUserNotifications extends IbssPage<IProps, IState> 
{
  private get apiClient() {return appContext().apiClient;}
  private get alert() { return appContext().alert; }
  private get appState() { return appContext().state; }
  private get labels() { return appContext().labels; }
  private get local() { return appContext().localStorageProvider; }


  constructor(props: IProps) 
  {
    super(props);
    this.state = 
    {
      isLoading: false,
      NotificationList: [],
      buildingId: this.appState.buildingId,
      searchTerm: "",
      selectedNotificationId: [],
      disableCancel: true,
      daysFilter: this.labels.HubLabelToday,
      startDate: DateHelper.now(),
      endDate: DateHelper.now(),
      dateDisabled: true,
    };
  }

  public async componentDidMount(): Promise<void> 
  {
    const { history } = this.props;
    this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    const buildingId = parseInt(this.props.match.params["buildingid"]);
    this.setState({ daysFilter: this.labels.HubLabelToday });
    if (buildingId !== undefined)
    {
        await this.appState.set({ buildingId: buildingId });
        if (buildingId !== 0)
        {
          history.push("/operational-services-notifications/" + this.state.buildingId);
        }
        else 
        {
          this.alert.show("", this.labels.HubLabelSelectBuildingError);
        }
      }
      this.setState({
        startDate: this.state.startDate.date().setZoneByNode(this.state.buildingId),
        endDate: this.state.endDate.date().setZoneByNode(this.state.buildingId),
        daysFilter: this.labels.HubTabToday,
      });
      this.handleGetNotificationData();
  }


  public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
  {
      if (prevState.buildingId != this.state.buildingId)
      {
          const { history } = this.props;
          const buildingID = this.state.buildingId;
          const path = generatePath(this.props.match.path, { buildingid: buildingID });
          history.replace(path);
          await this.handleGetNotificationData();
      }
  }


  public async handleGetNotificationData(): Promise<void> 
  {
    this.setState({ isLoading: true });  
    const userNotificationsFilter =  new UserNotificationsFilter ({
      startDate: this.state.startDate.setZoneByNode(this.state.buildingId).startOf('day'),
      endDate: this.state.endDate.setZoneByNode(this.state.buildingId).plus({ days: 1 }).startOf('day'),
      classification:'Building'
    })                                                                                                                                                                      
    try                                                                                                                                                                     
    {
      const userNotificationsData = await this.apiClient.userNotifications.getMany(this.state.buildingId,userNotificationsFilter);
      const notificationsList = userNotificationsData.map(i => NotificationView.fromResponse(i));
      await this.setStateAsync({ NotificationList: notificationsList, isLoading: false });
    } 
    catch (error) 
    {
      this.setState({
        isLoading: false,
      });
    }
  }

  private async dateRangeChanged(e: SelectChangeEvent<string>): Promise<void>
  {
    this.setState({ daysFilter: e.target.value });

    if (e.target.value === this.labels.HubLabelToday)
    {
        await this.setStateAsync({
            startDate: DateTime.now().date(),
            endDate: DateTime.now().date(),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
    if (e.target.value === this.labels.HubLabelTomorrow)
    {
        await this.setStateAsync({
            startDate: DateTime.now().date().plus({ days: 1 }),
            endDate: DateTime.now().date().plus({ days: 1 }),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
    if (e.target.value === this.labels.HubLabelThisWeek)
    {
        await this.setStateAsync({
            startDate: DateTime.now().startOf('week'),
            endDate: DateTime.now().startOf('week').plus({ days: 6 }),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
    if (e.target.value === this.labels.HubLabelNextWeek)
    {
        await this.setStateAsync({
            startDate: DateTime.now().startOf('week').plus({ days: 7 }),
            endDate: DateTime.now().startOf('week').plus({ days: 13 }),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
    if (e.target.value === this.labels.HubLabelThisMonth)
    {
        await this.setStateAsync({
            startDate: DateTime.now().startOf('month'),
            endDate: DateTime.now().startOf('month').plus({ months: 1, days: -1 }),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
    if (e.target.value === this.labels.HubLabelNextMonth)
    {
        await this.setStateAsync({
            startDate: DateTime.now().startOf('month').plus({ months: 1 }),
            endDate: DateTime.now().startOf('month').plus({ months: 2, days: -1 }),
            dateDisabled: true,
        });
        await this.handleGetNotificationData();
    }
  }

  private filterNotifications(searchTerm: string): NotificationView[]
  {
      const filteredNotifications = this.state.NotificationList.filter(notification =>
      {
          let key: keyof NotificationView;
          for (key in notification)
          {
              if (notification[key]?.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase()))
              {
                  return true;
              }
          }
          return false;
      });
      return filteredNotifications;
  }

  private notificationSelected(selection: GridRowSelectionModel): void
  {
      const selectedOrders: NotificationView[] = [];
      selection.forEach((id) =>
      {
          const orderData = this.filterNotifications(this.state.searchTerm).find(i => i.id === id);
          if(orderData)
          {
              selectedOrders.push(orderData)
          }
      });

      if(selectedOrders.length > 0)
      {
        this.setState({
          selectedNotificationId:selectedOrders,
          disableCancel: false,
        })
      } 
      else 
      {
        this.setState({
          disableCancel: true,
        })
      }
  }


  private renderNotificationAction(value: number, nodeId: number): React.ReactNode
  {
      return (
          <>
            <IbssSvgIcon
                className="pointer"
                onClick={() => this.props.history.push(`/operational-services-notifications/${value}/notifications/${nodeId}`)}
            >
                <EditIcon />
            </IbssSvgIcon>
          </>
      );
  }

  public render(): JSX.Element 
  {

    const dataGridHeader: GridColDef<NotificationView>[] = 
    [
      {
        headerName: this.labels.HubLabelClass,
        field: Helper.nameOf<NotificationView>("classification"),
        minWidth: 150,
      },
      {
        headerName: this.labels.HubLabelSubClass,
        field: Helper.nameOf<NotificationView>("subClassification"),
        minWidth: 250,
      },
      {
        headerName: this.labels.funcNotificationsEventCreatedAt_L,
        field: Helper.nameOf<NotificationView>("eventCreatedAt"),
        minWidth: 150,
        flex: 2,
        valueGetter: params => params.row.eventCreatedAt && params.row.eventCreatedAt.toLocaleDateTimeString()
      },
      {
        headerName: this.labels.HubLabelAction,
        field: "",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => this.renderNotificationAction(params.row.nodeId, params.row.id)
      },
    ];
    
    const filteredNotifications = this.filterNotifications(this.state.searchTerm);
  
    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
              <IbssPageHeader
                title={this.labels.HubLabelNotifications}
                selectedPreset={this.state.daysFilter}
                presetChanged={(e) => this.dateRangeChanged(e)}
                startDate={this.state.startDate.toJSDate()}
                endDate={this.state.endDate.toJSDate()}
                datesDisabled={this.state.dateDisabled}
                excludeCustom={true}
                presets={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
              />
               <div className="table-panel-description">
                  {this.labels.funcSystemNotificationTableDescriptionPt1_Message}
               </div>
            <Grid
              container
              rowSpacing={1}
              sx={{ display: "flex", alignItems: "center", mt: 0, ml: 0 }}
            >
              <Grid item md={12}>
                <Box>
                  <IbssFilter
                    showExport={false}
                    searchTerm={this.state.searchTerm}
                    onSearchTermChange={(event) => this.setState({ searchTerm: event.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
            
            <div className="position-relative">
              {this.state.isLoading  && <Spinner /> }
                <Box sx={{ mt: 1 }}>
                  <IbssDataGrid
                    height="calc(100vh - 318px)"
                    checkboxSelection
                    columns={dataGridHeader}
                    disableRowSelectionOnClick
                    rows={filteredNotifications}
                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.notificationSelected(rowSelectionModel)}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pageSizeOptions={[25, 50, 100]}
                    />
                </Box>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class NotificationView
{
    public id = 0;
    public nodeId = 0;
    public classification = "";
    public subClassification = "";
    public eventCreatedAt: DateTime | null = null;

    constructor(data: Partial<NotificationView>)
    {
        Object.assign(this, data);
    }

    public static fromResponse(menu: IUserNotifications)
    {
        const menuView = new NotificationView(
            {
                id: menu.Notification_Id,
                nodeId: menu.Node_Id,
                classification: menu.Classification,
                subClassification: menu.Subclassification,
                eventCreatedAt: menu._EventTime.offsetTimeByNode(menu.Node_Id),
            });
        return menuView;
    }
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
}

interface IState 
{
  isLoading: boolean;
  buildingId: number;
  disableCancel: boolean;
  NotificationList: NotificationView[];
  searchTerm: string;
  selectedNotificationId: NotificationView[];
  daysFilter: string;
  startDate: DateTime;
  endDate: DateTime;
  dateDisabled: boolean;
}

export default ListUserNotifications;
