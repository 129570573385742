import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';
import { appContext } from '../../../AppContext';

// There are three variants
// outlined = "outlined", the default is outlined input!
// filled = "filled", filled text input,
// standard = "standard", underlined text input

type IbssTextFieldProps = TextFieldProps & {
    maxLength?: number;
}

class IbssTextField extends React.Component<IbssTextFieldProps> 
{
    private labels = appContext().labels;
    render(): JSX.Element 
    {
        const { maxLength, helperText, value, ...rest } = this.props;
        const characterCountText = maxLength ? `${this.labels.funcCharacters_S} ${value?.toString().length}/${maxLength}` : '';
        const combinedHelperText = helperText ? `${helperText} ${characterCountText}` : characterCountText;
        return (
            <TextField
                {...this.props}
                autoComplete={this.props?.autoComplete ?? 'off'}
                inputProps={{
                    maxLength: maxLength,
                    ...rest.inputProps,
                }}
                helperText={combinedHelperText} 
            />
        )
    }
}

export default IbssTextField;

// this design is consistent with figma design for IbssTextField, which we should see being used in releases newer than 14.
// to create an IbssTextField that works with release 14 design - i.e. label to the left of input
// don't pass a label to TextField, put it all in a Grid system and sx prop in TextField should receive at least these values
// sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}}}
// this will get rid of the notch at the top of outlined TextField if there is no label (which might be cause of a clash with bootstrap styles! hence this fix)

// Grid components lays out the two components - a separate IbssInputLabel and IbssTextField. 
/* 
<Grid container direction="row" alignItems="center" justifyContent={'space-between'}>
    <Grid item>
        <IbssInputLabel htmlFor='your-own-id-here'>{'Your Own Label here'}</IbssInputLabel>
    </Grid>
    <Grid item>
        <IbssTextField 
            id="your-own-id-here"  
            variant="outlined" 
            value={this.state.x}
            onChange={this.handleChangeX}
            size="small"
            sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, 'width': 'Ypx','paddingRight': 'Zpx'}} />
    </Grid>
</Grid>
*/