import { Box, Grid, SelectChangeEvent, TextField } from "@mui/material";
import { appContext } from "../../../../AppContext";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { PagedResponse } from "../../../../Providers.Api/Models";
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import { DateHelper } from "../../../../Common/DateHelper";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import dayjs, { Dayjs } from "dayjs";
import { DateTime } from "luxon";
import { TagDetails, NotificationReminders } from "./DataModels";

class EditNotificationReminders extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get localStorage() { return appContext().localStorageProvider; }
    private reminderId: string;
    private nodeId: string;

    private userCanUpdate: boolean;
    private userCreate: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.userCanUpdate = this.localStorage.hasRight('DATAMODEL.NotificationReminders.Update');
        this.userCreate = this.localStorage.hasRight('DATAMODEL.NotificationReminders.Create');

        this.state =
        {
            eventTypes:
                [
                    {
                        label: this.labels.funcDefaultCostCodesBooking_Short,
                        value: 8
                    },
                    {
                        label: this.labels.funcVisitor_S,
                        value: 38
                    },
                    {
                        label: this.labels.HubLabelSpaces,
                        value: 4
                    },
                ],
            selectedEventTypes: "",
            status:
                [
                    {
                        label: this.labels.HubLabelActive,
                        value: "StatusActive"
                    },
                    {
                        label: this.labels.HubLabelInActive,
                        value: "StatusInactive"
                    }
                ],
            selectedStatus: "",
            notificationDuration: "",
            timeOfDay: DateHelper.null(),
            filter: "",
            builtInRule:
                [
                    {
                        label: this.labels.funcVisitReminder_S,
                        value: 2
                    },
                    {
                        label: this.labels.funcBookingReminder_S,
                        value: 1
                    },
                    {
                        label: this.labels.funcIncompleteBooking_S,
                        value: 3
                    }
                ],
            tagOptions: [],
            selectedBuiltInRule: "",
            name: "",
            selectedTags: [],
            concurrencyStamp: "",
        }
        this.reminderId = this.props.match.params.reminderid;
        this.nodeId = this.props.match.params.buildingid;
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcNotificationReminders_S + ' ' + ' - ' + ' ' + this.appState.buildingName;
        const tagData = await appContext().ibssApiClientV2.v2.byNodeid.tags.get<PagedResponse<TagDetails[]>>({ nodeId: Number(this.nodeId), select: TagDetails })
        const tagOptions = tagData.value.map(tag => ({ label: tag.Name, value: tag.Tag_Id }));
        this.setState
            ({
                tagOptions: tagOptions
            });
        if (this.reminderId)
        {
            const data = await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.byId.get<NotificationReminders>({ nodeId: parseInt(this.nodeId), id: this.reminderId, select: NotificationReminders });
            if (data)
            {
                this.setState({
                    name: data.Name,
                    filter: data.Filter,
                    selectedEventTypes: String(data.EventType_Id),
                    selectedStatus: data.Status,
                    selectedBuiltInRule: String(data.BuiltInRule),
                    notificationDuration: this.convertToMinutes(data.NotificationDuration),
                    timeOfDay: this.fromISODuration(data.TimeOfDay),
                    selectedTags: data.CustomData ? JSON.parse(data.CustomData)?.Tags : [],
                    concurrencyStamp: data.ConcurrencyStamp,
                })
            }
        }
    }

    public fromISODuration(isoDuration: string): DateTime 
    {
        const regex = /PT(?:(\d{1,2})H)?(?:(\d{1,2})M)?(?:(\d{1,2})S)?/;
        const match = isoDuration.match(regex);
        if (match)
        {
            const hours = parseInt(match[1] || '0', 10);
            const minutes = parseInt(match[2] || '0', 10);
            const seconds = parseInt(match[3] || '0', 10);
            return DateTime.fromObject({ hour: hours, minute: minutes, second: seconds });
        }
        return DateTime.invalid('Invalid ISO 8601 duration');
    }

    private notificationDurationUpdated(time: string): void
    {
        this.setState({ notificationDuration: time });
    }

    private timeOfDayUpdated(time: (Dayjs | null)): void
    {
        if (time?.isValid())
        {
            const luxonTime = DateHelper.fromPicker(time);
            this.setState({ timeOfDay: luxonTime });
        }
    }

    public toISODuration(isoDuration: DateTime): string
    {
        const hours = isoDuration.toFormat('HH');
        const minutes = isoDuration.toFormat('mm');
        return `PT${hours}H${minutes}M00S`;
    }

    public convertToISODurationFormat(minutes: number): string 
    {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        
        if (remainingMinutes === 0) 
        {
            return `PT${hours}H`;
        }

        return `PT${hours}H${remainingMinutes}M`;
    }

    public convertToMinutes(duration: string): string 
    {
        const hoursMatch = duration.match(/(\d+)H/);
        const minutesMatch = duration.match(/(\d+)M/);
        
        const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
        const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
        
        return String((hours * 60) + minutes);
    }

    private async submit(): Promise<void>
    {
        const payload = {
            EventType_Id: Number(this.state.selectedEventTypes),
            Status: this.state.selectedStatus,
            NotificationDuration: this.convertToISODurationFormat(Number(this.state.notificationDuration)),
            TimeOfDay: this.toISODuration(this.state.timeOfDay),
            Filter: this.state.filter,
            BuiltInRule: Number(this.state.selectedBuiltInRule),
            CustomData: JSON.stringify({
                Tags: this.state.selectedTags
            }),
            Name: this.state.name,
            Node_Id: parseInt(this.nodeId),
        }

        if (this.reminderId)
        {
            const updatePayload =
            {
                ...payload,
                ConcurrencyStamp: this.state.concurrencyStamp,
            }
            try 
            {
                const data = await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.byId.put({
                    nodeId: Number(this.nodeId),
                    id: this.reminderId,
                    body: updatePayload
                })
                if (data)
                {
                    this.props.history.goBack()
                }
            }
            catch (error) 
            {
            }
        }
        else
        {
            try 
            {
                const data = await appContext().ibssApiClientV2.v2.byNodeid.notificationReminders.post({
                    nodeId: parseInt(this.nodeId),
                    body: payload
                })
                if (data)
                {
                    this.props.history.goBack()
                }
            }
            catch (error) 
            {
            }
        }
    }

    private tagChanged(e: SelectChangeEvent<string[]>): void
    {
        const tag = e.target.value;
        const tagsArray = typeof tag === 'string' ? [tag] : tag;
        this.setState({ selectedTags: tagsArray });
    }

    public render(): JSX.Element
    {

        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className="table-panel m-4 py-2">
                                    <Box className='d-flex'>
                                        <Box className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </Box>
                                        <Box className='table-panel-header' style={{ paddingTop: '4px' }}>
                                            {this.labels.funcNotificationReminders_S}
                                        </Box>
                                    </Box>
                                    <IbssTextField
                                        className='mt-3'
                                        fullWidth
                                        type="text"
                                        name="Name"
                                        value={this.state.name}
                                        onChange={e => this.setState({ name: e.target.value })}
                                        label={this.labels.funcDelegateName_S}
                                        size="small"
                                    />
                                    <IbssFormControl fullWidth className='mt-3'>
                                        <IbssInputDropDown
                                            inputLabel={this.labels.HubLabelEventType}
                                            options={this.state.eventTypes}
                                            value={this.state.selectedEventTypes}
                                            id={"EventType"}
                                            onChange={(e: SelectChangeEvent<string>) => this.setState({ selectedEventTypes: e.target.value })}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mt-3'>
                                        <IbssInputDropDown
                                            inputLabel={this.labels.HubLabelStatus}
                                            options={this.state.status}
                                            value={this.state.selectedStatus}
                                            id={"Status"}
                                            onChange={(e: SelectChangeEvent<string>) => this.setState({ selectedStatus: e.target.value })}

                                        />
                                    </IbssFormControl>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box sx={{ display: "flex", mt: 2 }}>
                                                <TextField
                                                    type="number"
                                                    label={this.labels.funcNotificationDuration_S}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.notificationDuration}
                                                    onChange={(e) => this.notificationDurationUpdated(e.target.value)}
                                                />
                                                <Box
                                                    sx={{
                                                        padding: "10px",
                                                        backgroundColor: 'rgb(220 225 229)',
                                                        alignItems: "center",
                                                        display: "flex",
                                                        '&:hover': {
                                                            backgroundColor: 'rgb(220 225 229)',
                                                        },
                                                    }}
                                                >
                                                    {this.labels.HubLabelMinutes}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <IbssTimePicker
                                                className='ibss-timepicker'
                                                value={this.state.timeOfDay}
                                                label={this.labels.funcTimeOfDay_S}
                                                onChange={time => this.timeOfDayUpdated(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                                ampm={false}
                                                minutesStep={1}
                                                slotProps={{ textField: { fullWidth: true } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <IbssTextField
                                        className='mt-3'
                                        fullWidth
                                        type="text"
                                        value={this.state.filter}
                                        onChange={e => this.setState({ filter: e.target.value })}
                                        label={this.labels.HubLabelFilter}
                                        size="small"
                                    />
                                    <IbssFormControl fullWidth className='mt-3'>
                                        <IbssInputDropDown
                                            inputLabel={this.labels.funcBuiltInRule_S}
                                            options={this.state.builtInRule}
                                            value={this.state.selectedBuiltInRule}
                                            id={"buildingSelection"}
                                            onChange={(e: SelectChangeEvent<string>) => this.setState({ selectedBuiltInRule: e.target.value })}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mt-3'>
                                        <IbssInputDropDown
                                            inputLabel={this.labels.funcTags_S}
                                            options={this.state.tagOptions}
                                            value={this.state.selectedTags}
                                            id={"statusSelection"}
                                            isMultiple={true}
                                            onChange={(e: SelectChangeEvent<string[]>) => this.tagChanged(e)}
                                            fullWidth
                                        />
                                    </IbssFormControl>
                                    <IbssButton
                                        fullWidth
                                        className="mt-2"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={(!this.userCanUpdate || !this.userCreate)}
                                        onClick={() => this.submit()}
                                    >
                                        {this.labels.HubButtonSubmit}
                                    </IbssButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditNotificationReminders);

export interface IQueryParams
{
    buildingid: string;
    reminderid: string;
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    eventTypes: IListOption<number>[];
    selectedEventTypes: string;
    status: IListOption<string>[];
    selectedStatus: string;
    notificationDuration: string;
    timeOfDay: DateTime;
    filter: string;
    builtInRule: IListOption<number>[];
    selectedBuiltInRule: string;
    name: string;
    tagOptions: IListOption<string>[];
    selectedTags: string[];
    concurrencyStamp: string;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface IGridRow
{
    spaceId: string;
    spaceName: string;
    description: string;
    spaceType: string;
    status: string;
    policy: string;
    floor: string;
    zone: string;
    nodeId: number;
}
