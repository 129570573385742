import { FormControl, Radio, RadioGroup, FormControlLabel, Box, Grid, Typography, Divider } from '@mui/material';
import { ISpace } from '../../../Pages/Shared/Bookings/Edit/SelectSpaceDrawer';
import { Constants } from '../../../Common/Constants';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import "./SwapSpacecard.scss";

class SwapSpaceCard extends IbssComponent<IProps,IState>
{
    render(): JSX.Element
    {
        const { space, floorName, selectedSpaceId, handleSpaceSelect } = this.props;

        return (
            <>
            <Box className={'swap-space-card'}>
                <Grid container>
                    <Grid sm={2}>
                    <Box  className={'swap-space-card-img'} >
                        <img className="img-fluid" alt={space.spaceType} src={space.imageURI ? space.imageURI : Constants.imagePaths.get(space.spaceType)} />
                    </Box>
                    </Grid>
                    <Grid sm={9}>
                        <Box px={2} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
                        <Typography variant='body1' sx={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',fontWeight:'bold'}}>{space.name}</Typography>
                        <Box display={'flex'} sx={{ '& > :not(style)': { mr: 1 } }}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt="floor" style={{ width: '14px', marginRight: '0.25rem' }} />
                                <Typography variant="body2">{floorName}</Typography>
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt="zone" style={{ width: '14px', marginRight: '0.25rem' }} />
                                <Typography variant="body2">{space.zone}</Typography>
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/User Logged in (Fill).svg" alt="Capacity" style={{ width: '14px', marginRight: '0.25rem' }} />
                                <Typography style={{ fontSize: '0.75rem' }}>{space.capacity}</Typography>
                            </Box>
                        </Box>
                        </Box>
                    </Grid>
                    <Grid sm={1}>
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
                            <FormControl >
                                <RadioGroup
                                    value={selectedSpaceId}
                                    onChange={() => handleSpaceSelect(space.id)}
                                >
                                    <FormControlLabel value={space.id} control={<Radio />} label="" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </>
        );
    }
}

export default SwapSpaceCard;

interface IProps
{
    space: ISpace;
    floorName: string;
    selectedSpaceId: string;
    handleSpaceSelect: (id: string) => void;
}

interface IState
{
}