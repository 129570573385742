import { AxiosError } from 'axios';
import moment from 'moment';
import { appContext } from '../AppContext';
import ApiMessages from '../Providers.Text/ApiMessages';
import { DateTime } from 'luxon';
import IResponse from './IResponse';

export default class ApiMessageBuilder
{
    public getTitleAndMessage(error: (AxiosError<any> | null), nodeId: number): { title: string, message: string, isMessageHtml: boolean }
    {
        const labels = appContext().labels;
        const axiosData = error?.response?.data;
        const dataAsString = typeof (axiosData) == 'string' ? axiosData : undefined;

        const data: IResponse | undefined = !axiosData ? undefined : {
            responseId: axiosData.responseId || axiosData.ResponseId || undefined,
            title: axiosData.title || axiosData.Title || undefined,
            message: axiosData.message || axiosData.Message || undefined,
            detail: axiosData.detail || axiosData.Detail || undefined,
            details: axiosData.details || axiosData.Details || undefined,

            warnings: (axiosData.warnings || axiosData.Warnings)?.map((i: any) => ({
                responseId: i.responseId || i.ResponseId || undefined,
                title: i.title || i.Title || undefined,
                message: i.message || i.Message || undefined,
                details: i.details || i.Details || undefined,
            })) || undefined,

            errors: (axiosData.errors || axiosData.Errors)?.map((i: any) => ({
                detail: i.detail || i.Detail || undefined,
            })) || undefined,
        };

        if (error?.message == "Network Error")
        {
            return { title: labels.HubLabelnetworkFailure, message: labels.HubLabelPleaseTryAgain, isMessageHtml: false };
        }
        else if (error?.response?.status === 401)
        {
            // the global interceptor redirects to the login page when a 401 is encountered
            // an empty message prevents the popup from briefly showing
            return { title: "", message: "", isMessageHtml: false };
        }

        const title = this.getTitle(data?.responseId, data?.title);

        if (data?.errors && data.errors.length > 0)
        {
            const manyErrors = data.errors.length > 1;
            const message = (manyErrors ? ('<ul>' + data.errors.map(i => `<li>${i.detail || ''}</li>`).join('') + '</ul>') : data.errors[0].detail || labels.HubLabelSomethingWentWrong);
            return { title: title, message: message, isMessageHtml: manyErrors };
        }
        else if (data?.warnings && data.warnings.length > 0)
        {
            const title = data.warnings[0].title ?? labels.HubLabelError;
            const firstWarning = data.warnings[0];
            const message = this.getMessage(nodeId, firstWarning.responseId, firstWarning.details, firstWarning.message);
            return { title: title, message: message, isMessageHtml: false };
        }
        else if (dataAsString)
        {
            return { title: title, message: dataAsString, isMessageHtml: false };
        }
        else if (data)
        {
            const message = this.getMessage(nodeId, data.responseId, data.details, data.message || data.detail);
            return { title: title, message: message, isMessageHtml: false };
        }
        else
        {
            return { title: title, message: labels.HubLabelSomethingWentWrong, isMessageHtml: false };
        }
    }

    private getTitle(responseId?: string, title?: string): string
    {
        let labels = appContext().labels;
        let apiMessages = appContext().apiMessages;

        if (responseId)
        {
            let localisedTitle = apiMessages[responseId + "_Title" as keyof ApiMessages];
            if (localisedTitle)
            {
                return localisedTitle;
            }
        }
        return (title ?? labels.HubLabelError);
    }

    private static readonly argExpression = /\{\d+(:\w+)?\}/gi;
    private static readonly dateTimeExpression = /^datetime'(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)'$/i;
    private static readonly badArg = "-";

    private getMessage(nodeId: number, responseId?: string, details?: string[], message?: string): string
    {
        let labels = appContext().labels;
        let apiMessages = appContext().apiMessages;

        if (responseId)
        {
            let localisedMessage = apiMessages[responseId + "_Message" as keyof ApiMessages];
            if (localisedMessage)
            {
                let args = localisedMessage.match(ApiMessageBuilder.argExpression);
                if (args?.length ?? 0) // ensure there are enough details to honour the args
                {
                    let localisedMessageWithArgs = localisedMessage.replace(ApiMessageBuilder.argExpression, arg =>
                    {
                        const argMatch = arg.match(/\{(\d+)(:\w+)?\}/i) as RegExpMatchArray;
                        const argIndex = parseInt(argMatch[1] as string);
                        const detail = details?.[argIndex] ?? null;

                        if (detail == null)
                        {
                            return ApiMessageBuilder.badArg;
                        }

                        const format = argMatch[2]?.substring(1);
                        if (format == null)
                        {
                            return this.formatString(detail);
                        }

                        switch (format[0])
                        {
                            case 't':
                                return this.formatTime(detail, nodeId);

                            case 'd':
                                return this.formatDate(detail, nodeId);

                            case 'g':
                                return this.formatDateTime(detail, nodeId);

                            case 'N':
                            case 'F':
                                return this.formatNumber(detail, format);

                            default:
                                return ApiMessageBuilder.badArg;
                        }
                    });

                    return localisedMessageWithArgs;
                }
            }
        }
        return (message ?? labels.HubLabelSomethingWentWrong);
    }

    private formatTime(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleTimeString();
        return formattedValue;
    }

    private formatDate(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleDateString();
        return formattedValue;
    }

    private formatDateTime(value: string | number, nodeId: number): string
    {
        if (typeof (value) != "string")
        {
            return ApiMessageBuilder.badArg;
        }

        const dateTimeMatch = value.match(ApiMessageBuilder.dateTimeExpression);
        const dateTimeString = dateTimeMatch?.[1];

        if (dateTimeString == null)
        {
            return ApiMessageBuilder.badArg;
        }

        const formattedValue = DateTime.fromISO(dateTimeString).offsetTimeByNode(nodeId, true).toLocaleDateTimeString();
        return formattedValue;
    }

    private formatNumber(value: string | number, format: string): string
    {
        if (typeof (value) != "number")
        {
            return ApiMessageBuilder.badArg;
        }

        let decimalPlaces = parseInt(format.substring(1));
        if (isNaN(decimalPlaces))
        {
            decimalPlaces = 2;
        }

        const formattedValue = value.toFixed(decimalPlaces);
        return formattedValue;
    }

    private formatString(value: string | number): string
    {
        const formattedValue = (typeof (value) == "string" ? value : ApiMessageBuilder.badArg);
        return formattedValue;
    }
}