import { Drawer, DrawerProps } from '@mui/material';
import { IbssComponent } from '../Core/BaseComponent/IbssComponent';

class IbssDrawer extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        const { children, open, onClose, anchor } = this.props;
        return (
            <Drawer
                open={open}
                onClose={onClose}
                anchor={anchor}
            >
                {children}
            </Drawer>
        )
    }
}

export default IbssDrawer;

export interface IProps extends DrawerProps
{
}

export interface IState
{
}