import { DateHelper } from "../../../../Common/DateHelper";
import { ParentBookingRelationTypeEnum } from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class BookingSeriesResponse extends ODataSelect
{
    public OdataNextLink = '';
    Booking_Owner_Id = '';
    Booking_Description = '';
    Booking_Name = '';
    Booking_Details = [] as SeriesBookingDetails[]

    override toSelect(): string
    {
        return ''
    }
}

export class SeriesBookingDetails
{
    public Node_Id = 0;
    public Booking_Id = '';
    public Space_Id = '';
    public Space_Name = '';
    public Booking_Start = '';
    public Booking_End = '';
    public Parent_Booking_Relation_Type = ParentBookingRelationTypeEnum.Main;
    public Booking_Parties = '';
    public Booking_Party_Count = 0;
    public Booking_Owner_Name = '';
    public Booking_Owner_Email = '';
    public Booking_Owner_Id = '';
}

export class CateringOrder extends ODataSelect
{
    Booking_Id = '';
}

export class Booking extends ODataSelect
{
    Booking_Id = '';
    Booking_Resources = '';
}

