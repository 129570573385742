import { ODataSelect } from "../../../../Providers.Api/ODataQuery";


export class TagDetails extends ODataSelect
{
    Tag_Id = "";
    Name = "";
}

export class NotificationReminders extends ODataSelect
{
    Reminder_Id = "";
    Filter = "";
    NotificationDuration = "";
    TimeOfDay = "";
    Status = "";
    Name = "";
    Node_Id = 0;
    EventType_Id = 0;
    BuiltInRule = 0;
    CustomData = "";
    ConcurrencyStamp = "";
}
