import { DateHelper } from "../../../../Common/DateHelper";
import { IFilter } from "../../../../Providers.Api/ODataFilter";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class SpaceUtilisationData extends ODataSelect
{
    public Space_Id = '';
    public Space_Type = '';
    public Space_Class = '';
    public Node_Id = 0;
    public Node_Level_Name = '';
    public Space_Name = '';
    public Space_Type_Label = '';
    public Period_Start_Date = '';
    public Period_End_Date = '';
    public Period_Type = 0;
    public Period_Type_Value = 0;
    public Summary_Id = '';
    public Period_Current_Space_Value = 0;
    public Period_Previous_Space_Value = 0;
    public Period_Change_Space_Value = 0;
    public Period_Current_Capacity_Value = 0;
    public Period_Previous_Capacity_Value = 0;
    public Period_Change_Capacity_Value = 0;
    public Period_Current_Peak_Value = 0;
    public Period_Previous_Peak_Value = 0;
    public Period_Change_Peak_Value = 0;
    public Period_Current_Max_Occ_Value = 0;
    public Period_Previous_Max_Occ_Value = 0;
    public Period_Current_Avg_Occ_Value = 0;
    public Period_Previous_Avg_Occ_Value = 0;
    public Period_Change_Max_Occ_Value = 0;
    public Period_Change_Avg_Occ_Value = 0;
    public Meta_Bookable = 0;

    constructor(value?: Partial<SpaceUtilisationData>)
    {
        super();
        if (value)
        {
            Object.assign(this, value);
        }
    }
}

export class SpaceUtilisationFilter implements IFilter
{

    public Node_Level_Name = '';
    public Space_Class = '';
    public Period_Type = 0;
    public Period_Start_Date = DateHelper.null();
    public Period_End_Date = DateHelper.null();
    public Space_Type_Label = '';

    constructor(value: Partial<SpaceUtilisationFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.Node_Level_Name != "" ? `Node_Level_Name eq '${this.Node_Level_Name}'` : "");
        filterParts.push(this.Space_Class != "" ? `Space_Class eq '${this.Space_Class}'` : "");
        filterParts.push(this.Space_Type_Label != "" ? `Space_Type_Label eq '${this.Space_Type_Label}'` : "");
        filterParts.push(this.Period_Type ? `Period_Type eq ${this.Period_Type}` : '');
        filterParts.push(this.Period_Start_Date.isValid ? `Period_Start_Date ge datetime'${this.Period_Start_Date.toUTC().toISO()}'` : "");
        filterParts.push(this.Period_End_Date.isValid ? `Period_Start_Date lt datetime'${this.Period_End_Date.toUTC().toISO()}'` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }
}

export class Space
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Type = "";
    public Space_Type_Label = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public Space_Status = "";
    public ImageURI = "";
    public Booking_Policy_Id = "";
    public Meta_Bookable = 0;
    public Meta_Occ_Type = 0;
    public Meta_Ext_Occ_Count_Mode = 0;
    public Meta_Loc_Zone = "";
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
    public Space_Work_Type = "";
    public Space_Custom_Info = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
    public Env_Zone_Id = 0;
    public Meta_Ext_Catering_Space_Id = [];
    public Meta_Ext_Booking_System = "";
    public Space_Occupancy = 0;
}