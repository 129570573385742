import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePickerProps, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';
import { TextField } from '@mui/material';

class IbssStaticDatePicker extends IbssComponent<IProps, IState>
{

    constructor(props: any)
    {
        super(props);
    };

    render(): React.ReactNode
    {
        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                <StaticDatePicker
                    {...this.props}
                />
            </LocalizationProvider>
        );
    }
};

export default IbssStaticDatePicker;

export interface IProps extends StaticDatePickerProps<DateTime>
{
}

export interface IState
{
}
