import { ISearchConfigItem } from "../Providers.Api/Models";
import Helper from "./Helper";

export class WorkTypeSpaceTypeHelper
{
    public calculateNewWorkTypeAndSpaceType(buildingId: number, workType: string, spaceType: string): INewWorkTypeSpaceType
    {
        // work-type
        const workTypes = Helper.getWorkSpaceTypesByNodeId(buildingId).filter(i => !!i.Name).sort((a, b) => (a.Label < b.Label ? - 1 : 1));
        const defaultWorkType: ISearchConfigItem | undefined = workTypes.find(i => i.Name == 'FormalMeeting') ?? workTypes[0];
        const defaultWorkTypeId = defaultWorkType?.Name ?? 'Any';
        const newWorkType = workTypes.find(i => i.Name == workType);
        const newWorkTypeId = newWorkType?.Name ?? 'Any';
        const newWorkTypeState: State = (workType == 'Any' ? 'any' : (newWorkType ? 'something' : 'invalid'));

        // space-type
        const spaceTypes = Helper.getSpaceTypesByNodeId(buildingId).result.filter(i => !!i.Name).sort((a, b) => (a.Label < b.Label ? - 1 : 1));
        const defaultSpaceType: ISearchConfigItem | undefined = spaceTypes.find(i => i.Name == 'Desk') ?? spaceTypes[0];
        const defaultSpaceTypeId = defaultSpaceType?.Name ?? 'Any';
        const newSpaceType = spaceTypes.find(i => i.Name == spaceType);
        const newSpaceTypeId = newSpaceType?.Name ?? 'Any';
        const newSpaceTypeState: State = (spaceType == 'Any' ? 'any' : (newSpaceType ? 'something' : 'invalid'));

        // work-type / space-type combo
        const workSpaceTypeMappings: Mapping[] = [
            new Mapping('invalid', 'invalid', 'Any', defaultSpaceTypeId),
            new Mapping('invalid', 'any', defaultWorkTypeId, 'Any'),
            new Mapping('invalid', 'something', 'Any', newSpaceTypeId),
            new Mapping('any', 'invalid', 'Any', defaultSpaceTypeId),
            new Mapping('any', 'any', 'Any', defaultSpaceTypeId),
            new Mapping('any', 'something', 'Any', newSpaceTypeId),
            new Mapping('something', 'invalid', newWorkTypeId, 'Any'),
            new Mapping('something', 'any', newWorkTypeId, 'Any'),
            new Mapping('something', 'something', 'Any', newSpaceTypeId),
        ];

        const workSpaceTypeMapping = workSpaceTypeMappings.find(i => i.workTypeState == newWorkTypeState && i.spaceTypeState == newSpaceTypeState);
        return {
            newWorkType: workSpaceTypeMapping?.newWorkTypeId ?? workType ?? '',
            newSpaceType: workSpaceTypeMapping?.newSpaceTypeId ?? spaceType ?? '',
        };
    }
}

export interface INewWorkTypeSpaceType
{
    newWorkType: string;
    newSpaceType: string;
}

class Mapping
{
    public workTypeState: State;
    public spaceTypeState: State;
    public newWorkTypeId: string;
    public newSpaceTypeId: string;

    constructor(workTypeState: State, spaceTypeState: State, newWorkTypeId: string, newSpaceTypeId: string)
    {
        this.workTypeState = workTypeState;
        this.spaceTypeState = spaceTypeState;
        this.newWorkTypeId = newWorkTypeId;
        this.newSpaceTypeId = newSpaceTypeId;
    }
}

type State = 'invalid' | 'any' | 'something';
