import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import IbssTextField from "../../../Components/Inputs/TextField/IbssTextField";
import IbssFormControl from "../../../Components/Forms/FormControl/IbssFormControl";
import IbssAutocomplete from '../../Inputs/AutoComplete/IbssAutocomplete';
import { DataEntryRole } from '../../../Providers.Api/Roles/RoleRepository';
import { Box } from '@mui/material';
import { SystemFilter } from './DataModels';

class DuplicateSystemFilterModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; };
    private get appState() { return appContext().state; };

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            duplicateSystemFilterName: '',
            selectedSystemFilter: null,
            searchText: null,
            selectedRole: ''
        };
    }

    public componentDidMount(): void
    {
        const { selectedfilterId } = this.props;
        if (selectedfilterId) 
        {
            this.setDuplicateSystemFilter(selectedfilterId);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState): void
    {
        if (prevProps.show != this.props.show)
        {
            this.setDuplicateSystemFilter(this.props.selectedfilterId);
        }
    }

    private async setDuplicateSystemFilter(systemFilterId: string): Promise<void>
    {
        const systemFilter = await appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.get<SystemFilter>({
            nodeId: this.appState.buildingId,
            filterid: systemFilterId,
            select: SystemFilter,
        });
        if (systemFilter == null)
        {
            return;
        }

        this.setState({ 
            duplicateSystemFilterName: `Copy of ${systemFilter.Name}`, 
            selectedSystemFilter: systemFilter, 
            selectedRole: systemFilter.OwnedByRoleId ?? ''
        });    
    }

    private async duplicateFilterModalSubmitted(): Promise<void>
    {
        if (this.state.selectedSystemFilter)
        {
            try {
                await appContext().ibssApiClientV2.v2.byNodeid.filters.post<void>({
                    nodeId:  this.state.selectedSystemFilter.Node_Id,
                    body:{
                        ...this.state.selectedSystemFilter,
                        Name: this.state.duplicateSystemFilterName,
                        Filter_Id: '',
                        OwnedByRoleId: this.state.selectedRole
                    }
                });
            }
            catch {}
        }
        this.props.onSystemFilterCreated();
        this.props.onHide();
    }

    private async roleChanged(e: React.SyntheticEvent<Element, Event>, role: IRoles | null): Promise<void>
    {
        this.setState({ selectedRole: role?.value ?? '' });
    }

    private submitDisabled(): boolean
    {
        if (this.state.duplicateSystemFilterName === '' || this.state.selectedRole === '')
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    

    public render(): JSX.Element
    {
        const { show, onHide, roles } = this.props;
        const roleOptions = roles.map((role)=> {
            return {
                title: role.name,
                value: role.id
            }
        })

        return (
        <Modal show={show} onHide={() => onHide()}>
            <Modal.Header>
                <Modal.Title>{this.labels.HubLabelDuplicateSystemFilter}</Modal.Title>
                <button type="button" className="close" onClick={() => onHide()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <div className="m-4 text-center">
                <div className='m-4 text-left'>
                    {this.labels.HubLabelDuplicateSystemFilterText}
                </div>
                <IbssFormControl fullWidth>
                    <IbssTextField
                        label={this.labels.HubLabelName} 
                        type='text' 
                        variant='outlined' 
                        value={this.state.duplicateSystemFilterName} 
                        onChange={e => this.setState({duplicateSystemFilterName : e.target.value})} 
                     />
                </IbssFormControl>
              <Box my={2}>
              <IbssFormControl fullWidth>
                    <IbssAutocomplete
                        freeSolo
                        options={roleOptions}
                        value={this.state.searchText}
                        getOptionLabel={(option) => option.title}
                        onChange={(e, role: IRoles | null) => this.roleChanged(e, role)}
                        renderInput={params =>
                            <IbssTextField
                                {...params}
                                label={this.labels.HubLabelOwnerRoleLabel}
                                placeholder={this.labels.HubLabelOwnerRoleLabel}
                                variant="outlined"
                            />}
                    />
                </IbssFormControl>
              </Box>
            </div>
            <Modal.Footer>
                <div style={{ textAlign: 'center' }}>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px' }}
                        variant="contained"
                        onClick={() => this.duplicateFilterModalSubmitted()}
                        disabled={this.submitDisabled()}
                    >
                        {this.labels.funcDuplicate}
                    </IbssButton>
                </div>
            </Modal.Footer>
        </Modal>
        )
    }
}

export default DuplicateSystemFilterModal;

export interface IState
{
    duplicateSystemFilterName: string;
    selectedSystemFilter: SystemFilter | null;
    searchText: string | null;
    selectedRole: string;
}

export interface IProps
{
    show: boolean;
    onHide: () => void;
    selectedfilterId: string;
    roles: DataEntryRole[];
    onSystemFilterCreated: () => Promise<void>;
}

interface IRoles
{
    title: string;
    value: string;
}