import { DateTime } from "luxon";
import { StringHelper } from "../../../Common/StringHelper";
import { appContext } from "../../../AppContext";
import { DateHelper } from "../../../Common/DateHelper";

export class DateRangeHelper
{
    private get labels() { return appContext().labels; }
    private string = new StringHelper();
    private dateRanges: DateRange[] = [];
    private today: DateTime;

    constructor(buildingId: number)
    {
        this.today = DateHelper.today(buildingId);
    }

    public addToday(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelToday, this.today, this.today);
    }

    public addTomorrow(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelTomorrow, this.today.plus({ days: 1 }), this.today.plus({ days: 1 }));
    }

    public addThisWeek(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelThisWeek, this.today.startOf('week'), this.today.startOf('week').plus({ days: 6 }));
    }

    public addNextWeek(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelNextWeek, this.today.startOf('week').plus({ days: 7 }), this.today.startOf('week').plus({ days: 13 }));
    }

    public addThisMonth(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelThisMonth, this.today.startOf('month'), this.today.startOf('month').plus({ months: 1, days: -1 }));
    }

    public addNextMonth(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelNextMonth, this.today.startOf('month').plus({ months: 1 }), this.today.startOf('month').plus({ months: 2, days: -1 }));
    }

    public addAll(): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelAll, this.today, this.today.plus({ year: 1 }));
    }

    public addDateRange(period: string, start: DateTime, end: DateTime): DateRangeHelper
    {
        this.dateRanges.push(new DateRange({ period: period, start: start, end: end }));
        return this;
    }

    public getDateRange(period?: string, defaultPeriod?: string, start?: DateTime, end?: DateTime): DateRange
    {
        const string = this.string;
        const labels = this.labels;
        const today = this.today;
        const periodOrDefault = (!period && !start && !end ? defaultPeriod : period) ?? labels.HubLabelCustom;
        const dateRange = this.dateRanges.find(i => string.toConcatenatedLowercase(i.period) == string.toConcatenatedLowercase(periodOrDefault));

        const result = new DateRange({
            period: dateRange?.period ?? labels.HubLabelCustom,
            start: dateRange?.start ?? start ?? today,
            end: dateRange?.end ?? end ?? today,
        });
        return result;
    }
}

export class DateRange
{
    private get labels() { return appContext().labels; }

    public period = '';
    public start = DateHelper.null();
    public end = DateHelper.null();

    constructor(value: Partial<DateRange>)
    {
        Object.assign(this, value);
    }

    public get isPeriodCustom(): boolean
    {
        return this.period == this.labels.HubLabelCustom;
    }
}
