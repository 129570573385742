import { Component } from 'react';

export default class SoundLevelIcon extends Component {
    render() {
        return (
            <svg width="26" height="26" viewBox="0 0 34 34" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9642 5.43789C16.2241 5.56278 16.3893 5.82556 16.3893 6.11387V28.8861C16.3893 29.1745 16.2241 29.4372 15.9642 29.5621C15.7044 29.687 15.3959 29.6519 15.1708 29.4718L7.24328 23.1298H1C0.585786 23.1298 0.25 22.794 0.25 22.3798V12.6202C0.25 12.206 0.585786 11.8702 1 11.8702H7.24328L15.1708 5.52822C15.3959 5.34811 15.7044 5.313 15.9642 5.43789ZM14.8893 7.67434L7.97489 13.2059C7.8419 13.3123 7.67667 13.3702 7.50636 13.3702H1.75V21.6298H7.50636C7.67667 21.6298 7.8419 21.6877 7.97489 21.7941L14.8893 27.3257V7.67434Z" fill="inherit"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2116 5.46959C27.5046 5.17674 27.9794 5.17681 28.2723 5.46975C31.4623 8.66071 33.2543 12.988 33.2543 17.5C33.2543 22.012 31.4623 26.3393 28.2723 29.5303C27.9794 29.8232 27.5046 29.8233 27.2116 29.5304C26.9187 29.2376 26.9186 28.7627 27.2115 28.4697C30.1202 25.5601 31.7543 21.6143 31.7543 17.5C31.7543 13.3857 30.1202 9.43991 27.2115 6.53025C26.9186 6.23731 26.9187 5.76244 27.2116 5.46959ZM21.4698 11.2115C21.7627 10.9186 22.2376 10.9187 22.5304 11.2116C24.1957 12.8774 25.1312 15.1364 25.1312 17.4919C25.1312 19.8473 24.1957 22.1063 22.5304 23.7721C22.2376 24.0651 21.7627 24.0651 21.4698 23.7723C21.1768 23.4794 21.1767 23.0046 21.4696 22.7116C22.8537 21.3271 23.6312 19.4496 23.6312 17.4919C23.6312 15.5342 22.8537 13.6566 21.4696 12.2721C21.1767 11.9792 21.1768 11.5043 21.4698 11.2115Z" fill="inherit"/>
            </svg>
        )
    }
};