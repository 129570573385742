import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public systemFilter?: string;
    public date?: DateTime;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'date':
                return this.serialiseDate(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue) || null;
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'date':
                return this.deserialiseDate(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
