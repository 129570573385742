import React, { Component } from "react";
import
{
    ScheduleComponent,
    ViewsDirective,
    ViewDirective,
    ResourcesDirective,
    ResourceDirective,
    Inject,
    EventRenderedArgs,
    Day,
    PopupOpenEventArgs,
} from "@syncfusion/ej2-react-schedule";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { Box, Typography } from "@mui/material";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { appContext } from "../../../../AppContext";
import Helper from "../../../../Common/Helper";
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { ITodayBookings } from "./DataModels";
import Link from '@mui/material/Link';

class ExistingBookings extends IbssComponent<IProps, State>
{
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private helper = new Helper();

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            buildingStartHrs: "",
            buildingEndHrs: "",
            openScheduleView: false,
        };
    }

    public componentDidMount(): void
    {
        this.getBuildingHours();
    }

    private getBuildingHours(): void
    {
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.appState.buildingId);

        this.setStateAsync({
            buildingStartHrs: building?.Occ_Office_hrs_stt.slice(0, -3) ?? '07:00',
            buildingEndHrs: building?.Occ_Office_hrs_stp.slice(0, -3) ?? '19:00',
        });
    }

    private onEventRendered(args: EventRenderedArgs): void 
    {
        (args.element as HTMLElement).style.color = '#263238';
        (args.element as HTMLElement).style.backgroundColor = 'white';
        (args.element as HTMLElement).style.borderColor = '#1C957B';
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
    }

    private onPopupOpen(args: PopupOpenEventArgs): void
    {
        args.cancel = true;
    }

    render(): JSX.Element
    {

        const space = [
            {
                "text": this.props.spaceName,
                "id": this.props.spaceId
            }
        ]

        const fieldsData = {
            id: { title: 'id', name: 'id' },
            subject: { title: 'subject', name: 'subject' },
            startTime: { title: 'startTime', name: 'startTime' },
            endTime: { title: 'endTime', name: 'endTime' },
        };

        const eventSettings = {
            dataSource: this.props.todayBookings.map((booking: IBooking) => ({
                ...booking,
                startTime: booking.startTime.toJSDate(),
                endTime: booking.endTime.toJSDate(),
            })),
            fields: fieldsData
        };

        const group = { enableCompactView: false, resources: ['MeetingRoom'] };

        const sortedDates = this.props.todayBookings.sort((a, b) => {
            return a.startTime.toMillis() - b.startTime.toMillis();
        });

        return (
            <div>
                <Box display={'flex'} justifyContent={'space-between'} my={2}>
                    <Typography variant="body2">{this.labels.funcCurrentSpaceBookings_S}</Typography>
                    <Link
                        className="pointer"
                        tabIndex={0}
                        onClick={() => this.setState({ openScheduleView: true })}
                        onKeyDown={(e) => { e.key === 'Enter' && this.setState({ openScheduleView: true }) }}
                        variant="body2"
                    >
                        {this.labels.funcOpenScheduleForSpace_S}
                    </Link>
                </Box>

                <Box display={'flex'} flexWrap={'wrap'}>
                    {this.props.todayBookings && this.props.todayBookings.length > 0 ? (
                        sortedDates.map((booking, index) => (
                            <Box display={'flex'} key={booking.id}>
                                <Typography variant="body2" mr={1}>
                                    {`${booking.startTime.toLocaleTimeString()} - ${booking.endTime.toLocaleTimeString()}`}
                                </Typography>
                                {index !== this.props.todayBookings.length - 1 && (
                                    <Typography variant="body2" mr={1}> | </Typography>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2">{this.labels.funcOrphanedUsersNoBookingsFound_M}</Typography>
                    )}
                </Box>

                <IbssDialog
                    aria-modal="true"
                    aria-label="cancel booking modal"
                    open={this.state.openScheduleView}
                    header={this.props.spaceName}
                    dialogContent={
                        <Box sx={{ flex: 1 }}>
                            <ScheduleComponent
                                className="oneLensScheduleView"
                                currentView="Day"
                                width="100%"
                                eventSettings={eventSettings}
                                group={group}
                                showHeaderBar={false}
                                startHour={`${this.state.buildingStartHrs.split(":")[0]}:00`}
                                endHour={this.state.buildingEndHrs}
                                workHours={{
                                    highlight: true,
                                    start: this.state.buildingStartHrs,
                                    end: this.state.buildingEndHrs,
                                }}
                                eventRendered={this.onEventRendered}
                                selectedDate={this.props.selectedDate.toJSDate()}
                                popupOpen={this.onPopupOpen.bind(this)}
                            >
                                <ResourcesDirective>
                                    <ResourceDirective
                                        field="spaceId"
                                        title="Room Type"
                                        name="MeetingRoom"
                                        allowMultiple={true}
                                        dataSource={space}
                                        textField="text"
                                        idField="id"
                                        colorField="color"
                                    />
                                </ResourcesDirective>
                                <Inject services={[Day]} />
                                <ViewsDirective>
                                    <ViewDirective option="Day" />
                                </ViewsDirective>
                            </ScheduleComponent>
                        </Box>
                    }
                    onClose={() =>
                        this.setState({
                            openScheduleView: false,
                        })
                    }
                    fullWidth
                />
            </div>
        );
    }
}

export default ExistingBookings;


interface IProps
{
    buildingId: number;
    floorId: number;
    spaceId: string;
    spaceName: string;
    selectedDate: DateTime;
    todayBookings: IBooking[];
}

interface State
{
    buildingStartHrs: string;
    buildingEndHrs: string;
    openScheduleView: boolean;
}

export interface IBooking
{
    id: string;
    subject: string;
    spaceId: string;
    startTime: DateTime;
    endTime: DateTime;
}