import { ODataSelect } from "../../../../Providers.Api/ODataQuery";
import { DateHelper } from "../../../../Common/DateHelper";
import { BookingStatus } from "../../../../Providers.Api/Bookings/BookingRepository";
import { ParentBookingRelationTypeEnum } from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import Helper from "../../../../Common/Helper";

export class BookingNote extends ODataSelect
{
    public Node_Id = 0;
    public Note_Id = '';
    public Event_Id = '';
    public EventType_Id = 0;
    public EventType_Name = ''; 
    public Note = '';
    public IsDeleted = 0;

    public init(response: any, nodeId: number): BookingNote
    {
        super.init(response, nodeId);
        return this;
    }
}

export class BookingParties extends ODataSelect
{
    public Space_Id = '';
    public Visit_Id = '';
    public Booking_Id = '';
    public Booking_Start =  DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Name = '';
    public Booking_Status = '';
    public Node_Id = 0;
    public Booking_Participant_Email = '';
    public Booking_Participant_Name = '';
    public Booking_Participant_Organisation = '';
    public Record_Id = '';
    public Booking_Resource_Id = '';
    public Booking_Visitor = 0;
    public Booking_Participant_Type = 0;
    public Booking_Participant_CheckedIn = 0; 
    public Booking_Participant_Status = '';
    public Booking_Early_Checkin = '';
    public Space_Name = '';
    public Space_Name_Label = '';
    public Node_Name = '';

    public init(response: any, nodeId: number): BookingParties
    {
        super.init(response, nodeId);
        this.Booking_Start = DateHelper.fromIsoByNode(response.Booking_Start as unknown as string, nodeId);
        this.Booking_End = DateHelper.fromIsoByNode(response.Booking_End as unknown as string, nodeId);
        return this;
    }
}

export class Filter extends ODataSelect
{
    public Filter_Id = '';
    public EventType_Id = 0;
    public Filter = '';
    public Status = '';
    public Name = '';
    public Node_Id = 0;
    public OwnedByUserId = '';
    public CreatedByUserId = '';
    public CreatedAt = '';
    public ModifiedByUserId = '';
    public ModifiedAt = '';
    public ConcurrencyStamp = '';
    public Building_Bypass = false;

    public init(response: any, nodeId: number): Filter
    {
        super.init(response, nodeId);
        return this;
    }
}

export class Booking extends ODataSelect
{
    public Node_Id = 0;
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Description = "";
    public Booking_Start = '';
    public Booking_End = '';
    public Booking_Completed_Time = '';
    public Booking_Early_Checkin = '';
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public Booking_Owner_Email = "";
    public Booking_IsActive = 0;
    public Booking_IsCancelled = 0;
    public Booking_IsCheckedIn = 0;
    public Booking_AutoCheckIn = 0;
    public Booking_IsEarly_Checkin = 0;
    public Booking_IsLate_Checkin = 0;
    public Booking_Share_Loc = 0;
    public Booking_IsApproved = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Layout = "";
    public _CreatedAt = '';
    public _CreatedBy = "";
    public Booking_Parties = "";
    public Booking_Series_Id = ""
    public Parent_Booking_Id = "";
    public Parent_Booking_Relation_Type = ParentBookingRelationTypeEnum.Main;
    public Booking_Attendance = 0;

    public init(response: any, nodeId: number): Booking
    {
        super.init(response, nodeId);
        return this;
    }
}

export class ITag extends ODataSelect
{
    public Tag_Id = "";
    public TagType = "";
    public Values = "";
    public Icon = "";
    public Description = "";
    public Status = "";
    public Node_Id = 0;
    public Name = "";
    public ConcurrencyStamp = "";
    public OwnedByUserId = "";
    public OwnedByRoleId = "";
    public CreatedByUserId = "";
    public ModifiedByUserId = "";
    public TenancyId = "";

    public init(response: any, nodeId: number): ITag
    {
        super.init(response, nodeId);
        return this;
    }
}
export class ITagToEventType extends ODataSelect
{
    public Node_Id = "";
    public Tag2EventType_Id = "";
    public Tag_Id = "";
    public TagType = "";
    public Name = "";
    public Value = "";
    public Icon = "";
    public Record_Id = "";
    public EventType_Id = 0;

    public init(response: any, nodeId: number): ITagToEventType
    {
        super.init(response, nodeId);
        return this;
    }
}

export interface IDeleteTagToEventType
{
    Message: string;
    Details: string[];
}

export interface ICreateTagResponse
{
    Details: ITagToEventType[];
    Message: string;
}

export class Note extends ODataSelect
{
    public Note_Id = "";
    public Note = "";
    public CreatedBy = "";
    public CreatedAt = DateHelper.null();

    public init(response: any, nodeId: number): Note
    {
        super.init(response, nodeId);
        this.CreatedAt = DateHelper.fromIsoByNode(response.CreatedAt, nodeId);
        return this;
    }
}

export class Visit extends ODataSelect
{
    public EventId = '';
    public OwnedBy = '';
    public OwnedById = '';
    public Visit_Id = '';
    public Space_Id = '';
    public Booking_Id = '';
    public Booking_Name = '';
    public Node_Id = 0;
    public Space_Name = '';
    public Visitor_First_Name = '';
    public Visitor_Last_Name = '';
    public Visitor_Email = '';
    public Visitor_Company = '';
    public Visit_Host_Name = '';
    public Visit_Host_Email = '';
    public Visit_IsCheckedIn = 0;
    public Visit_IsApproved = 0;
    public Visit_IsCancelled = 0;
    public Visit_IsNoShow = 0;
    public Visit_IsCheckedOut = 0;
    public Visit_Approval_Comments = '';
    public Visitor_Dietary_Pref = 0;
    public Visit_Status = '';
    public Visit_IsDenied = 0;
    public Visit_Save_Info = 0;
    public Meta_Ext_Visit_Id = '';
    public Visit_IsEarly_Checkin = 0;
    public Visit_IsLate_Checkin = 0;
    public Visit_Scheduled_Duration = 0;
    public Visit_Actual_Duration = 0;
    public Visit_Pacs_Id = '';
    public ID = 0;
    public ETag = '';
}

export class Space extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public Space_Status = "";
    public Space_Type ='';
    public Space_Type_Label = '';
    public Booking_Policy_Id = "";
    public Space_Work_Type = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
}

export class SpaceData extends ODataSelect
{
    public Space_Id = '';
}

export class BuildingBookingPolicy extends ODataSelect 
{
    public Node_Id = 0;
    public Booking_Policy_Id = "";
    public Booking_Policy_Name = "";
    public Booking_Policy: IBookingPolicyObject = 
    {  
        PolicyType: "",
        BuildingId: "",
        FloorId: "",
        Allowed_TimeRange: "",
        Booking_Policy_Description: "",
        BookingSlots: 
        {
            BookableTime: [],
            ExcludedDates: [],
        }
    };
}

export interface IBookingPolicyObject 
{
    PolicyType: string;
    BuildingId: string;
    FloorId: string;
    Allowed_TimeRange: string;
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots 
{
    BookableTime: IBookableTime[];
    ExcludedDates: IExcludedDate[];
}

export interface IBookableTime
{
    Days: number[];
    StartTime: string;
    EndTime: string;
}

export interface IExcludedDate 
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}

export interface ICreateNoteResponse
{
    Note_Id: string;
}