import { appContext } from '../../../../AppContext';
import Guid from '../../../../Common/Guid';
import { getFloorUsingFloorId } from '../../../../Common/Helper';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import FloorPlan from '../../../../Components/Data/FloorPlan/FloorPlan';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import React from 'react';

class FloorPlanPopup extends IbssComponent<IProps, IState>
{

    private get apiCache() { return appContext().apiCache; }
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            mapUrls: []
        };
    };

    public async componentDidMount(): Promise<void>
    {
        const allFloors = appContext().localStorageProvider.getNodeData().Regions.flatMap(region => region.Buildings).flatMap(building => building.Floors);
        const mapUrls = allFloors.map(i => ({ floorId: i.Node_Id, url: i.Floor_MapURI }));
        this.setState({ mapUrls: mapUrls })
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.show}
                onClose={() => this.props.close()}
                header={this.labels.funcSpaceLayoutViewMap_S}
                maxWidth={'xl'}
                dialogContent=
                {
                    <>
                        <FloorPlan
                            key="map"
                            mapUrls={this.state.mapUrls}
                            loadSpaces={Guid.empty}
                            mapFailedToLoad={() => {}}
                            floorId={this.props.floorId}
                            spacesToHighlight={this.props.spacesToHighlight}
                            enableSpaceClick={false}
                        />
                    </>
                }
                fullWidth
                footer=
                {
                    <>
                    </>
                }
            />
        )
    }
}

export default FloorPlanPopup;

export interface IProps
{
    floorId: number;
    show: boolean;
    close: () => void;
    spacesToHighlight: string[];
}

export interface IState
{
    mapUrls: IMapUrl[];
}

export interface IMapUrl
{
    floorId: number;
    url: string;
}