import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import Temperature from "../../Icons/SpaceTypeIcons/temperature";
import SoundLevelIcon from "../../Icons/SoundLevel";
import EnvironmentIcon from "../../Icons/Environment";
import HumidityIcon from "../../Icons/humidity";
import CalendarIcon from "../../Icons/CalendarIcon";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import VisitorIcon from "../../Icons/VisitorIcon";
import { Box } from "@mui/material";
import InfoOutlineIcon from "../../Icons/InfoOutlineIcon";
export class ChartPanelInfo extends IbssComponent<IProps, IState> 
{
    private renderIcon(): JSX.Element
    {
        switch (this.props.iconName)
        {
            case "Temperature":
                return <Temperature />;
            case "Sound":
                return <SoundLevelIcon />;
            case "Environment":
                return <EnvironmentIcon />;
            case "Humidity":
                return <HumidityIcon />;
            case "Calendar":
                return <CalendarIcon />;
            case "InfoOutline":
                return <InfoOutlineIcon />;
            case "Visitor":
                return <VisitorIcon />;
            default:
                throw new Error('Not supported');
        }
    }

    public render(): JSX.Element 
    {
        return (
            <div className="chart-panel-info">
                <div className="chart-panel-heading">
                    <Box className="chart-panel-icon" mx={1}>
                        <IbssSvgIcon>
                            {this.renderIcon()}
                        </IbssSvgIcon>
                    </Box>
                    <h1 className="chart-panel-header">{this.props.chartHeading}</h1>
            </div>
            </div>
        );
    }
}
export interface IProps
{
    iconName: 'Temperature' | 'Sound' | 'Environment' | 'Humidity' | 'Calendar' | 'InfoOutline' | 'Visitor';
    chartHeading: string;
}
export interface IState 
{
}