import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { IGetV2BookingResponse, IGetV2BookingEndpoint, GetV2BookingEndpoint } from "./GetV2BookingEndpoint";
import { IGetV1BookingResponse, IGetV1BookingEndpoint } from "./GetV1BookingEndpoint";
import * as GetV2Bookings from "./GetV2BookingsEndpoint";
import { ICreateBookingResponse, ICreateV1BookingEndpoint, ICreateV1BookingRequest } from "./CreateV1BookingEndpoint";
import { ICreateV2Booking_BookingParty, ICreateV2Booking_CostCodeAllocation, ICreateV2BookingEndpoint, ICreateV2BookingRequest, ICreateV2BookingResponse } from "./CreateV2BookingEndpoint";
import { IDeleteBookingResponse, IDeleteV1BookingEndpoint } from "./DeleteV1BookingEndpoint";
import { IDeleteV2BookingEndpoint } from "./DeleteV2BookingEndpoint";
import { IUpdateBookingResponse, IUpdateV1BookingEndpoint, IUpdateV1BookingRequest } from "./UpdateV1BookingEndpoint";
import { IUpdateV2BookingEndpoint, IUpdateV2BookingRequest } from "./UpdateV2BookingEndpoint";
import { IPatchV2BookingEndpoint, IPatchV2BookingRequest, IPatchV2BookingResponse } from "./PatchV2BookingEndpoint";
import { PagedResponse } from "../Models";
import { ODataQuery } from "../ODataQuery";
import { IFilter } from "../ODataFilter";

export class BookingRepository implements IBookingRepository
{
    private getV2BookingEndpoint: IGetV2BookingEndpoint;
    private getV1BookingEndpoint: IGetV1BookingEndpoint;
    private getV2BookingsEndpoint: GetV2Bookings.IGetV2BookingsEndpoint;
    private createV1BookingEndpoint: ICreateV1BookingEndpoint;
    private createV2BookingEndpoint: ICreateV2BookingEndpoint;
    private deleteV1BookingEndpoint: IDeleteV1BookingEndpoint;
    private deleteV2BookingEndpoint: IDeleteV2BookingEndpoint;
    private updateV1BookingEndpoint: IUpdateV1BookingEndpoint;
    private updateV2BookingEndpoint: IUpdateV2BookingEndpoint;
    private patchV2BookingEndpoint: IPatchV2BookingEndpoint; // no patch for v1

    constructor(
        getV2BookingEndpoint: IGetV2BookingEndpoint,
        getV1BookingEndpoint: IGetV1BookingEndpoint,
        getV2BookingsEndpoint: GetV2Bookings.IGetV2BookingsEndpoint,
        createV1BookingEndpoint: ICreateV1BookingEndpoint,
        createV2BookingEndpoint: ICreateV2BookingEndpoint,
        deleteV1BookingEndpoint: IDeleteV1BookingEndpoint,
        deleteV2BookingEndpoint: IDeleteV2BookingEndpoint,
        updateV1BookingEndpoint: IUpdateV1BookingEndpoint,
        updateV2BookingEndpoint: IUpdateV2BookingEndpoint,
        patchV2BookingEndpoint: IPatchV2BookingEndpoint,
    )
    {
        this.getV2BookingEndpoint = getV2BookingEndpoint;
        this.getV1BookingEndpoint = getV1BookingEndpoint;
        this.getV2BookingsEndpoint = getV2BookingsEndpoint;
        this.createV1BookingEndpoint = createV1BookingEndpoint;
        this.createV2BookingEndpoint = createV2BookingEndpoint;
        this.deleteV1BookingEndpoint = deleteV1BookingEndpoint;
        this.deleteV2BookingEndpoint = deleteV2BookingEndpoint;
        this.updateV1BookingEndpoint = updateV1BookingEndpoint;
        this.updateV2BookingEndpoint = updateV2BookingEndpoint;
        this.patchV2BookingEndpoint = patchV2BookingEndpoint;
    }

    public getV1Booking(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>
    {
        return this.getV1BookingEndpoint.execute(nodeId, bookingId);
    }

    public getV2Booking(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>
    {
        return this.getV2BookingEndpoint.execute(nodeId, bookingId);
    }

    public getV2Bookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>
    {
        return this.getV2BookingsEndpoint.execute(query,startTime, endTime, spaceIds);
    }

    public createV1Booking(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>
    {
        return this.createV1BookingEndpoint.execute(nodeId, booking, suppressErrorPopup);
    }

    public createV2Booking(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>
    {
        return this.createV2BookingEndpoint.execute(nodeId, booking, suppressErrorPopup);
    }

    public deleteV1Booking(buildingId: number, bookingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>
    {
        return this.deleteV1BookingEndpoint.execute(buildingId, bookingId, suppressErrorPopup);
    }

    public deleteV2Booking(buildingId: number, bookingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>
    {
        return this.deleteV2BookingEndpoint.execute(buildingId, bookingId, suppressErrorPopup);
    }

    public updateV1Booking(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>
    {
        return this.updateV1BookingEndpoint.execute(nodeId, bookingId, booking);
    }

    public updateV2Booking(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>
    {
        return this.updateV2BookingEndpoint.execute(nodeId, bookingId, booking);
    }

    public patchV2Booking(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>
    {
        return this.patchV2BookingEndpoint.execute(nodeId, bookingId, booking);
    }
}

export interface IBookingRepository
{
    getV1Booking(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>;
    getV2Booking(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>;
    getV2Bookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>;
    createV1Booking(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>;
    createV2Booking(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>;
    deleteV1Booking(nodeId: number, buildingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>;
    deleteV2Booking(nodeId: number, buildingId: string, suppressErrorPopup: boolean): Promise<IDeleteBookingResponse>;
    updateV1Booking(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>;
    updateV2Booking(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>;
    patchV2Booking(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>;
}

export class BookingFilter implements IFilter
{
    public baseFilter: (IFilter | null) = null;
    public startDate = DateHelper.null();
    public endDate = DateHelper.null();
    public bookingStatus = "";
    public bookingStatusNot = new Array<string>();
    public bookingOwnerEmail = "";
    public createdBy = "";
    public spaceName = "";

    constructor(value: Partial<BookingFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.baseFilter ? this.baseFilter.toODataString() : "");
        filterParts.push(this.startDate.isValid ? `Booking_Start ge datetime'${this.startDate.toUTC().toISO()}'` : "");
        filterParts.push(this.endDate.isValid ? `Booking_End lt datetime'${this.endDate.toUTC().toISO()}'` : "");
        filterParts.push(this.bookingStatus ? `Booking_Status eq '${this.bookingStatus}'` : "");
        this.bookingStatusNot.forEach(i => filterParts.push(`Booking_Status ne '${i}'`));
        filterParts.push(this.bookingOwnerEmail ? `Booking_Owner_Email eq '${this.bookingOwnerEmail.toLocaleLowerCase()}'` : "");
        filterParts.push(this.createdBy ? `CreatedBy eq '${this.createdBy.toLocaleLowerCase()}'` : "");
        filterParts.push(this.spaceName ? `Space_Name eq '${this.spaceName}'` : "");
        return filterParts.filter(i => i !== "").join(" and ");
    }

}

export type BookingStatus = ("" | "Cancelled" | "No Show" | "Auto Cancelled" | "Completed" | "Amended" | "New" | "Checked In"  | "Late Checkin" | "Early Checkin" | "In Progress" | "Active");
