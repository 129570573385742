import { ODataSelect } from "../../../Providers.Api/ODataQuery";
import { DateHelper } from "../../../Common/DateHelper";
import { DateTime } from "luxon";

export interface ICateringOrder
{
    Menu_Id: string;
    Order_Id: string;
    Node_Id: number,
    Menu_Items: IMenuItem[];
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Order_Status: string;
    Catering_Attendees: string;
    Catering_Notes: string;
    Cost_Code_Allocation: string;
    Catering_Order_Restrictions: string;
    Booking_Name: string;
    Booking_Id: string;
    Space_Name: string;
    Space_Id: string;
}

export interface ICreateCateringOrder
{
    Node_Id: number;
    Menu_Items: string;
    Booking_Id: string;
    Space_Id: string;
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Order_Status: string;
    Catering_Attendees: number;
    Catering_Notes: string;
    Cost_Code_Allocation: string;
    Catering_Total_Value: number;
    Catering_Order_Restrictions: string;
}

export interface ICostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export interface IMenuItem
{
    Id: string;
    Menu_Id: string;
    QuantityOfItems: number;
}

export class ISelectCateringOrder extends ODataSelect
{
    Menu_Id = "";
    Order_Id = "";
    Node_Id = 0;
    Menu_Items = "";
    Catering_Service_Time = "";
    Catering_Clearing_Time = "";
    Catering_Order_Status = "";
    Catering_Attendees = "";
    Catering_Notes = "";
    Cost_Code_Allocation = "";
    Catering_Order_Restrictions = "";
    Booking_Name = "";
    Booking_Id = "";
    Space_Name = "";
    Space_Id = "";
}

export class ICateringMenuExpanded extends ODataSelect
{
    public Node_Id = 0;
    public Menu_Id = '';
    public Name = '';
    public Description = '';
    public Short_Description = '';
    public ImageURI = '';
    public Status = '';
    public ConcurrencyStamp = '';
    public Available_From = DateTime.now();
    public Available_Until = DateTime.now();
    public Menu_MenuItems: IMenuItems[] = [];

    public toExpand()
    {
        return 'Menu_MenuItems($expand=MenuItem($expand=MenuItem_Restrictions($expand=Restriction)))';
    }

    public init(response: any, nodeId: number): ICateringMenuExpanded
    {
        super.init(response, nodeId)
        return {
            ...response,
            Node_Id: response.Node_Id,
            Menu_Id: response.Menu_Id,
            Name: response.Name,
            Description: response.Description,
            Short_Description: response.Short_Description,
            ImageURI: response.ImageURI,
            Status: response.Status,
            ConcurrencyStamp: response.ConcurrencyStamp,
            Available_From: DateHelper.fromIsoByNode(response.Available_From, nodeId),
            Available_Until: DateHelper.fromIsoByNode(response.Available_Until, nodeId),
            Menu_MenuItems: response.Menu_MenuItems,
        }
    }
}

export interface IMenuItems
{
    MenuItem_Id: string;
    Menu_Id: string;
    MenuItem: IMenuItem;
}