import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';

class UnsavedChangesModal extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.show}
                onClose={() => this.props.close()}
                header={this.labels.HubLabelSaveChanges}
                dialogContent=
                {
                    <>
                        <div>{this.labels.funcSpaceLayoutsUnsavedChangesPt1_Message}</div>
                        <div>{this.labels.funcSpaceLayoutsUnsavedChangesPt2_Message}</div>
                    </>
                }
                fullWidth
                footer=
                {
                    <div style={{ width: '85%' }}>
                        <div style={{ float: 'right' }}>
                            <IbssButton className='mr-2' variant='contained' color='error' onClick={() => this.props.save()}>{this.labels.HubButtonSave}</IbssButton>
                            <IbssButton variant='contained' color='secondary' onClick={() => this.props.ignoreChanges()}>{this.labels.funcSpaceLayoutIgnore_S}</IbssButton>
                        </div>
                    </div>
                }
            />
        )
    }
}

export default UnsavedChangesModal;

export interface IProps
{
    show: boolean;
    close: () => void;
    save: () => void;
    ignoreChanges: () => void;
}

export interface IState
{
}