import { ICreateEndpoint, ICreateOrderPolicyPayload } from "./CreateEndpoint";
import { IDeleteEndpoint } from "./DeleteEndpoint";
import { IGetManyEndpoint } from "./GetManyEndpoint";
import { IGetByIdEndpoint } from "./GetOrderPolicyById";
import { IUpdateEndpoint, IUpdateOrpderPolicyPayload } from "./UpdateEndpoint";

export class CateringOrderPolicyRepository implements ICateringOrderPolicyRepository
{
    private getManyEndpoint: IGetManyEndpoint;
    private deleteEndpoint: IDeleteEndpoint;
    private createEndpoint: ICreateEndpoint;
    private getByIdEndPoint: IGetByIdEndpoint;
    private updateOrderPolicyById : IUpdateEndpoint

    constructor(
        getManyEndpoint: IGetManyEndpoint,
        deleteEndpoint: IDeleteEndpoint,
        createEndpoint: ICreateEndpoint,
        getByIdEndPoint: IGetByIdEndpoint,
        updateOrderPolicyById:IUpdateEndpoint,
    )
    {
        this.getManyEndpoint = getManyEndpoint;
        this.deleteEndpoint = deleteEndpoint;
        this.createEndpoint = createEndpoint;
        this.getByIdEndPoint = getByIdEndPoint;
        this.updateOrderPolicyById = updateOrderPolicyById
    }

    public getMany(buildingId: number): Promise<ICateringOrderPolicy[]>
    {
        return this.getManyEndpoint.execute(buildingId);
    }

    public getById(buildingId: number,policyId:string) : Promise<ICateringOrderPolicy>
    {
        return this.getByIdEndPoint.execute(buildingId,policyId);
    }
    
    public delete(buildingId: number, spaceCateringId: string): Promise<void>
    {
        return this.deleteEndpoint.execute(buildingId, spaceCateringId);
    }

    public create(buildingId: number, payload :ICreateOrderPolicyPayload): Promise<void>
    {
        return this.createEndpoint.execute(buildingId,payload);
    }

    public update(buildingId: number,payload: IUpdateOrpderPolicyPayload): Promise<void>
    {
        return this.updateOrderPolicyById.execute(buildingId,payload);
    }
}

export interface ICateringOrderPolicyRepository
{
    getMany(buildingId: number): Promise<ICateringOrderPolicy[]>;
    delete(buildingId: number, spaceCateringId: string): Promise<void>;
    create(buildingId: number, payload :ICreateOrderPolicyPayload): Promise<void>;
    getById(buildingId: number,policyId:string) : Promise<ICateringOrderPolicy>;
    update(buildingId: number,payload: IUpdateOrpderPolicyPayload) : Promise<void>
}

export interface ICateringOrderPolicy
{
    OrderPolicy_Id: string;
    OrderPolicy_Ref: string;
    Description: string;
    AdvanceNotice: string;
    PreparationTime_Mins: number;
    Kitchen_OperatingHours_Start: string;
    Kitchen_OperatingHours_Stop: string;
    Name: string;
    ConcurrencyStamp: string;
    OwnedByUserId: string;
    OwnedByRoleId: string;
    CreatedByUserId: string;
    ModifiedByUserId: string;
    TenancyId: string;
    Node_Id: number;
}