import React, { Component } from "react";
import {TablePanelInfo} from "../../../../Components/Headings/TableHeading/TablePanelInfo";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { appContext } from "../../../../AppContext";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import './SpacesTable.scss';

export default class SpacesTable extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            canExport: false,
        };
    }

    public componentDidMount(): void
    {
        this.setState({ canExport: this.localStorage.hasRight("API.Spaces.Export") });
    }

    public render(): JSX.Element
    {
        const { showDownloadButton, downloadButtonClassName, isDownloadButtonDisabled, downloadButtonClicked, showFilterButton, filterButtonClicked, isLoading, tableData } = this.props
        return (
            <div className="rightPanel-main-content">
                <div className="table-panel">
                    <TablePanelInfo
                        tableHeading={this.props.title}
                        iconSrc="MeetingRoom"
                    />
                    <div className="table-panel-content">
                        {this.state.canExport && showDownloadButton &&
                            <button
                                className={downloadButtonClassName}
                                disabled={isDownloadButtonDisabled}
                                onClick={downloadButtonClicked}
                            >
                                {this.labels.HubButtonExport}
                            </button>
                        }
                    </div>
                    {showFilterButton &&
                        <div className={'spacesTableBtnContainer'}>
                            <button className="button-primary button-filter" onClick={filterButtonClicked}>{this.labels.HubLabelFilter}</button>
                        </div>
                    }
                    <div className="position-relative">
                        {(isLoading || isLoading) && <Spinner />}
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <IbssDataGrid
                                    columns={this.props.tableData.columns}
                                    rows={this.props.tableData.rows}
                                    getRowId={(row: ITableRow) => row.spaceId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface IProps
{
    title: string;
    isLoading: boolean;
    showDownloadButton: boolean;
    downloadButtonClassName?: string;
    isDownloadButtonDisabled?: boolean;
    downloadButtonClicked: () => void;
    showFilterButton?: boolean;
    filterButtonClicked?: () => void;
    tableData:
    {
        columns: GridColDef[]
        rows: Array<ITableRow>
    }
}

interface ITableRow 
{
    metaOccType: number;
    spaceClass: string;
    spaceExtOccCount: number;
    spaceId: string;
    spaceName: string;
    spaceStatusText: string;
    spaceType: string;
    occupancy: number;
}

export interface IState
{
    canExport: boolean;
}
