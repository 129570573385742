import { MenuItemKey } from "../../Layout/Sidebar/MenuItemKey";
import OneLensListBuildings from "../../../Pages/OneLens/Buildings/List/ListBuildings";
import ViewVisitTrends from "../../../Pages/OneLens/Visits/ViewTrends/ViewVisitTrends";
import ViewSpaceOperationsOverview from "../../../Pages/OneLens/Spaces/ViewOperationsOverview/ViewSpaceOperationsOverview";
import ListCateringOrders from "../../../Pages/OneLens/CateringOrders/List/ListCateringOrders";
import ListDirectedCleaningSpaces from "../../../Pages/OneLens/Spaces/ListDirectedCleaning/ListDirectedCleaningSpaces";
import EditCateringItem from "../../../Pages/OneLens/CateringItems/Edit/EditCateringItem";
import EditNotification2 from "../../../Pages/OneLens/Notifications/Edit2/EditNotification2";
import ListUserNotifications from "../../../Pages/OneLens/UserNotifications/List/ListUserNotifications";
import ListMostRecentEnvironmentalDataByZone from "../../../Pages/OneLens/EnvironmentalData/ListMostRecentByZone/ListMostRecentEnvironmentalDataByZone";
import ViewEnvironmentalDailySummary from "../../../Pages/OneLens/EnvironmentalDailySummaries/View/ViewEnvironmentalDailySummary";
import ListSensors from "../../../Pages/OneLens/Sensors/List/ListSensors";
import ViewEnvironmentalDataOverview from "../../../Pages/OneLens/EnvironmentalData/ViewOverview/ViewEnvironmentalDataOverview";
import ViewSpaceUtilisation2 from "../../../Pages/OneLens/Spaces/ViewUtilisation2/ViewSpaceUtilisation";
import ViewMostRecentEnvironmentalDataByZone from "../../../Pages/OneLens/EnvironmentalData/ViewMostRecentByZone/ViewMostRecentEnvironmentalDataByZone";
import ListEnvironmentalDailySummariesByZone from "../../../Pages/OneLens/EnvironmentalDailySummaries/ListByZone/ListEnvironmentalDailySummariesByZone";
import ViewSensor from "../../../Pages/OneLens/Sensors/View/ViewSensor";
import ListFloorsAndViewFloorPlan from "../../../Pages/OneLens/Floors/ListAndViewPlan/ListFloorsAndViewFloorPlan";
import EditCateringOrder from "../../../Pages/Shared/CateringOrders/Edit/EditCateringOrder";
import ListCateringItems from "../../../Pages/OneLens/CateringItems/List/ListCateringItems";
import ViewCateringOrder from "../../../Pages/OneLens/CateringOrders/View/ViewCateringOrder";
import ListCateringOrderPolicies from "../../../Pages/OneLens/CateringOrderPolicies/List/ListCateringOrderPolicies";
import ListCostCodes from "../../../Pages/Shared/CostCodes/List/ListCostCodes";
import ListCateringMenus from "../../../Pages/OneLens/CateringMenus/List/ListCateringMenus";
import EditCateringSupplier from "../../../Pages/OneLens/CateringSuppliers/Edit/EditCateringSupplier";
import ListCateringSuppliers from "../../../Pages/OneLens/CateringSuppliers/List/ListCateringSuppliers";
import ViewBookingsSchedule from "../../../Pages/OneLens/Bookings/ViewSchedule/ViewBookingsSchedule";
import ViewVisitDashboard from "../../../Pages/OneLens/Visits/ViewDashboard/ViewVisitDashboard";
import EditCateringOrderPolicy from "../../../Pages/OneLens/CateringOrderPolicies/Edit/EditCateringOrderPolicy";
import EditCateringMenu from "../../../Pages/OneLens/CateringMenus/Edit/EditCateringMenu";
import ViewTrends from "../../../Pages/OneLens/Bookings/ViewTrends/ViewTrends";
import ListDietaryTerms from "../../../Pages/OneLens/CateringDietaryTerms/List/ListDietaryTerms";
import ListDeletedUsers from "../../../Pages/OneLens/Users/ListDeleted/ListDeletedUsers";
import OnelensHome from "../../../Pages/OneLens/Shared/Home/OnelensHome";
import OneLensEditNotification from "../../../Pages/OneLens/Notifications/Edit/EditNotification";
import OneLensListNotifications from "../../../Pages/OneLens/Notifications/List/ListNotifications";
import ListPantryTimeline from "../../../Pages/OneLens/CateringPantryTimeline/List/ListPantryTimeline";
import { ViewAnalytics } from "../../../Pages/OneLens/Visits/ViewAnalytics/ViewAnalytics";
import ViewBookingsManagedSchedule from "../../../Pages/OneLens/Bookings/ViewDashboard/ViewBookingsManagedSchedule";
import ListTasks from "../../../Pages/Shared/Tasks/List/ListTasks";
import ListVisits from "../../../Pages/Shared/Visits/List/ListVisits";
import EditVisit from "../../../Pages/Shared/Visits/Edit/EditVisit";
import EditTask from "../../../Pages/Shared/Tasks/Edit/EditTask";
import ListBookings from "../../../Pages/Shared/Bookings/List/ListBookings";
import SearchSpaces from "../../../Pages/Shared/Spaces/Search/SearchSpaces";
import EditBooking from "../../../Pages/Shared/Bookings/Edit/EditBooking";
import ViewBookingSeries from "../../../Pages/Shared/BookingSeries/View/ViewBookingSeries";
import ViewSpaceAnalyticsOverview from "../../../Pages/OneLens/Spaces/ViewAnalyticsOverview/ViewSpaceAnalyticsOverview";
import ViewSpaceUtilisation from "../../../Pages/OneLens/Spaces/ViewUtilisation/ViewSpaceUtilisation";
import ViewSpaceDailySummary from "../../../Pages/OneLens/Spaces/ViewDailySummary/ViewSpaceDailySummary";
import { IRoute } from "./IbssRouter";
import ListSpaceLayouts from "../../../Pages/OneLens/Spaces/SpaceLayouts/ListSpaceLayouts";

export default class OneLensRoutes
{
    public routes: IRoute[] = [
        {
            requiredRights: [],
            path: "/operational-services-buildings",
            component: OneLensListBuildings
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.DirectedCleaning",
                "ONELENS360.OperationalServices.Notifications",
                "ONELENS360.OperationalServices.OccupancyMonitor",
                "ONELENS360.OperationalServices.Spaces",
                "ONELENS360.OperationalServices.Tasks",
                "ONELENS360.OperationalServices.Visitors",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-buildings/:buildingid",
            component: OneLensListBuildings,
            menuItemId: MenuItemKey.OneLens
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Tasks",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-tasks/:buildingid",
            component: ListTasks,
            menuItemId: MenuItemKey.OneLens_Operations_Tasks
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Visitors",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-Visitors/:buildingid/visit/:visit_Id/:tab",
            component: EditVisit,
            menuItemId: MenuItemKey.OneLens_Operations_Visitors_List
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Visitors",
                "ONELENS360.OperationalServices.*"
            ],
            path: '/one-lens/visits',
            legacyPaths: [
                { path: "/operational-services-visitors/:buildingid/:tab/:filter", parameterMap: { "buildingid": "building" } },
                { path: "/operational-services-visitors/:buildingid/:tab", parameterMap: { "buildingid": "building" } },
                { path: "/operational-services-visitors/:buildingid", parameterMap: { "buildingid": "building" } }
            ],
            component: ListVisits,
            menuItemId: MenuItemKey.OneLens_Operations_Visitors_List
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Visitors",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-visitors-dashboard/:buildingid",
            component: ViewVisitDashboard,
            menuItemId: MenuItemKey.OneLens_Operations_Visitors_Dashboard
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Visitors",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-visitors-trends/:buildingid",
            component: ViewVisitTrends,
            menuItemId: MenuItemKey.OneLens_Operations_Visitors_Trends
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Tasks",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-tasks/:buildingid/task/:task_Id/:tab",
            component: EditTask,
            menuItemId: MenuItemKey.OneLens_Operations_Tasks
        },
        {
            requiredRights: [],
            path: "/operational-services-home/",
            component: OnelensHome,
            menuItemId: MenuItemKey.OneLens_Home
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: '/one-lens/bookings',
            legacyPaths: [
                { path: "/operational-services-bookings-list/:buildingid", parameterMap: { buildingid: 'building', status: 'booking-statuses' } },
                { path: "/operational-services-bookings/:buildingid/:tab", parameterMap: { buildingid: 'building', tab: '', status: 'booking-statuses' } },
                { path: "/operational-services-bookings/:buildingid/:tab/:filter", parameterMap: { buildingid: 'building', tab: '', filter: '', status: 'booking-statuses' } },
            ],
            component: ListBookings,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_List
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-bookings/:buildingid/bookings/:bookingid/:spaceId",
            component: EditBooking,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_List
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/oneLens/:buildingId/booking-series/view/:seriesId",
            component: ViewBookingSeries,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_List
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingId/bookings/trends",
            component: ViewTrends,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_Trends
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/one-lens/bookings/schedule",
            legacyPaths: [{ path: "/buildings/:buildingid/bookings/schedule-view", parameterMap: { buildingid: "building" } }],
            component: ViewBookingsSchedule,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_ScheduleView
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Bookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/one-lens/bookings/dashboard",
            legacyPaths: [{ path: "/buildings/:buildingid/bookings/dashboard", parameterMap: { buildingid: "building" } }],
            component: ViewBookingsManagedSchedule,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_Dashboard
        },
        {
            requiredRights: [],
            path: "/operational-services-home/",
            component: OnelensHome,
            menuItemId: MenuItemKey.OneLens_Home
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: '/one-lens/catering-orders',
            legacyPaths: [
                { path: '/catering-orders/:buildingid', parameterMap: { buildingid: 'building' } },
                { path: '/operational-services-catering/:buildingid', parameterMap: { buildingid: 'building' } },
            ],
            component: ListCateringOrders,
            menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/oneLens/:buildingId/catering/pantry-timeline",
            component: ListPantryTimeline,
            menuItemId: MenuItemKey.OneLens_Operations_Catering_PantryTimeline
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/catering-items/:buildingid",
            component: ListCateringItems,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingid/catering-order-policies",
            component: ListCateringOrderPolicies,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingid/catering-order-policies/new",
            component: EditCateringOrderPolicy,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingid/catering-order-policies/:policyId/edit",
            component: EditCateringOrderPolicy,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-catering/:buildingid/catering/:spacecateringId",
            component: EditCateringItem,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-catering/:buildingid/catering/:spacecateringId/view",
            component: ViewCateringOrder,
            menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering"
            ],
            path: "/buildings/:buildingId/catering-menus",
            component: ListCateringMenus,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering"
            ],
            path: "/buildings/:buildingId/catering-menus/new",
            component: EditCateringMenu,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering"
            ],
            path: "/buildings/:buildingId/catering-menus/:cateringMenuId/edit",
            component: EditCateringMenu,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/catering-dietary-terms/:buildingId",
            component: ListDietaryTerms,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_DietaryTerms
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingId/catering-suppliers",
            component: ListCateringSuppliers,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingId/catering-suppliers/new",
            component: EditCateringSupplier,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/buildings/:buildingId/catering-suppliers/:supplierId/edit",
            component: EditCateringSupplier,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-catering-suppliers/create/:buildingId",
            component: EditCateringSupplier,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-catering-suppliers/edit/:buildingId/:supplierId",
            component: EditCateringSupplier,
            menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.DirectedCleaning",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-directed-cleaning/:buildingid",
            component: ListDirectedCleaningSpaces,
            menuItemId: MenuItemKey.OneLens_Operations_DirectedCleaning
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.OrphanedBookings",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/users/deleted",
            component: ListDeletedUsers,
            menuItemId: MenuItemKey.OneLens_Operations_DeletedUsers
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Notifications",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-notifications/:buildingid",
            component: ListUserNotifications,
            menuItemId: MenuItemKey.OneLens_Operations_Notifications
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Notifications",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-notifications/:buildingid/notifications/:notificationId",
            component: EditNotification2,
            menuItemId: MenuItemKey.OneLens_Operations_Notifications
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Notifications"
            ],
            path: "/oneLens/:buildingid/announcements/create",
            component: OneLensEditNotification,
            menuItemId: MenuItemKey.OneLens_Operations_Announcements
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Notifications"
            ],
            path: "/oneLens/:buildingid/announcements/edit/:notificationid",
            component: OneLensEditNotification,
            menuItemId: MenuItemKey.OneLens_Operations_Announcements
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Notifications"
            ],
            path: "/oneLens/:buildingid/announcements/list",
            component: OneLensListNotifications,
            menuItemId: MenuItemKey.OneLens_Operations_Announcements
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Zones",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-zones/:buildingid",
            component: ListMostRecentEnvironmentalDataByZone,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Zones",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-zones/:buildingid/:zoneId",
            component: ViewMostRecentEnvironmentalDataByZone,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.CostCodes",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-cost-codes/:buildingid",
            component: ListCostCodes,
            menuItemId: MenuItemKey.OneLens_Operations_CostCodes
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Zones",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-zones/:buildingid/zones/:zoneId",
            component: ViewEnvironmentalDailySummary,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Zones",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-zones/:buildingid/:zoneId/historical",
            component: ListEnvironmentalDailySummariesByZone,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Sensors",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-sensors/:buildingid",
            component: ListSensors,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Sensors",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-sensors/:buildingid/:sensorId",
            component: ViewSensor,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Overview",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-overview/:buildingid",
            component: ViewEnvironmentalDataOverview,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Overview
        },
        {
            requiredRights: [
                "ONELENS360.SustainabilityAnalytics.Sensors",
                "ONELENS360.SustainabilityAnalytics.*"
            ],
            path: "/sustainability-analytics-floors/:buildingid",
            component: ListFloorsAndViewFloorPlan,
            menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Floors
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Catering",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-catering/:buildingid/catering/:bookingid/:cateringOrderId/edit",
            component: EditCateringOrder,
            menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Spaces",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-spaces/:buildingid",
            component: ViewSpaceOperationsOverview,
            menuItemId: MenuItemKey.OneLens_Operations_Spaces_Spaces
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Spaces",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-spaces/:buildingid/:tab",
            component: ViewSpaceOperationsOverview,
            menuItemId: MenuItemKey.OneLens_Operations_Spaces_Spaces
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.Spaces",
                "ONELENS360.OperationalServices.*"
            ],
            path: "/operational-services-spaces/:buildingid/:tab/:filter",
            component: ViewSpaceOperationsOverview,
            menuItemId: MenuItemKey.OneLens_Operations_Spaces_Spaces
        },
        {
            requiredRights: [
                "DATAMODEL.Spaces.Read",
                "ONELENS360.OperationalServices.*"
            ],
            path: '/one-lens/spaces/search',
            legacyPaths: [{ path: '/operational-services/buildings/:buildingid/spaces/search', parameterMap: { 'buildingid': 'building' } }],
            component: SearchSpaces,
            menuItemId: MenuItemKey.OneLens_Operations_Bookings_List
        },
        {
            requiredRights: [
                "ONELENS360.SpaceAnalytics.Overview",
                "ONELENS360.SpaceAnalytics.*"
            ],
            path: "/space-analytics-overview/:buildingid",
            component: ViewSpaceAnalyticsOverview,
            menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview
        },
        {
            requiredRights: [
                "ONELENS360.SpaceAnalytics.Analytics",
                "ONELENS360.SpaceAnalytics.*"
            ],
            path: "/space-analytics-analytics",
            component: ViewSpaceUtilisation2,
            menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview
        },
        {
            requiredRights: [
                "ONELENS360.SpaceAnalytics.Heatmaps",
                "ONELENS360.SpaceAnalytics.*"
            ],
            path: "/space-analytics-heatmaps/:buildingid",
            component: ViewSpaceUtilisation,
            menuItemId: MenuItemKey.OneLens_Analytics_Space_Heatmaps
        },
        {
            requiredRights: [
                "ONELENS360.SpaceAnalytics.SpaceDetails",
                "ONELENS360.SpaceAnalytics.*"
            ],
            path: "/space-analytics-detail",
            component: ViewSpaceDailySummary,
            menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview
        },
        {
            requiredRights: [
                "ONELENS360.VisitorAnalytics.Overview"
            ],
            path: "/onelens/visits/analytics",
            component: ViewAnalytics,
            menuItemId: MenuItemKey.OneLens_Analytics_Visitors
        },
        {
            requiredRights: [
                "ONELENS360.OperationalServices.SpaceLayouts",
            ],
            path: "/oneLens/:buildingId/space-layouts",
            component: ListSpaceLayouts,
            menuItemId: MenuItemKey.OneLens_Operations_Space_Layouts
        },
    ];
}