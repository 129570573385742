import { Grid } from "@mui/material";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import { DateTime } from "luxon";
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import IbssButton from "../../Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import MarkdownEditor from "../../Inputs/MarkdownEditor/MarkdownEditor";
import React from "react";

export default class EditNoteDialog extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private editorRef = React.createRef<MarkdownEditor>();

    private async okClicked(): Promise<void>
    {
        await this.editorRef.current?.flush();
        this.props.onOk();
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                header={this.labels.funcAddNote_S}
                dialogContent=
                {
                    <>
                        <MarkdownEditor
                            ref={this.editorRef}
                            initialValue={this.props.markdown}
                            onChange={markdown => this.props.onChange(markdown)}
                            style={{ width: "450px", height: "200px" }}
                        />
                    </>
                }
                footer=
                {
                    <>
                        <IbssButton
                            onClick={() => this.props.onClose()}
                            variant="contained"
                            sx={{ width: '100px' }}
                            className="btn-theme-secondary mr-3"
                        >
                            {this.labels.HubButtonCancel}
                        </IbssButton>
                        <IbssButton
                            onClick={() => this.okClicked()}
                            variant="contained"
                            sx={{ width: '100px' }}
                        >
                            {this.labels.HubButtonSave}
                        </IbssButton>
                    </>
                }
            >
            </IbssDialog>
        );
    }
}

export interface IProps
{
    markdown: string;
    open: boolean;
    onClose: () => void;
    onOk: () => void;
    onChange: (markdown: string) => void;
}

export interface IState
{
}
