export class ObjectHelper
{
    public hasProperty<TObject extends {}>(object: TObject, property: (object: TObject) => any): boolean
    {
        const propertyExpressionParts = property.toString().split('.');
        const propertyName = propertyExpressionParts[propertyExpressionParts.length - 1];
        const objectProperties = Object.keys(object);
        const hasProperty = objectProperties.includes(propertyName);
        return hasProperty;
    }

    public shallowCopy<T>(source: T): T
    {
        const copyOfPrototypes = Object.create(Object.getPrototypeOf(source));
        const copy = Object.assign(copyOfPrototypes, source);
        return copy;
    }
}