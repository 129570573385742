import { DateTime } from "luxon";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class BuildingBookingPolicy extends ODataSelect 
{
    public Node_Id = 0;
    public Booking_Policy_Id = "";
    public Booking_Policy_Name = "";
    public Booking_Policy: IBookingPolicyObject = 
    {  
        PolicyType: "",
        BuildingId: "",
        FloorId: "",
        Allowed_TimeRange: "",
        Booking_Policy_Description: "",
        BookingSlots: 
        {
            BookableTime: [],
            ExcludedDates: [],
        }
    };
}

export interface IBookingPolicyObject 
{
    PolicyType: string;
    BuildingId: string;
    FloorId: string;
    Allowed_TimeRange: string;
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots 
{
    BookableTime: IBookableTime[];
    ExcludedDates: IExcludedDate[];
}

export interface IBookableTime
{
    Days: number[];
    StartTime: string;
    EndTime: string;
}

export interface IExcludedDate 
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}
