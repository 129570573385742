import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssStaticDatePicker from '../../../../Components/Inputs/DatePicker/IbssStaticDatePicker';
import './SelectMultipleDatesModal.scss'
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { Box, TextField } from '@mui/material';
import { DateHelper } from '../../../../Common/DateHelper';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class SelectMultipleDatesModal extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            selectedDate: DateTime.now(),
            selectedFrom: DateTime.now().plus({ minutes: 5 }),
            selectedTo: DateTime.now().plus({ minutes: 65 }),
            showDateAlreadySelectedError: false
        };
    };

    public addDate(): void
    {
        const { selectedFrom, selectedTo, selectedDate } = this.state
        let from = selectedFrom;
        let to = selectedTo;
        from = from.set({ day: selectedDate?.get('day'), month: selectedDate?.get('month'), year: selectedDate?.get('year') });
        to = to.set({ day: selectedDate?.get('day'), month: selectedDate?.get('month'), year: selectedDate?.get('year') });
        if (this.props.selectedDates.some(x => x.from.get('day') == from.get('day') && x.from.get('month') == from.get('month') && x.from.get('year') == from.get('year')))
        {
            this.setState({ showDateAlreadySelectedError: true })
            return;
        }
        else
        {
            this.props.onAdd(from, to);
            this.setState({ showDateAlreadySelectedError: false });
        }
    }

    public dateChanged(value: DateTime): void
    {
        this.setState({ selectedDate: value });
    }

    private fromTimeUpdated(time: Date | null): void
    {
        const luxonTime = DateHelper.fromPicker(time);
        this.setState({ selectedFrom: luxonTime });

        if (luxonTime >= this.state.selectedTo)
        {
            this.setState({ selectedTo: luxonTime.plus({ minutes: 5 }) });
        }
    }

    private toTimeUpdated(time: Date | null): void
    {
        const luxonTime = DateHelper.fromPicker(time);
        this.setState({ selectedTo: luxonTime });

        if (luxonTime <= this.state.selectedFrom)
        {
            this.setState({ selectedFrom: luxonTime.minus({ minutes: 5 }) });
        }
    }

    private close(): void
    {
        this.props.onClose();
        this.setState({ showDateAlreadySelectedError: false });
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="setup reset unavailable modal"
                open={this.props.open}
                fullWidth
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.funcAdvancedBookingAddMultipleDateTimes_Message}</label>
                    </>
                }
                dialogContent=
                {
                    <div className='hide-action-bar d-flex'>
                        <IbssStaticDatePicker slots={{ toolbar: undefined }} disablePast onChange={e => this.setState({ selectedDate: e })} value={this.state.selectedDate} />
                        <Box className='mt-1'>
                            <IbssTimePicker
                                value={this.state.selectedFrom}
                                onChange={e => this.fromTimeUpdated(e?.toJSDate() as Date)}
                                ampm={false}
                                minutesStep={1}
                                slotProps={{ textField: { helperText: this.labels.funcAdvancedBookingsMeetingStart_S, label: this.labels.HubLabelFrom, fullWidth: true } }}
                            />
                            <IbssTimePicker
                                className='mt-1'
                                value={this.state.selectedTo}
                                onChange={e => this.toTimeUpdated(e?.toJSDate() as Date)}
                                ampm={false}
                                minutesStep={1}
                                slotProps={{ textField: { helperText: this.labels.funcAdvancedBookingsMeetingEnd_S, label: this.labels.HubLabelTo, fullWidth: true } }}
                            />
                        </Box>
                    </div>
                }
                footer=
                {
                    <div style={{ textAlign: 'center' }}>
                        <IbssButton
                            variant="contained"
                            disabled={!this.state.selectedFrom.isValid || !this.state.selectedTo.isValid}
                            onClick={() => this.addDate()}
                        >
                            {this.labels.HubButtonAdd}
                        </IbssButton>
                        {
                            this.state.showDateAlreadySelectedError &&
                            <div className='text-danger'>{this.labels.funcAdvancedBookingDateSelectedError_Message}</div>
                        }
                    </div>
                }
                onClose={() => this.close()}
            />
        )
    }
}

export default SelectMultipleDatesModal;

export interface IProps
{
    open: boolean;
    selectedDates: ISelctedDates[];
    onAdd: (from: DateTime, to: DateTime) => void;
    onClose: () => void;
}

export interface IState
{
    selectedDate: DateTime | null;
    selectedFrom: DateTime;
    selectedTo: DateTime;
    showDateAlreadySelectedError: boolean;
}

export interface ISelctedDates
{
    from: DateTime;
    to: DateTime;
}