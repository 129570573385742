import { ChangeEvent, Component } from 'react';
import IbssDialog from '../../Dialogs/BaseDialog/IbssDialog';
import IbssButton from '../../Buttons/Button/IbssButton';
import IbssInput from '../../Inputs/Input/IbssInput';
import IbssFormControl from '../../Forms/FormControl/IbssFormControl';
import { Button } from '@mui/material';
import { appContext } from '../../../AppContext';

class MediaSelector extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            show: false,
            focusedImage: { name: '', url: '' },
            selectedImage: { name: '', url: this.props.defaultSelectedImage ?? '' },
            selectedFile: { name: '', path: '' },
            showFileNameExistsError: false
        };
    };

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.defaultSelectedImage != this.props.defaultSelectedImage)
        {
            this.setState({ selectedImage: { name: '', url: this.props.defaultSelectedImage ?? '' } })
        }
    }

    private imageSelected(image: IImage): void
    {
        this.setState({ focusedImage: image });
    }

    private selectImage(): void
    {
        this.setState({ selectedImage: this.state.focusedImage, show: false });
        this.props.imageSelected(this.state.focusedImage);
    }

    private fileSelected(e: ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ showFileNameExistsError: false })
        const fileType = e.target.value.split('.').pop() ?? '';
        const allowedFileTypes = ['jpeg', 'jpg', 'gif', 'png', 'svg'];
        if (allowedFileTypes.includes(fileType))
        {
            this.setState({ selectedFile: { name: e.target.name, path: e.target.value } });
            if (e.target.files)
            {
                if (this.props.options.map(x => x.name).includes(e.target.files[0].name))
                {
                    this.setState({ showFileNameExistsError: true })
                }
                else
                {
                    const formData = new FormData();
                    formData.append("file", e.target.files[0]);
                    formData.append("fileName", e.target.files[0].name);
                    this.props.uploadFile(e.target.files[0].name, formData, false)
                }
            }
        }
    }

    public render(): JSX.Element
    {
        const filteredOptions = this.props.options
        return (
            <div>
                {
                    !this.props.noPreview &&
                    <img width={100} height={100} src={this.state.selectedImage.url.length > 0 ? this.state.selectedImage.url : "/images/ImageSelectPlaceholder.svg"} alt="img-thumbnail"/>
                        
                }
                <IbssButton sx={{ marginBottom: '28px', marginLeft: '15px' }} onClick={() => this.setState({ show: true })} variant='contained'>{this.labels.HubLabelAddSelectImage}</IbssButton>
                <IbssDialog
                    aria-modal="true"
                    aria-label="image selector modal"
                    fullWidth
                    maxWidth={'md'}
                    open={this.state.show}
                    onClose={() => this.setState({ show: false })}
                    header=
                    {
                        <>
                            <label className="modal-heading">{this.labels.HubLabelImageSelection}</label>
                        </>
                    }
                    dialogContent=
                    {
                        <>
                            <div className='upload-photo'>
                                <label htmlFor="upload-photo">
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={e => this.fileSelected(e)}
                                    />
                                    <Button variant="contained" component="span" fullWidth>
                                        {this.labels.HubLabelUpload}
                                    </Button>
                                </label>
                            </div>
                            {
                                this.state.showFileNameExistsError &&
                                <div className='text-danger mb-2 text-center'>
                                    {this.labels.HubLabelImageNameExistsError}
                                </div>
                            }
                            <div className='d-flex-wrap'>
                                {
                                    filteredOptions.length > 0 &&
                                    filteredOptions.map(image =>
                                    {
                                        return (
                                            <div
                                                key={image.name}
                                                style={{
                                                    border: `${this.state.focusedImage.name == image.name ? '3px solid #4893CF' : ''}`,
                                                    boxShadow: `${this.state.focusedImage.name == image.name ? '0px 4px 4px 0px #00000040' : ''}`,
                                                    margin: '5px',
                                                    height: '100px'
                                                }}
                                                onClick={() => this.imageSelected(image)}>
                                                <img width={100} height={100} src={image.url} alt={image.name}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <IbssFormControl sx={{ width: '300px', fontSize: '12px' }} className='mt-2'>
                                {this.labels.HubLabelSelectedImage}
                                <IbssInput fullWidth value={this.state.focusedImage.name} type='underlined' disabled />
                            </IbssFormControl>
                            <div className='mt-1 font-sm'>{this.state.focusedImage.url}</div>
                            <div>
                                <div style={{ width: '20%', margin: '20px auto' }}>
                                    <IbssButton fullWidth disabled={this.state.focusedImage.url == ''} onClick={() => this.selectImage()} variant='contained'>{this.labels.HubLabelOk}</IbssButton>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        )
    }
}

export default MediaSelector;

export interface IProps
{
    options: IImage[];
    imageSelected: (file: { name: string, url: string }) => void;
    uploadFile: (filename: string, file: FormData, processAsMap: boolean) => Promise<void>;
    defaultSelectedImage: string;
    noPreview?: boolean;
}

export interface IState
{
    show: boolean;
    focusedImage: IImage;
    selectedImage: IImage;
    selectedFile: IFile;
    showFileNameExistsError: boolean;
}

export interface IImage
{
    name: string;
    url: string;
}

export interface IFile
{
    name: string;
    path: string;
}