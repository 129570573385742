/* eslint-disable no-loop-func */
import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import AddIcon from '@mui/icons-material/Add';
import
Helper, {
    getBuildingTimeZoneByNodeId,
    getBuildingNameUsingFloorNodeId,
    getBuildingNodeIdUsingFloorNodeId,
    truncateString,
    dateConverter
} from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import moment from "moment";
import { appContext } from "../../../../AppContext";
import { RouterProps, RouteComponentProps, generatePath } from "react-router";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import { SelectChangeEvent } from "@mui/material/Select";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormControl, Grid, Typography } from "@mui/material";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssPageHeader, { StartOrEnd } from "../../../../Components/Forms/DateRange/IbssPageHeader";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import { BookingFilter } from "./BookingFilter";
import RefreshIcon from '@mui/icons-material/Refresh';
import customTheme from "../../../../customTheme";
import { ODataQuery } from "../../../../Providers.Api/ODataQuery";
import * as GetV2BookingsEndpoint from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import React from "react";
import { KeyValue } from "../../../../Common/KeyValue";
import { PagedResponse } from "../../../../Providers.Api/Models";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BuildingChangeReason } from "../../../../Components/Core/BasePage/Types";
import { InputOption } from "../../../../Components/Inputs/SelectList2/InputSelectBox";
import { Booking, MyBooking, MyPrimaries, SpaceZones, PrimariesBooking, SystemFilter, UsersRoles, DownloadBooking } from "./DataModels";
import ServerDataGrid, { DataGridQueryResult, IDataQuery, Column, IPage, IPages } from "../../../../Components/Data/ServerDataGrid/ServerDataGrid";
import { toggleButtonListObject } from "../../../../Components/Inputs/ToggleButtons/IbssToggleButtons";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { SearchSpacesHelper } from "../../Spaces/Search/SearchSpacesHelper";
import CancelBookingsDialog from "../../../../Components/Dialogs/ChangeBookingDetailsDialog/CancelBookingsDialog";
import { BookingView, DataGrid } from "./DataGrid";
import { FilterDialog, IOption } from "./FilterDialog";
import { QueryParams } from "./QueryParams";
import { StringHelper } from "../../../../Common/StringHelper";
import { DateRangeHelper } from "../../../../Components/Core/BasePage/DateRangeHelper";
import { AxiosResponse } from "axios";
import IbssBookingListHeader from "./IbssBookingListHeader";
import { Constants } from "../../../../Common/Constants";
import PreparingExportDialog from "../../../../Components/Dialogs/PreparingExportDialog/PreparingExportDialog";

class ListBookings extends IbssPage<IProps, IState, QueryParams>
{
    private pageSize = 100;
    private dataGridRef = React.createRef<DataGrid>();
    private get dataGrid() { return this.dataGridRef.current; }
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private hasV2Rights = this.local.hasRight("API.Bookings.V2");
    private hasApproveRights = this.local.hasRight("API.Bookings.Approve");
    private hasRejectRights = this.local.hasRight("API.Bookings.Deny");
    private hasFilterReadRights = this.local.hasRight("DATAMODEL.Filters.Read");
    private string = new StringHelper();

    private enableRefreshButtonAfterTimeOut(): void
    {
        setTimeout(() =>
        {
            this.setState({ isRefreshButtonDisabled: false });
        }, 60000);
    }

    private async handleRefreshButtonClick(): Promise<void>
    {
        this.setState({ isRefreshButtonDisabled: true });
        await this.dataGrid?.refresh(true);
        this.enableRefreshButtonAfterTimeOut();
    }

    constructor(props: IProps)
    {
        super(props, new QueryParams());

        this.state =
        {
            getRightApiResponseErrorLoading: false,
            isFilter: false,
            disableExport: false,
            isRefreshButtonDisabled: true,
            showCancelModal: false,
            showRejectModal: false,
            isLoading: false,
            show: false,
            buildingid: 1,
            canExport: false,
            isCreateRight: false,
            isUpdateRight: false,
            isApproveRight: false,
            isDenyRight: false,
            selectedBookingToday: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingYesterdayAuto: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext7Day: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedCustomFilterBooking: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            isCancel: false,
            isCheckIn: false,
            isCheckOut: false,
            isV2Rights: false,
            isCheckInOnBehalfOf: false,
            bookingStatuses: [],
            relationshipOptions: [],
            selectedRelationships: [],
            userList: [{ label: "", email: "" }],
            bookedForEmail: "",
            bookedByEmail: "",
            spaceOptions: [],
            selectedSpace: '',
            filterMode: false,
            confirmModalshow: false,
            isCompleteLoading: false,
            isToday: false,
            daysFilter: "",
            bookingStartDate: DateHelper.now().toJSDate(),
            bookingEndDate: DateHelper.now().toJSDate(),
            dateDisabled: true,
            rowSelectionModel: [],
            cancelButtonDisabled: true,
            approveButtonEnabled: true,
            rejectButtonEnabled: true,
            checkInButtonEnabled: true,
            checkOutButtonEnabled: true,
            tableMessage: "",
            cancelAllRowsEnable: true,
            modalStatus: "",
            isMyBookings: false,
            isMyBookingsForOthers: false,
            searchTerm: "",
            isOperationalServicesBookings: false,
            filterOptions: [],
            selectedFilterId: '-1',
            filterQuery: '',
            showViewBookingSeriesModal: false,
            selectedBookingSeries: { seriesId: '', nodeId: 0, bookingId: '', spaceId: '' },
            currentUsersRoleIds: [],
            selectedBookingZone: 'Any',
            zoneOptions: [],
            isPrimariesBookings: false,
            primariesList: [],
            selectedPrimary: '-1',
            showPreparingExportPopup: false,
            pagesOfBookings: {
                pages: [],
                pageIndex: 0,
                skipToken: '',
            },
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.getPageTitle('');
        const url = window.location.href;
        const path = new URL(url).pathname;

        const relationshipOptions = [
            { label: this.labels.HubLabelAny, value: 'Any' },
            { label: this.labels.dataBookingMain_S, value: '0' },
            { label: this.labels.dataBookingCreateSetup_S, value: '1' },
            { label: this.labels.dataBookingCreateReset_S, value: '2' }
        ];

        await this.setStateAsync({
            isMyBookings: this.area == 'flex' && path.includes("mine"),
            isMyBookingsForOthers: this.area == 'flex' && path.includes("for-others"),
            isPrimariesBookings: this.area == 'flex' && path.includes("primaries"),
            isOperationalServicesBookings: this.area == 'onelens',
            relationshipOptions: relationshipOptions,
        });
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevParams: QueryParams): Promise<void>
    {
        if (firstLoad)
        {
            this.dataGrid?.clearCache();
        }

        const queryParams = this.queryParams;
        if (firstLoad || queryParams.filter == prevParams.filter) // do not show the spinner if typing filter text otherwise text box loses focus
        {
            this.setState({ isLoading: true }); // render method fires many times and is expensive so show loading spinner until we're done
        }

        if (this.area == 'onelens' && !queryParams.building)
        {
            this.pushQueryParams({ building: this.appState.buildingId }, true);
            return;
        }
        else if (this.area == 'flex' && queryParams.building)
        {
            this.pushQueryParams({ building: undefined }, true);
            return;
        }

        const buildingId = queryParams.building ?? this.local.getUserPreferences().SearchPrefs.DefaultBuilding ?? 1;
        await this.setStateAsync({ isFilter: false, buildingid: buildingId });

        if (firstLoad || (queryParams.building != prevParams.building))
        {
            await this.loadSystemFilters();
            await this.loadZones();
            await this.loadSpaces();
        }

        const selectedSystemFilter = this.state.filterOptions.find(i => i.label == queryParams.systemFilter);
        if (queryParams.systemFilter && !selectedSystemFilter)
        {
            this.pushQueryParams({ systemFilter: undefined }, true);
            return;
        }

        const selectedZone = this.state.zoneOptions.find(i => i.value == queryParams.zone);
        if (queryParams.zone && !selectedZone)
        {
            this.pushQueryParams({ zone: undefined }, true);
            return;
        }

        const selectedSpace = this.state.spaceOptions.find(i => i.value == queryParams.space);
        if (queryParams.space && !selectedSpace)
        {
            this.pushQueryParams({ space: undefined }, true);
            return;
        }

        const selectedRelationships = this.state.relationshipOptions.filter(i => queryParams.relationshipTypes?.includes(i.label));
        const defaultPeriod = (this.state.isMyBookings || this.state.isMyBookingsForOthers ? this.labels.HubLabelThisWeek : this.labels.HubLabelToday);

        const dateRange = new DateRangeHelper(this.state.buildingid)
            .addToday()
            .addAll()
            .addTomorrow()
            .addThisWeek()
            .addNextWeek()
            .addThisMonth()
            .addNextMonth()
            .getDateRange(queryParams.period, defaultPeriod, queryParams.start, queryParams.end);

        await this.setStateAsync({
            searchTerm: queryParams.filter || '',
            selectedFilterId: selectedSystemFilter?.value || '-1',
            selectedPrimary: queryParams.primary || '-1',
            bookingStatuses: queryParams.bookingStatuses || ['Any'],
            selectedRelationships: (selectedRelationships.length == 0 ? ['Any'] : selectedRelationships.map(i => i.value)),
            selectedBookingZone: queryParams.zone || 'Any',
            bookedForEmail: queryParams.bookedFor || '',
            bookedByEmail: queryParams.bookedBy || '',
            selectedSpace: queryParams.space || '',
            daysFilter: dateRange.period,
            bookingStartDate: dateRange.start.toJSDate(),
            bookingEndDate: dateRange.end.toJSDate(),
            dateDisabled: (dateRange.period != this.labels.HubLabelCustom),
        });

        const { history } = this.props;
        if (this.state.isPrimariesBookings)
        {
            const primaryBookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry('userApiClientV1.api.user.v1.delegates.myPrimaries.get', () =>
                appContext().userApiClientV1.api.user.v1.delegates.myPrimaries.get<PagedResponse<MyPrimaries[]>>({ select: MyPrimaries }));

            const primariesOptions = [
                new InputOption(this.labels.HubLabelAny, "-1"),
                ...primaryBookings.value.map(i => new InputOption(i.PrimaryDisplayName ?? '', i.PrimaryEmail ?? '')),
            ];
            this.setState({ primariesList: primariesOptions });
        }
        this.setState({ isToday: true });

        const onelensBookingsSearchCriteria = this.session.getOnelensBookingSearchCriteria();
        if (onelensBookingsSearchCriteria.startTime.isValid && onelensBookingsSearchCriteria.endTime.isValid && onelensBookingsSearchCriteria.filterId)
        {
            // Set startTime and endTime set in bookings dashboard, stored in sessionStorage
            this.setState({ bookingStartDate: onelensBookingsSearchCriteria.startTime.toJSDate(), bookingEndDate: onelensBookingsSearchCriteria.endTime.toJSDate() });
            await this.dateRangeDropdownChanged(this.labels.HubLabelCustom);

            // Set selected filterId from sessionStorage
            await this.filterChanged(onelensBookingsSearchCriteria.filterId);

            // Reset session onelensBookingsSearchCriteria
            this.session.setOnelensBookingSearchCriteria(DateHelper.null(), DateHelper.null(), '');
        }

        this.setRights();
        const building = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(i => i.Node_Id == this.state.buildingid);
        this.pageTitle = this.getPageTitle(building?.Name ?? "");
        await this.getCurrentUserRoles();

        // system filter
        if (this.state.selectedFilterId == '-1')
        {
            await this.setStateAsync({ filterQuery: '' });
        }
        else
        {
            const filterEndpoint = {
                name: 'ibssApiClientV2.v2.byNodeid.filters.byFilterid.get',
                options: {
                    nodeId: this.state.buildingid,
                    filterid: this.state.selectedFilterId,
                    select: SystemFilter
                }
            };
            const selectedFilter = await appContext().inMemoryCache.lazyGetWithQuickExpiry(JSON.stringify(filterEndpoint), () =>
                appContext().ibssApiClientV2.v2.byNodeid.filters.byFilterid.get<SystemFilter>(filterEndpoint.options));

            await this.setState({ filterQuery: selectedFilter.Filter });
        }

        // load bookings
        this.setState({ isLoading: false });
        await this.dataGrid?.refresh(false);
        this.enableRefreshButtonAfterTimeOut();
    }

    private async getCurrentUserRoles(): Promise<void> 
    {
        try
        {
            const currentUsersRoles = await appContext().inMemoryCache.lazyGetWithQuickExpiry('userApiClientV1.api.user.v1.users.userroles2.get', () => appContext().userApiClientV1.api.user.v1.users.userroles2.get<UsersRoles[]>({ select: UsersRoles }));
            const currentUsersRoleIds = currentUsersRoles?.map(i => i.id) ?? null;
            this.setState({ currentUsersRoleIds: currentUsersRoleIds });
        }
        catch
        {
        }
    }

    public async loadSystemFilters(): Promise<void>
    {
        if (this.hasFilterReadRights && this.state.isOperationalServicesBookings)
        {
            try
            {
                const filters = await appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<SystemFilter[]>>({
                    nodeId: this.state.buildingid,
                    select: SystemFilter,
                    filter: `Status ne 'StatusInactive' and (Building_Bypass eq false)`,
                });
                const updatedfilterOptions = [
                    new InputOption(this.labels.HubLabelNone, "-1"),
                    ...filters.value.map(i => new InputOption(i.Name, i.Filter_Id ?? ''))
                ];
                await this.setStateAsync({ filterOptions: updatedfilterOptions });

            }
            catch (error)
            {
                this.setState({ filterOptions: [] });
            }
        }
    }

    private async loadZones(): Promise<void>
    {
        const zones = await appContext().ibssApiClientV1.v1.byNodeid.spaceZones.get<SpaceZones[]>({
            nodeId: this.state.buildingid,
            select: SpaceZones
        });

        const options = await zones
            .map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }))
            .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label && t.value === item.value));

        await options.unshift({ label: "Any", value: "Any" });
        await this.setStateAsync({ zoneOptions: options });
    }

    private async loadSpaces(): Promise<void>
    {
        const spaces = await this.apiCache.getSpacesByBuilding(this.state.buildingid);
        const spaceOptions = spaces.map(i => ({ key: i.Space_Id, value: i.Space_Name }));
        await this.setStateAsync({ spaceOptions: spaceOptions });
    }

    private getPageTitle(buildingName: string): string
    {
        let title = "";
        if (this.state.isMyBookings || this.state.isPrimariesBookings || this.state.isMyBookingsForOthers)
        {
            title = this.labels.HubMenumySchedule;
        }
        else
        {
            title = `${this.labels.HubLabelFacilityManagementText} ${buildingName}`;
        }
        return title;
    }

    private setRights(): void
    {
        const res = this.local.getIbssRightList();
        this.setState({ canExport: this.local.hasRight("API.Bookings.Export") });
        this.setState({ isCreateRight: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("Create") > -1) });
        this.setState({ isUpdateRight: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("Update") > -1) });
        this.setState({ isCancel: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("Delete") > -1) });
        this.setState({ isV2Rights: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("V2") > -1) });
        this.setState({ isApproveRight: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("Approve") > -1 && this.local.permissionAppliesToBuilding('API.Bookings.Approve', this.state.buildingid)) });
        this.setState({ isDenyRight: (res && res.ADMINPORTAL && res.ADMINPORTAL.Bookings && res.ADMINPORTAL.Bookings.indexOf("Deny") > -1 && this.local.permissionAppliesToBuilding('API.Bookings.Deny', this.state.buildingid)) });
        this.setState({ isCheckIn: (res && res.ADMINPORTAL && res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("CheckIn") > -1) });
        this.setState({ isCheckOut: (res && res.ADMINPORTAL && res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1) });
        this.setState({ isCheckInOnBehalfOf: (res && res.ADMINPORTAL && res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1 && res.ADMINPORTAL.Spaces.indexOf("CheckInOnBehalfOf") > -1) });
    }

    private filterButtonClicked(): void
    {
        this.setState({
            isFilter: !this.state.isFilter,
            filterMode: true,
        });
    }

    private filterModalClosed(): void
    {
        this.setState({ isFilter: false });
    }

    private filterModalOkayed(): void
    {
        const selectedRelationships = this.state.relationshipOptions.filter(i => this.state.selectedRelationships.includes(i.value));

        this.pushQueryParams({
            bookingStatuses: (!this.state.bookingStatuses[0] || this.state.bookingStatuses[0] == 'Any' ? undefined : this.state.bookingStatuses),
            relationshipTypes: (selectedRelationships.length == 0 || selectedRelationships[0].value == 'Any' ? undefined : selectedRelationships.map(i => i.label)),
            zone: (this.state.selectedBookingZone == 'Any' ? undefined : this.state.selectedBookingZone) || undefined,
            bookedFor: this.state.bookedForEmail || undefined,
            bookedBy: this.state.bookedByEmail || undefined,
            space: this.state.selectedSpace || undefined,
        });
    }

    private async dateRangeDropdownChanged(value: string): Promise<void>
    {
        const period = this.string.toKebabCase(value);
        const customPeriod = this.string.toKebabCase(this.labels.HubLabelCustom);
        const periodIsCustom = (period == customPeriod);

        this.pushQueryParams({
            period: period,
            start: (periodIsCustom ? DateTime.fromJSDate(this.state.bookingStartDate) : undefined),
            end: (periodIsCustom ? DateTime.fromJSDate(this.state.bookingEndDate) : undefined),
        });
    }

    private startDateChanged(date: DateTime): void
    {
        this.pushQueryParams({ start: date });
    }

    private endDateChanged(date: DateTime): void
    {
        this.pushQueryParams({ end: date });
    }

    private async exportClicked(): Promise<void>
    {
        this.setState({ disableExport: true, showPreparingExportPopup: true });
        setTimeout(() => this.setState({ disableExport: false }), 60000);

        const filter = new BookingFilter({
            nodeId: this.state.buildingid,
            from: DateTime.fromJSDate(this.state.bookingStartDate).date(),
            to: DateTime.fromJSDate(this.state.bookingEndDate).date().plus({ days: 1 }),
            systemFilter: this.state.filterQuery ?? '',
            statuses: this.state.bookingStatuses,
            relationships: this.state.selectedRelationships,
            zone: this.state.selectedBookingZone,
            bookedFor: this.state.bookedForEmail,
            bookedBy: this.state.bookedByEmail,
            space: this.state.selectedSpace,
        });

        appContext().ibssApiClientV2.v2.byNodeid.bookings.download.post({
            nodeId: this.state.buildingid,
            body: {
                Node_Id: this.state.buildingid,
                Filter: filter.toString(),
                Select: '_CreatedAt,_CreatedBy,Booking_Id,Booking_Owner_Name,Booking_Owner_Email,Booking_Start,Booking_End,Booking_Name,Space_Id,Space_Name,Space_Layout,Booking_Status,Booking_Checkin_Time,Booking_Completed_Time,Meta_Loc_Zone,Booking_Cancelled_Time,Floor_Name',
            },
        });
    }

    private checkOutManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<void>((resolve, reject) =>
            {
                try
                {
                    const spaceId = booking.Space_Id.split(';')[0];
                    let body = {
                        BookingOwnerEmail: booking.Booking_Owner_Email
                    }
                    appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkout.patch({
                        nodeId: booking.Node_Id,
                        id: spaceId,
                        body: body,
                    })
                        .then(() => resolve());
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage(true);
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private approveManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.approve.patch<string>({
                        nodeId: booking.Node_Id!,
                        bookingid: booking.id
                    })
                        .then(i => resolve(i));
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelBookingsApprovedSuccesfullyMsg, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage(true);
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private rejectManyClicked(): void
    {
        this.setState({
            showRejectModal: false,
            isCompleteLoading: true,
        });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.deny.patch<string>({
                        nodeId: booking.Node_Id,
                        bookingid: booking.id
                    })
                        .then(i => resolve(i));
                    this.setState({ isCompleteLoading: false });

                }
                catch
                {
                    this.setState({ isCompleteLoading: false });
                    return reject(new Error(`Failed to reject the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(messages =>
            {
                this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage(true);
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async confirmCancelManyClicked(): Promise<void>
    {
        this.setState({ showCancelModal: false, isCompleteLoading: true });
        const numOfBookings = this.state.rowSelectionModel.length;
        let numOfFailedDeletes = 0;

        for (const booking of this.state.rowSelectionModel)
        {
            try
            {
                const response = await this.bookingService.delete(booking.Node_Id, booking.id || "");
            }
            catch
            {
                numOfFailedDeletes++;
            }
        }

        if (numOfFailedDeletes > 0)
        {
            this.alert.show(this.labels.HubLabelError, this.labels.funcManyBookingsNotCancelled_D.replace("{0}", numOfFailedDeletes.toString()).replace("{1}", numOfBookings.toString()));
        }
        else
        {
            this.alert.show(this.labels.HubLabelSuccess, this.labels.funcManyBookingsCancelled_D.replace("{0}", numOfBookings.toString()));
        }

        this.setState({ isCompleteLoading: false, cancelButtonDisabled: true });
        setTimeout(() => this.dataGrid?.refresh(true), 500); // bookings takes time to update in the API
    }

    public createBookingClicked(): void
    {
        const { history } = this.props;
        if (this.state.isPrimariesBookings)
        {
            history.push(`/flex/spaces/search?${SearchSpacesHelper.buildQuery()}`);
        }
        else
        {
            history.push(`/one-lens/spaces/search?${SearchSpacesHelper.buildQuery(this.state.buildingid)}`);
        }
    }

    public updateBookingClicked(bookingId: string, spaceId: string): void
    {
        const { history } = this.props;
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId;
        }
        if (this.state.isPrimariesBookings)
        {
            history.push(`/flex/bookings/primaries/${this.state.buildingid}/mybooking/${bookingId}/${bookingSpaceId}`);
        }
        else
        {
            history.push(`/operational-services-bookings/${this.state.buildingid}/bookings/${bookingId}/${spaceId}`);
        }
    }

    private createBookingForMeClicked(bookingId: string, spaceId: string, nodeId: number): void
    {
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings/${getBuildingNodeIdUsingFloorNodeId(nodeId)}/mybooking/${bookingId}/${bookingSpaceId}`);
    }

    private createBookingForOthersClicked(bookingId: string, spaceId: string, nodeId: number): void
    {
        let bookingSpaceId: string | undefined = "";
        if (spaceId.includes(";"))
        {
            const space_id = spaceId.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = spaceId

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings-for-others/${getBuildingNodeIdUsingFloorNodeId(nodeId)}/mybookingother/${bookingId}/${bookingSpaceId}`);
    }

    private updateBookingRoute(): void
    {
        const { selectedBookingSeries } = this.state;

        if (this.state.isOperationalServicesBookings || this.state.isPrimariesBookings)
        {
            this.updateBookingClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId);
        }

        if (this.state.isMyBookings)
        {
            this.createBookingForMeClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId, selectedBookingSeries.nodeId);
        }

        if (this.state.isMyBookingsForOthers)
        {
            this.createBookingForOthersClicked(selectedBookingSeries.bookingId, selectedBookingSeries.spaceId, selectedBookingSeries.nodeId);
        }
    }

    private viewSeriesRoute(): void
    {
        if (this.state.isOperationalServicesBookings)
        {
            this.props.history.push(`/oneLens/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
        else if (this.state.isMyBookings)
        {
            this.props.history.push(`/flex/bookings/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
        else if (this.state.isMyBookingsForOthers)
        {
            this.props.history.push(`/flex/bookings-for-others/${this.state.selectedBookingSeries.nodeId}/booking-series/view/${this.state.selectedBookingSeries.seriesId}`);
        }
    }

    private debounceFilterTextTimer: NodeJS.Timeout | null = null;
    private async filterTextChanged(searchTerm: string): Promise<void> 
    {
        this.setState({ searchTerm: searchTerm, pagesOfBookings: { ...this.state.pagesOfBookings, pageIndex: 0 } });
        clearTimeout(this.debounceFilterTextTimer ?? undefined);
        this.debounceFilterTextTimer = setTimeout(() => this.pushQueryParams({ filter: searchTerm }), Constants.longDebounceTimeInMilliseconds);
    }

    private get filteredPages(): IPages<BookingView>
    {
        const searchTerm = this.state.searchTerm;
        if (!searchTerm)
        {
            return this.state.pagesOfBookings;
        }
        const dataGridColumns = this.dataGrid?.dataGridColumns ?? [];

        const filteredRows = this.state.pagesOfBookings.pages
            .flatMap(i => i.rows)
            .filter(booking =>
            {
                const lowerSearchTerm = searchTerm.toLocaleLowerCase();
                let key: keyof BookingView;

                for (key in booking) 
                {
                    const column = dataGridColumns.find(i => i.field.toLocaleLowerCase() == key.toLocaleLowerCase());
                    const value = column?.valueGetter?.({ row: booking } as any) ?? booking[key]?.toString();

                    if (column && value && value.toLocaleLowerCase().includes(lowerSearchTerm))
                    {
                        return true;
                    }
                }
                return false;
            })
            .slice(0, this.pageSize);

        const pages = { ...this.state.pagesOfBookings, pages: [{ rows: filteredRows }], skipToken: '' };
        return pages;
    }

    private primaryChanged(primary: string): void
    {
        this.pushQueryParams({ primary: primary == '-1' ? undefined : primary });
    }

    private async filterChanged(selectedId: string): Promise<void>
    {
        const selectedFilter = this.state.filterOptions.find(i => i.value == selectedId);
        this.pushQueryParams({ systemFilter: (selectedId == '-1' ? undefined : selectedFilter?.label) });
    }

    private changeLayout(layout: string | null): void 
    {
        if (layout !== null)
        {
            this.props.history.push(`/buildings/${this.state.buildingid}/bookings/dashboard`);
        }
    }

    public render(): JSX.Element
    {
        if (this.state.isLoading)
        {
            return (<Spinner />);
        }

        let filterStatusOption = [
            { label: this.labels.HubLabelApproved, value: "Approved" },
            { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
            {
                label: this.labels.HubLabelAutoCancelledStatus,
                value: "Auto Cancelled",
            },
            { label: this.labels.HubLabelCancelled, value: "Cancelled" },
            { label: this.labels.HubLabelCompleted, value: "Completed" },
            { label: this.labels.HubLabelamended, value: "Amended" },
            { label: this.labels.HubLabelNew, value: "New" },
            { label: this.labels.HublabelEarlyCheckIn, value: "Early Check In" },
            { label: this.labels.HubLabelInProgress, value: "In Progress" },
            { label: this.labels.HublabelLateCheckIn, value: "Late Checkin" },
            { label: this.labels.HubLabelNoShow, value: "No Show" },
        ].sort((a, b) =>
        {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })

        filterStatusOption = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterStatusOption]

        const actions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonDisabled,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubLabelReject,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.rejectButtonEnabled || !(this.hasV2Rights && this.hasRejectRights),
                onClick: () => this.setState({
                    showRejectModal: true,
                    modalStatus: "reject"
                })
            },
            {
                label: this.labels.HubLabelApprove,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.approveButtonEnabled || !(this.hasV2Rights && this.hasApproveRights),
                onClick: () => this.approveManyClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: this.state.isCreateRight ? false : true,
                onClick: () => this.createBookingClicked(),
            }
        ]

        const checkInActions: IActionButton[] = this.state.isPrimariesBookings ? [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkOutButtonEnabled,
                onClick: () => this.checkOutManyClicked()
            },
            {
                label: this.labels.HubButtonCheckIn,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkInButtonEnabled,
                onClick: () => this.dataGrid?.checkInOneClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: this.state.isCreateRight ? false : true,
                onClick: () => this.createBookingClicked(),
            }
        ] : [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {

                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonDisabled,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkOutButtonEnabled,
                onClick: () => this.checkOutManyClicked()
            },
        ]

        const otherActions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelAllRowsEnable,
                onClick: () => this.setState({
                    showCancelModal: true,
                    modalStatus: "cancel"
                })
            }
        ]


        const layoutOptions: toggleButtonListObject[] =
            [
                { value: 'allList', icon: <FormatListBulletedIcon /> },
                { value: 'scheduleView', icon: <CalendarTodayIcon /> },
            ];

        return (
            <>
                <div className="">
                    <PreparingExportDialog open={this.state.showPreparingExportPopup} onClose={() => this.setState({ showPreparingExportPopup: false })} />
                    <IbssDialog
                        aria-modal="true"
                        aria-label="view series modal"
                        onClose={() => this.setState({
                            showViewBookingSeriesModal: false,
                            selectedBookingSeries: { seriesId: '', nodeId: 0, bookingId: '', spaceId: '' }
                        })}
                        open={this.state.showViewBookingSeriesModal}
                        header={this.labels.funcConfirmViewBookingSeries_Message}
                        fullWidth
                        dialogContent={
                            <>
                                <div className="mb-3">{this.labels.funcSeriesBookingSelected_Message}</div>
                            </>
                        }
                        footer={
                            <>
                                <div className="d-flex justify-content-between w-90">
                                    <IbssButtonRedo color="secondary" variant="contained"
                                        className="mr-2"
                                        disabled={!this.state.isUpdateRight}
                                        onClick={() => this.updateBookingRoute()}
                                    >
                                        {this.labels.funcViewBooking_S}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo color="primary" variant="contained"
                                        className="ml-2"
                                        onClick={() => this.viewSeriesRoute()}
                                    >
                                        {this.labels.funcViewSeries_S}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />

                    <FilterDialog
                        isOpen={this.state.isFilter}
                        buildingId={this.state.buildingid}
                        isMyBookings={this.state.isMyBookings}
                        isMyBookingsForOthers={this.state.isMyBookingsForOthers}
                        isOperationalServicesBookings={this.state.isOperationalServicesBookings}
                        selectedBookingStatuses={this.state.bookingStatuses}
                        relationshipOptions={this.state.relationshipOptions}
                        selectedRelationships={this.state.selectedRelationships}
                        zoneOptions={this.state.zoneOptions}
                        selectedZone={this.state.selectedBookingZone}
                        bookedForEmail={this.state.bookedForEmail}
                        bookedByEmail={this.state.bookedByEmail}
                        spaceOptions={this.state.spaceOptions}
                        selectedSpace={this.state.selectedSpace}

                        onBookingStatusChange={i => this.setState({ bookingStatuses: i })}
                        onRelationshipStatusChange={i => this.setState({ selectedRelationships: i })}
                        onZoneChange={i => this.setState({ selectedBookingZone: i })}
                        onBookedForChange={(label, email) => this.setState({ bookedForEmail: email })}
                        onBookedByChange={(label, email) => this.setState({ bookedByEmail: email })}
                        onSpaceChange={i => this.setState({ selectedSpace: i })}
                        onClose={() => this.filterModalClosed()}
                        onOkayed={() => this.filterModalOkayed()}
                    />
                    {/** start of cancel booking modal **/}

                    <CancelBookingsDialog
                        show={this.state.showCancelModal}
                        onClose={() => this.setState({ showCancelModal: false })}
                        updateBookings={() => this.confirmCancelManyClicked()}
                    />

                    {/** end of cancel booking modal */}

                    {/** start of reject booking modal **/}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="reject booking modal"
                        onClose={() => this.setState({
                            showRejectModal: false,
                        })}
                        open={this.state.showRejectModal}
                        header={this.labels.HubLabelModalConfirmTitle}
                        fullWidth
                        dialogContent={
                            <>
                                {
                                    <p>{this.labels.HubLabelRejectAllMessage}</p>
                                }
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo color="secondary" variant="contained"
                                    className="mr-2"
                                    onClick={() => this.setState({
                                        showRejectModal: !this.state.showRejectModal,
                                    })}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained"
                                    className="ml-2"
                                    onClick={() => this.rejectManyClicked()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/** end of cancel reject modal */}

                    <div className="page-height-exct-header">
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <IbssBookingListHeader
                                    title={this.state.isMyBookingsForOthers ? this.labels.HubMenuBookingsForOthers : this.state.isPrimariesBookings ? this.labels.HubLabelBookingsForPrimaries : this.labels.HubLabelBookingsOverview}
                                    description={this.state.isPrimariesBookings ? this.labels.HubLabelBookingsForPrimariesDescription : ''}
                                    selectedPreset={this.state.daysFilter}
                                    presetChanged={(e) => this.dateRangeDropdownChanged(e.target.value)}
                                    startDate={DateTime.fromJSDate(this.state.bookingStartDate)}
                                    onLayoutChange={(newLayout) => this.changeLayout(newLayout)}
                                    layout={"allList"}
                                    toggleButtonList={this.state.isOperationalServicesBookings ? layoutOptions : []}
                                    endDate={DateTime.fromJSDate(this.state.bookingEndDate)}
                                    datesDisabled={this.state.dateDisabled}
                                    presets={[this.labels.HubLabelAll, this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                    onStartDateChange={date => this.startDateChanged(date)}
                                    onEndDateChange={date => this.endDateChanged(date)}
                                    isOperationalServicesBookings={this.state.isOperationalServicesBookings}
                                    isPrimariesBookings={this.state.isPrimariesBookings}
                                    onfilterButtonClicked={() => this.filterButtonClicked()}
                                    primaryChanged={(primary: string) => this.primaryChanged(primary)}
                                    selectedPrimary={this.state.selectedPrimary}
                                    primariesList={this.state.primariesList}
                                    selectedFilterId={this.state.selectedFilterId}
                                    filterOptions={this.state.filterOptions}
                                    filterChanged={(selectedId: string) => this.filterChanged(selectedId)}
                                    searchTerm={this.state.searchTerm}
                                    filterTextChanged={(text: string) => this.filterTextChanged(text)}
                                    disableExport={this.state.disableExport}
                                    canExport={this.state.canExport && !(this.state.isMyBookings || this.state.isMyBookingsForOthers || this.state.isPrimariesBookings)}
                                    exportClicked={() => this.exportClicked()}
                                />
                                <Grid container rowSpacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                    <Grid sm={12}>
                                        {this.state.isOperationalServicesBookings && (<Typography className="table-panel-description">{this.labels.HubLabelBookingFilterText}</Typography>)}
                                    </Grid>
                                    <Grid sm={12}>
                                        <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                            {this.state.isMyBookingsForOthers ?
                                                <IbssActionButton buttons={otherActions} />
                                                :
                                                <IbssActionButton buttons={this.state.isMyBookings || this.state.isPrimariesBookings ? checkInActions : actions} />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>

                                {this.state.isCompleteLoading &&
                                    <Spinner />
                                }
                                <Box sx={{ mt: 1 }}>
                                    <DataGrid
                                        ref={this.dataGridRef}
                                        buildingid={this.state.buildingid}
                                        searchTerm={this.state.searchTerm}
                                        bookingStartDate={this.state.bookingStartDate}
                                        bookingEndDate={this.state.bookingEndDate}
                                        isMyBookings={this.state.isMyBookings}
                                        isMyBookingsForOthers={this.state.isMyBookingsForOthers}
                                        isPrimariesBookings={this.state.isPrimariesBookings}
                                        bookingStatuses={this.state.bookingStatuses}
                                        selectedUserBookedByObjectEmail={this.state.bookedByEmail}
                                        selectedSpaceOptionValue={this.state.selectedSpace}
                                        selectedPrimary={this.state.selectedPrimary}
                                        selectedUserBookedForObjectEmail={this.state.bookedForEmail}
                                        relationshipStatuses={this.state.selectedRelationships.filter(i => i != 'Any')}
                                        filterQuery={this.state.filterQuery}
                                        selectedFilterId={this.state.selectedFilterId}
                                        selectedBookingZone={this.state.selectedBookingZone}
                                        currentUsersRoleIds={this.state.currentUsersRoleIds}
                                        isCancel={this.state.isCancel}
                                        isCheckIn={this.state.isCheckIn}
                                        isCheckOut={this.state.isCheckOut}
                                        isApproveRight={this.state.isApproveRight}
                                        isDenyRight={this.state.isDenyRight}
                                        isUpdateRight={this.state.isUpdateRight}
                                        rowSelectionModel={this.state.rowSelectionModel}
                                        pageSize={this.pageSize}
                                        pages={this.filteredPages}
                                        onLoadingChange={i => this.setState({ isCompleteLoading: i })}
                                        onPagesChange={pages => this.setStateAsync({ pagesOfBookings: pages })}
                                        onRowSelectionChange={i => this.setState({ rowSelectionModel: i })}
                                        onCanCancelChange={i => this.setState({ cancelButtonDisabled: i })}
                                        onCanCancelAllChange={i => this.setState({ cancelAllRowsEnable: i })}
                                        onCanAproveChange={i => this.setState({ approveButtonEnabled: i })}
                                        onCanRejectChange={i => this.setState({ rejectButtonEnabled: i })}
                                        onCanCheckInChange={i => this.setState({ checkInButtonEnabled: i })}
                                        onCanCheckOutChange={i => this.setState({ checkOutButtonEnabled: i })}
                                        onCancelBooking={booking => this.setState({ showCancelModal: true, rowSelectionModel: [booking]})}
                                        onCreateBookingForMe={(bookingId, spaceId, nodeId) => this.createBookingForMeClicked(bookingId, spaceId, nodeId)}
                                        onCreateBookingForOther={(bookingId, spaceId, nodeId) => this.createBookingForOthersClicked(bookingId, spaceId, nodeId)}
                                        onUpdateBooking={(bookingId, spaceId) => this.updateBookingClicked(bookingId, spaceId)}
                                        onSeriesBookingSelected={row => this.setState({ showViewBookingSeriesModal: true, selectedBookingSeries: { seriesId: row.BookingSeriesId, nodeId: row.Node_Id, bookingId: row.Booking_Id, spaceId: row.Space_Id } })}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

interface IProps extends RouterProps { }

interface IState
{
    getRightApiResponseErrorLoading: boolean;
    isFilter: boolean;
    isLoading: boolean;
    disableExport: boolean;
    isRefreshButtonDisabled: boolean;
    show: boolean;
    buildingid: number;
    canExport: boolean;
    isCreateRight: boolean;
    isUpdateRight: boolean;
    selectedBookingToday: IBooking[];
    selectedBookingNext: IBooking[];
    selectedBookingYesterdayAuto: IBooking[];
    selectedBookingNext7Day: IBooking[];
    selectedCustomFilterBooking: IBooking[];
    isCancel: boolean;
    isCheckIn: boolean;
    isCheckOut: boolean;
    isV2Rights: boolean;
    isApproveRight: boolean;
    isDenyRight: boolean;
    isCheckInOnBehalfOf: boolean;
    relationshipOptions: IOption[];
    selectedRelationships: string[];
    bookingStatuses: string[];
    userList: ILabelEmail[];
    bookedForEmail: string;
    bookedByEmail: string;
    spaceOptions: KeyValue<string, string>[];
    selectedSpace: string;
    filterMode: boolean;
    confirmModalshow: boolean;
    isCompleteLoading: boolean;
    isToday: boolean;
    daysFilter: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    dateDisabled: boolean;
    rowSelectionModel: BookingView[];
    cancelButtonDisabled: boolean;
    approveButtonEnabled: boolean;
    rejectButtonEnabled: boolean;
    tableMessage: string;
    checkInButtonEnabled: boolean;
    checkOutButtonEnabled: boolean;
    cancelAllRowsEnable: boolean;
    showCancelModal: boolean;
    showRejectModal: boolean;
    modalStatus: string;
    isMyBookings: boolean;
    isMyBookingsForOthers: boolean;
    searchTerm: string;
    isOperationalServicesBookings: boolean;
    filterOptions: InputOption[],
    selectedFilterId: string;
    filterQuery: string | null;
    showViewBookingSeriesModal: boolean;
    selectedBookingSeries: { seriesId: string, nodeId: number, bookingId: string, spaceId: string };
    currentUsersRoleIds: string[];
    selectedBookingZone: string;
    zoneOptions: InputOption[];
    isPrimariesBookings: boolean;
    primariesList: InputOption[];
    selectedPrimary: string;
    showPreparingExportPopup: boolean;
    pagesOfBookings: IPages<BookingView>;
}

interface IBooking
{
    id: number;
    status: string;
    spaceId: string;
    ownerEmail: string;
}

interface ILabelEmail
{
    label?: string;
    email: string;
}

interface IFilterObject
{
    buildingId: number;
    statusValue: string;
    bookedFor: string;
    bookedBy: string;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}

export interface CustomRouteState 
{
    status: string;
}

export default connect(mapStateToProps)(ListBookings);
