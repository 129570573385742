import { DateTime } from "luxon";
import { BaseQueryParams } from "../../../../Components/Core/BasePage/BaseQueryParams";

export class QueryParams extends BaseQueryParams
{
    public filter?: string;
    public systemFilter?: string;
    public primary?: string;
    public bookingStatuses?: string[];
    public relationshipTypes?: string[];
    public zone?: string;
    public bookedFor?: string;
    public bookedBy?: string;
    public space?: string;
    public period?: string;
    public start?: DateTime;
    public end?: DateTime;

    public serialise(propertyName: keyof QueryParams, propertyValue: any): string | null
    {
        switch (propertyName)
        {
            case 'bookingStatuses':
            case 'relationshipTypes':
                return this.serialiseArray<string>(propertyValue, i => i);
            case 'start':
            case 'end':
                return this.serialiseDate(propertyValue);
            default:
                return super.serialise(propertyName, propertyValue) || null;
        }
    }

    public deserialise(propertyName: keyof QueryParams, queryValue: string | null): any
    {
        switch (propertyName)
        {
            case 'bookingStatuses':
            case 'relationshipTypes':
                return this.deserialiseArray<string>(queryValue, i => i);
            case 'start':
            case 'end':
                return this.deserialiseDate(queryValue);
            default:
                return super.deserialise(propertyName, queryValue);
        }
    }
}
