import axios, { AxiosResponse } from "axios";
import { StringHelper } from "../../Common/StringHelper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CostCodeType, ICostCode } from "./CostCodeRepository";

export class GetCostCodesEndpoint implements IGetCostCodesEndpoint
{
    private string = new StringHelper();

    public async execute(take: number, startOfCodeCode?: string, costCodeType?: CostCodeType): Promise<ICostCode[]>
    {
        try
        {
            let query =
                `$select=Cost_Code,Cost_Code_Description,Cost_Code_Id,Cost_Code_Type,EventTime,Cost_Code_Source&` +
                `$top=${take}&` +
                `$filter=Cost_Code_Id ne null and Cost_Code_IsEnabled eq 1`

            if (startOfCodeCode)
            {
                query = query + ` and Cost_Code ge '${startOfCodeCode}' and Cost_Code lt '${this.string.incrementLastCharacter(startOfCodeCode)}'`
            }

            if (costCodeType != null)
            {
                switch (costCodeType)
                {
                    case CostCodeType.ClientCode:
                        query = query + ` and Cost_Code_Type eq 100`;
                        break;

                    case CostCodeType.DepartmentCode:
                        query = query + ` and Cost_Code_Type eq 101`;
                        break;

                    case CostCodeType.StandardCostCode:
                        query = query + ` and (Cost_Code_Type ge 0 and Cost_Code_Type le 99)`;
                        break;
                }
            }

            const response: AxiosResponse<ICostCode[]> = await axios.get(`${apis.allApi}${1}/CostCodes?${query}`)
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetCostCodesEndpoint
{
    execute(take: number, startOfCodeCode?: string, costCodeType?: CostCodeType): Promise<ICostCode[]>;
}
