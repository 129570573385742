import { Box } from '@mui/material'
import { appContext } from '../../../../AppContext';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IVisitorStatus } from './EditVisit';
import VisitorStatusChecker from './VisitorStatusChecker';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

export default class EditVisitorStatus extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    render() 
    {
        return (
            <Box className="tablePanel">
                <h3>{this.labels.HubLabelStatus}</h3>
                <IbssTextField
                    fullWidth
                    required
                    className='mt-3'
                    disabled
                    label={this.labels.HubLabelVisitID}
                    value={this.props.visitId}
                    variant="outlined"
                />
                <IbssTextField
                    fullWidth
                    required
                    disabled
                    label={this.labels.HubLabelStatus}
                    value={this.props.status}
                    className='mt-3'
                    variant="outlined"
                />

                <VisitorStatusChecker visitorStatus={this.props.status}/>
            </Box>
        )
    }
}

interface IProps
{
    visitId: string;
    status: string;
    visitorStatus: IVisitorStatus;
}

interface IState {}