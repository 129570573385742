import { Box, Card, Grid, Typography } from '@mui/material';
import React, { Component } from 'react'
import { appContext } from '../../../../AppContext';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';


type labelsList = {
    label: string,
    checked: boolean,
    onChange: (e: any) => void,
    name: string,
}

type labelsListProp = {
    labelsList: labelsList[]
};

class IbssUserPrefNotificationUI extends Component<labelsListProp> {
    private labels = appContext().labels;

    public constructor(props:labelsListProp) {
        super(props);
    }

    render() {
        return (
            <Card className="card-userPref">
                <Grid
                    container
                    gap={1}
                >
                    <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleTailorNotification}</Typography>
                    <Typography className='card-userPref-text' variant="body2" gutterBottom>{this.labels.funcSubHeaderNotification_D}</Typography>
                </Grid>

                <Grid
                    container
                    item
                    display={"block"}
                    alignItems="start"
                    direction="column"
                    gap={2}
                >
                    {this.props.labelsList.map(({ label, checked, onChange, name }) => {
                        const isPushNotification = name === 'visitorPush' || name === 'taskPush' || name === 'bookingsPush';

                        return (
                            <IbssToolTip
                                key={name}
                                title={isPushNotification ? this.labels.funcPushNotificationInfo_D : ""}
                                arrow
                            >
                                <Box my={1}>
                                    <IbssSwitchLabel
                                        label={label}
                                        labelClasses={{ style: { margin: "20px 0px" } }}
                                        labelPlacement={LabelPlacementType.start}
                                        checked={checked}
                                        onChange={onChange}
                                        name={name}
                                        switchDisabled={isPushNotification}
                                    />
                                </Box>
                            </IbssToolTip>
                        );
                    })}

                </Grid>

            </Card>
        )
    }
};

export default IbssUserPrefNotificationUI;
