import { ODataSelect } from "../../../Providers.Api/ODataQuery";
 
export class CostCodeModal extends ODataSelect
{
    Cost_Code = '' ;
    Cost_Code_Description = '';
    Cost_Code_Id = '';
    Cost_Code_Type = 0;
    EventTime = '';
    Cost_Code_Source = '';
}