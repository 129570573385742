import { Component } from 'react';

export default class RegionIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 36 36" fill="Inherit" xmlns="http://www.w3.org/2000/svg">
                <path id="Path_71" data-name="Path 71" d="M16.743,1.456a5.538,5.538,0,0,0-5.581,5.492,7.606,7.606,0,0,0,.73,2.759,21.984,21.984,0,0,0,1.78,3.262A19.689,19.689,0,0,0,15.7,15.6a6.07,6.07,0,0,0,.8.735,1.474,1.474,0,0,0,.243.149,1.506,1.506,0,0,0,.236-.146,6.155,6.155,0,0,0,.8-.732A19.9,19.9,0,0,0,19.8,12.983a21.868,21.868,0,0,0,1.79-3.26,7.6,7.6,0,0,0,.735-2.776A5.538,5.538,0,0,0,16.743,1.456ZM9.706,6.948a7.037,7.037,0,0,1,14.074,0,8.966,8.966,0,0,1-.854,3.352,23.3,23.3,0,0,1-1.911,3.484,21.329,21.329,0,0,1-2.184,2.824,7.566,7.566,0,0,1-1,.909,1.951,1.951,0,0,1-1.09.44,1.972,1.972,0,0,1-1.1-.44,7.473,7.473,0,0,1-1.006-.915,21.136,21.136,0,0,1-2.182-2.835,23.419,23.419,0,0,1-1.9-3.485A8.971,8.971,0,0,1,9.706,6.948Z" fill-rule="evenodd" />
                <path id="Path_72" data-name="Path 72" d="M32.156,7.865a.728.728,0,0,1,.359.627V25.246a.728.728,0,0,1-.374.636l-10.015,5.57a.728.728,0,0,1-.68.015L10.483,25.975,1.062,30.849A.728.728,0,0,1,0,30.2V12.528A.728.728,0,0,1,.362,11.9L5.038,9.179a.728.728,0,1,1,.732,1.259L1.456,12.946v16.06l8.688-4.494a.728.728,0,0,1,.66,0l10.95,5.485,9.3-5.175V9.729l-4.213,2.338a.728.728,0,1,1-.706-1.273l5.294-2.938A.728.728,0,0,1,32.156,7.865Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
