import { Box, Card, Grid, SelectChangeEvent } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { RouteComponentProps } from "react-router";
import { appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";
import Helper from "../../../../Common/Helper";
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo";
import { InfiniteScrollerResult } from "../../../../Components/Navigation/InfiniteScroller/InfiniteScroller";
import MediaSelector, { IFile } from "../../../../Components/DialogLaunchers/MediaSelector/MediaSelector";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssInputDropDown, { ISelectOption } from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import { IbssLuxonDateTimePicker } from "../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssTransferList, { IListItem as ITransferListItem } from "../../../../Components/Data/TransferList/IbssTransferList";
import { CateringMenuItemClassification, CateringMenuItemStatus, Filter } from "../../../../Providers.Api/CateringMenuItems/CateringMenuItemRepository";
import { CateringMenuItem } from "../../../../Providers.Api/CateringMenuItems/CateringMenuItemRepository";
import { PagedResponse } from "../../../../Providers.Api/Models";
import { Role } from "../../../../Providers.Api/Roles/RoleRepository";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { ICateringSpaces } from "../../../../Providers.Api/CateringSpaces/CateringSpacesRepository";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { DataEntryRole } from "./DataModels";

export default class CateringMenus extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get alert() { return appContext().alert; }
    private get services() { return appContext().services; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get apiCache() { return appContext().apiCache; }
    private menuItemsRef = React.createRef<IbssTransferList>();
    private rolesRef = React.createRef<IbssTransferList>();
    private spaceRef = React.createRef<IbssTransferList>();
    private get buildingId() { return parseInt(this.props.match.params.buildingId) }
    private get cateringMenuId() { return this.props.match.params.cateringMenuId }
    private get isNew() { return this.cateringMenuId == null }
    private get cardHeight() { return this.isNew ? "calc(100vh - 225px)" : "" }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            allRoles: [],
            allSpaces: [],
            buildingId: this.appState.buildingId,
            loading: false,
            name: "",
            nameError: "",
            shortDescription: "",
            shortDescriptionError: "",
            availableFrom: DateHelper.null(),
            availableFromError: "",
            availableTo: DateHelper.null(),
            availableToError: "",
            images: [],
            imageUrl: "",
            statuses: [{ label: this.labels.funcCateringMenuEditActive_S, value: "StatusActive" }, { label: this.labels.funcCateringMenuEditInactive_S, value: "StatusInactive" }],
            status: "Active",
            concurrencyStamp: "",

            showMenuItems: false,
            menuItemsClassification: CateringMenuItemClassification.Null,
            selectedMenuItems: [],
            selectedMenuItemsForClassification: [],

            showRoles: false,
            showSpaces: false,
            selectedRoles: [],
            selectedSpaces: [],
            selectedSpaceIds: [],
            availableSpaces: [],
            availableRoles: [],

            showConfirmDelete: false,
            selectedBeveragesSummary: "",
            selectedSnacksSummary: "",
            selectedFoodSummary: "",
            selectedRolesSummary: "",
            selectedSpaceSummary: "",
        };
    }

    private get menuItemsHeader(): string
    {
        switch (this.state.menuItemsClassification)
        {
            case CateringMenuItemClassification.Beverage:
                return this.labels.funcCateringMenuEditIncludeBeverages_S;
            case CateringMenuItemClassification.Snack:
                return this.labels.funcCateringMenuEditIncludeSnacks_S;
            case CateringMenuItemClassification.Food:
                return this.labels.funcCateringMenuEditIncludeFood_S;
            default:
                return "";
        }
    }

    private availableMenuItems(allItems: ITransferListItem[], selected: CateringMenuItem[]): ITransferListItem[]
    {
        const indexedSelected = new Map(selected.map(i => [i.MenuItem_Id, i]));
        const availableItems = allItems.filter(i => i.id && !indexedSelected.has(i.id)).sort((a, b) => a.primaryText < b.primaryText ? -1 : 1);
        return availableItems;
    }

    private availabeRoles(selected: Role[]): ITransferListItem[]
    {
        const indexedSelected = new Map(selected.map(i => [i.Id, i]));

        return this.state
            .allRoles
            .filter(i => !indexedSelected.has(i.id))
            .map(i =>
            ({
                id: i.id,
                primaryText: i.name,
                secondaryText: "",
            }))
            .sort((a, b) => a.primaryText < b.primaryText ? -1 : 1);
    }

    private availabeSpaces(selected: ICateringSpaces[]): ITransferListItem[]
    {
        const indexedSelected = new Map(selected.map(i => [i.Space_Id, i]));

        return this.state
            .allSpaces
            .filter(i => !indexedSelected.has(i.Space_Id) && i.Meta_Serv_Reqs_Catering == 1)
            .map(i =>
            ({
                id: i.Space_Id,
                primaryText: i.Space_Id,
                secondaryText: "",
            }))
            .sort((a, b) => a.primaryText < b.primaryText ? -1 : 1);
    }

    private selectedSummary(items: string[]): string
    {
        if (items.length == 0)
        {
            return this.labels.funcCateringMenuEditNoneSelected_S;
        }
        else if (items.length <= 3)
        {
            return `${items.join(", ")}.`;
        }
        else
        {
            return this.labels.funcCateringMenuEditMenuItemsAndMore_S
                .replace("{menu-items}", items.slice(0, 3).join(", "))
                .replace("{remaining}", (items.length - 3).toString());
        }
    }

    public async componentDidMount(): Promise<void>
    {
        try
        {
            this.setState({ loading: true });
            this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
            this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
            this.loadImages();

            if (this.cateringMenuId == null)
            {
                throw new Error(this.labels.funcCateringMenuEditMenuIdIsNull_S);

            }

            const menu = await this.apiClient.cateringMenus.getById(this.buildingId, this.cateringMenuId, false, false);
            this.setState({
                name: menu.Name,
                shortDescription: menu.Short_Description,
                availableFrom: menu.Available_From.offsetTimeByNode(this.buildingId),
                availableTo: menu.Available_Until.offsetTimeByNode(this.buildingId),
                imageUrl: menu.ImageURI,
                status: menu.Status,
                concurrencyStamp: menu.ConcurrencyStamp,
            });

            let allRoles: DataEntryRole[] = [];
            try
            {
                allRoles = await appContext().ibssApiClientV2.v2.roles.get<DataEntryRole[]>({ suppressErrorPopup: true });
            }
            catch { }
            const allSpaces = await this.apiCache.getSpacesByBuilding(this.state.buildingId);

            this.setState({
                loading: false,
                allRoles: allRoles,
                allSpaces: allSpaces
            });

            await this.setMenuItemSummaries();
            await this.setRoleSummaries();
            await this.setSpacesSummaries();
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void> 
    {
        if (prevState.buildingId !== this.appState.buildingId)
        {
            this.props.history.push(`/buildings/${this.state.buildingId}/catering-menus/new`);
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ loading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.loadImages();
        this.setState({ loading: false });
    }

    private async setMenuItemSummaries(): Promise<void>
    {
        try 
        {
            if (this.cateringMenuId == null)
            {
                throw new Error(this.labels.funcCateringMenuEditMenuIdIsNull_S);
            }

            const selectedMenuItems = await this.apiClient.cateringMenuItems.getManyByMenuId(this.buildingId, this.cateringMenuId, CateringMenuItem, new Filter({ status: CateringMenuItemStatus.Active }));

            this.setState({
                selectedBeveragesSummary: this.selectedSummary(selectedMenuItems.value.filter(i => i.Classification == CateringMenuItemClassification.Beverage).map(i => i.Name)),
                selectedSnacksSummary: this.selectedSummary(selectedMenuItems.value.filter(i => i.Classification == CateringMenuItemClassification.Snack).map(i => i.Name)),
                selectedFoodSummary: this.selectedSummary(selectedMenuItems.value.filter(i => i.Classification == CateringMenuItemClassification.Food).map(i => i.Name)),
            });
        } catch (error) 
        {
        }

    }

    private async setRoleSummaries(): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            throw new Error(this.labels.funcCateringMenuEditMenuIdIsNull_S);
        }

        const selectedRoles = await this.apiClient.roles.getManyByMenuId(this.buildingId, this.cateringMenuId, Role);

        this.setState({
            selectedRolesSummary: this.selectedSummary(selectedRoles.value.map(i => i.Name)),
        });
    }

    private async setSpacesSummaries(): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            throw new Error(this.labels.funcCateringMenuEditMenuIdIsNull_S);
        }

        try 
        {
            const selectedSpaces = await this.apiClient.cateringSpaces.getManyByMenuId(this.buildingId, this.cateringMenuId);
            this.setState({
                selectedSpaceIds: selectedSpaces.map((item) => item.Space_Id),
                selectedSpaceSummary: this.selectedSummary(selectedSpaces.map(i => i.Space_Id)),
            });
        }
        catch (error) 
        {
        }
    }

    private async validateCreate(): Promise<boolean>
    {
        let isValid = true;
        isValid = (await this.validateName()) && isValid;
        isValid = (await this.validateShortDescription()) && isValid;
        isValid = (await this.validateAvailableFrom()) && isValid;
        isValid = (await this.validateAvailableTo()) && isValid;
        return isValid;
    }

    private async validateName(): Promise<boolean>
    {
        await this.setStateAsync({ nameError: this.state.name.trim() == "" ? this.labels.funcCateringMenuEditRequired_S : "" });
        return (this.state.nameError == "");
    }

    private async validateShortDescription(): Promise<boolean>
    {
        await this.setStateAsync({ shortDescriptionError: this.state.shortDescription.trim() == "" ? this.labels.funcCateringMenuEditRequired_S : "" });
        return (this.state.shortDescriptionError == "");
    }

    private async validateAvailableFrom(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.availableFrom.isValid && this.state.availableTo.isValid && this.state.availableFrom >= this.state.availableTo);

        // validate 'available from'
        if (this.state.availableFrom.isNull())
        {
            await this.setStateAsync({ availableFromError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.availableFrom.isValid)
        {
            await this.setStateAsync({ availableFromError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ availableFromError: this.labels.funcCateringMenuEditMustBeBeforeAvailableTo_S });
        }
        else
        {
            await this.setStateAsync({ availableFromError: "" });
        }

        // validate 'available to'
        if (this.state.availableTo.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ availableToError: this.labels.funcCateringMenuEditMustBeAfterAvailableFrom_S });
            }
            else
            {
                await this.setStateAsync({ availableToError: "" });
            }
        }

        return (this.state.availableFromError == "");
    }

    private async validateAvailableTo(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.availableFrom.isValid && this.state.availableTo.isValid && this.state.availableFrom >= this.state.availableTo);

        // validate 'available to'
        if (this.state.availableTo.isNull())
        {
            await this.setStateAsync({ availableToError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.availableTo.isValid)
        {
            await this.setStateAsync({ availableToError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ availableToError: this.labels.funcCateringMenuEditMustBeAfterAvailableFrom_S });
        }
        else
        {
            await this.setStateAsync({ availableToError: "" });
        }

        // validate 'available from'
        if (this.state.availableFrom.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ availableFromError: this.labels.funcCateringMenuEditMustBeBeforeAvailableTo_S });
            }
            else
            {
                await this.setStateAsync({ availableFromError: "" });
            }
        }

        return (this.state.availableToError == "");
    }

    private async nameChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        await this.setStateAsync({ name: event.target.value });
        await this.validateName();
    }

    private async shortDescriptionChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        await this.setStateAsync({ shortDescription: event.target.value });
        await this.validateShortDescription();
    }

    private async availableFromChanged(value: DateTime): Promise<void>
    {
        await this.setStateAsync({ availableFrom: value });
        await this.validateAvailableFrom();
    }

    private async availableToChanged(value: DateTime): Promise<void>
    {
        await this.setStateAsync({ availableTo: value });
        await this.validateAvailableTo();
    }

    private async loadImages(): Promise<void>
    {
        try
        {
            const images = await this.apiClient.files.getFiles('r/Catering');
            images.forEach(i => i.path = `https://storage.ibss.${Helper.environment}/images/r/Catering/${i.name}`);
            this.setState({ images: images });
        }
        catch
        {
        }
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        try
        {
            this.setState({ loading: true });
            await this.apiClient.files.uploadFile(file, 'r/Catering/' + filename, processAsMap);
            this.loadImages();
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private async showMenuItemsClicked(classification: CateringMenuItemClassification): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            return;
        }

        this.setState({ loading: true });
        const filter = new Filter({ classification: classification, status: CateringMenuItemStatus.Active });
        // we can't lazy load the selected menu-items because we need the full set to work out the available menu-items
        const selectedMenuItems = await this.apiClient.cateringMenuItems.getManyByMenuId(this.buildingId, this.cateringMenuId, CateringMenuItem, filter);

        this.setState({
            loading: false,
            showMenuItems: true,
            menuItemsClassification: classification,
            selectedMenuItems: selectedMenuItems.value,
            selectedMenuItemsForClassification: selectedMenuItems.value.filter(x => x.Classification == classification).map(i => ({ id: i.MenuItem_Id.toString(), primaryText: i.Name, secondaryText: i.Description })),
        });
    }

    private async getAvailableMenuItems(skipToken: string): Promise<InfiniteScrollerResult<ITransferListItem>>
    {
        const filter = new Filter({ classification: this.state.menuItemsClassification, status: CateringMenuItemStatus.Active });
        const menuItems = await this.apiClient.cateringMenuItems.getMany(this.buildingId, 20, skipToken, CateringMenuItem, filter);
        const menuItemsAsTransferList = menuItems.value.map(i => ({ id: i.MenuItem_Id.toString(), primaryText: i.Name, secondaryText: i.Description }));
        const availableItems = this.availableMenuItems(menuItemsAsTransferList, this.state.selectedMenuItems);
        return new InfiniteScrollerResult(availableItems, menuItems.skipToken);
    }

    private cancelMenuItemsClicked(): void
    {
        this.setState({ showMenuItems: false });
    }

    private async saveMenuItemsClicked(): Promise<void>
    {
        try
        {
            if (this.cateringMenuId == null)
            {
                return;
            }

            const list = this.menuItemsRef.current;
            if (list == null)
            {
                return;
            }

            this.setState({ loading: true });
            const selectedIdsForClassification = list.state.right.map(i => i.id).filter(i => i != null) as string[];
            const selectedIdsForOtherClassifications = this.state.selectedMenuItems.filter(x => x != undefined && x.Classification != this.state.menuItemsClassification && !selectedIdsForClassification.includes(x.MenuItem_Id)).map(i => i.MenuItem_Id) as string[];
            const allSelectedMenuItemIds = [...selectedIdsForClassification, ...selectedIdsForOtherClassifications]

            await this.services.cateringMenuItems.setSelected(this.buildingId, this.cateringMenuId, this.state.menuItemsClassification, allSelectedMenuItemIds);
        }
        finally
        {
            await this.setMenuItemSummaries();
            this.setState({ loading: false, showMenuItems: false });
        }
    }

    private async showRolesClicked(): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            return;
        }

        this.setState({ loading: true });
        const selectedRoles = await this.apiClient.roles.getManyByMenuId(this.buildingId, this.cateringMenuId, Role);

        this.setState({
            loading: false,
            showRoles: true,
            selectedRoles: selectedRoles.value.map(i => ({ id: i.Id, primaryText: i.Name, secondaryText: "" })),
            availableRoles: this.availabeRoles(selectedRoles.value),
        });
    }

    private async spacesClicked(): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            return;
        }

        try 
        {
            this.setState({ loading: true });
            const spacesByBuilding = await this.apiClient.cateringSpaces.getManyByMenuId(this.buildingId, this.cateringMenuId);
            this.setState({
                loading: false,
                showSpaces: true,
                selectedSpaces: spacesByBuilding.map(i => ({ id: i.Space_Id, primaryText: i.Space_Id, secondaryText: "" })),
                availableSpaces: this.availabeSpaces(spacesByBuilding),
            });
        }
        catch (error) 
        {
            this.setState({
                loading: false,
                showSpaces: false,
            })
        }
    }

    private cancelRolesClicked(): void
    {
        this.setState({ showRoles: false });
    }

    private cancelSpaceClicked(): void
    {
        this.setState({ showSpaces: false });
    }

    private async saveRolesClicked(): Promise<void>
    {
        try
        {
            if (this.cateringMenuId == null)
            {
                return;
            }

            const list = this.rolesRef.current;
            if (list == null)
            {
                return;
            }

            this.setState({ loading: true });
            const selectedIds = list.state.right.map(i => i.id).filter(i => i != null) as string[];
            await this.services.roles.setSelected(this.buildingId, this.cateringMenuId, selectedIds);
        }
        finally
        {
            await this.setRoleSummaries();
            this.setState({ loading: false, showRoles: false });
        }
    }

    private async saveSpaceClicked(): Promise<void>
    {
        try
        {
            if (this.cateringMenuId == null)
            {
                return;
            }

            const list = this.spaceRef.current;
            if (list == null)
            {
                return;
            }

            const selectedIdsForSpaces = list.state.right.map(i => i.id).filter(i => i != null) as string[];
            const filteredSpace = selectedIdsForSpaces.filter(item => !this.state.selectedSpaces.some(obj => obj.id === item));
            const excluded = this.state.selectedSpaceIds.filter((item) => !selectedIdsForSpaces.includes(item));
            if (filteredSpace.length === 0 && excluded.length === 0)
            {
                return;
            }
            else
            {
                await this.apiClient.cateringSpaces.createDelete(this.buildingId, this.cateringMenuId, filteredSpace, excluded);
            }
        }
        finally
        {
            await this.setSpacesSummaries();
            this.setState({ loading: false, showSpaces: false });
        }
    }

    private async deleteClicked(): Promise<void>
    {
        this.setState({ showConfirmDelete: true });
    }

    private cancelDeleteClicked(): void
    {
        this.setState({ showConfirmDelete: false });
    }

    private async confirmDeleteClicked(): Promise<void>
    {
        if (this.cateringMenuId == null)
        {
            return;
        }

        try
        {
            this.setState({ loading: true });
            await this.apiClient.cateringMenus.delete(this.buildingId, this.cateringMenuId, false);
            this.props.history.push(`/buildings/${this.buildingId}/catering-menus/`);
        }
        finally
        {
            this.setState({ loading: false, showConfirmDelete: false });
        }
    }

    private cancelClicked(): void
    {
        this.props.history.push(`/buildings/${this.buildingId}/catering-menus/`);
    }

    private async saveClicked(): Promise<void>
    {
        const isValid = await this.validateCreate();
        if (!isValid)
        {
            return;
        }

        try
        {
            this.setState({ loading: true });

            if (this.cateringMenuId == null)
            {
                const payload =
                {
                    Node_Id: this.buildingId,
                    Name: this.state.name,
                    Short_Description: this.state.shortDescription,
                    Available_From: this.state.availableFrom.setZoneByNode(this.buildingId),
                    Available_Until: this.state.availableTo.setZoneByNode(this.buildingId),
                    ImageURI: this.state.imageUrl,
                };
                const menuId = await this.apiClient.cateringMenus.create(payload);
                this.props.history.push(`/buildings/${this.buildingId}/catering-menus/${menuId}/edit`);
            }
            else
            {
                const payload =
                {
                    Menu_Id: this.cateringMenuId,
                    Node_Id: this.buildingId,
                    Name: this.state.name,
                    Short_Description: this.state.shortDescription,
                    Available_From: this.state.availableFrom.setZoneByNode(this.buildingId),
                    Available_Until: this.state.availableTo.setZoneByNode(this.buildingId),
                    ImageURI: this.state.imageUrl,
                    Status: this.state.status,
                    ConcurrencyStamp: this.state.concurrencyStamp,
                };

                await this.apiClient.cateringMenus.update(payload);
                this.props.history.push(`/buildings/${this.buildingId}/catering-menus/`);
            }
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                {this.state.loading && <Spinner />}
                <IbssDialog
                    aria-modal="true"
                    aria-label="menu items"
                    fullWidth
                    open={this.state.showMenuItems}
                    onClose={() => this.cancelMenuItemsClicked()}
                    header={this.menuItemsHeader}
                    dialogContent={
                        <>
                            <IbssTransferList
                                ref={this.menuItemsRef}
                                left={[]}
                                getLeft={i => this.getAvailableMenuItems(i)}
                                right={this.state.selectedMenuItemsForClassification}
                                showInfiniteScroller={true}
                            />
                        </>
                    }
                    footer={
                        <>
                            <IbssButton
                                color="secondary"
                                variant="outlined"
                                className="mr-1"
                                onClick={() => this.cancelMenuItemsClicked()}>{this.labels.HubButtonCancel}
                            </IbssButton>
                            <IbssButton
                                color="primary"
                                variant="contained"
                                onClick={() => this.saveMenuItemsClicked()}>{this.labels.HubButtonSave}
                            </IbssButton>
                        </>
                    }
                />

                <IbssDialog
                    aria-modal="true"
                    aria-label="available to these roles"
                    fullWidth
                    open={this.state.showRoles}
                    onClose={() => this.cancelRolesClicked()}
                    header={this.labels.funcCateringMenuEditAvailableToRoles_S}
                    dialogContent={
                        <>
                            <IbssTransferList
                                ref={this.rolesRef}
                                left={this.state.availableRoles}
                                right={this.state.selectedRoles}
                            />
                        </>
                    }
                    footer={
                        <>
                            <IbssButton
                                color="secondary"
                                variant="outlined"
                                className="mr-1"
                                onClick={() => this.cancelRolesClicked()}>{this.labels.HubButtonCancel}
                            </IbssButton>
                            <IbssButton
                                color="primary"
                                variant="contained"
                                onClick={() => this.saveRolesClicked()}>{this.labels.HubButtonSave}
                            </IbssButton>
                        </>
                    }
                />

                <IbssDialog
                    aria-modal="true"
                    aria-label="available to these spaces"
                    fullWidth
                    open={this.state.showSpaces}
                    onClose={() => this.cancelSpaceClicked()}
                    header={this.labels.HubLabelAvailableToSpaces}
                    dialogContent={
                        <>
                            <IbssTransferList
                                ref={this.spaceRef}
                                left={this.state.availableSpaces}
                                right={this.state.selectedSpaces}
                            />
                        </>
                    }
                    footer={
                        <>
                            <IbssButton
                                color="secondary"
                                variant="outlined"
                                className="mr-1"
                                onClick={() => this.cancelRolesClicked()}>{this.labels.HubButtonCancel}
                            </IbssButton>
                            <IbssButton
                                color="primary"
                                variant="contained"
                                onClick={() => this.saveSpaceClicked()}>{this.labels.HubButtonSave}
                            </IbssButton>
                        </>
                    }
                />

                <ConfirmModal
                    show={this.state.showConfirmDelete}
                    modalHeading={this.labels.funcCateringMenuEditDelete_S}
                    modalMessage={this.labels.funcCateringMenuEditConfirmDelete_S}
                    handleModal={() => this.cancelDeleteClicked()}
                    okButton={() => this.confirmDeleteClicked()}
                />

                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content ">
                        <Grid container columnSpacing={{ sm: 2, md: 3 }} sx={{ backgroundColor: "transparent" }}>
                            <Grid item sm={12} md={6} sx={{ backgroundColor: "transparent" }}>
                                <Card style={{ height: this.cardHeight, borderRadius: '8px' }}>
                                    <Box className="m-3">
                                        <FormPanelInfo
                                            formHeading={this.labels.funcCateringMenuEditCateringMenu_S}
                                            iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                                        />
                                        <form>
                                            <Grid container spacing={3} rowSpacing={4}>
                                                {!this.isNew && <Grid item sm={12}>
                                                    <IbssTextField
                                                        label={this.labels.funcCateringMenuEditMenuId_S}
                                                        fullWidth
                                                        value={this.cateringMenuId}
                                                        disabled
                                                    />
                                                </Grid>}
                                                <Grid item sm={12} md={6}>
                                                    <IbssTextField
                                                        label={this.labels.funcCateringMenuEditName_S}
                                                        fullWidth
                                                        value={this.state.name}
                                                        onChange={e => this.nameChanged(e)}
                                                        error={this.state.nameError != ""}
                                                        helperText={this.state.nameError}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} md={6}>
                                                    <IbssTextField
                                                        label={this.labels.funcCateringMenuEditShortDescription_S}
                                                        fullWidth
                                                        value={this.state.shortDescription}
                                                        onChange={e => this.shortDescriptionChanged(e)}
                                                        error={this.state.shortDescriptionError != ""}
                                                        helperText={this.state.shortDescriptionError}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} md={6}>
                                                    <IbssLuxonDateTimePicker
                                                        label={this.labels.funcCateringMenuEditAvailableFrom_S}
                                                        value={this.state.availableFrom}
                                                        onChange={e => this.availableFromChanged(e)}
                                                        input=
                                                        {{
                                                            error: this.state.availableFromError != "",
                                                            helperText: this.state.availableFromError
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} md={6}>
                                                    <IbssLuxonDateTimePicker
                                                        label={this.labels.funcCateringMenuEditAvailableTo_S}
                                                        value={this.state.availableTo}
                                                        onChange={e => this.availableToChanged(e)}
                                                        input=
                                                        {{
                                                            error: this.state.availableToError != "",
                                                            helperText: this.state.availableToError
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} md={6}>
                                                    <IbssInputDropDown
                                                        id="statusDropDown"
                                                        fullWidth
                                                        inputLabel={this.labels.HubLabelStatus}
                                                        options={this.state.statuses}
                                                        value={this.state.status}
                                                        onChange={(e: SelectChangeEvent<string>) => this.setState({ status: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Box>
                                </Card>
                                {!this.isNew &&
                                    <Card className="form-panel" style={{ height: this.cardHeight, overflow: 'auto', borderRadius: '8px', marginTop: '1.5rem' }}>
                                        <Box className="m-3">
                                            <FormPanelInfo
                                                formHeading={this.labels.funcCateringMenuEditOptions_S}
                                                iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                                                style={{ height: "30px" }}
                                            />
                                            <Grid container spacing={3} rowSpacing={4}>
                                                <Grid item sm={12} md={12}>
                                                    <h3>{this.labels.funcCateringMenuEditIncludeBeverages_D}</h3>
                                                    <IbssButton disabled={this.state.loading} color="primary" variant="contained" className="mr-2" onClick={() => this.showMenuItemsClicked(CateringMenuItemClassification.Beverage)}>{this.labels.funcCateringMenuEditSet_S}</IbssButton>
                                                    <span>{this.state.selectedBeveragesSummary}</span>
                                                </Grid>
                                                <Grid item sm={12} md={12}>
                                                    <h3>{this.labels.funcCateringMenuEditIncludeSnacks_D}</h3>
                                                    <IbssButton disabled={this.state.loading} color="primary" variant="contained" className="mr-2" onClick={() => this.showMenuItemsClicked(CateringMenuItemClassification.Snack)}>{this.labels.funcCateringMenuEditSet_S}</IbssButton>
                                                    <span>{this.state.selectedSnacksSummary}</span>
                                                </Grid>
                                                <Grid item sm={12} md={12}>
                                                    <h3>{this.labels.funcCateringMenuEditIncludeFood_D}</h3>
                                                    <IbssButton disabled={this.state.loading} color="primary" variant="contained" className="mr-2" onClick={() => this.showMenuItemsClicked(CateringMenuItemClassification.Food)}>{this.labels.funcCateringMenuEditSet_S}</IbssButton>
                                                    <span>{this.state.selectedFoodSummary}</span>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                }
                            </Grid>
                            <Grid item sm={12} md={6} sx={{ backgroundColor: "transparent" }}>
                                <Card className="form-panel" style={{ height: this.cardHeight, overflow: 'auto', borderRadius: '8px' }}>
                                    <Box className="m-3">
                                        <FormPanelInfo
                                            formHeading={this.labels.funcCateringMenuEditImage_S}
                                            iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                                        />
                                        <form>
                                            <MediaSelector
                                                options={this.state.images.map(i => ({ name: i.name, url: i.path }))}
                                                imageSelected={file => this.setState({ imageUrl: file.url })}
                                                uploadFile={(filename, file, processAsMap) => this.uploadImage(filename, file, processAsMap)}
                                                defaultSelectedImage={this.state.imageUrl}
                                            />
                                        </form>
                                    </Box>
                                </Card>
                                {!this.isNew &&
                                    <Box className="form-panel" mt={3}>
                                        <Grid container spacing={3} rowSpacing={4}>
                                            <Grid item sm={12} md={12}>
                                                <h3>{this.labels.funcCateringMenuEditAvailableToRoles_D}</h3>
                                                <IbssButton disabled={this.state.loading} color="primary" variant="contained" className="mr-2" onClick={() => this.showRolesClicked()}>{this.labels.funcCateringMenuEditSet_S}</IbssButton>
                                                <span>{this.state.selectedRolesSummary}</span>
                                            </Grid>
                                            <Grid item sm={12} md={12}>
                                                <h3>{this.labels.HubLabelAvailableForSpaces}</h3>
                                                <IbssButton disabled={this.state.loading} color="primary" variant="contained" className="mr-2" onClick={() => this.spacesClicked()}>{this.labels.funcCateringMenuEditSet_S}</IbssButton>
                                                <span>{this.state.selectedSpaceSummary}</span>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Grid>
                            <Grid className="form-panel" item sm={12} md={12} sx={{ backgroundColor: "transparent" }}>
                                <Grid container>
                                    <Grid item sm={4} md={6}>
                                        <IbssButton color="error" variant="contained" disabled={!this.isNew && this.local.hasRight("DATAMODEL.CateringMenus.Delete") ? false : true} className="mr-2" onClick={() => this.deleteClicked()}>Delete</IbssButton>
                                    </Grid>
                                    <Grid item sm={8} md={6} textAlign="right">
                                        <IbssButton color="secondary" variant="contained" className="mr-2" onClick={() => this.cancelClicked()}>{this.labels.HubButtonCancel}</IbssButton>
                                        <IbssButton color="primary" variant="contained" className="ml-0" disabled={!(this.local.hasRight("DATAMODEL.CateringMenus.Create") || this.local.hasRight("DATAMODEL.CateringMenus.Update"))} onClick={() => this.saveClicked()}>{this.labels.HubButtonSave}</IbssButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }
}

export interface IProps extends RouteComponentProps<IMatchParams>
{
}

export interface IMatchParams
{
    buildingId: string;
    cateringMenuId?: string;
}

export interface IState
{
    allRoles: DataEntryRole[],
    allSpaces: ICateringSpaces[],
    buildingId: number,
    loading: boolean;
    name: string;
    nameError: string;
    shortDescription: string;
    shortDescriptionError: string;
    availableFrom: DateTime;
    availableFromError: string;
    availableTo: DateTime;
    availableToError: string;
    images: IFile[];
    imageUrl: string;
    statuses: ISelectOption[],
    status: string;
    concurrencyStamp: string;

    showMenuItems: boolean;
    menuItemsClassification: CateringMenuItemClassification;
    selectedMenuItems: CateringMenuItem[],
    selectedMenuItemsForClassification: ITransferListItem[],

    showRoles: boolean;
    showSpaces: boolean;
    selectedRoles: ITransferListItem[],
    availableRoles: ITransferListItem[],
    selectedSpaces: ITransferListItem[],
    availableSpaces: ITransferListItem[],
    selectedSpaceIds: string[],

    showConfirmDelete: boolean;
    selectedBeveragesSummary: string;
    selectedSnacksSummary: string;
    selectedFoodSummary: string;
    selectedRolesSummary: string;
    selectedSpaceSummary: string;
}
