import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';

class SpaceMustBeOnSameFloorModal extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.show}
                onClose={() => this.props.close()}
                header={this.labels.funcSameFloorWarningTitle_S}
                dialogContent=
                {
                    <div>{this.labels.funcSameFloorWarning_Message}</div>
                }
                fullWidth
                footer=
                {
                    <>
                        <IbssButton variant='contained' color='primary' onClick={() => this.props.close()}>{this.labels.HubLabelOk}</IbssButton>
                    </>
                }
            />
        )
    }
}

export default SpaceMustBeOnSameFloorModal;

export interface IProps
{
    show: boolean;
    close: () => void;
}

export interface IState
{
}